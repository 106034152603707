/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobFileSchema } from './JobFileSchema';
import {
    JobFileSchemaFromJSON,
    JobFileSchemaFromJSONTyped,
    JobFileSchemaToJSON,
} from './JobFileSchema';
import type { RunTaskActionWhenDoneEnum } from './RunTaskActionWhenDoneEnum';
import {
    RunTaskActionWhenDoneEnumFromJSON,
    RunTaskActionWhenDoneEnumFromJSONTyped,
    RunTaskActionWhenDoneEnumToJSON,
} from './RunTaskActionWhenDoneEnum';

/**
 * 
 * @export
 * @interface RunTaskSchema
 */
export interface RunTaskSchema {
    /**
     * 
     * @type {number}
     * @memberof RunTaskSchema
     */
    taskId: number;
    /**
     * 
     * @type {number}
     * @memberof RunTaskSchema
     */
    arrayTaskId: number;
    /**
     * 
     * @type {string}
     * @memberof RunTaskSchema
     */
    command: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RunTaskSchema
     */
    envVars?: { [key: string]: string; };
    /**
     * 
     * @type {Array<JobFileSchema>}
     * @memberof RunTaskSchema
     */
    files: Array<JobFileSchema>;
    /**
     * 
     * @type {RunTaskActionWhenDoneEnum}
     * @memberof RunTaskSchema
     */
    doneAction: RunTaskActionWhenDoneEnum;
}

/**
 * Check if a given object implements the RunTaskSchema interface.
 */
export function instanceOfRunTaskSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "arrayTaskId" in value;
    isInstance = isInstance && "command" in value;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "doneAction" in value;

    return isInstance;
}

export function RunTaskSchemaFromJSON(json: any): RunTaskSchema {
    return RunTaskSchemaFromJSONTyped(json, false);
}

export function RunTaskSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunTaskSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': json['task_id'],
        'arrayTaskId': json['array_task_id'],
        'command': json['command'],
        'envVars': !exists(json, 'env_vars') ? undefined : json['env_vars'],
        'files': ((json['files'] as Array<any>).map(JobFileSchemaFromJSON)),
        'doneAction': RunTaskActionWhenDoneEnumFromJSON(json['done_action']),
    };
}

export function RunTaskSchemaToJSON(value?: RunTaskSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_id': value.taskId,
        'array_task_id': value.arrayTaskId,
        'command': value.command,
        'env_vars': value.envVars,
        'files': ((value.files as Array<any>).map(JobFileSchemaToJSON)),
        'done_action': RunTaskActionWhenDoneEnumToJSON(value.doneAction),
    };
}

