/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMiniSchema
 */
export interface UserMiniSchema {
    /**
     * 
     * @type {number}
     * @memberof UserMiniSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMiniSchema
     */
    email: string;
}

/**
 * Check if a given object implements the UserMiniSchema interface.
 */
export function instanceOfUserMiniSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function UserMiniSchemaFromJSON(json: any): UserMiniSchema {
    return UserMiniSchemaFromJSONTyped(json, false);
}

export function UserMiniSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMiniSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'email': json['email'],
    };
}

export function UserMiniSchemaToJSON(value?: UserMiniSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'avatar_url': value.avatarUrl,
        'title': value.title,
        'email': value.email,
    };
}

