/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountScopeSchema } from './AccountScopeSchema';
import {
    AccountScopeSchemaFromJSON,
    AccountScopeSchemaFromJSONTyped,
    AccountScopeSchemaToJSON,
} from './AccountScopeSchema';
import type { CompoundScopeSchema } from './CompoundScopeSchema';
import {
    CompoundScopeSchemaFromJSON,
    CompoundScopeSchemaFromJSONTyped,
    CompoundScopeSchemaToJSON,
} from './CompoundScopeSchema';
import type { OrganizationScopeSchema } from './OrganizationScopeSchema';
import {
    OrganizationScopeSchemaFromJSON,
    OrganizationScopeSchemaFromJSONTyped,
    OrganizationScopeSchemaToJSON,
} from './OrganizationScopeSchema';
import type { StaffScopeSchema } from './StaffScopeSchema';
import {
    StaffScopeSchemaFromJSON,
    StaffScopeSchemaFromJSONTyped,
    StaffScopeSchemaToJSON,
} from './StaffScopeSchema';
import type { UserScopeSchema } from './UserScopeSchema';
import {
    UserScopeSchemaFromJSON,
    UserScopeSchemaFromJSONTyped,
    UserScopeSchemaToJSON,
} from './UserScopeSchema';

/**
 * 
 * @export
 * @interface ValidScopesSchema
 */
export interface ValidScopesSchema {
    /**
     * 
     * @type {Array<AccountScopeSchema>}
     * @memberof ValidScopesSchema
     */
    accounts: Array<AccountScopeSchema>;
    /**
     * 
     * @type {Array<OrganizationScopeSchema>}
     * @memberof ValidScopesSchema
     */
    organizations: Array<OrganizationScopeSchema>;
    /**
     * 
     * @type {Array<UserScopeSchema>}
     * @memberof ValidScopesSchema
     */
    users: Array<UserScopeSchema>;
    /**
     * 
     * @type {Array<CompoundScopeSchema>}
     * @memberof ValidScopesSchema
     */
    compoundScopes: Array<CompoundScopeSchema>;
    /**
     * 
     * @type {Array<StaffScopeSchema>}
     * @memberof ValidScopesSchema
     */
    customStaffScopes: Array<StaffScopeSchema>;
}

/**
 * Check if a given object implements the ValidScopesSchema interface.
 */
export function instanceOfValidScopesSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accounts" in value;
    isInstance = isInstance && "organizations" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "compoundScopes" in value;
    isInstance = isInstance && "customStaffScopes" in value;

    return isInstance;
}

export function ValidScopesSchemaFromJSON(json: any): ValidScopesSchema {
    return ValidScopesSchemaFromJSONTyped(json, false);
}

export function ValidScopesSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidScopesSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': ((json['accounts'] as Array<any>).map(AccountScopeSchemaFromJSON)),
        'organizations': ((json['organizations'] as Array<any>).map(OrganizationScopeSchemaFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserScopeSchemaFromJSON)),
        'compoundScopes': ((json['compound_scopes'] as Array<any>).map(CompoundScopeSchemaFromJSON)),
        'customStaffScopes': ((json['custom_staff_scopes'] as Array<any>).map(StaffScopeSchemaFromJSON)),
    };
}

export function ValidScopesSchemaToJSON(value?: ValidScopesSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': ((value.accounts as Array<any>).map(AccountScopeSchemaToJSON)),
        'organizations': ((value.organizations as Array<any>).map(OrganizationScopeSchemaToJSON)),
        'users': ((value.users as Array<any>).map(UserScopeSchemaToJSON)),
        'compound_scopes': ((value.compoundScopes as Array<any>).map(CompoundScopeSchemaToJSON)),
        'custom_staff_scopes': ((value.customStaffScopes as Array<any>).map(StaffScopeSchemaToJSON)),
    };
}

