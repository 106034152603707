/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingEventKind } from './BillingEventKind';
import {
    BillingEventKindFromJSON,
    BillingEventKindFromJSONTyped,
    BillingEventKindToJSON,
} from './BillingEventKind';

/**
 * 
 * @export
 * @interface BillingEventListSchema
 */
export interface BillingEventListSchema {
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    id: number;
    /**
     * 
     * @type {BillingEventKind}
     * @memberof BillingEventListSchema
     */
    kind: BillingEventKind;
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    organizationId: number;
    /**
     * 
     * @type {Date}
     * @memberof BillingEventListSchema
     */
    eventDatetime: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillingEventListSchema
     */
    eventAddedDatetime: Date;
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    amountCredits?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingEventListSchema
     */
    creator?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEventListSchema
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEventListSchema
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEventListSchema
     */
    organization?: string;
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    clusterId?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    instanceId?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingEventListSchema
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingEventListSchema
     */
    note: string;
}

/**
 * Check if a given object implements the BillingEventListSchema interface.
 */
export function instanceOfBillingEventListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "eventDatetime" in value;
    isInstance = isInstance && "eventAddedDatetime" in value;
    isInstance = isInstance && "note" in value;

    return isInstance;
}

export function BillingEventListSchemaFromJSON(json: any): BillingEventListSchema {
    return BillingEventListSchemaFromJSONTyped(json, false);
}

export function BillingEventListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEventListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': BillingEventKindFromJSON(json['kind']),
        'organizationId': json['organization_id'],
        'eventDatetime': (new Date(json['event_datetime'])),
        'eventAddedDatetime': (new Date(json['event_added_datetime'])),
        'amountCredits': !exists(json, 'amount_credits') ? undefined : json['amount_credits'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'instanceId': !exists(json, 'instance_id') ? undefined : json['instance_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'note': json['note'],
    };
}

export function BillingEventListSchemaToJSON(value?: BillingEventListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'kind': BillingEventKindToJSON(value.kind),
        'organization_id': value.organizationId,
        'event_datetime': (value.eventDatetime.toISOString()),
        'event_added_datetime': (value.eventAddedDatetime.toISOString()),
        'amount_credits': value.amountCredits,
        'creator': value.creator,
        'user': value.user,
        'account': value.account,
        'organization': value.organization,
        'cluster_id': value.clusterId,
        'instance_id': value.instanceId,
        'user_id': value.userId,
        'note': value.note,
    };
}

