import React from "react";
import { Box, Grid, Stack, Link } from "@mui/material";
import { formatSeconds } from "../../../utils";
import { theme } from "../../../theme";
import { ComputationSummary } from "../Analytics/ClusterStatistics";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { useTimezone } from "../../../utils/hooks";
import { formatInTimeZone } from "date-fns-tz";

const TIME_FORMAT = "H:mm:ss";

export const ComputationStatistics = ({
  computation,
}: {
  computation: ComputationSummary;
}): React.ReactElement => {
  const [displayTz] = useTimezone();
  const duration =
    computation.start &&
    computation.stop &&
    computation.start < computation.stop
      ? formatSeconds(
          new Date(computation.stop).getTime() -
            new Date(computation.start).getTime(),
        )
      : "";
  const startString = computation.start
    ? formatInTimeZone(
        new Date(computation.start * 1000),
        displayTz,
        TIME_FORMAT,
      )
    : "";
  const stopString = computation.stop
    ? formatInTimeZone(
        new Date(computation.stop * 1000),
        displayTz,
        TIME_FORMAT,
      )
    : "";
  const stats = [
    [
      "Time Span",
      `${startString}${startString && stopString ? "–" : "—"}${stopString}`,
    ],
    ["Duration", duration],
    [
      "Tasks",
      computation.nTasks
        ? computation.nTasks >= 0
          ? computation.nTasks.toLocaleString()
          : ""
        : 0,
    ],
  ];

  const activityColor = (activity: string) => {
    switch (activity) {
      case "thread-cpu":
        return "#00C49F";

      case "thread-noncpu":
        return "#FFBB28";

      case "executor":
        return "#FF8042";

      case "other":
        return "#0088FE";

      default:
        return "#AAA";
    }
  };

  return (
    <>
      <Stack>
        {stats.map(([label, value]) => (
          <Grid key={label}>
            <Box
              sx={{
                display: "inline-block",
                color: theme.palette.custom.grey.dark,
                fontSize: theme.typography.pxToRem(13),
                textAlign: "right",
                mr: 1,
                minWidth: "8rem",
              }}
            >
              {label}
            </Box>
            <Box
              sx={{
                display: "inline-block",
                color: theme.palette.text.primary,
                fontSize: theme.typography.pxToRem(16),
                fontWeight: "500",
                lineHeight: 1,
              }}
            >
              {value}
            </Box>
          </Grid>
        ))}
      </Stack>

      <PieChart width={240} height={160}>
        <Pie
          data={computation.fineMetricsMinor}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={20}
          outerRadius={40}
          isAnimationActive={false}
        >
          {computation.fineMetricsMinor.map((entry, index) => (
            <Cell
              key={`major-cell-${index}`}
              fill={activityColor(entry.activity)}
            />
          ))}
        </Pie>
        <Pie
          data={computation.fineMetricsMajor}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={44}
          label={(v) => `${v.value.toFixed(2)}s`}
          isAnimationActive={false}
        >
          {computation.fineMetricsMajor.map((entry, index) => (
            <Cell
              key={`minor-cell-${index}`}
              fill={activityColor(entry.activity)}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(v: number) => `${v.toFixed(2)}s`}
          wrapperStyle={{
            outline: "none",
          }}
        />
      </PieChart>
      <Link
        href="https://distributed.dask.org/en/stable/fine-performance-metrics.html"
        target="_blank"
        sx={{ fontSize: "small" }}
      >
        Fine Performance Metrics docs
      </Link>
    </>
  );
};
