import React, { createContext, ReactElement, ReactNode } from "react";
import { WorkspaceSchema } from "../api-client";

export const WorkspaceContext = createContext<WorkspaceSchema>({
  id: 0,
  active: false,
  creditLimit: 0,
  earlyAdopter: false,
  configuredCustomerHosted: false,
  canUseCoiledHosted: false,
  setupComplete: false,
  slug: "",
  name: "",
  avatarUrl: "",
});

type WorkspaceContextProviderProps = {
  workspace: WorkspaceSchema;
  children: ReactNode;
};

export const WorkspaceContextProvider = ({
  workspace,
  children,
}: WorkspaceContextProviderProps): ReactElement => {
  return (
    <WorkspaceContext.Provider value={{ ...workspace }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
