/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateWorkspaceInviteSchema
 */
export interface UpdateWorkspaceInviteSchema {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkspaceInviteSchema
     */
    isAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteSchema
     */
    coreLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteSchema
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteSchema
     */
    workspaceId: number;
}

/**
 * Check if a given object implements the UpdateWorkspaceInviteSchema interface.
 */
export function instanceOfUpdateWorkspaceInviteSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "coreLimit" in value;
    isInstance = isInstance && "workspaceId" in value;

    return isInstance;
}

export function UpdateWorkspaceInviteSchemaFromJSON(json: any): UpdateWorkspaceInviteSchema {
    return UpdateWorkspaceInviteSchemaFromJSONTyped(json, false);
}

export function UpdateWorkspaceInviteSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkspaceInviteSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAdmin': json['is_admin'],
        'creditLimit': json['credit_limit'],
        'coreLimit': json['core_limit'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'workspaceId': json['workspace_id'],
    };
}

export function UpdateWorkspaceInviteSchemaToJSON(value?: UpdateWorkspaceInviteSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_admin': value.isAdmin,
        'credit_limit': value.creditLimit,
        'core_limit': value.coreLimit,
        'id': value.id,
        'workspace_id': value.workspaceId,
    };
}

