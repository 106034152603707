export enum BackendTypeServer {
  ECS = "ecs",
  VM = "vm",
  AWS_HOSTED = "vm_aws",
  GCP_HOSTED = "vm_gcp",
  AZURE_HOSTED = "vm_azure",
}

export enum AUTH_TYPE {
  ROLE_AUTH = "role",
  KEY_AUTH = "key",
}

export type AWSCredentials = {
  type: AUTH_TYPE;
  accessKeyId?: string;
  secretAccessKey?: string;
  roleArn?: string;
  externalId?: string;
};

type GCPServiceAccount = {
  type?: string;
  clientId?: string;
  projectId?: string;
  privateKeyId?: string;
  privateKey?: string;
  authUri?: string;
  tokenUri?: string;
  clientEmail?: string;
  authProviderX509CertUrl?: string;
  clientX509CertUrl?: string;
};
export type GCPCredentials = {
  credentials?: GCPServiceAccount;
  instanceServiceAccount?: string;
};
