/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SoftwareEnvironmentSpecLightSchema } from './SoftwareEnvironmentSpecLightSchema';
import {
    SoftwareEnvironmentSpecLightSchemaFromJSON,
    SoftwareEnvironmentSpecLightSchemaFromJSONTyped,
    SoftwareEnvironmentSpecLightSchemaToJSON,
} from './SoftwareEnvironmentSpecLightSchema';

/**
 * 
 * @export
 * @interface SoftwareEnvironmentAliasListSchema
 */
export interface SoftwareEnvironmentAliasListSchema {
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    specCount: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    accountSlug: string;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    organization?: string;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    organizationId?: number;
    /**
     * 
     * @type {SoftwareEnvironmentSpecLightSchema}
     * @memberof SoftwareEnvironmentAliasListSchema
     */
    latestSpec: SoftwareEnvironmentSpecLightSchema;
}

/**
 * Check if a given object implements the SoftwareEnvironmentAliasListSchema interface.
 */
export function instanceOfSoftwareEnvironmentAliasListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "specCount" in value;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "accountSlug" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "latestSpec" in value;

    return isInstance;
}

export function SoftwareEnvironmentAliasListSchemaFromJSON(json: any): SoftwareEnvironmentAliasListSchema {
    return SoftwareEnvironmentAliasListSchemaFromJSONTyped(json, false);
}

export function SoftwareEnvironmentAliasListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentAliasListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'name': json['name'],
        'specCount': json['spec_count'],
        'account': json['account'],
        'accountSlug': json['account_slug'],
        'accountId': json['account_id'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'latestSpec': SoftwareEnvironmentSpecLightSchemaFromJSON(json['latest_spec']),
    };
}

export function SoftwareEnvironmentAliasListSchemaToJSON(value?: SoftwareEnvironmentAliasListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'name': value.name,
        'spec_count': value.specCount,
        'account': value.account,
        'account_slug': value.accountSlug,
        'account_id': value.accountId,
        'organization': value.organization,
        'organization_id': value.organizationId,
        'latest_spec': SoftwareEnvironmentSpecLightSchemaToJSON(value.latestSpec),
    };
}

