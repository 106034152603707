import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import {
  getInteractions,
  postHasSeenInteraction,
  getInteractionFlag,
  putInteractionFlag,
  getInteractionFlags,
} from "./fetch";
import {
  UserInteractions,
  InteractionType,
  InteractionFlag,
  InteractionFlagNames,
} from "./types";
import { ApiClient } from "../../apiUtils";
import {
  InteractionsViewsGetUserInteractionsRequest,
  PagedUserInteractionListSchema,
} from "../../api-client";
const KEY = "interactionFlag";

export const useInteractions = (): UseQueryResult<UserInteractions> => {
  return useQuery("getInteractions", () => getInteractions());
};

export const useHasSeenInteractionMutation = (): UseMutationResult<
  Response,
  unknown,
  Omit<InteractionType, InteractionType.Unsubscribed>,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (interaction: Omit<InteractionType, InteractionType.Unsubscribed>) => {
      return postHasSeenInteraction(interaction);
    },
    {
      onSuccess: () => queryClient.invalidateQueries("getInteractions"),
    },
  );
};

export const useInteractionFlag = (
  flagName: InteractionFlagNames,
): UseQueryResult<InteractionFlag> => {
  return useQuery([KEY, flagName], () => getInteractionFlag(flagName));
};

export const useInteractionFlags = <K extends InteractionFlagNames>(
  flagNames: K[],
): UseQueryResult<{ [key in K]: InteractionFlag }> => {
  return useQuery([KEY, flagNames], () => getInteractionFlags(flagNames), {
    refetchInterval: 10000,
  });
};

export const useCompleteInteractionFlagMutation = (
  flagName: InteractionFlagNames,
): UseMutationResult<InteractionFlag> => {
  const queryClient = useQueryClient();

  return useMutation(() => putInteractionFlag(flagName, /* complete= */ true), {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY, flagName]);
    },
  });
};

export const useUserInteractions = (
  filters: InteractionsViewsGetUserInteractionsRequest,
  // seconds
  refetchInterval: number = 10,
): UseQueryResult<
  PagedUserInteractionListSchema,
  InteractionsViewsGetUserInteractionsRequest
> => {
  const result = useQuery<
    PagedUserInteractionListSchema,
    InteractionsViewsGetUserInteractionsRequest
  >(
    ["userInteractions", filters],
    () => ApiClient.interactionsViewsGetUserInteractions(filters),
    {
      refetchInterval: refetchInterval * 1000, // convert to milliseconds,
    },
  );
  return result;
};
