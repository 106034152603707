/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DockerNetworkVolume
 */
export interface DockerNetworkVolume {
    /**
     * 
     * @type {string}
     * @memberof DockerNetworkVolume
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DockerNetworkVolume
     */
    options: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof DockerNetworkVolume
     */
    workersOnly?: boolean;
}

/**
 * Check if a given object implements the DockerNetworkVolume interface.
 */
export function instanceOfDockerNetworkVolume(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "options" in value;

    return isInstance;
}

export function DockerNetworkVolumeFromJSON(json: any): DockerNetworkVolume {
    return DockerNetworkVolumeFromJSONTyped(json, false);
}

export function DockerNetworkVolumeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerNetworkVolume {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'options': json['options'],
        'workersOnly': !exists(json, 'workers_only') ? undefined : json['workers_only'],
    };
}

export function DockerNetworkVolumeToJSON(value?: DockerNetworkVolume | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'options': value.options,
        'workers_only': value.workersOnly,
    };
}

