/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Package mismatch severity level
 * Using a high int so we have room to add extra levels as needed
 * 
 * Ordering is allow comparison like
 * 
 * if somelevel >= PackageLevelEnum.STRICT_MATCH:
 *     <some logic for high or critical levels>
 * @export
 */
export const PackageLevelEnum = {
    NUMBER_100: 100,
    NUMBER_75: 75,
    NUMBER_50: 50,
    NUMBER_0: 0,
    NUMBER_MINUS_1: -1,
    NUMBER_MINUS_2: -2,
    NUMBER_MINUS_3: -3
} as const;
export type PackageLevelEnum = typeof PackageLevelEnum[keyof typeof PackageLevelEnum];


export function PackageLevelEnumFromJSON(json: any): PackageLevelEnum {
    return PackageLevelEnumFromJSONTyped(json, false);
}

export function PackageLevelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageLevelEnum {
    return json as PackageLevelEnum;
}

export function PackageLevelEnumToJSON(value?: PackageLevelEnum | null): any {
    return value as any;
}

