/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackendTypesEnum } from './BackendTypesEnum';
import {
    BackendTypesEnumFromJSON,
    BackendTypesEnumFromJSONTyped,
    BackendTypesEnumToJSON,
} from './BackendTypesEnum';

/**
 * 
 * @export
 * @interface SoftwareEnvBuildLight
 */
export interface SoftwareEnvBuildLight {
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvBuildLight
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvBuildLight
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvBuildLight
     */
    updated: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvBuildLight
     */
    built: boolean;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvBuildLight
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvBuildLight
     */
    sizeBytes?: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvBuildLight
     */
    reason: string;
    /**
     * 
     * @type {BackendTypesEnum}
     * @memberof SoftwareEnvBuildLight
     */
    backendType: BackendTypesEnum;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvBuildLight
     */
    specId: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvBuildLight
     */
    regionName: string;
}

/**
 * Check if a given object implements the SoftwareEnvBuildLight interface.
 */
export function instanceOfSoftwareEnvBuildLight(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "built" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "backendType" in value;
    isInstance = isInstance && "specId" in value;
    isInstance = isInstance && "regionName" in value;

    return isInstance;
}

export function SoftwareEnvBuildLightFromJSON(json: any): SoftwareEnvBuildLight {
    return SoftwareEnvBuildLightFromJSONTyped(json, false);
}

export function SoftwareEnvBuildLightFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvBuildLight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'built': json['built'],
        'state': json['state'],
        'sizeBytes': !exists(json, 'size_bytes') ? undefined : json['size_bytes'],
        'reason': json['reason'],
        'backendType': BackendTypesEnumFromJSON(json['backend_type']),
        'specId': json['spec_id'],
        'regionName': json['region_name'],
    };
}

export function SoftwareEnvBuildLightToJSON(value?: SoftwareEnvBuildLight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'built': value.built,
        'state': value.state,
        'size_bytes': value.sizeBytes,
        'reason': value.reason,
        'backend_type': BackendTypesEnumToJSON(value.backendType),
        'spec_id': value.specId,
        'region_name': value.regionName,
    };
}

