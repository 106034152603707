import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";

type ControlledSwitchProps<TFieldValues extends FieldValues = any> =
  SwitchProps & {
    control: Control<TFieldValues>;
    name: string;
    label?: string | React.ReactNode;
    row?: boolean;
  };

export const HookSwitch = React.forwardRef<
  HTMLButtonElement,
  ControlledSwitchProps
>(
  (
    { control, name, label, ...props }: ControlledSwitchProps,
    ref,
  ): React.ReactElement => {
    return label ? (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <FormControlLabel
              label={label}
              control={
                <Switch
                  {...field}
                  {...props}
                  value={field.value}
                  checked={field.value}
                  defaultValue={field.value}
                  ref={ref}
                />
              }
            />
          );
        }}
      />
    ) : (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Switch
              {...field}
              {...props}
              value={field.value}
              checked={field.value}
              ref={ref}
            />
          );
        }}
      />
    );
  },
);
