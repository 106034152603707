/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentStateEnum } from './ComponentStateEnum';
import {
    ComponentStateEnumFromJSON,
    ComponentStateEnumFromJSONTyped,
    ComponentStateEnumToJSON,
} from './ComponentStateEnum';

/**
 * 
 * @export
 * @interface ComponentSchema
 */
export interface ComponentSchema {
    /**
     * 
     * @type {number}
     * @memberof ComponentSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ComponentSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComponentSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentStateEnum}
     * @memberof ComponentSchema
     */
    state: ComponentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ComponentSchema
     */
    reason: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentSchema
     */
    destroyDesired: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComponentSchema
     */
    destroyReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof ComponentSchema
     */
    destroyRequestedDt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ComponentSchema
     */
    retries: number;
    /**
     * 
     * @type {string}
     * @memberof ComponentSchema
     */
    retryReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentSchema
     */
    coiledManaged: boolean;
}

/**
 * Check if a given object implements the ComponentSchema interface.
 */
export function instanceOfComponentSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "destroyDesired" in value;
    isInstance = isInstance && "retries" in value;
    isInstance = isInstance && "coiledManaged" in value;

    return isInstance;
}

export function ComponentSchemaFromJSON(json: any): ComponentSchema {
    return ComponentSchemaFromJSONTyped(json, false);
}

export function ComponentSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'state': ComponentStateEnumFromJSON(json['state']),
        'reason': json['reason'],
        'destroyDesired': json['destroy_desired'],
        'destroyReason': !exists(json, 'destroy_reason') ? undefined : json['destroy_reason'],
        'destroyRequestedDt': !exists(json, 'destroy_requested_dt') ? undefined : (new Date(json['destroy_requested_dt'])),
        'retries': json['retries'],
        'retryReason': !exists(json, 'retry_reason') ? undefined : json['retry_reason'],
        'coiledManaged': json['coiled_managed'],
    };
}

export function ComponentSchemaToJSON(value?: ComponentSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'state': ComponentStateEnumToJSON(value.state),
        'reason': value.reason,
        'destroy_desired': value.destroyDesired,
        'destroy_reason': value.destroyReason,
        'destroy_requested_dt': value.destroyRequestedDt === undefined ? undefined : (value.destroyRequestedDt.toISOString()),
        'retries': value.retries,
        'retry_reason': value.retryReason,
        'coiled_managed': value.coiledManaged,
    };
}

