/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClusterInfraStateEnum } from './ClusterInfraStateEnum';
import {
    ClusterInfraStateEnumFromJSON,
    ClusterInfraStateEnumFromJSONTyped,
    ClusterInfraStateEnumToJSON,
} from './ClusterInfraStateEnum';
import type { ClusterStateEnum } from './ClusterStateEnum';
import {
    ClusterStateEnumFromJSON,
    ClusterStateEnumFromJSONTyped,
    ClusterStateEnumToJSON,
} from './ClusterStateEnum';
import type { InstanceStateEnum } from './InstanceStateEnum';
import {
    InstanceStateEnumFromJSON,
    InstanceStateEnumFromJSONTyped,
    InstanceStateEnumToJSON,
} from './InstanceStateEnum';
import type { ProcessStateEnum } from './ProcessStateEnum';
import {
    ProcessStateEnumFromJSON,
    ProcessStateEnumFromJSONTyped,
    ProcessStateEnumToJSON,
} from './ProcessStateEnum';

/**
 * 
 * @export
 * @interface State
 */
export interface State {
}

/**
 * Check if a given object implements the State interface.
 */
export function instanceOfState(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StateFromJSON(json: any): State {
    return StateFromJSONTyped(json, false);
}

export function StateFromJSONTyped(json: any, ignoreDiscriminator: boolean): State {
    return json;
}

export function StateToJSON(value?: State | null): any {
    return value;
}

