/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrometheusReadCredSchema
 */
export interface PrometheusReadCredSchema {
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    endpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    authType?: string;
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    sigv4Key?: string;
    /**
     * 
     * @type {string}
     * @memberof PrometheusReadCredSchema
     */
    sigv4Secret?: string;
}

/**
 * Check if a given object implements the PrometheusReadCredSchema interface.
 */
export function instanceOfPrometheusReadCredSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrometheusReadCredSchemaFromJSON(json: any): PrometheusReadCredSchema {
    return PrometheusReadCredSchemaFromJSONTyped(json, false);
}

export function PrometheusReadCredSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrometheusReadCredSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'authType': !exists(json, 'auth_type') ? undefined : json['auth_type'],
        'sigv4Key': !exists(json, 'sigv4_key') ? undefined : json['sigv4_key'],
        'sigv4Secret': !exists(json, 'sigv4_secret') ? undefined : json['sigv4_secret'],
    };
}

export function PrometheusReadCredSchemaToJSON(value?: PrometheusReadCredSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'endpoint': value.endpoint,
        'region': value.region,
        'auth_type': value.authType,
        'sigv4_key': value.sigv4Key,
        'sigv4_secret': value.sigv4Secret,
    };
}

