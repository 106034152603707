import React from "react";
import {
  FieldPath,
  SubmitHandler,
  UseFormProps,
  useForm,
} from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import { Stack } from "@mui/material";

type Props<TFormShape extends FieldValues> = {
  formActive: boolean;
  submitting: boolean;
  error: boolean;
  errorMessage: string;
  formOptions: UseFormProps<TFormShape>;
  fieldName: FieldPath<TFormShape>;
  value: string;
  inputType?: string;
  handleCancel: () => void;
  handleSubmit: SubmitHandler<TFormShape>;
};

export const SingleLineForm = <TFormShape extends Record<string, unknown>>({
  formActive,
  submitting,
  error,
  errorMessage = "",
  formOptions,
  fieldName,
  value,
  inputType = "text",
  handleCancel,
  handleSubmit,
}: Props<TFormShape>): React.ReactElement => {
  const {
    register,
    formState: { errors: formErrors },
    handleSubmit: handleSubmitWrapper,
    setValue,
    clearErrors,
  } = useForm<TFormShape>(formOptions);

  const formRef = React.useRef<HTMLFormElement>(null);

  const handleCancelButtonClick = () => {
    // @ts-ignore  I've had enough fun with generics today
    setValue(fieldName, value);
    clearErrors(fieldName);
    handleCancel();
  };

  // @ts-ignore
  error = error || Boolean(formErrors[fieldName]);
  // @ts-ignore
  if (formErrors[fieldName]) {
    errorMessage = "This field can't be blank";
  }
  return (
    <form ref={formRef} onSubmit={handleSubmitWrapper(handleSubmit)}>
      <Stack spacing={1}>
        <TextField
          {...register(fieldName, { required: true })}
          error={error}
          type={inputType}
          defaultValue={value}
          disabled={!formActive}
          helperText={errorMessage || undefined}
        >
          {value}
        </TextField>
        {formActive && (
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleCancelButtonClick}
              disabled={false}
              size="small"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={false} size="small">
              {submitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Stack>
        )}
      </Stack>
    </form>
  );
};
