import React, { useContext, useEffect } from "react";
import { initialize as initializeAnalytics, analytics } from "./analytics";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider, IconButton } from "@mui/material";

import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import {
  LoginForm,
  LoginRequiredRoute,
  SignUpForm,
  VerifyEmail,
  ForgotUsername,
} from "./auth";
import { HandleProviderResponse } from "./auth/HandleProviderResponse";
import { HandleWorkOSLogin } from "./auth/HandleWorkOS";
import { theme } from "./theme";
import { Urls } from "./domain/urls";
import { MainLayout, MinimalLayout } from "./Layouts";
import { ClustersRouter } from "./pages/Clusters";
import { Profile } from "./pages/Profile";
import { Software } from "./pages/Software";
import { ErrorBoundary } from "./ErrorBoundary";
import { BillingRouter } from "./pages/Billing";
import { UserInfoPopup } from "./pages/UserInfoPopup";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { GoCoiledPro } from "./pages/Marketing/GoCoiledPro";
import { StaffRouter } from "./pages/Staff";
import { FE_USER_INFO_FORM_ENABLED } from "./auth/LoginForm";
import { AnalyticsProvider } from "use-analytics";
import { SetupRouter } from "./pages/Setup";
import { PageProvider } from "./shared-components/Page";
import { LoadingScreen } from "./shared-components/LoadingScreen";
import { GetStartedRouter } from "./pages/GetStarted";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LicenseInfo } from "@mui/x-license-pro";
import { useCookie } from "./utils/hooks";
import { AuthenticationContextProvider } from "./crud/auth/context";
import { AppConfigContextProvider } from "./crud/config/context";
import { AUTH_COOKIE, UPGRADE_COOKIE } from "./cookieUtils";
import { useConfig } from "./crud/config/hooks";
import { QueryParamProvider } from "use-query-params";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
// @ts-ignore
import bash from "highlight.js/lib/languages/bash";
// @ts-ignore
import shell from "highlight.js/lib/languages/shell";

// @ts-ignore
import python from "highlight.js/lib/languages/python";
// @ts-ignore
import yaml from "highlight.js/lib/languages/yaml";
import { ActivateToken } from "./pages/ActivateToken/ActivateToken";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { InteractionFlagsContext } from "./crud/interactions/context";
import { MainLayoutContextProvider } from "./Layouts/context";
import { ComponentSandbox } from "./pages/ComponentSandbox";
import { UsageRouter } from "./pages/Usage";
import { Team } from "./pages/Team";
import { TagAlertPreview } from "./pages/Staff/TagAlertPreview";

SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("yaml", yaml);
SyntaxHighlighter.registerLanguage("shell", shell);

// setting notifyOnChangeProps prevents re-renders caused
// by changes to query results that the component does not even use
const queryClient = new QueryClient({
  defaultOptions: { queries: { notifyOnChangeProps: "tracked" } },
});

// Key is designed to be public info
// https://mui.com/x/introduction/licensing/#security
LicenseInfo.setLicenseKey(
  "354b4261db86f51a1acc1bfe174abf99Tz03OTgwOCxFPTE3MzMwNjU0MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const LegacyClusterDetailsRedirect = (): React.ReactElement => {
  const { account, clusterId } = useParams();
  return (
    <Navigate
      to={`/clusters/${clusterId}/infrastructure?account=${account}`}
      replace
    />
  );
};

const LegacySetupRedirect = () => {
  const { account } = useParams();
  return (
    <Navigate to={`/settings/setup/credentials?account=${account}`} replace />
  );
};
const LegacyAccountRedirect = () => {
  const { account } = useParams();
  return <Navigate to={`/settings/setup?account=${account}`} replace />;
};

const WorkOSLoginRedirect = () => {
  window.location.href = "/api/v2/workos/login";
  return <></>;
};

const MainRoutes = () => {
  const navigate = useNavigate();
  const [upgradeCookie, setUpgradeCookie] = useCookie(UPGRADE_COOKIE);
  const {
    "has-created-at-least-three-clusters": {
      completed: hasCreatedThreeClusters,
    },
  } = useContext(InteractionFlagsContext);

  // default to clusters unless you don't have permission, then show software
  const accountHomeUrl = !hasCreatedThreeClusters
    ? `/${Urls.GetStarted}`
    : `/${Urls.Clusters}`;
  // if user has visited the page to upgrade and then signed in
  if (upgradeCookie) {
    setUpgradeCookie(undefined);
    navigate(`${Urls.Billing}`, { replace: true });
  }
  const location = useLocation();
  return (
    <Routes>
      <Route
        path={`account`}
        element={
          <Navigate
            to={`/${Urls.Setup}/${Urls.Credentials}` + location.search}
            replace
          />
        }
      />
      <Route path={`${Urls.Software}/*`} element={<Software />} />
      <Route path={`${Urls.Team}/*`} element={<Team />} />
      <Route path={`${Urls.Clusters}/*`} element={<ClustersRouter />} />
      <Route path={`${Urls.Billing}/*`} element={<BillingRouter />} />
      <Route path={`${Urls.Usage}/*`} element={<UsageRouter />} />
      <Route path={`${Urls.Setup}/*`} element={<SetupRouter />} />
      <Route path="*" element={<Navigate to={`${accountHomeUrl}`} replace />} />
    </Routes>
  );
};

const CoiledLayout = (): React.ReactElement => {
  const { isSuccess, data: config } = useConfig();
  return (
    <>
      <QueryParamProvider
        // Avoiding using the react router adapter until
        // https://github.com/pbeshai/use-query-params/issues/286 is resolved
        adapter={ReactRouter6Adapter}
        options={{
          enableBatching: true,
        }}
      >
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          action={(snackbarId) => (
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => closeSnackbar(snackbarId)}
            >
              <Close />
            </IconButton>
          )}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <PageProvider>
                  <AnalyticsProvider instance={analytics}>
                    <CssBaseline />
                    {isSuccess ? (
                      <AppConfigContextProvider config={config}>
                        <Outlet />
                      </AppConfigContextProvider>
                    ) : (
                      <LoadingScreen />
                    )}
                  </AnalyticsProvider>
                </PageProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </SnackbarProvider>
      </QueryParamProvider>
    </>
  );
};
export const App = (): React.ReactElement => {
  const [authToken, setToken] = useCookie(AUTH_COOKIE, undefined);
  useEffect(initializeAnalytics, []);
  const browserRoute = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<CoiledLayout />}>
        <Route path={"/component-sandbox"} element={<ComponentSandbox />} />
        <Route
          path={"/tag-alert-content-preview"}
          element={<TagAlertPreview />}
        />
        <Route
          path={"/:account/clusters/:clusterId/details"}
          element={<LegacyClusterDetailsRedirect />}
        />
        <Route
          path={"/:account/clusters/:clusterId/infrastructure"}
          element={<LegacyClusterDetailsRedirect />}
        />
        <Route
          path={"/:account/settings/setup/update-backend-options"}
          element={<LegacySetupRedirect />}
        />
        <Route path={"/:account/account"} element={<LegacyAccountRedirect />} />
        {/* hacky way to get workos login link */}
        <Route path={"/workos"} element={<WorkOSLoginRedirect />} />
        <Route path="/users/workos/login" element={<HandleWorkOSLogin />} />
        <Route
          path={Urls.GithubLogin}
          element={
            <HandleProviderResponse provider="github" displayName="GitHub" />
          }
        />
        <Route
          path={Urls.GoogleLogin}
          element={
            <HandleProviderResponse provider="google" displayName="Google" />
          }
        />
        <Route path={Urls.SignUp} element={<SignUpForm />} />
        <Route path={`${Urls.Login}/*`} element={<LoginForm />} />
        <Route path={Urls.RetrieveUsername} element={<ForgotUsername />} />
        <Route path={Urls.ForgotPassword} element={<ForgotPassword />} />
        <Route
          path={`${Urls.ResetPassword}/key/:uid/:token`}
          element={<ResetPassword />}
        />
        <Route path={Urls.VerifyEmailSent} element={<VerifyEmail />} />
        <Route path={Urls.VerifyEmailReSend} element={<VerifyEmail />} />
        <Route
          path={`${Urls.VerifyEmailConfirm}/:key`}
          element={<VerifyEmail />}
        />
        <Route path={`${Urls.CoiledProPage}`} element={<GoCoiledPro />} />
        {FE_USER_INFO_FORM_ENABLED ? (
          <Route
            path={Urls.UserInfoForm}
            element={
              <LoginRequiredRoute path={Urls.UserInfoForm}>
                <MinimalLayout>
                  <UserInfoPopup />
                </MinimalLayout>
              </LoginRequiredRoute>
            }
          />
        ) : null}
        <Route
          path="*"
          element={
            <LoginRequiredRoute>
              <MainLayoutContextProvider>
                <Routes>
                  <Route
                    path={Urls.ActivateToken}
                    element={<ActivateToken />}
                  />
                  <Route
                    path="*"
                    element={
                      <MainLayout>
                        <Routes>
                          <Route path={Urls.Profile} element={<Profile />} />
                          <Route
                            path={Urls.ActivateToken}
                            element={<ActivateToken />}
                          />
                          <Route
                            path={Urls.GetStarted + "/*"}
                            element={<GetStartedRouter />}
                          />
                          <Route
                            path={Urls.CoiledStaff + "/*"}
                            element={
                              <LoginRequiredRoute staffRequired>
                                <StaffRouter />
                              </LoginRequiredRoute>
                            }
                          />
                          <Route path="*" element={<MainRoutes />} />
                        </Routes>
                      </MainLayout>
                    }
                  />
                </Routes>
              </MainLayoutContextProvider>
            </LoginRequiredRoute>
          }
        />
      </Route>,
    ),
  );
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <AuthenticationContextProvider token={authToken} setToken={setToken}>
          <RouterProvider router={browserRoute} />
        </AuthenticationContextProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};
