/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GCPNetworkCreateSchema } from './GCPNetworkCreateSchema';
import {
    GCPNetworkCreateSchemaFromJSON,
    GCPNetworkCreateSchemaFromJSONTyped,
    GCPNetworkCreateSchemaToJSON,
} from './GCPNetworkCreateSchema';

/**
 * 
 * @export
 * @interface GCPAccountInfraCreateSchema
 */
export interface GCPAccountInfraCreateSchema {
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountInfraCreateSchema
     */
    managed: boolean;
    /**
     * 
     * @type {GCPNetworkCreateSchema}
     * @memberof GCPAccountInfraCreateSchema
     */
    network?: GCPNetworkCreateSchema;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPAccountInfraCreateSchema
     */
    schedulerNetworkTags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPAccountInfraCreateSchema
     */
    clusterNetworkTags?: Array<string>;
}

/**
 * Check if a given object implements the GCPAccountInfraCreateSchema interface.
 */
export function instanceOfGCPAccountInfraCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "managed" in value;

    return isInstance;
}

export function GCPAccountInfraCreateSchemaFromJSON(json: any): GCPAccountInfraCreateSchema {
    return GCPAccountInfraCreateSchemaFromJSONTyped(json, false);
}

export function GCPAccountInfraCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPAccountInfraCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managed': json['managed'],
        'network': !exists(json, 'network') ? undefined : GCPNetworkCreateSchemaFromJSON(json['network']),
        'schedulerNetworkTags': !exists(json, 'scheduler_network_tags') ? undefined : json['scheduler_network_tags'],
        'clusterNetworkTags': !exists(json, 'cluster_network_tags') ? undefined : json['cluster_network_tags'],
    };
}

export function GCPAccountInfraCreateSchemaToJSON(value?: GCPAccountInfraCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managed': value.managed,
        'network': GCPNetworkCreateSchemaToJSON(value.network),
        'scheduler_network_tags': value.schedulerNetworkTags,
        'cluster_network_tags': value.clusterNetworkTags,
    };
}

