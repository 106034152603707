/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LogLine } from './LogLine';
import {
    LogLineFromJSON,
    LogLineFromJSONTyped,
    LogLineToJSON,
} from './LogLine';

/**
 * 
 * @export
 * @interface SoftwareEnvironmentBuildLogs
 */
export interface SoftwareEnvironmentBuildLogs {
    /**
     * 
     * @type {Array<LogLine>}
     * @memberof SoftwareEnvironmentBuildLogs
     */
    events: Array<LogLine>;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentBuildLogs
     */
    nextToken?: string;
}

/**
 * Check if a given object implements the SoftwareEnvironmentBuildLogs interface.
 */
export function instanceOfSoftwareEnvironmentBuildLogs(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function SoftwareEnvironmentBuildLogsFromJSON(json: any): SoftwareEnvironmentBuildLogs {
    return SoftwareEnvironmentBuildLogsFromJSONTyped(json, false);
}

export function SoftwareEnvironmentBuildLogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentBuildLogs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(LogLineFromJSON)),
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
    };
}

export function SoftwareEnvironmentBuildLogsToJSON(value?: SoftwareEnvironmentBuildLogs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': ((value.events as Array<any>).map(LogLineToJSON)),
        'nextToken': value.nextToken,
    };
}

