import React, { ReactElement } from "react";
import { LinearProgress } from "@mui/material";
import { CoiledLogo } from "../icons/CoiledLogoMark";
import { Container, Stack } from "@mui/system";

export const LoadingScreen = (): ReactElement => {
  return (
    <Container>
      <Stack
        sx={{ height: "100vh" }}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <CoiledLogo
          sx={(theme) => ({
            height: "150px",
            width: "auto",
            margin: "16px 0 16px 0",
            color: theme.palette.primary.main,
          })}
        />
        <LinearProgress sx={{ height: "15px", width: "50%" }} />
      </Stack>
    </Container>
  );
};
