/* eslint-disable @typescript-eslint/naming-convention */
import { ApiClient } from "../../apiUtils";
import {
  PagedSoftwareEnvironmentSpecListSchema,
  SoftwareEnvBuildLight,
  SoftwareEnvironmentAliasSchema,
  SoftwareEnvironmentBuildLogs,
  SoftwareEnvironmentBuildSchema,
  SoftwareEnvironmentSpecSchema,
} from "../../api-client";

export const getClusterSoftwareBuild = async (
  accountSlug: string,
  senvBuildId: number,
  signal?: AbortSignal,
): Promise<SoftwareEnvironmentBuildSchema> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuild(
    {
      account: accountSlug,
      id: senvBuildId,
    },
    {
      signal,
    },
  );
};

export const getSoftwareSpec = async (
  accountSlug: string,
  specId: number,
  signal?: AbortSignal,
): Promise<SoftwareEnvironmentSpecSchema> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpec(
    {
      account: accountSlug,
      pk: specId,
    },
    {
      signal,
    },
  );
};

export const getSoftwareAliasBuilds = async (
  accountSlug: string,
  aliasId: number,
  signal?: AbortSignal,
): Promise<SoftwareEnvBuildLight[]> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentListSoftwareAliasBuilds(
    {
      account: accountSlug,
      id: aliasId,
    },
    {
      signal,
    },
  );
};

export const getSoftwareEnvironmentBuildLogs = async (
  accountSlug: string,
  senvBuildId: number,
  pageToken?: string,
  signal?: AbortSignal,
): Promise<SoftwareEnvironmentBuildLogs> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentGetSoftwareEnvBuildLogs(
    {
      account: accountSlug,
      id: senvBuildId,
      pageToken,
    },
    { signal },
  );
};

export const fetchSoftwareAliasSpecs = async (
  accountSlug: string,
  aliasId: number,
  limit?: number,
  offset?: number,
  signal?: AbortSignal,
): Promise<PagedSoftwareEnvironmentSpecListSchema> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentListSoftwareEnvAliasSpecs(
    {
      account: accountSlug,
      pk: aliasId,
      limit,
      offset,
    },
    { signal },
  );
};

export const fetchSoftwareAlias = async (
  accountSlug: string,
  id: number,
  signal?: AbortSignal,
): Promise<SoftwareEnvironmentAliasSchema> => {
  return ApiClient.declarativeViewsSoftwareEnvironmentGetSoftwareEnvAliasById(
    {
      account: accountSlug,
      pk: id,
    },
    { signal },
  );
};

export const getSoftwareSpecYaml = async (
  accountSlug: string,
  specId: number,
  signal?: AbortSignal,
): Promise<string> => {
  const resp =
    await ApiClient.declarativeViewsSoftwareEnvironmentGetSoftwareEnvSpecYamlRaw(
      {
        account: accountSlug,
        id: specId,
      },
      {
        signal,
      },
    );
  return resp.value();
};
