import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { Page } from "../../shared-components/Page";
import { Billing } from "./Billing";
import { Urls } from "../../domain/urls";

export const BillingRouter = (): React.ReactElement => {
  const crumbs = useMemo(
    () => [{ text: "Billing", link: `/${Urls.Billing}` }, { text: "Manage" }],
    [],
  );
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Page title="Billing - Management" breadCrumbs={crumbs}>
            <Billing />
          </Page>
        }
      />
    </Routes>
  );
};
