/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AzureCredentialsSupersetSchema } from './AzureCredentialsSupersetSchema';
import {
    AzureCredentialsSupersetSchemaFromJSON,
    AzureCredentialsSupersetSchemaFromJSONTyped,
    AzureCredentialsSupersetSchemaToJSON,
} from './AzureCredentialsSupersetSchema';

/**
 * 
 * @export
 * @interface AzureCredentialsSchema
 */
export interface AzureCredentialsSchema {
    /**
     * 
     * @type {AzureCredentialsSupersetSchema}
     * @memberof AzureCredentialsSchema
     */
    credentials?: AzureCredentialsSupersetSchema;
    /**
     * 
     * @type {string}
     * @memberof AzureCredentialsSchema
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureCredentialsSchema
     */
    resourceGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureCredentialsSchema
     */
    defaultRegion?: string;
}

/**
 * Check if a given object implements the AzureCredentialsSchema interface.
 */
export function instanceOfAzureCredentialsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AzureCredentialsSchemaFromJSON(json: any): AzureCredentialsSchema {
    return AzureCredentialsSchemaFromJSONTyped(json, false);
}

export function AzureCredentialsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AzureCredentialsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentials': !exists(json, 'credentials') ? undefined : AzureCredentialsSupersetSchemaFromJSON(json['credentials']),
        'subscriptionId': !exists(json, 'subscription_id') ? undefined : json['subscription_id'],
        'resourceGroupName': !exists(json, 'resource_group_name') ? undefined : json['resource_group_name'],
        'defaultRegion': !exists(json, 'default_region') ? undefined : json['default_region'],
    };
}

export function AzureCredentialsSchemaToJSON(value?: AzureCredentialsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentials': AzureCredentialsSupersetSchemaToJSON(value.credentials),
        'subscription_id': value.subscriptionId,
        'resource_group_name': value.resourceGroupName,
        'default_region': value.defaultRegion,
    };
}

