/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpanSchema
 */
export interface SpanSchema {
    /**
     * 
     * @type {string}
     * @memberof SpanSchema
     */
    identifier: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpanSchema
     */
    name: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SpanSchema
     */
    parentSpanId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SpanSchema
     */
    enqueued?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SpanSchema
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SpanSchema
     */
    stop?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SpanSchema
     */
    done: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpanSchema
     */
    nbytesTotal: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof SpanSchema
     */
    cumulativeWorkerMetrics?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof SpanSchema
     */
    states?: object;
    /**
     * 
     * @type {object}
     * @memberof SpanSchema
     */
    allDurations?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof SpanSchema
     */
    collections?: Array<object>;
}

/**
 * Check if a given object implements the SpanSchema interface.
 */
export function instanceOfSpanSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "done" in value;
    isInstance = isInstance && "nbytesTotal" in value;

    return isInstance;
}

export function SpanSchemaFromJSON(json: any): SpanSchema {
    return SpanSchemaFromJSONTyped(json, false);
}

export function SpanSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpanSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'name': json['name'],
        'parentSpanId': !exists(json, 'parent_span_id') ? undefined : json['parent_span_id'],
        'enqueued': !exists(json, 'enqueued') ? undefined : (new Date(json['enqueued'])),
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
        'done': json['done'],
        'nbytesTotal': json['nbytes_total'],
        'cumulativeWorkerMetrics': !exists(json, 'cumulative_worker_metrics') ? undefined : json['cumulative_worker_metrics'],
        'states': !exists(json, 'states') ? undefined : json['states'],
        'allDurations': !exists(json, 'all_durations') ? undefined : json['all_durations'],
        'collections': !exists(json, 'collections') ? undefined : json['collections'],
    };
}

export function SpanSchemaToJSON(value?: SpanSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'name': value.name,
        'parent_span_id': value.parentSpanId,
        'enqueued': value.enqueued === undefined ? undefined : (value.enqueued.toISOString()),
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
        'done': value.done,
        'nbytes_total': value.nbytesTotal,
        'cumulative_worker_metrics': value.cumulativeWorkerMetrics,
        'states': value.states,
        'all_durations': value.allDurations,
        'collections': value.collections,
    };
}

