import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { useCompleteInteractionFlagMutation } from "../../../crud/interactions/hooks";
import { InteractionFlagNames } from "../../../crud/interactions/types";
import { InteractionFlagsContext } from "../../../crud/interactions/context";

export const useOnboarding = (): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
] => {
  const setFlag = useCompleteInteractionFlagMutation(
    InteractionFlagNames.CompletedSetupOnce,
  );
  const { "completed-setup-once": flag } = useContext(InteractionFlagsContext);
  return useMemo(
    () => [!flag.completed, (val) => setFlag.mutate(val)],
    [flag, setFlag],
  );
};
