import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../apiUtils";
import { Membership } from "./types";

export const getMemberships = async (): Promise<Membership[]> => {
  const buildPayload = await ky
    .get(`/api/v2/user/me/memberships`, { headers: getStandardHeaders() })
    .json();
  return camelizeKeys(buildPayload) as Membership[];
};
