import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip as MUITooltip,
  useTheme,
  IconButton,
  Card,
  Box,
  Typography,
} from "@mui/material";

import {
  Bar,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useUsageByUser } from "../../crud/usage/hooks";
import React, { useContext, useMemo } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { Download, Square } from "@mui/icons-material";
import { useTags } from "../../crud/tags/hooks";
import { GroupedTooltip } from "../Clusters/Information/charts/GroupedTooltip";

import {
  useQueryParam,
  withDefault,
  NumberParam,
  createEnumParam,
  StringParam,
} from "use-query-params";

import { UserContext } from "../../crud/user/context";
import {
  DeclarativeViewsUsageGetUsageByUserGroupbyEnum,
  DeclarativeViewsUsageGetUsageByUserPeriodEnum,
  DeclarativeViewsUsageGetUsageByUserUnitEnum,
} from "../../api-client";
import {
  ScopeSelector,
  useScopedContext,
} from "../../shared-components/ScopeSelector";
import { usePersistentQueryParam } from "../../utils/hooks";

const PeriodParam = createEnumParam(
  Object.values(DeclarativeViewsUsageGetUsageByUserPeriodEnum),
);
const UnitParam = createEnumParam(
  Object.values(DeclarativeViewsUsageGetUsageByUserUnitEnum),
);
const GroupbyOptions: { [key: string]: string } = {
  account: "Workspace",
  cluster: "Cluster",
  instance_type: "Instance Type",
  user: "User",
  zone: "Zone",
  cloud_provider: "Cloud Provider",
};

const fmtTimeLabel = (
  time: number,
  period: DeclarativeViewsUsageGetUsageByUserPeriodEnum,
): string => {
  // our billing system is in UTC, always display utc
  return moment(time)
    .utc(false)
    .format(period === "month" ? "yyyy-MM" : "yyyy-MM-DD");
};

const toMoment = (unix: number): moment.Moment => moment.utc(unix * 1000);
const fromMoment = (t: moment.Moment): number => t.unix();

export const MemberSpend = (): React.ReactElement => {
  const [scope] = useScopedContext();
  // persist period/group by/unit in URL and in local storage
  const [period, setPeriod] =
    usePersistentQueryParam<DeclarativeViewsUsageGetUsageByUserPeriodEnum>({
      key: "period",
      paramType: PeriodParam,
      default: DeclarativeViewsUsageGetUsageByUserPeriodEnum.Day,
    });
  const [groupby, setGroupby] =
    usePersistentQueryParam<DeclarativeViewsUsageGetUsageByUserGroupbyEnum>({
      key: "groupby",
      paramType: StringParam,
      default: DeclarativeViewsUsageGetUsageByUserGroupbyEnum.User,
    });
  const [unit, setUnit] =
    usePersistentQueryParam<DeclarativeViewsUsageGetUsageByUserUnitEnum>({
      key: "unit",
      paramType: UnitParam,
      default: DeclarativeViewsUsageGetUsageByUserUnitEnum.Credits,
    });

  const defaultStart = useMemo(() => moment.utc().subtract(2, "month"), []);
  const defaultEnd = useMemo(() => moment.utc().add(1, "day"), []);

  // Don't persist start and end date in local storage, because most of the time you actually want this to be
  // relative time period (e.g., last two months), not the actual date range you looked at when you first
  // looked at this page. One common UX is to have "last N months" as explicit option, but since we don't
  // currently have that UX, it's best if we don't persist the specific start and end date in local storage.
  const [startDate, setStartDate] = useQueryParam<number>(
    "start",
    withDefault(NumberParam, fromMoment(defaultStart)),
  );
  const [endDate, setEndDate] = useQueryParam<number>(
    "end",
    withDefault(NumberParam, fromMoment(defaultEnd)),
  );

  const apiRef = useGridApiRef();

  const { data: tags } = useTags({
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    ...(scope.type === "custom-staff-list"
      ? { customStaffScope: scope.id }
      : {}),
    systemTags: false,
  });
  const { user } = useContext(UserContext);

  const {
    palette: {
      custom: { chartColors },
    },
  } = useTheme();
  const { data, isSuccess, isLoading, isError } = useUsageByUser({
    period,
    start: toMoment(startDate).toDate(),
    end: toMoment(endDate).toDate(),
    unit,
    groupby: groupby.split(
      ":",
    )[0] as DeclarativeViewsUsageGetUsageByUserGroupbyEnum,
    groupbyTag: groupby.split(":")[1],
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    ...(scope.type === "custom-staff-list"
      ? { customStaffScope: scope.id }
      : {}),
  });
  const periods = data?.usage.map((u) => u.period);

  type MemberUsageRowType = {
    groupkey: string;
    [k: string]: number | string;
  };
  const memberRows = data?.groups.map((groupkey) => {
    const periodCols = Object.fromEntries(
      data?.usage.map((u) => [
        fmtTimeLabel(u.period, period),
        u[groupkey]?.toFixed(2),
      ]),
    );

    return { groupkey, ...periodCols };
  });
  const columns = useMemo<GridColDef<MemberUsageRowType>[]>(
    () => [
      {
        field: "groupkey",
        headerName: GroupbyOptions[groupby] || groupby,
        width: 200,
        renderCell: (params) => (
          <Stack direction="row" spacing={1}>
            <Square
              sx={{
                color: isSuccess
                  ? chartColors[
                      data.groups.indexOf(params.value) % chartColors.length
                    ]
                  : undefined,
              }}
            />
            <p>{params.value}</p>
          </Stack>
        ),
      },
      ...(periods
        ? periods.map((p) => ({
            field: fmtTimeLabel(p, period),
            width: 150,
            type: "number",
          }))
        : []),
    ],
    [groupby, periods, isSuccess, chartColors, data?.groups, period],
  );

  const timeRange = [
    // janky workaround for
    // https://github.com/recharts/recharts/issues/2711
    (period === "month"
      ? toMoment(startDate).subtract(2, "month")
      : toMoment(startDate).subtract(2, "day")
    ).unix() * 1000,
    (period === "month"
      ? toMoment(endDate).add(1, "month")
      : toMoment(endDate).add(2, "day")
    ).unix() * 1000,
  ];

  const valuePrefix =
    unit === "credits" || unit === "instance-minutes" ? "" : "$";
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack
          direction="row"
          component={Card}
          sx={{ width: "fit-content", p: 1 }}
          spacing={2}
        >
          <MUITooltip title="Download currently selected range as CSV">
            <IconButton
              onClick={() =>
                apiRef.current.exportDataAsCsv({
                  fileName: `${toMoment(startDate)
                    .utc(false)
                    .format("yyyy-MM-DD")}_to_${toMoment(endDate)
                    .utc(false)
                    .format(
                      "yyyy-MM-DD",
                    )}_coiled_credit_spend_by_${period.toLowerCase()}`,
                })
              }
            >
              <Download />
            </IconButton>
          </MUITooltip>
          <FormControl size="small" variant="outlined" sx={{ width: "100px" }}>
            <InputLabel id="granularity-select-label">Granularity</InputLabel>
            <Select
              labelId="granularity-select-label"
              id="granularity-select"
              value={period}
              onChange={(e) =>
                setPeriod(
                  e.target
                    .value as DeclarativeViewsUsageGetUsageByUserPeriodEnum,
                )
              }
              label="Granularity"
              sx={{ textTransform: "capitalize" }}
            >
              {Object.values(DeclarativeViewsUsageGetUsageByUserPeriodEnum)
                .filter((value) => user.isStaff || value !== "hour")
                .map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <DateRangePicker
            timezone="UTC"
            value={[toMoment(startDate), toMoment(endDate)]}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                sx: {
                  width: "120px",
                },
              },
            }}
            onChange={(newValue) => {
              setStartDate(
                fromMoment(newValue[0] ? newValue[0] : defaultStart),
              );
              setEndDate(fromMoment(newValue[1] ? newValue[1] : defaultEnd));
            }}
          />
          <FormControl
            size="small"
            variant="outlined"
            sx={{
              width: "150px",
            }}
          >
            <InputLabel id="group-by-select-label">Group By</InputLabel>
            <Select
              labelId="group-by-select-label"
              id="group-by-select"
              value={groupby}
              onChange={(e) =>
                setGroupby(
                  e.target
                    .value as DeclarativeViewsUsageGetUsageByUserGroupbyEnum,
                )
              }
              label="Group By"
              sx={{ textTransform: "capitalize" }}
            >
              {Object.values(DeclarativeViewsUsageGetUsageByUserGroupbyEnum)
                .filter(
                  (v) => v !== "tag" && (user.isStaff ? true : v !== "cluster"),
                )
                .map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {GroupbyOptions[value] || value}
                  </MenuItem>
                ))}
              {(tags || []).map((v) => (
                <MenuItem value={`tag:${v.label}`} key={`tag:${v.label}`}>
                  &nbsp;Tag: {v.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {user.isStaff && (
            <FormControl
              variant="outlined"
              size="small"
              sx={{ width: "250px" }}
            >
              <InputLabel id="unit-select-label">Unit</InputLabel>
              <Select
                labelId="unit-select-label"
                id="unit-select"
                value={unit}
                onChange={(e) =>
                  setUnit(
                    e.target
                      .value as DeclarativeViewsUsageGetUsageByUserUnitEnum,
                  )
                }
                label="Unit"
              >
                <MenuItem value={"credits"}>Coiled Credits</MenuItem>
                <MenuItem value={"coiled-cost"}>Coiled Cost*</MenuItem>
                <MenuItem value={"approx-cloud-cost"}>
                  Approximate Cloud Cost
                </MenuItem>
                <MenuItem value={"instance-minutes"}>Instance Minutes</MenuItem>
              </Select>
            </FormControl>
          )}
        </Stack>
        <ScopeSelector />
      </Stack>
      <Card sx={{ p: 1 }}>
        {isSuccess && data.usage.length === 0 && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.action.hover,
              height: "35vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>No Usage for these dates</Typography>
          </Box>
        )}
        {(isLoading || isError) && <Skeleton height="35vh" />}
        {isSuccess && data.usage.length > 0 && (
          <ResponsiveContainer key="chart" minHeight={"35vh"}>
            <BarChart data={data?.usage}>
              <CartesianGrid />
              <YAxis
                type="number"
                textAnchor="end"
                tickFormatter={(v, i) => Math.floor(v).toString()}
              />
              <XAxis
                dataKey="period"
                type="number"
                scale="utc"
                domain={timeRange}
                tickFormatter={(v) => fmtTimeLabel(v, period)}
                minTickGap={30}
                padding={"gap"}
              />
              <Tooltip
                content={
                  <GroupedTooltip
                    formatter={(v) =>
                      `${valuePrefix}${(v as unknown as number)?.toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        },
                      )}`
                    }
                    labelFormatter={(v) => fmtTimeLabel(v, period)}
                    showTotal
                    descSortByValue
                  />
                }
                wrapperStyle={{
                  outline: "none",
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              />
              {data.groups.map((u, i) => (
                <Bar
                  fillOpacity={0.8}
                  stroke={chartColors[i % chartColors.length]}
                  barSize={600}
                  key={u}
                  // loop colors, it'll get ugly when we
                  // run out of colors but hopefully
                  // nobody has that many users yet
                  fill={chartColors[i % chartColors.length]}
                  dataKey={u}
                  stackId={"1"}
                  name={u}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
        <DataGridPro
          key="datagrid"
          density="compact"
          localeText={{ noRowsLabel: "No usage for these dates" }}
          loading={isLoading}
          apiRef={apiRef}
          sx={{ border: "unset", height: "35vh" }}
          pinnedColumns={{ left: ["groupkey"] }}
          initialState={{
            sorting: {
              sortModel: [{ field: "groupkey", sort: "asc" }],
            },
          }}
          getRowId={(row) => row.groupkey}
          columns={columns}
          rows={memberRows || []}
        />
      </Card>
    </Stack>
  );
};
