import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  CreateWorkspaceSchema,
  ResponseError,
  UsersViewsWorkspacesUpdateWorkspaceRequest,
  UsersViewsWorkspacesWorkspacesRequest,
  WorkspaceLightWithMembershipSchema,
  WorkspaceSchema,
} from "../../api-client";

export const useWorkspaces = (
  request: UsersViewsWorkspacesWorkspacesRequest,
): UseQueryResult<WorkspaceLightWithMembershipSchema[]> => {
  return useQuery({
    queryKey: ["useWorkspaces", request],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsWorkspacesWorkspaces(request, { signal }),
    keepPreviousData: true,
  });
};

export const useCreateWorkspaceMutation = (): UseMutationResult<
  WorkspaceSchema,
  ResponseError,
  CreateWorkspaceSchema,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    "createWorkspace",
    (params) =>
      ApiClient.usersViewsWorkspacesCreateWorkspace({
        createWorkspaceSchema: params,
      }),
    {
      onSuccess: (data, vars) => {
        queryClient.setQueriesData<WorkspaceLightWithMembershipSchema[]>(
          {
            queryKey: "useWorkspaces",
            predicate: (v: any) =>
              v.queryKey[1].organizationId === data.organization?.id ||
              v.queryKey[1].organzation === data.organization?.name,
          },
          (oldData) => {
            const acct = {
              id: data.id,
              avatarUrl: data.avatarUrl,
              membershipSet: [],
              name: data.name,
              organization: data.organization,
              slug: data.slug,
              creditLimit: data.creditLimit,
            };
            return oldData ? [...oldData, acct] : [acct];
          },
        );
      },
    },
  );
};

export const useUpdateWorkspaceMutation = (): UseMutationResult<
  WorkspaceSchema,
  ResponseError,
  UsersViewsWorkspacesUpdateWorkspaceRequest,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    "createWorkspace",
    (params) => ApiClient.usersViewsWorkspacesUpdateWorkspace(params),
    {
      onSuccess: (data, vars) => {
        queryClient.setQueryData<WorkspaceLightWithMembershipSchema[]>(
          ["userWorkspaces", data.organization?.id],
          (oldData) => {
            const acct = {
              id: data.id,
              avatarUrl: data.avatarUrl,
              membershipSet: [],
              name: data.name,
              organization: data.organization,
              slug: data.slug,
              creditLimit: data.creditLimit,
            };
            return oldData
              ? [...oldData.filter((v) => v.id !== vars.workspaceId), acct]
              : [acct];
          },
        );
      },
    },
  );
};
