/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceInviteSchema
 */
export interface WorkspaceInviteSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceInviteSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceInviteSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceInviteSchema
     */
    updated: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceInviteSchema
     */
    expiration: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceInviteSchema
     */
    organizationInviteId: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceInviteSchema
     */
    workspaceId: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceInviteSchema
     */
    coreLimit: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceInviteSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceInviteSchema
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceInviteSchema
     */
    email: string;
}

/**
 * Check if a given object implements the WorkspaceInviteSchema interface.
 */
export function instanceOfWorkspaceInviteSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "expiration" in value;
    isInstance = isInstance && "organizationInviteId" in value;
    isInstance = isInstance && "workspaceId" in value;
    isInstance = isInstance && "coreLimit" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function WorkspaceInviteSchemaFromJSON(json: any): WorkspaceInviteSchema {
    return WorkspaceInviteSchemaFromJSONTyped(json, false);
}

export function WorkspaceInviteSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceInviteSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'expiration': (new Date(json['expiration'])),
        'organizationInviteId': json['organization_invite_id'],
        'workspaceId': json['workspace_id'],
        'coreLimit': json['core_limit'],
        'creditLimit': json['credit_limit'],
        'isAdmin': json['is_admin'],
        'email': json['email'],
    };
}

export function WorkspaceInviteSchemaToJSON(value?: WorkspaceInviteSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'expiration': (value.expiration.toISOString()),
        'organization_invite_id': value.organizationInviteId,
        'workspace_id': value.workspaceId,
        'core_limit': value.coreLimit,
        'credit_limit': value.creditLimit,
        'is_admin': value.isAdmin,
        'email': value.email,
    };
}

