import { format } from "date-fns";
import { LogPage } from "../../../crud/logs/types";
import { MetricsPage } from "../../../crud/metrics/types";
import { TimeRange } from "../types";

export const timeRangeToString = (timeRange: TimeRange): string =>
  format(new Date(timeRange[0]), "HH:mm:ss") +
  "–" +
  format(new Date(timeRange[1]), "HH:mm:ss");

export const getLatestTimestamp = (
  pages?: LogPage[] | MetricsPage[],
): number | undefined => {
  const timestamps = pages?.flatMap((page) =>
    page.data?.map(({ timestamp }) => timestamp),
  );
  if (!timestamps?.length) {
    return undefined;
  }
  // using timestamps.reduce rather than Math.max(...timestamps) to avoid
  // putting timestamps on the callstack (which can cause Maximum call stack size exceeded)
  const latestTimestamp =
    timestamps && timestamps.reduce((a, b) => Math.max(a, b), -Infinity);
  return latestTimestamp;
};
