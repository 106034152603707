/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupersetRuntimeOptionsAPISchema } from './SupersetRuntimeOptionsAPISchema';
import {
    SupersetRuntimeOptionsAPISchemaFromJSON,
    SupersetRuntimeOptionsAPISchemaFromJSONTyped,
    SupersetRuntimeOptionsAPISchemaToJSON,
} from './SupersetRuntimeOptionsAPISchema';

/**
 * 
 * @export
 * @interface CreateClusterSchema
 */
export interface CreateClusterSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    softwareEnvironment?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateClusterSchema
     */
    environ?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof CreateClusterSchema
     */
    privateToCreator?: boolean;
    /**
     * 
     * @type {SupersetRuntimeOptionsAPISchema}
     * @memberof CreateClusterSchema
     */
    options?: SupersetRuntimeOptionsAPISchema;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    workers: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    workerClass?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateClusterSchema
     */
    workerOptions?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateClusterSchema
     */
    workerInstanceTypes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    workerDiskSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    workerDiskThroughput?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    schedulerClass?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateClusterSchema
     */
    schedulerOptions?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateClusterSchema
     */
    schedulerInstanceTypes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    schedulerDiskSize?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateClusterSchema
     */
    tags?: { [key: string]: string; };
    /**
     * 
     * @type {object}
     * @memberof CreateClusterSchema
     */
    daskConfig?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClusterSchema
     */
    extraWorkerOnScheduler?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    nWorkerSpecsPerHost?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClusterSchema
     */
    envId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    envName?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateClusterSchema
     */
    autoSpec?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClusterSchema
     */
    useAwsCredsEndpoint?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    customSubdomain?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateClusterSchema
     */
    batchJobIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateClusterSchema
     */
    extraUserContainer?: string;
}

/**
 * Check if a given object implements the CreateClusterSchema interface.
 */
export function instanceOfCreateClusterSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "workers" in value;
    isInstance = isInstance && "workerInstanceTypes" in value;
    isInstance = isInstance && "schedulerInstanceTypes" in value;

    return isInstance;
}

export function CreateClusterSchemaFromJSON(json: any): CreateClusterSchema {
    return CreateClusterSchemaFromJSONTyped(json, false);
}

export function CreateClusterSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClusterSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'softwareEnvironment': !exists(json, 'software_environment') ? undefined : json['software_environment'],
        'environ': !exists(json, 'environ') ? undefined : json['environ'],
        'privateToCreator': !exists(json, 'private_to_creator') ? undefined : json['private_to_creator'],
        'options': !exists(json, 'options') ? undefined : SupersetRuntimeOptionsAPISchemaFromJSON(json['options']),
        'workers': json['workers'],
        'workerClass': !exists(json, 'worker_class') ? undefined : json['worker_class'],
        'workerOptions': !exists(json, 'worker_options') ? undefined : json['worker_options'],
        'workerInstanceTypes': json['worker_instance_types'],
        'workerDiskSize': !exists(json, 'worker_disk_size') ? undefined : json['worker_disk_size'],
        'workerDiskThroughput': !exists(json, 'worker_disk_throughput') ? undefined : json['worker_disk_throughput'],
        'schedulerClass': !exists(json, 'scheduler_class') ? undefined : json['scheduler_class'],
        'schedulerOptions': !exists(json, 'scheduler_options') ? undefined : json['scheduler_options'],
        'schedulerInstanceTypes': json['scheduler_instance_types'],
        'schedulerDiskSize': !exists(json, 'scheduler_disk_size') ? undefined : json['scheduler_disk_size'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'daskConfig': !exists(json, 'dask_config') ? undefined : json['dask_config'],
        'extraWorkerOnScheduler': !exists(json, 'extra_worker_on_scheduler') ? undefined : json['extra_worker_on_scheduler'],
        'nWorkerSpecsPerHost': !exists(json, 'n_worker_specs_per_host') ? undefined : json['n_worker_specs_per_host'],
        'envId': !exists(json, 'env_id') ? undefined : json['env_id'],
        'envName': !exists(json, 'env_name') ? undefined : json['env_name'],
        'autoSpec': !exists(json, 'auto_spec') ? undefined : json['auto_spec'],
        'useAwsCredsEndpoint': !exists(json, 'use_aws_creds_endpoint') ? undefined : json['use_aws_creds_endpoint'],
        'customSubdomain': !exists(json, 'custom_subdomain') ? undefined : json['custom_subdomain'],
        'batchJobIds': !exists(json, 'batch_job_ids') ? undefined : json['batch_job_ids'],
        'extraUserContainer': !exists(json, 'extra_user_container') ? undefined : json['extra_user_container'],
    };
}

export function CreateClusterSchemaToJSON(value?: CreateClusterSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'software_environment': value.softwareEnvironment,
        'environ': value.environ,
        'private_to_creator': value.privateToCreator,
        'options': SupersetRuntimeOptionsAPISchemaToJSON(value.options),
        'workers': value.workers,
        'worker_class': value.workerClass,
        'worker_options': value.workerOptions,
        'worker_instance_types': value.workerInstanceTypes,
        'worker_disk_size': value.workerDiskSize,
        'worker_disk_throughput': value.workerDiskThroughput,
        'scheduler_class': value.schedulerClass,
        'scheduler_options': value.schedulerOptions,
        'scheduler_instance_types': value.schedulerInstanceTypes,
        'scheduler_disk_size': value.schedulerDiskSize,
        'tags': value.tags,
        'dask_config': value.daskConfig,
        'extra_worker_on_scheduler': value.extraWorkerOnScheduler,
        'n_worker_specs_per_host': value.nWorkerSpecsPerHost,
        'env_id': value.envId,
        'env_name': value.envName,
        'auto_spec': value.autoSpec,
        'use_aws_creds_endpoint': value.useAwsCredsEndpoint,
        'custom_subdomain': value.customSubdomain,
        'batch_job_ids': value.batchJobIds,
        'extra_user_container': value.extraUserContainer,
    };
}

