import React from "react";
import { Box } from "@mui/material";
import { Props as DefaultLegendProps } from "recharts/types/component/DefaultLegendContent.d";
import { groupBy } from "lodash";
import { getLabel } from "./utils";

/**
 * Renders a custom legend where the domains are grouped by color.
 */

export const GroupedLegend = (
  props: DefaultLegendProps,
): React.ReactElement => {
  const payloadGroupedByColors = Object.entries(
    groupBy(props.payload, (domain) => domain.color),
  );

  return (
    <Box sx={{ textAlign: "center" }}>
      {payloadGroupedByColors.map(([color, entries]) => (
        <>
          <Circle key={color} color={color} />
          <Box sx={{ display: "inline-block", mr: "10px" }}>
            {getLabel(entries)}
          </Box>
        </>
      ))}
    </Box>
  );
};

const Circle = ({ color }: { color: string }) => (
  <svg
    className="recharts-surface"
    width="11"
    height="11"
    viewBox="0 0 32 32"
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "4px",
    }}
  >
    <path
      fill={color}
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
    />
  </svg>
);
