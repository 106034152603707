/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackageLevelEnum } from './PackageLevelEnum';
import {
    PackageLevelEnumFromJSON,
    PackageLevelEnumFromJSONTyped,
    PackageLevelEnumToJSON,
} from './PackageLevelEnum';

/**
 * 
 * @export
 * @interface ScanResultPackageSchema
 */
export interface ScanResultPackageSchema {
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    name: string;
    /**
     * 
     * @type {PackageLevelEnum}
     * @memberof ScanResultPackageSchema
     */
    priorityOverride?: PackageLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    pythonMajorVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    pythonMinorVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    pythonPatchVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    source: ScanResultPackageSchemaSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    channelUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    subdir?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    condaName?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultPackageSchema
     */
    wheelTarget?: string;
}


/**
 * @export
 */
export const ScanResultPackageSchemaSourceEnum = {
    Pip: 'pip',
    Conda: 'conda'
} as const;
export type ScanResultPackageSchemaSourceEnum = typeof ScanResultPackageSchemaSourceEnum[keyof typeof ScanResultPackageSchemaSourceEnum];


/**
 * Check if a given object implements the ScanResultPackageSchema interface.
 */
export function instanceOfScanResultPackageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "pythonMajorVersion" in value;
    isInstance = isInstance && "pythonMinorVersion" in value;
    isInstance = isInstance && "pythonPatchVersion" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function ScanResultPackageSchemaFromJSON(json: any): ScanResultPackageSchema {
    return ScanResultPackageSchemaFromJSONTyped(json, false);
}

export function ScanResultPackageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScanResultPackageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'priorityOverride': !exists(json, 'priority_override') ? undefined : PackageLevelEnumFromJSON(json['priority_override']),
        'pythonMajorVersion': json['python_major_version'],
        'pythonMinorVersion': json['python_minor_version'],
        'pythonPatchVersion': json['python_patch_version'],
        'source': json['source'],
        'channelUrl': !exists(json, 'channel_url') ? undefined : json['channel_url'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'subdir': !exists(json, 'subdir') ? undefined : json['subdir'],
        'condaName': !exists(json, 'conda_name') ? undefined : json['conda_name'],
        'version': json['version'],
        'wheelTarget': !exists(json, 'wheel_target') ? undefined : json['wheel_target'],
    };
}

export function ScanResultPackageSchemaToJSON(value?: ScanResultPackageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'priority_override': PackageLevelEnumToJSON(value.priorityOverride),
        'python_major_version': value.pythonMajorVersion,
        'python_minor_version': value.pythonMinorVersion,
        'python_patch_version': value.pythonPatchVersion,
        'source': value.source,
        'channel_url': value.channelUrl,
        'channel': value.channel,
        'subdir': value.subdir,
        'conda_name': value.condaName,
        'version': value.version,
        'wheel_target': value.wheelTarget,
    };
}

