/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientEnvironmentMetadata
 */
export interface ClientEnvironmentMetadata {
    /**
     * 
     * @type {string}
     * @memberof ClientEnvironmentMetadata
     */
    basePrefix: string;
    /**
     * 
     * @type {string}
     * @memberof ClientEnvironmentMetadata
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof ClientEnvironmentMetadata
     */
    prefix: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientEnvironmentMetadata
     */
    sysPath: Array<string>;
}

/**
 * Check if a given object implements the ClientEnvironmentMetadata interface.
 */
export function instanceOfClientEnvironmentMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "basePrefix" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "prefix" in value;
    isInstance = isInstance && "sysPath" in value;

    return isInstance;
}

export function ClientEnvironmentMetadataFromJSON(json: any): ClientEnvironmentMetadata {
    return ClientEnvironmentMetadataFromJSONTyped(json, false);
}

export function ClientEnvironmentMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientEnvironmentMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basePrefix': json['base_prefix'],
        'platform': json['platform'],
        'prefix': json['prefix'],
        'sysPath': json['sys_path'],
    };
}

export function ClientEnvironmentMetadataToJSON(value?: ClientEnvironmentMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_prefix': value.basePrefix,
        'platform': value.platform,
        'prefix': value.prefix,
        'sys_path': value.sysPath,
    };
}

