import React from "react";
import { Box } from "@mui/material";
import { TooltipProps } from "recharts";
import { removeParentheses } from "./utils";

/**
 * A custom tooltip that groups items by color.
 */

type GroupedTooltipProps = {
  showTotal?: boolean;
  descSortByValue?: boolean;
} & TooltipProps<string, string>;

export const GroupedTooltip = (
  props: GroupedTooltipProps,
): React.ReactElement | null => {
  const { active, payload, formatter, label, labelFormatter, showTotal } =
    props;

  let total = 0;
  const maxValByColor: Record<string, number> = {};

  if (active && payload && payload.length) {
    // Group items by color
    const itemsByColor: Record<string, { name: string; value: string }[]> = {};
    payload.forEach((entry) => {
      const { name, value } = entry;
      const color = entry.color ?? entry.payload?.fill;

      total += Number(value);
      maxValByColor[color] = Math.max(
        maxValByColor[color] || 0,
        Number(value) || 0,
      );

      if (!color) {
        return;
      }
      if (!itemsByColor[color]) {
        itemsByColor[color] = [];
      }
      if (name && value) {
        itemsByColor[color].push({ name, value });
        // sort so that item with largest value for this color is shown first
        itemsByColor[color].sort(
          (a, b) => (Number(b.value) || 0) - (Number(a.value) || 0),
        );
      }
    });

    const sortedColors = props.descSortByValue
      ? Object.entries(maxValByColor)
          // sort from largest to smallest
          .sort((a, b) => b[1] - a[1])
          .map((x) => x[0])
      : Object.keys(itemsByColor);

    const formattedTotal =
      showTotal && payload.length > 1 && total
        ? formatter
          ? // @ts-ignore
            formatter(total, "", null, null, null)
          : String(total)
        : null;

    return (
      <Box
        sx={{
          background: "white",
          opacity: 0.8,
          border: "1px solid #dedede",
          py: 0.75,
          px: 1,
        }}
      >
        <Box sx={{ fontWeight: "bold", mb: 0.5 }}>
          {labelFormatter ? labelFormatter(label, payload) : label}
        </Box>
        {sortedColors.map((color) => {
          const items = itemsByColor[color];
          const itemName = items[0] && "name" in items[0] ? items[0].name : "";

          // hide colors without any items
          if (!items.length) return <></>;

          let group = removeParentheses(itemName)[1];

          // Special case for "other (sum of small durations)" items,
          // let's not remove the parentheses
          const isOtherItem = itemName.startsWith("other");
          if (isOtherItem) {
            group = "";
          }

          return (
            <Box key={color}>
              <Box
                sx={{
                  borderRadius: "100%",
                  backgroundColor: color,
                  width: "11px",
                  height: "11px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  mr: "4px",
                }}
              />
              {group ? <span>{group}: </span> : null}
              {items.map((item, index) => {
                const result = formatter
                  ? formatter(item.value, item.name, item, index, payload)
                  : [item.value, item.name];

                const [value, name] = Array.isArray(result)
                  ? result
                  : [result, item.name];

                return (
                  <Box
                    sx={{ display: "inline-block", mr: 0.75, mb: 0.5 }}
                    key={name}
                  >
                    <span style={{ color: "#908e89" }}>
                      {isOtherItem ? name : removeParentheses(name)[0]}:{" "}
                    </span>
                    <span>{value}</span>
                  </Box>
                );
              })}
            </Box>
          );
        })}
        {formattedTotal && (
          <Box>
            <b>Total:</b> {formattedTotal}
          </Box>
        )}
      </Box>
    );
  }

  return null;
};
