import React, { useEffect } from "react";
import { RefreshOutlined, SvgIconComponent } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { useIsFetching } from "react-query";

type QueryFetchingIndicatorProps = {
  Icon?: SvgIconComponent;
  onClick?: () => void;
  iconProps?: SvgIconProps;
  query: string;
};
export const QueryFetchingIndicator = React.forwardRef<
  SVGSVGElement,
  QueryFetchingIndicatorProps
>(
  (
    { Icon, iconProps, query, onClick }: QueryFetchingIndicatorProps,
    ref,
  ): React.ReactElement => {
    // Smoothly spin an SVG icon
    // avoids snapping the icon back into position
    // when spin is ended

    const spin = !!useIsFetching(query, { exact: false });
    const [isSpinning, setIsSpinning] = React.useState(false);
    useEffect(() => {
      if (!isSpinning && spin) setIsSpinning(spin);
    }, [spin, isSpinning]);
    const { sx: iconSx, ...iconRemainingProps } = iconProps || { sx: {} };
    const IconToUse = Icon ? Icon : RefreshOutlined;
    return (
      <IconToUse
        color="primary"
        onClick={onClick}
        fontSize="small"
        onAnimationEnd={() => setIsSpinning(false)}
        sx={{
          cursor: onClick ? "pointer" : undefined,
          animation: () => {
            if (!spin && isSpinning) {
              return "spin 0.5s linear 1 forwards";
            } else if (spin) {
              return "spin 0.5s linear infinite forwards";
            } else {
              return undefined;
            }
          },
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
          opacity: "50%",
          ...iconSx,
        }}
        {...iconRemainingProps}
        ref={ref}
      />
    );
  },
);
