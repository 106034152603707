/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchitectureTypesEnum } from './ArchitectureTypesEnum';
import {
    ArchitectureTypesEnumFromJSON,
    ArchitectureTypesEnumFromJSONTyped,
    ArchitectureTypesEnumToJSON,
} from './ArchitectureTypesEnum';
import type { ClientEnvironmentMetadata } from './ClientEnvironmentMetadata';
import {
    ClientEnvironmentMetadataFromJSON,
    ClientEnvironmentMetadataFromJSONTyped,
    ClientEnvironmentMetadataToJSON,
} from './ClientEnvironmentMetadata';
import type { ClientPackageSchema } from './ClientPackageSchema';
import {
    ClientPackageSchemaFromJSON,
    ClientPackageSchemaFromJSONTyped,
    ClientPackageSchemaToJSON,
} from './ClientPackageSchema';
import type { PackageApproxRequest } from './PackageApproxRequest';
import {
    PackageApproxRequestFromJSON,
    PackageApproxRequestFromJSONTyped,
    PackageApproxRequestToJSON,
} from './PackageApproxRequest';

/**
 * 
 * @export
 * @interface ApproximateSpec
 */
export interface ApproximateSpec {
    /**
     * 
     * @type {ArchitectureTypesEnum}
     * @memberof ApproximateSpec
     */
    architecture: ArchitectureTypesEnum;
    /**
     * 
     * @type {ClientEnvironmentMetadata}
     * @memberof ApproximateSpec
     */
    metadata?: ClientEnvironmentMetadata;
    /**
     * 
     * @type {Array<ClientPackageSchema>}
     * @memberof ApproximateSpec
     */
    packages: Array<ClientPackageSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproximateSpec
     */
    indexUrls?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ApproximateSpec
     */
    pipCheckErrors?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {boolean}
     * @memberof ApproximateSpec
     */
    gpuEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApproximateSpec
     */
    scanId?: number;
}

/**
 * Check if a given object implements the ApproximateSpec interface.
 */
export function instanceOfApproximateSpec(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "architecture" in value;
    isInstance = isInstance && "packages" in value;

    return isInstance;
}

export function ApproximateSpecFromJSON(json: any): ApproximateSpec {
    return ApproximateSpecFromJSONTyped(json, false);
}

export function ApproximateSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproximateSpec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'architecture': ArchitectureTypesEnumFromJSON(json['architecture']),
        'metadata': !exists(json, 'metadata') ? undefined : ClientEnvironmentMetadataFromJSON(json['metadata']),
        'packages': ((json['packages'] as Array<any>).map(ClientPackageSchemaFromJSON)),
        'indexUrls': !exists(json, 'index_urls') ? undefined : json['index_urls'],
        'pipCheckErrors': !exists(json, 'pip_check_errors') ? undefined : json['pip_check_errors'],
        'gpuEnabled': !exists(json, 'gpu_enabled') ? undefined : json['gpu_enabled'],
        'scanId': !exists(json, 'scan_id') ? undefined : json['scan_id'],
    };
}

export function ApproximateSpecToJSON(value?: ApproximateSpec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'architecture': ArchitectureTypesEnumToJSON(value.architecture),
        'metadata': ClientEnvironmentMetadataToJSON(value.metadata),
        'packages': ((value.packages as Array<any>).map(ClientPackageSchemaToJSON)),
        'index_urls': value.indexUrls,
        'pip_check_errors': value.pipCheckErrors,
        'gpu_enabled': value.gpuEnabled,
        'scan_id': value.scanId,
    };
}

