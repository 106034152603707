import React from "react";
import CardContent from "@mui/material/CardContent";
import { Container, Grid, GridSize, Typography } from "@mui/material";
import { ApiError, parseError } from "../../../apiUtils";
import { Waiting } from "../../../shared-components/Waiting";
import ky from "ky";
import { TitleCard } from "../../../shared-components/Cards";
import { useToken } from "../../../utils/hooks";

/*
 Response from the fetchData function.
*/
type DataResponse<T> = {
  /*
   The backend can send intentional "no-data" 204 responses to indicate that the
   requested data is empty or does not exist. In that event, `data` is set to `null`.
  */
  data?: T | null;

  /*
   Possible error from the backend.
  */
  error?: ApiError;
};

/*
 Fetch data from the server at a given sub-path.
*/
const fetchData = async <T = unknown,>(
  path: string,
  token: string,
): Promise<DataResponse<T>> => {
  try {
    const response = await ky.get(`/${path}`, {
      headers: { Authorization: `Token ${token}` },
    });
    if (response.status === 204) {
      return { data: null };
    }
    const data = (await response.json()) as T;
    return { data };
  } catch (err) {
    return { error: parseError(err) };
  }
};

/*
 Props for a render component that can render data fetched from the backend.
 This defines a common interface for "analytics data renderers". They are not
 required, but this handles some common logic and interactions.

 It is generic with respect to the shape of the data fetched from the server (T).
 A second generic, U, defineds an optional data shape for "selected" data in the
 renderer. If a user can interact with, filter, select, etc the rendered data,
 this callback can be fired with the selected elements, and other UI elements
 can respond to that.
*/
export type AnalyticsRendererProps<T, U = never> = {
  /*
   The JSON-able data fetched from the server to render.
  */
  data: T;

  /*
   The account currently viewed.
  */
  account: string;

  /*
   The cluster currently viewed, if any.
  */
  clusterId?: number;

  /*
   An optional callback. If the renderer supports selecting subsets of the
   data, other UI elements may want to respond to that. This callback can be
   called by the renderer in that event, with the selection data type given by U.
  */
  onSelection?: (selected?: U) => void;
};

/*
 These panels are laid out using Material UI grid (itself based on flexbox).
 We pass widths for different browser sizes to allow for responsive resizing
 of the components.

 NOTE: This may need to be rethought.
*/
type GridSizing = {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
};

/*
 A wrapper component for an analytics renderer, which provides some default
 logic around fetching data, layout, and showing skeletons.
*/
type AnalyticsPaneProps<T, U = never> = {
  /*
   The path from which to fetch the data.
  */
  path: string;

  /*
   The title for the card.
  */
  title?: string;

  /*
   An optional subtitle for the card.
  */
  subTitle?: string;

  /*
   The account currently viewed.
  */
  account: string;

  /*
   The cluster currently viewed, if any.
  */
  clusterId?: number;

  /*
   Material UI Grid sizing information to help with layout.
  */
  containerWidth: GridSizing;

  /*
   A component to render the actual data that is fetched.
  */
  render: React.ComponentType<AnalyticsRendererProps<T, U>>;

  /*
   An optional callback that can be fired if data is selected in the renderer.
  */
  onSelection?: (selected?: U) => void;

  /*
   An optional component to render while data is being fetched.
  */
  loading?: React.ComponentType<Record<string, never>>;
};

export const AnalyticsPane = <T, U = never>(
  props: AnalyticsPaneProps<T, U>,
): React.ReactElement => {
  const [data, setData] = React.useState<T | null>();
  const [error, setError] = React.useState<string | undefined>();
  const token = useToken();

  // Load the data. This only runs if the path has changed.
  React.useEffect(() => {
    (async () => {
      // If we have a loading renderer, show that until the data is fetched.
      if (props.loading) {
        setData(undefined);
      }

      const result = await fetchData<T>(props.path, token);
      if ("error" in result) {
        setError("Something went wrong, unable to load data.");
      } else {
        setData(result.data);
      }
    })();
  }, [props.path, props.loading, token]);

  if (error) return <Container maxWidth="xs">{error}</Container>;

  // TODO: we should also allow a no-data renderer to be supplied.
  if (data === null) {
    return (
      <Grid item {...props.containerWidth}>
        <TitleCard title={props.title}>
          <CardContent>
            <Typography>No data to show</Typography>
          </CardContent>
        </TitleCard>
      </Grid>
    );
  }

  return (
    <Grid item {...props.containerWidth}>
      <TitleCard title={props.title}>
        {data === undefined
          ? React.createElement(props.loading || Waiting)
          : React.createElement(props.render, {
              data,
              account: props.account,
              clusterId: props.clusterId,
              onSelection: props.onSelection,
            })}
      </TitleCard>
    </Grid>
  );
};
