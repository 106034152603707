export const validateEmail = (value: string): boolean => {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

// validates 150 characters or fewer. Lowercase letters, digits and -/_ only
export const validateUserName = (value: string): boolean => {
  const usernameRegex = /^([a-z0-9_-]+){1,150}$/;
  if (usernameRegex.test(value)) {
    return true;
  }
  return false;
};

// Must contain at least 10 characters
export const validatePassword = (value: string): boolean => {
  if (value.length >= 10) {
    return true;
  }
  return false;
};
