/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpanUpdate
 */
export interface SpanUpdate {
    /**
     * 
     * @type {string}
     * @memberof SpanUpdate
     */
    identifier: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpanUpdate
     */
    name: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SpanUpdate
     */
    parentSpanId?: string;
    /**
     * 
     * @type {number}
     * @memberof SpanUpdate
     */
    enqueued?: number;
    /**
     * 
     * @type {number}
     * @memberof SpanUpdate
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof SpanUpdate
     */
    stop?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SpanUpdate
     */
    done: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpanUpdate
     */
    nbytesTotal: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof SpanUpdate
     */
    cumulativeWorkerMetrics?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof SpanUpdate
     */
    states?: object;
    /**
     * 
     * @type {object}
     * @memberof SpanUpdate
     */
    allDurations?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof SpanUpdate
     */
    callstack?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SpanUpdate
     */
    collections?: Array<object>;
}

/**
 * Check if a given object implements the SpanUpdate interface.
 */
export function instanceOfSpanUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "done" in value;
    isInstance = isInstance && "nbytesTotal" in value;

    return isInstance;
}

export function SpanUpdateFromJSON(json: any): SpanUpdate {
    return SpanUpdateFromJSONTyped(json, false);
}

export function SpanUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpanUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'name': json['name'],
        'parentSpanId': !exists(json, 'parent_span_id') ? undefined : json['parent_span_id'],
        'enqueued': !exists(json, 'enqueued') ? undefined : json['enqueued'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'stop': !exists(json, 'stop') ? undefined : json['stop'],
        'done': json['done'],
        'nbytesTotal': json['nbytes_total'],
        'cumulativeWorkerMetrics': !exists(json, 'cumulative_worker_metrics') ? undefined : json['cumulative_worker_metrics'],
        'states': !exists(json, 'states') ? undefined : json['states'],
        'allDurations': !exists(json, 'all_durations') ? undefined : json['all_durations'],
        'callstack': !exists(json, 'callstack') ? undefined : json['callstack'],
        'collections': !exists(json, 'collections') ? undefined : json['collections'],
    };
}

export function SpanUpdateToJSON(value?: SpanUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'name': value.name,
        'parent_span_id': value.parentSpanId,
        'enqueued': value.enqueued,
        'start': value.start,
        'stop': value.stop,
        'done': value.done,
        'nbytes_total': value.nbytesTotal,
        'cumulative_worker_metrics': value.cumulativeWorkerMetrics,
        'states': value.states,
        'all_durations': value.allDurations,
        'callstack': value.callstack,
        'collections': value.collections,
    };
}

