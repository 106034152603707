import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  useDeleteGCPRegionSetup,
  useGCPSubnets,
} from "../../../crud/setup/hooks";
import { Add, Delete, Save } from "@mui/icons-material";
import { GCPUserSubnet } from "../../../crud/setup/fetch";
import { HookSwitch } from "../../../shared-components/HookSwitch";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { ExpandableCard } from "../../../shared-components/Cards";
import { LoadingButton } from "@mui/lab";
import { ComponentIcon } from "./StateIcon";
import { GCPRegionalInfraSchema, GCPSubnet } from "../../../crud/setup/types";
import { useOnboarding } from "./hooks";
import { useAnalytics } from "use-analytics";
import { BackendTypeServer } from "../../../domain/people";
import { useIsAccountAdmin } from "../../../crud/account/hooks";

type ConfirmationDialogProps = {
  title: string;
  open: boolean;
  children: React.ReactNode;
  onClose: (success: boolean) => void;
};

const ConfirmationDialog = (
  props: ConfirmationDialogProps,
): React.ReactElement => {
  const { onClose, open, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog open={open} {...other}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

type GCPAddSubnetForm = {
  subnet?: {
    link: string;
    name: string;
  };
  forWorkers: boolean;
  forSchedulers: boolean;
};

type SubnetDialogProps = {
  availableSubnets: GCPUserSubnet[];
  open: boolean;
  onClose: () => void;
  onSubmit: (result: GCPAddSubnetForm) => void;
};

const SubnetDialog = ({
  availableSubnets,
  open,
  onClose,
  onSubmit,
}: SubnetDialogProps): React.ReactElement => {
  const { reset, control, handleSubmit } = useForm<GCPAddSubnetForm>({
    defaultValues: {
      subnet: {
        link: "",
        name: "",
      },
      forWorkers: true,
      forSchedulers: true,
    },
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
          reset();
        })}
      >
        <DialogTitle>Add existing Subnet</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary="Subnet Link" />
              <Controller
                control={control}
                name="subnet"
                rules={{ required: "Link is required" }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, item) => {
                      if (typeof item === "string") {
                        onChange({ link: item, name: item });
                      } else if (item) {
                        onChange(item);
                      }
                    }}
                    value={value}
                    sx={{ minWidth: "400px" }}
                    freeSolo
                    autoComplete
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      } else {
                        return option.name;
                      }
                    }}
                    options={availableSubnets || []}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={value?.name || ""}
                        placeholder="Subnet Link"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Allow Schedulers" />
              <HookSwitch control={control} size="small" name="forSchedulers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Allow Workers" />
              <HookSwitch control={control} size="small" name="forWorkers" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              onClose();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

type RegionFormProps = {
  current: GCPRegionalInfraSchema;
  workspaceSlug: string;
  managed?: boolean;
  onCancel: (id: number) => void;
  onSubmit: (data: GCPRegionalInfraSchema) => void;
};

export const GCPRegionForm = ({
  current,
  workspaceSlug,
  managed,
  onCancel,
  onSubmit,
}: RegionFormProps): React.ReactElement => {
  const analytics = useAnalytics();
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const isNew = current.id === -1;
  const [onboarding] = useOnboarding();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [subnetFormOpen, setSubnetFormOpen] = useState(false);
  const { data: userSubnets } = useGCPSubnets(workspaceSlug, current.region);
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<GCPRegionalInfraSchema>({
    defaultValues: {
      ...current,
      managed,
    },
  });
  const {
    fields: subnetFields,
    append: appendSubnet,
    remove: removeSubnet,
  } = useFieldArray({
    name: "subnets",
    control,
    keyName: "index",
  });
  const subnets = watch("subnets");

  const deleteRegion = useDeleteGCPRegionSetup(workspaceSlug);

  useEffect(() => {
    reset(current);
  }, [current, reset]);
  const region = watch("region");
  const [editMode, setEditMode] = useState(true);
  useEffect(() => {
    if (managed && isNew) {
      removeSubnet();
      appendSubnet({
        region: current.region,
        forSchedulers: true,
        forWorkers: true,
        id: 1,
      });
    } else if (!managed && isNew) {
      removeSubnet();
    }
  }, [setValue, managed, isNew, removeSubnet, appendSubnet, current.region]);

  useEffect(() => {
    if (onboarding && current?.component?.state === "created") {
      // they successfully created the global infra
      // close the form after a short delay
      setTimeout(() => setEditMode(false), 200);
      analytics.track("setup-region-created", {
        provider: BackendTypeServer.GCP_HOSTED,
        region: current.region,
        managed: current.managed,
      });
    }
  }, [reset, current, isNew, onboarding, analytics]);

  const subnetColumns = useMemo<GridColDef<GCPSubnet>[]>(
    () => [
      {
        field: "component.state",
        valueGetter: (params) => params.row.component,
        renderCell: (params) => (
          <ComponentIcon coiledComponent={params.value} />
        ),
        headerName: "Status",
        maxWidth: 65,
      },
      {
        field: "name",
        headerName: "Name",
        width: 300,
      },
      {
        field: "link",
        headerName: "Self Link",
        width: 300,
      },
      {
        field: "forWorkers",
        headerName: "Workers",
        type: "boolean",
        maxWidth: 100,
      },
      {
        field: "forSchedulers",
        headerName: "Schedulers",
        type: "boolean",
        maxWidth: 100,
      },
      {
        field: "cidr",
        headerName: "CIDR",
        maxWidth: 150,
        flex: 1,
      },
      ...(managed || (!managed && !isNew)
        ? []
        : [
            {
              field: "actions",
              type: "actions",
              getActions: (params: GridRowParams) =>
                managed
                  ? []
                  : [
                      <GridActionsCellItem
                        key="delete"
                        icon={
                          <Tooltip key="delete" title="Forget Subnet">
                            <Delete />
                          </Tooltip>
                        }
                        onClick={() => removeSubnet(params.row.index)}
                        label="Delete"
                      />,
                    ],
            },
          ]),
    ],
    [managed, removeSubnet, isNew],
  );
  const complicationsIn = !isNew || !managed;
  return (
    <>
      <ConfirmationDialog
        open={deleteConfirmation}
        onClose={(sucess) => {
          if (sucess) {
            deleteRegion.mutate(current.id);
          }
          setDeleteConfirmation(false);
        }}
        title={"Delete Region"}
      >
        Are you sure you wish to delete the {current.region} region?
      </ConfirmationDialog>
      <SubnetDialog
        open={subnetFormOpen}
        availableSubnets={userSubnets || []}
        onClose={() => setSubnetFormOpen(false)}
        onSubmit={(result) => {
          const existingSubnet = userSubnets?.find(
            (s) => s.link === result.subnet?.link,
          );
          appendSubnet({
            ...result,
            id: subnetFields.length + 1,
            link: result?.subnet?.link,
            name: existingSubnet?.name,
            cidr: existingSubnet?.cidr,
            region: current.region,
            forWorkers: result?.forWorkers,
            forSchedulers: result?.forSchedulers,
          });
          setSubnetFormOpen(false);
        }}
      />
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        style={{ width: "100%" }}
      >
        <ExpandableCard
          elevation={3}
          title={
            current ? (
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <ComponentIcon
                    coiledComponent={current.component}
                    animateIn
                    confetti
                  />

                  <span>Region - {current.region}</span>
                  {current.default && <Chip label="Default" size="small" />}
                  <Chip
                    label={managed ? "Coiled Managed" : "Custom"}
                    size="small"
                  />
                </Stack>
                {!isNew && (
                  <Stack direction={"row"} sx={{ marginLeft: "auto" }}>
                    {!isNew && (
                      <IconButton
                        disabled={!isAdmin || !isDirty}
                        type="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Tooltip title="Save changes">
                          <Save />
                        </Tooltip>
                      </IconButton>
                    )}

                    <IconButton
                      disabled={!isAdmin}
                      onClick={(e) => {
                        if (current.id === -1) {
                          onCancel(current.id);
                        } else {
                          setDeleteConfirmation(true);
                        }
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Tooltip title="Remove this region">
                        <Delete />
                      </Tooltip>
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            ) : (
              `Region - ${region}`
            )
          }
          isOpen={editMode}
          handleClick={() => setEditMode(!editMode)}
        >
          <CardContent>
            {current.component?.state === "error" && (
              <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>
                <AlertTitle>Error </AlertTitle>
                {current.component?.reason}
              </Alert>
            )}
            <List dense>
              <ListItem sx={{ paddingLeft: 0 }}>
                <Tooltip
                  title={
                    "This can only be changed by recreating the global infrastructure"
                  }
                >
                  <div style={{ marginRight: "12px" }}>
                    <HookSwitch control={control} name="managed" disabled />
                  </div>
                </Tooltip>
                <ListItemText primary="Coiled Managed" />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <Tooltip
                  title={
                    !isNew
                      ? "To change the current default, select a new default region"
                      : "Make this region the default for clusters"
                  }
                >
                  <div style={{ marginRight: "12px" }}>
                    <HookSwitch
                      control={control}
                      name="default"
                      disabled={!isNew && current.default}
                    />
                  </div>
                </Tooltip>
                <ListItemText primary="Default Region" />
              </ListItem>
              <Collapse in={complicationsIn} unmountOnExit>
                {!isNew && (
                  <Alert severity="info">
                    To edit resources, delete and recreate the region
                  </Alert>
                )}
                <div>
                  <Divider>Subnets</Divider>
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <DataGridPro
                        rows={subnetFields.map((field, index) => ({
                          ...field,
                          id: index,
                        }))}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              link: false,
                            },
                          },
                        }}
                        localeText={{ noRowsLabel: "No Subnets" }}
                        autoHeight
                        hideFooter
                        density="compact"
                        columns={subnetColumns}
                      />
                      {!managed && (
                        <Tooltip title={"Add Subnet"}>
                          <span>
                            <Button
                              startIcon={<Add />}
                              disabled={!isNew}
                              onClick={() => setSubnetFormOpen(true)}
                            >
                              Add Subnet
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </div>
              </Collapse>
            </List>

            <CardActions>
              {isNew && (
                <Button
                  variant="secondary"
                  startIcon={<Delete />}
                  onClick={() => onCancel(current.id)}
                >
                  Cancel
                </Button>
              )}
              {isNew && (
                <LoadingButton
                  disabled={!subnets?.length || (!isDirty && !isNew)}
                  startIcon={<Save />}
                  type="submit"
                >
                  Create
                </LoadingButton>
              )}
            </CardActions>
          </CardContent>
        </ExpandableCard>
      </form>
    </>
  );
};
