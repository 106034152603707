import { datadogLogs } from "@datadog/browser-logs";
import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../apiUtils";
import {
  InteractionType,
  InteractionSpec,
  InteractionFlag,
  Interaction,
  UserInteractions,
  ServerInteractionTypesResponse,
  ServerInteractionsResponse,
  InteractionFlagNames,
} from "./types";

const hasInteracted = (
  interactionName: InteractionType,
  interactionTypes: InteractionSpec[],
  interactions: Interaction[],
): boolean => {
  const interactionType = interactionTypes.find(
    ({ name }) => name === interactionName,
  );
  if (!interactionType || interactionType.expired) {
    return true;
  }
  return Boolean(
    interactions.find(
      ({ interaction_type }) => interaction_type === interactionName,
    ),
  );
};

export const getInteractions = async (): Promise<UserInteractions> => {
  try {
    // There are a lot of ways we could get really clever about generalizing this.
    // We only have two interactions we care about so I've gone with a very
    // straight-ahead approach.
    const interactionTypesPromise = ky
      .get(`/api/v1/interaction_types/`, {
        headers: getStandardHeaders(),
      })
      .json() as Promise<ServerInteractionTypesResponse>;
    const interactionsPromise = ky
      .get(`/api/v1/interactions/`, {
        headers: getStandardHeaders(),
      })
      .json() as Promise<ServerInteractionsResponse>;
    const [interactionTypes, interactions] = await Promise.all([
      interactionTypesPromise,
      interactionsPromise,
    ]);
    return {
      hasSeenECSWarning: hasInteracted(
        InteractionType.HasSeenECSWarning,
        interactionTypes.results,
        interactions.results,
      ),
      hasSeenUserInfoForm: hasInteracted(
        InteractionType.HasSeenUserInfoForm,
        interactionTypes.results,
        interactions.results,
      ),
      hasSeenSpotDefaultChanged: hasInteracted(
        InteractionType.HasSeenSpotChanged,
        interactionTypes.results,
        interactions.results,
      ),
      hasSeenApiTokenWarning: hasInteracted(
        InteractionType.HasSeenApiTokenWarning,
        interactionTypes.results,
        interactions.results,
      ),
      hasRequestedActivation: hasInteracted(
        InteractionType.HasRequestedActivation,
        interactionTypes.results,
        interactions.results,
      ),
    };
  } catch (err) {
    datadogLogs.logger.error("Error loading user interactions", { error: err });
    // let's not spam them with stuff if there's
    // an unexpected error in our API call
    return {
      hasSeenECSWarning: true,
      hasSeenUserInfoForm: true,
      hasSeenApiTokenWarning: true,
      hasSeenSpotDefaultChanged: true,
      hasRequestedActivation: true,
    };
  }
};

export const postHasSeenInteraction = async (
  interaction: Omit<InteractionType, InteractionType.Unsubscribed>,
): Promise<Response> => {
  return ky.post(`/api/v1/interactions/`, {
    headers: getStandardHeaders(),
    json: {
      interaction_type: interaction,
    },
  });
};

export const getInteractionFlag = async (
  flagName: string,
): Promise<InteractionFlag> => {
  try {
    const payload = await ky
      .get(`/api/v2/interactions/flag/${flagName}`, {
        headers: getStandardHeaders(),
      })
      .json();
    return camelizeKeys(payload) as InteractionFlag;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get interaction flag ${flagName} - Reason: ${err}`,
    );
    throw err;
  }
};

export const getInteractionFlags = async <K extends InteractionFlagNames>(
  flagNames: K[],
): Promise<{ [key in K]: InteractionFlag }> => {
  try {
    const payload = await ky
      .get(`/api/v2/interactions/flag`, {
        headers: getStandardHeaders(),
        searchParams: new URLSearchParams(
          flagNames.map((name) => ["name", name]),
        ),
      })
      .json();
    const camelized = camelizeKeys(payload) as InteractionFlag[];
    return Object.fromEntries(
      camelized.map((flag) => {
        return [flag.name as keyof K, flag];
      }),
    ) as { [key in K]: InteractionFlag };
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get interaction flag ${flagNames} - Reason: ${err}`,
    );
    throw err;
  }
};

export const putInteractionFlag = async (
  flagName: string,
  completed: boolean,
): Promise<InteractionFlag> => {
  try {
    const payload = await ky
      .put(`/api/v2/interactions/flag/${flagName}`, {
        headers: getStandardHeaders(),
        json: { completed },
      })
      .json();
    return camelizeKeys(payload) as InteractionFlag;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to put interaction flag ${flagName} - Reason: ${err}`,
    );
    throw err;
  }
};

export const getUserInteractionKeys = async (
  query: string,
): Promise<string[]> => {
  const resp = await ky.get("/api/v2/interactions/user-interactions/keys", {
    headers: getStandardHeaders(),
    searchParams: new URLSearchParams([["query", query]]),
  });
  return resp.json();
};

export const getUserInteractionValues = async (
  key: string,
  startsWith: string,
): Promise<string[]> => {
  const resp = await ky.get(
    "/api/v2/interactions/user-interactions/key/values",
    {
      headers: getStandardHeaders(),
      searchParams: new URLSearchParams([
        ["key", key],
        ["starts_with", startsWith],
      ]),
    },
  );
  return resp.json();
};
