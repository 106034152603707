import React, { createContext, ReactElement, ReactNode } from "react";
import { UserInteractions } from "../interactions/types";
import { Membership } from "./types";
import { OrganizationMembershipSchema, UserSchema } from "../../api-client";

interface UserContextInterface {
  user: UserSchema;
  memberships: Membership[];
  organizationMemberships: OrganizationMembershipSchema[];
  interactions: UserInteractions;
}

export const UserContext = createContext<UserContextInterface>({
  user: {
    id: 0,
    fullName: "",
    preferredName: "",
    isStaff: false,
    username: "",
    defaultAccount: {
      id: 0,
      slug: "",
      name: "",
      avatarUrl: "",
    },
    defaultOrganization: {
      id: 0,
      name: "",
      isPersonal: true,
    },
    email: "",
    hasUsablePassword: false,
    profile: {
      avatarUrl: "",
      canContact: false,
      company: "",
      phoneNumber: "",
      title: "",
      workEmail: "",
    },
  },
  memberships: [],
  organizationMemberships: [],
  interactions: {
    hasSeenApiTokenWarning: false,
    hasSeenUserInfoForm: false,
    hasSeenSpotDefaultChanged: false,
    hasRequestedActivation: false,
    hasSeenECSWarning: false,
  },
});

type UserContextProviderProps = {
  user: UserSchema;
  memberships: Membership[];
  organizationMemberships: OrganizationMembershipSchema[];
  interactions: UserInteractions;
  children: ReactNode;
};

export const UserContextProvider = ({
  user,
  memberships,
  interactions,
  organizationMemberships,
  children,
}: UserContextProviderProps): ReactElement => {
  return (
    <UserContext.Provider
      value={{
        user,
        interactions,
        memberships,
        organizationMemberships,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
