import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CoiledLogo = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      sx={{
        "& path": {
          fill: "currentColor",
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 746.74 368.87"
      xmlSpace="preserve"
      {...props}
    >
      <defs>
        <style>
          .st0
          {/* .st0{fill:#E8E1D4;} */}
        </style>
      </defs>
      <g>
        <path
          className="st0"
          d="M248.77,265.52h-3.13l-11.67,32.1h3.06l3.42-9.68h13.45l3.42,9.68h3.06l-11.6-32.1h-.01Zm-7.4,19.78l5.69-16.01h.21l5.69,16.01h-11.59Z"
        />
        <path
          className="st0"
          d="M299.87,272.92c-1.14-2.42-2.85-4.2-4.98-5.48s-4.77-1.92-7.83-1.92h-9.96v32.1h9.32c3.2,0,5.98-.64,8.26-1.92,2.28-1.28,3.99-3.13,5.2-5.55,1.21-2.42,1.78-5.34,1.78-8.68s-.64-6.19-1.78-8.54m-2.49,15.8c-.93,1.99-2.35,3.56-4.2,4.63s-4.13,1.64-6.9,1.64h-6.33v-26.83h6.83c2.63,0,4.77,.57,6.55,1.57s3.13,2.63,3.99,4.63c.93,1.99,1.35,4.34,1.35,7.12,.07,2.78-.36,5.2-1.28,7.26"
        />
        <path
          className="st0"
          d="M323.78,277.26c-.57-1.07-1.21-1.85-2.06-2.49-.85-.64-1.78-1-2.78-1.28-1-.21-1.99-.36-2.85-.36-1.21,0-2.42,.14-3.63,.5s-2.28,.93-3.2,1.71c-.93,.78-1.71,1.85-2.28,3.13l2.63,.93c.43-1.07,1.21-1.92,2.28-2.7s2.49-1.14,4.27-1.14,3.2,.5,4.2,1.42,1.49,2.28,1.49,3.91v.57c0,.64-.28,1.07-.71,1.35-.5,.28-1.21,.5-2.13,.57-.93,.07-2.06,.28-3.42,.43-1.35,.14-2.56,.43-3.7,.71s-2.13,.71-2.92,1.21c-.85,.5-1.49,1.21-1.92,1.99-.43,.85-.71,1.85-.71,2.99,0,1.57,.36,2.85,1.07,3.91,.71,1.07,1.64,1.92,2.85,2.49,1.21,.57,2.49,.85,3.99,.85,1.35,0,2.49-.21,3.49-.64,1-.43,1.85-1,2.49-1.64,.64-.64,1.14-1.35,1.49-2.06h.14v3.84h2.78v-16.37c-.07-1.42-.28-2.78-.85-3.84m-1.99,11.1c0,1.35-.28,2.49-.85,3.63-.57,1.07-1.42,1.99-2.49,2.63-1.07,.64-2.35,1-3.84,1s-2.92-.36-3.91-1.21c-1.07-.78-1.57-1.92-1.57-3.42,0-1,.28-1.78,.78-2.42s1.28-1.14,2.21-1.49c.93-.36,2.13-.64,3.49-.78,.5-.07,1.07-.14,1.71-.21,.64-.07,1.28-.21,1.92-.28,.64-.14,1.21-.28,1.71-.43,.5-.14,.85-.36,1.07-.57v3.56h-.23Z"
        />
        <path
          className="st0"
          d="M347.48,278.83l-2.56,.71c-.21-.71-.57-1.35-1.07-1.99-.5-.64-1.07-1.07-1.78-1.42s-1.64-.5-2.7-.5c-1.64,0-2.99,.36-3.99,1.14-1.07,.78-1.57,1.78-1.57,2.99,0,1.07,.36,1.85,1.07,2.49,.71,.64,1.85,1.14,3.34,1.49l3.63,.85c1.99,.5,3.56,1.28,4.55,2.35,.99,1.07,1.49,2.42,1.49,3.99,0,1.35-.36,2.56-1.14,3.63-.78,1.07-1.78,1.85-3.13,2.49s-2.92,.93-4.63,.93c-2.35,0-4.27-.5-5.84-1.57-1.49-1.07-2.49-2.56-2.92-4.63l2.7-.64c.36,1.42,1,2.49,2.06,3.27,1,.71,2.35,1.07,3.99,1.07,1.85,0,3.34-.43,4.41-1.28,1.07-.85,1.64-1.85,1.64-3.13,0-1-.36-1.78-1-2.49-.64-.64-1.64-1.14-2.92-1.49l-3.91-.93c-2.06-.5-3.63-1.28-4.63-2.42s-1.49-2.42-1.49-4.06c0-1.35,.36-2.49,1.07-3.49,.71-1,1.71-1.78,2.99-2.35s2.7-.85,4.34-.85c2.21,0,3.99,.5,5.27,1.49,1.28,1,2.13,2.56,2.7,4.34"
        />
        <polygon points="371.82 297.61 368.26 297.61 358.87 285.66 356.09 288.29 356.09 297.61 353.32 297.61 353.32 265.52 356.09 265.52 356.09 284.88 356.45 284.88 367.69 273.56 371.25 273.56 360.93 283.88 371.82 297.61" />
        <path
          className="st0"
          d="M413.24,275.55h-2.92c-.21-1.07-.64-2.13-1.21-3.06s-1.28-1.78-2.13-2.49-1.85-1.28-2.92-1.64-2.28-.57-3.56-.57c-1.99,0-3.84,.5-5.55,1.57-1.71,1.07-2.99,2.63-3.99,4.63-1,2.06-1.49,4.55-1.49,7.47s.5,5.48,1.49,7.54c1,2.06,2.35,3.56,3.99,4.63s3.49,1.57,5.55,1.57c1.28,0,2.42-.21,3.56-.57,1.07-.36,2.06-.93,2.92-1.64s1.57-1.49,2.13-2.49c.57-.93,.93-1.99,1.21-3.06h2.92c-.28,1.49-.71,2.85-1.49,4.13-.78,1.28-1.64,2.35-2.7,3.34-1.07,1-2.35,1.71-3.77,2.21-1.42,.5-2.99,.78-4.7,.78-2.7,0-5.12-.71-7.19-2.06-2.06-1.35-3.7-3.27-4.91-5.69s-1.78-5.34-1.78-8.75,.57-6.26,1.78-8.75,2.85-4.34,4.91-5.69c2.06-1.35,4.48-2.06,7.19-2.06,1.71,0,3.27,.28,4.7,.78,1.42,.5,2.7,1.28,3.77,2.21s1.99,2.06,2.7,3.34,1.21,2.85,1.49,4.34"
        />
        <path
          className="st0"
          d="M437.51,279.18c-.93-1.85-2.13-3.34-3.7-4.41s-3.42-1.57-5.48-1.57-3.91,.5-5.48,1.57-2.85,2.49-3.7,4.41c-.85,1.92-1.35,4.06-1.35,6.55s.43,4.63,1.35,6.48c.93,1.85,2.13,3.34,3.7,4.41s3.42,1.57,5.48,1.57,3.91-.5,5.48-1.57,2.85-2.49,3.7-4.41c.93-1.85,1.35-3.99,1.35-6.48s-.43-4.7-1.35-6.55m-2.35,11.46c-.57,1.49-1.49,2.7-2.63,3.56-1.14,.93-2.56,1.35-4.27,1.35s-3.06-.43-4.27-1.35c-1.14-.93-2.06-2.06-2.63-3.56-.57-1.49-.93-3.13-.93-4.91s.28-3.49,.93-4.98c.57-1.49,1.49-2.7,2.63-3.63s2.56-1.35,4.27-1.35,3.06,.43,4.27,1.35c1.14,.93,2.06,2.13,2.63,3.63,.64,1.49,.93,3.13,.93,4.98s-.36,3.42-.93,4.91"
        />
        <path
          className="st0"
          d="M444.34,297.61v-24.05h2.7v3.7h.21c.5-1.28,1.28-2.21,2.42-2.92s2.49-1.07,4.06-1.07,2.99,.36,4.13,1.14c1.07,.78,1.85,1.85,2.42,3.2h.21c.57-1.35,1.42-2.42,2.63-3.2s2.7-1.21,4.48-1.21c2.21,0,3.99,.71,5.27,2.13,1.28,1.42,1.92,3.49,1.92,6.12v16.15h-2.78v-16.15c0-1.92-.5-3.34-1.42-4.27-.93-.93-2.28-1.42-3.84-1.42-1.85,0-3.27,.57-4.27,1.71-1,1.14-1.49,2.56-1.49,4.27v15.87h-2.85v-16.37c0-1.64-.43-2.92-1.35-3.91-.92-.99-2.21-1.49-3.84-1.49-1.14,0-2.13,.28-2.99,.78-.85,.5-1.57,1.28-2.06,2.28-.5,1-.78,2.06-.78,3.34v15.44l-2.78-.07h0Z"
        />
        <path
          className="st0"
          d="M500.63,279.11c-.85-1.85-1.99-3.34-3.49-4.34-1.49-1.07-3.2-1.57-5.2-1.57-1.49,0-2.7,.28-3.63,.78-1,.5-1.78,1.14-2.35,1.92-.57,.78-1.07,1.49-1.35,2.21h-.36v-4.55h-2.7v33.09h2.78v-13.52h.28c.36,.71,.78,1.49,1.35,2.28,.57,.78,1.42,1.42,2.35,1.92s2.21,.78,3.63,.78c1.99,0,3.7-.5,5.2-1.57s2.63-2.49,3.49-4.34c.85-1.85,1.28-4.06,1.28-6.55s-.5-4.7-1.28-6.55m-2.42,11.53c-.57,1.49-1.42,2.7-2.49,3.56-1.14,.85-2.49,1.35-4.06,1.35s-2.85-.43-3.99-1.28c-1.07-.85-1.92-1.99-2.49-3.56-.57-1.49-.85-3.2-.85-5.12s.28-3.63,.85-5.12c.57-1.49,1.42-2.63,2.49-3.49s2.42-1.28,3.99-1.28,2.99,.43,4.06,1.28,1.92,2.06,2.49,3.56c.57,1.49,.85,3.13,.85,4.98s-.28,3.63-.85,5.12"
        />
        <path
          className="st0"
          d="M523.69,277.26c-.57-1.07-1.21-1.85-2.06-2.49-.85-.64-1.78-1-2.78-1.28-1-.21-1.99-.36-2.85-.36-1.21,0-2.42,.14-3.63,.5s-2.28,.93-3.2,1.71c-.93,.78-1.71,1.85-2.28,3.13l2.63,.93c.43-1.07,1.21-1.92,2.28-2.7s2.49-1.14,4.27-1.14,3.2,.5,4.2,1.42,1.49,2.28,1.49,3.91v.57c0,.64-.28,1.07-.71,1.35-.5,.28-1.21,.5-2.13,.57-.93,.14-2.06,.28-3.42,.43-1.35,.14-2.56,.43-3.7,.71s-2.13,.71-2.92,1.21c-.85,.5-1.49,1.21-1.92,1.99-.43,.85-.71,1.85-.71,2.99,0,1.57,.36,2.85,1.07,3.91,.71,1.07,1.64,1.92,2.85,2.49,1.21,.57,2.49,.85,3.99,.85,1.35,0,2.49-.21,3.49-.64,1-.43,1.85-1,2.49-1.64s1.14-1.35,1.49-2.06h.14v3.84h2.78v-16.37c-.07-1.42-.36-2.78-.85-3.84m-1.99,11.1c0,1.35-.28,2.49-.85,3.63-.57,1.07-1.42,1.99-2.49,2.63s-2.35,1-3.84,1-2.92-.36-3.91-1.21c-1.07-.78-1.57-1.92-1.57-3.42,0-1,.28-1.78,.78-2.42s1.28-1.14,2.21-1.49c.93-.36,2.13-.64,3.49-.78,.5-.07,1.07-.14,1.71-.21,.64-.07,1.28-.21,1.85-.28,.64-.14,1.21-.28,1.71-.43s.85-.36,1.07-.57v3.56h-.16Z"
        />
        <path
          className="st0"
          d="M534.01,282.6v15.02h-2.78v-24.05h2.7v3.77h.21c.57-1.21,1.42-2.21,2.63-2.99,1.21-.71,2.7-1.14,4.41-1.14s3.06,.36,4.27,1c1.21,.71,2.21,1.64,2.85,2.99,.64,1.35,1,2.92,1,4.84v15.59h-2.78v-15.44c0-1.99-.57-3.56-1.64-4.7s-2.56-1.71-4.41-1.71c-1.28,0-2.35,.28-3.34,.78-1,.5-1.78,1.35-2.35,2.35-.5,1.07-.78,2.28-.78,3.7"
        />
        <path
          className="st0"
          d="M557.21,306.65c-.57,0-1.07-.07-1.57-.14s-.85-.21-1.14-.36l.78-2.49c.93,.28,1.78,.43,2.56,.36,.71-.07,1.42-.43,1.99-1s1.07-1.49,1.57-2.7l.85-2.42-8.97-24.34h2.99l7.12,20.35h.21l7.12-20.35h2.99l-10.18,27.9c-.43,1.14-.93,2.13-1.57,2.92-.64,.78-1.35,1.35-2.13,1.71-.71,.36-1.64,.57-2.63,.57"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M85.57,210.52C38.76,210.97,.45,173.39,0,126.58-.45,79.77,37.13,41.46,83.94,41.01h1.63V210.52Z"
        />
        <path
          className="st0"
          d="M155.38,234.07c0-34.93-21.42-66.28-53.97-78.97v157.93c32.54-12.69,53.97-44.03,53.97-78.96Z"
        />
        <path
          className="st0"
          d="M101.53,110.84c30.61,0,55.42-24.8,55.43-55.41C156.96,24.82,132.16,.01,101.55,0h-.02V110.83h0Z"
        />
        <path
          className="st0"
          d="M46.3,304.59c-.02,27.08,15.11,51.9,39.2,64.28V240.33c-24.07,12.38-39.2,37.18-39.2,64.25h0Z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M370.41,186.92c0-30,19.46-49.64,48.92-49.64s49.1,19.64,49.1,49.64-19.46,49.47-49.1,49.47-48.92-19.63-48.92-49.47h0Zm84.02,0c0-22.36-13.83-36.92-35.1-36.92s-35.1,14.55-35.1,36.92,13.83,36.92,35.1,36.92,35.1-14.54,35.1-36.92Z"
        />
        <path className="st0" d="M483.23,140.91h13.83v91.84h-13.83v-91.84Z" />
        <path className="st0" d="M516.84,99.08h13.83V232.77h-13.83V99.08Z" />
        <path
          className="st0"
          d="M650.54,186.92c0-28.73,19.64-49.64,46.56-49.64,14.35-.27,27.79,6.97,35.46,19.09h.18v-57.29h14V232.77h-13.64l.19-15.82h-.19c-7.72,12.3-21.31,19.67-35.83,19.43-26.91,0-46.73-20.91-46.73-49.47h0Zm82.75,.01c0-20.91-14.55-36.92-34.37-36.92s-34.56,16.01-34.56,36.92,14.74,36.92,34.56,36.92,34.37-16.18,34.37-36.92Z"
        />
        <path
          className="st0"
          d="M355.97,142.56h-17.81c-1.05-4.71-2.88-8.85-5.49-12.41-2.61-3.57-5.77-6.57-9.48-9.01-3.71-2.44-7.82-4.28-12.31-5.51-4.5-1.23-8.18-1.85-13.06-1.85-8.9,0-16.96,2.07-24.16,6.21-7.2,4.14-12.92,10.24-17.16,18.29-4.24,8.06-6.35,17.94-6.35,29.65s2.12,21.59,6.35,29.65c4.24,8.06,9.96,14.15,17.16,18.29,7.2,4.14,15.26,6.21,24.16,6.21,4.88,0,9.57-.62,14.07-1.85,4.5-1.23,8.6-3.07,12.31-5.51,3.71-2.44,6.87-5.46,9.48-9.05,2.61-3.59,4.44-7.71,5.49-12.38h17.81c-1.34,6.91-3.78,13.1-7.32,18.56-3.54,5.46-7.95,10.09-13.21,13.9-5.27,3.81-11.17,6.7-17.7,8.68-6.53,1.98-13.51,2.97-20.93,2.97-12.54,0-23.69-2.82-33.46-8.45-9.77-5.63-17.45-13.65-23.05-24.03-5.6-10.39-8.4-22.71-8.4-36.98s2.8-26.59,8.4-36.98c5.6-10.39,13.28-18.4,23.05-24.04,9.76-5.63,20.92-8.45,33.46-8.45,7.42,0,13.38,.99,19.92,2.97,6.53,1.98,12.43,4.88,17.7,8.68,5.26,3.81,9.67,8.43,13.21,13.87,3.54,5.44,5.98,11.63,7.32,18.59Z"
        />
        <path
          className="st0"
          d="M638.69,185.28c0-8.98-1.29-16.54-3.88-22.68-2.59-6.14-6.07-11.08-10.43-14.82s-9.2-6.45-14.51-8.12c-5.32-1.67-10.7-2.51-16.16-2.51-9.44,0-17.7,2.12-24.77,6.36-7.06,4.25-12.56,10.14-16.49,17.68-3.93,7.54-5.89,16.24-5.89,26.1s1.96,18.5,5.89,25.91c3.93,7.42,9.54,13.18,16.83,17.3,7.3,4.11,15.98,6.17,26.07,6.17,7.09,0,13.47-1.01,19.15-3.04,5.68-2.02,10.45-4.86,14.31-8.52,3.86-3.66,6.61-7.91,8.24-12.75l.99-4.06-16.52,.05c-1.37,3.34-3.3,6.13-5.79,8.36-2.5,2.24-5.47,3.91-8.9,5.02-3.43,1.1-7.25,1.66-11.48,1.66-6.5,0-12.2-1.31-17.1-3.92-4.91-2.61-8.74-6.43-11.49-11.47-2.49-4.56-3.85-10.05-4.08-16.45h76.01v-6.27Zm-75.97-6.51c.3-4.47,1.53-8.69,3.67-12.66,2.5-4.64,6.07-8.41,10.73-11.32,4.66-2.9,10.19-4.35,16.59-4.35,5.82,0,10.87,1.23,15.17,3.7,4.29,2.46,7.61,5.82,9.95,10.09,2.34,4.26,3.51,9.1,3.51,14.54h-59.62Z"
        />
        <path
          className="st0"
          d="M481.74,112.03c0-4.88,3.27-8.13,8.33-8.13s8.49,3.25,8.49,8.13-3.57,8.28-8.49,8.28-8.33-3.25-8.33-8.28h0Z"
        />
      </g>
    </SvgIcon>
  );
};
