/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirewallSpec } from './FirewallSpec';
import {
    FirewallSpecFromJSON,
    FirewallSpecFromJSONTyped,
    FirewallSpecToJSON,
} from './FirewallSpec';

/**
 * 
 * @export
 * @interface ClusterOptionsFrontendSchema
 */
export interface ClusterOptionsFrontendSchema {
    /**
     * 
     * @type {number}
     * @memberof ClusterOptionsFrontendSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ClusterOptionsFrontendSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClusterOptionsFrontendSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptionsFrontendSchema
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptionsFrontendSchema
     */
    zoneName?: string;
    /**
     * 
     * @type {FirewallSpec}
     * @memberof ClusterOptionsFrontendSchema
     */
    firewallSpec?: FirewallSpec;
}

/**
 * Check if a given object implements the ClusterOptionsFrontendSchema interface.
 */
export function instanceOfClusterOptionsFrontendSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ClusterOptionsFrontendSchemaFromJSON(json: any): ClusterOptionsFrontendSchema {
    return ClusterOptionsFrontendSchemaFromJSONTyped(json, false);
}

export function ClusterOptionsFrontendSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterOptionsFrontendSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'zoneName': !exists(json, 'zone_name') ? undefined : json['zone_name'],
        'firewallSpec': !exists(json, 'firewall_spec') ? undefined : FirewallSpecFromJSON(json['firewall_spec']),
    };
}

export function ClusterOptionsFrontendSchemaToJSON(value?: ClusterOptionsFrontendSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'region_name': value.regionName,
        'zone_name': value.zoneName,
        'firewall_spec': FirewallSpecToJSON(value.firewallSpec),
    };
}

