/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserListSchema
 */
export interface UserListSchema {
    /**
     * 
     * @type {number}
     * @memberof UserListSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserListSchema
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserListSchema
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListSchema
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListSchema
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListSchema
     */
    title?: string;
}

/**
 * Check if a given object implements the UserListSchema interface.
 */
export function instanceOfUserListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function UserListSchemaFromJSON(json: any): UserListSchema {
    return UserListSchemaFromJSONTyped(json, false);
}

export function UserListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function UserListSchemaToJSON(value?: UserListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'avatar_url': value.avatarUrl,
        'title': value.title,
    };
}

