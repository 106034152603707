import ky from "ky";
import { getStandardHeaders } from "../../apiUtils";
import { Banner } from "./types";

export const getBanners = async (): Promise<Banner[]> => {
  const payload = (await ky
    .get(`/api/v1/banners`, {
      headers: getStandardHeaders(),
    })
    .json()) as any;
  return payload.results.map((b: any) => ({
    ...b,
    start: new Date(b.start),
    end: new Date(b.end),
  })) as Banner[];
};
