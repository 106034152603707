/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CacheEntrySchema
 */
export interface CacheEntrySchema {
    /**
     * 
     * @type {number}
     * @memberof CacheEntrySchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CacheEntrySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CacheEntrySchema
     */
    platform: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CacheEntrySchema
     */
    versions: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CacheEntrySchema
     */
    channelUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CacheEntrySchema
     */
    pythonVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof CacheEntrySchema
     */
    source: CacheEntrySchemaSourceEnum;
}


/**
 * @export
 */
export const CacheEntrySchemaSourceEnum = {
    Conda: 'conda',
    Pip: 'pip'
} as const;
export type CacheEntrySchemaSourceEnum = typeof CacheEntrySchemaSourceEnum[keyof typeof CacheEntrySchemaSourceEnum];


/**
 * Check if a given object implements the CacheEntrySchema interface.
 */
export function instanceOfCacheEntrySchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "versions" in value;
    isInstance = isInstance && "channelUrl" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function CacheEntrySchemaFromJSON(json: any): CacheEntrySchema {
    return CacheEntrySchemaFromJSONTyped(json, false);
}

export function CacheEntrySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CacheEntrySchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'platform': json['platform'],
        'versions': json['versions'],
        'channelUrl': json['channel_url'],
        'pythonVersion': !exists(json, 'python_version') ? undefined : json['python_version'],
        'source': json['source'],
    };
}

export function CacheEntrySchemaToJSON(value?: CacheEntrySchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'platform': value.platform,
        'versions': value.versions,
        'channel_url': value.channelUrl,
        'python_version': value.pythonVersion,
        'source': value.source,
    };
}

