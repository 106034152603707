/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GCPSubnetCreateSchema } from './GCPSubnetCreateSchema';
import {
    GCPSubnetCreateSchemaFromJSON,
    GCPSubnetCreateSchemaFromJSONTyped,
    GCPSubnetCreateSchemaToJSON,
} from './GCPSubnetCreateSchema';

/**
 * 
 * @export
 * @interface GCPRegionalInfraCreateSchema
 */
export interface GCPRegionalInfraCreateSchema {
    /**
     * 
     * @type {boolean}
     * @memberof GCPRegionalInfraCreateSchema
     */
    _default: boolean;
    /**
     * 
     * @type {string}
     * @memberof GCPRegionalInfraCreateSchema
     */
    region: string;
    /**
     * 
     * @type {boolean}
     * @memberof GCPRegionalInfraCreateSchema
     */
    managed: boolean;
    /**
     * 
     * @type {Array<GCPSubnetCreateSchema>}
     * @memberof GCPRegionalInfraCreateSchema
     */
    subnets: Array<GCPSubnetCreateSchema>;
}

/**
 * Check if a given object implements the GCPRegionalInfraCreateSchema interface.
 */
export function instanceOfGCPRegionalInfraCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "managed" in value;
    isInstance = isInstance && "subnets" in value;

    return isInstance;
}

export function GCPRegionalInfraCreateSchemaFromJSON(json: any): GCPRegionalInfraCreateSchema {
    return GCPRegionalInfraCreateSchemaFromJSONTyped(json, false);
}

export function GCPRegionalInfraCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPRegionalInfraCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_default': json['default'],
        'region': json['region'],
        'managed': json['managed'],
        'subnets': ((json['subnets'] as Array<any>).map(GCPSubnetCreateSchemaFromJSON)),
    };
}

export function GCPRegionalInfraCreateSchemaToJSON(value?: GCPRegionalInfraCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default': value._default,
        'region': value.region,
        'managed': value.managed,
        'subnets': ((value.subnets as Array<any>).map(GCPSubnetCreateSchemaToJSON)),
    };
}

