import React, { ReactElement } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { datadogLogs } from "@datadog/browser-logs";
import { MinimalLayout } from "./Layouts";
import { PageCouldntLoad } from "./shared-components/PageCouldntLoad";
import { HTTPError } from "ky";
import { ApiError } from "./apiUtils";

type Props = {
  children?: ReactElement;
};
type State = {
  errorLines: string[];
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorLines: [] };
  }

  static getDerivedStateFromError(error: Error): State {
    const status = (error as HTTPError)?.response?.status;

    // Add more error cases here in the future
    if (status === 404) {
      return {
        errorLines: [ApiError.NotFound],
      };
    } else {
      return {
        errorLines: [
          "Oops! An unknown error occurred loading the app.",
          "Please reach out if you need any help.",
        ],
      };
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    datadogLogs.logger.error("Uncaught error caught by error boundary", {
      error: error.toString(),
      errorInfo: errorInfo.componentStack,
    });
  }

  render(): ReactElement | undefined {
    const { children } = this.props;
    if (this.state.errorLines.length > 0) {
      return (
        <MinimalLayout>
          <PageCouldntLoad errorLines={this.state.errorLines} />
          <Container
            maxWidth="xs"
            sx={{
              padding: "32px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button href="https://github.com/coiled/feedback/issues/new/choose">
              open an issue
            </Button>
          </Container>
        </MinimalLayout>
      );
    }
    return children;
  }
}
