import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const DashboardIcon = (): React.ReactElement => {
  return (
    <SvgIcon>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 26 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon-/-dashboard-/-dark"
            transform="translate(0.000000, 0.000000)"
            stroke="currentColor"
          >
            <rect id="Rectangle" x="0.5" y="0.5" width="11" height="11" />
            <rect
              id="Rectangle-Copy-12"
              x="0.5"
              y="14.5"
              width="11"
              height="11"
            />
            <rect
              id="Rectangle-Copy-11"
              x="14.5"
              y="0.5"
              width="11"
              height="11"
            />
            <rect
              id="Rectangle-Copy-13"
              x="14.5"
              y="14.5"
              width="11"
              height="11"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
