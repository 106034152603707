import { Chip, ChipProps, Tooltip } from "@mui/material";
import React from "react";

type BuildStatusChipProps = {
  status?: string;
  reason?: string;
};
export const BuildStatusChip = ({
  status,
  reason,
  ...chipProps
}: BuildStatusChipProps & ChipProps): React.ReactElement => {
  switch (status) {
    case "error":
      return (
        <Tooltip title={reason}>
          <Chip
            size="small"
            variant="outlined"
            color="error"
            label="Error"
            {...chipProps}
          />
        </Tooltip>
      );
    case "built":
      return (
        <Tooltip title={reason}>
          <Chip
            size="small"
            variant="outlined"
            color="success"
            label="Built"
            {...chipProps}
          />
        </Tooltip>
      );
    case undefined:
      return <></>;
    default:
      return (
        <Tooltip title={reason}>
          <Chip
            size="small"
            variant="outlined"
            color="warning"
            label={status}
            {...chipProps}
          />
        </Tooltip>
      );
  }
};
