import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Page } from "../../shared-components/Page";
import { GetStarted } from "./GetStarted";
import { Routes, Route } from "react-router-dom";

export const GetStartedRouter = (): React.ReactElement => {
  const breadCrumbs = useMemo(() => [{ text: "Get Started" }], []);
  return (
    <Routes>
      <Route
        path=""
        element={
          <Page title="Get Started" breadCrumbs={breadCrumbs}>
            <Helmet>
              <script
                defer
                data-domain="coiled.io,cloud.coiled.io"
                src="https://plausible.io/js/script.js"
              />
            </Helmet>
            <GetStarted />
          </Page>
        }
      />
    </Routes>
  );
};
