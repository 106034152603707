import { alpha, createTheme, darken } from "@mui/material";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}
declare module "@mui/lab/LoadingButton" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

// These extend the Palette to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      disabledBackground: string;
      yellow: string;
      orange: string;
      white: string;
      red: {
        main: string;
        pale: string;
      };
      blue: {
        light: string;
        main: string;
        dark: string;
        pale: string;
      };
      grey: {
        neutral: string;
        dark: string;
        warm: string;
        cool: string;
      };
      chartColors: string[];
    };
  }
  interface PaletteOptions {
    custom: {
      disabledBackground: string;
      yellow: string;
      orange: string;
      white: string;
      red: {
        main: string;
        pale: string;
      };
      blue: {
        light: string;
        main: string;
        dark: string;
        pale: string;
      };
      grey: {
        neutral: string;
        dark: string;
        warm: string;
        cool: string;
      };
      chartColors: string[];
    };
  }
}
// hex palette colors
const white = "#ffffff";
const brownBlack = "#22252A";
const darkBrown = "#191B1F";
const darkGrey = "#908e89";
const warmGrey = "#d5cec7";
const grey = "#E9E9E9";
const coolGrey = "#f5f6fa";
const red = "#d93e00";
const yellow = "#f2ff26";
const orange = "#ff682c";
const lightBlue = "#4C7CFF";
const blue = "#1F5AFF";
const darkBlue = darken(blue, 0.2);
const paleBlue = "#f5f6fb";

const paleRed = "#fbefea";

const globalTheme = createTheme({
  palette: {
    primary: {
      main: darkBrown,
      dark: brownBlack,
      light: grey,
      contrastText: coolGrey,
    },
    secondary: {
      main: blue,
      dark: darken(blue, 0.15),
      light: lightBlue,
      contrastText: coolGrey,
    },
    custom: {
      disabledBackground: alpha(grey, 0.5),
      blue: {
        light: lightBlue,
        main: blue,
        dark: darkBlue,
        pale: paleBlue,
      },
      grey: {
        neutral: grey,
        dark: darkGrey,
        warm: warmGrey,
        cool: coolGrey,
      },
      yellow,
      orange,
      white,
      red: {
        main: red,
        pale: paleRed,
      },
      chartColors: [
        "#7EB26D", // 0: pale green
        "#EAB839", // 1: mustard
        "#6ED0E0", // 2: light blue
        // '#EF843C', // 3: orange
        // '#E24D42', // 4: red
        "#1F78C1", // 5: ocean
        "#BA43A9", // 6: purple
        "#705DA0", // 7: violet
        "#508642", // 8: dark green
        "#CCA300", // 9: dark sand
        "#447EBC",
        "#C15C17",
        "#890F02",
        "#0A437C",
        "#6D1F62",
        "#584477",
        "#B7DBAB",
        "#F4D598",
        "#70DBED",
        "#F9BA8F",
        "#F29191",
        "#82B5D8",
        "#E5A8E2",
        "#AEA2E0",
        "#629E51",
        "#E5AC0E",
        "#64B0C8",
        "#E0752D",
        "#BF1B00",
        "#0A50A1",
        "#962D82",
        "#614D93",
        "#9AC48A",
        "#F2C96D",
        "#65C5DB",
        "#F9934E",
        "#EA6460",
        "#5195CE",
        "#D683CE",
        "#806EB7",
        "#3F6833",
        "#967302",
        "#2F575E",
        "#99440A",
        "#58140C",
        "#052B51",
        "#511749",
        "#3F2B5B",
        "#E0F9D7",
        "#FCEACA",
        "#CFFAFF",
        "#F9E2D2",
        "#FCE2DE",
        "#BADFF4",
        "#F9D9F9",
        "#DEDAF7",
      ],
    },
    background: {
      default: grey,
    },
    error: {
      main: red,
    },
    text: {
      primary: darkBrown,
    },
  },
  typography: {
    fontWeightMedium: 400,
    // fontFamily: "mundial, Roboto Mono",
    h1: {
      fontFamily: "mundial, sans-serif",
      fontSize: "34px",
    },
    h2: {
      fontFamily: "mundial, sans-serif",
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "16px",
    },
    h3: {
      fontFamily: "mundial, sans-serif",
      fontSize: "16px",
      fontWeight: 600,
    },
    // CTA
    button: {
      fontFamily: "mundial, sans-serif",
      textTransform: "capitalize",
      fontSize: "1rem",
    },
    // NOTE body1 and body2 are switched from the original style guide
    // to match Typography defaulting to body1 being the standard font style
    // body1 is, inter alia, sidebar nav text
    body1: {
      fontFamily: "mundial, sans-serif",
      color: darkBrown,
      fontSize: "16px",
    },
    // body2 is, inter alia, text in datagrids
    body2: {
      fontFamily: "mundial, sans-serif",
      color: darkBrown,
      fontSize: "16px",
      fontWeight: 300,
    },
    // detail 1
    subtitle1: {
      fontFamily: "mundial, sans-serif",
      fontSize: "12px",
    },
    // detail 2
    subtitle2: {
      fontFamily: "mundial, sans-serif",
      fontVariantLigatures: "none",
      fontSize: "13px",
    },
    overline: {
      fontFamily: "mundial, sans-serif",
    },
    caption: {
      fontFamily: "mundial, sans-serif",
    },
  },
});

export const theme = createTheme(
  {
    components: {
      MuiCircularProgress: {
        defaultProps: { color: "secondary" },
      },
      MuiButtonGroup: {
        defaultProps: {
          variant: "primary",
        },
        styleOverrides: {
          firstButton: {
            borderRightColor: "transparent",
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
          lastButton: {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            marginLeft: "-1px",
          },
        },
      },
      MuiCard: {
        defaultProps: { elevation: 0 },
        styleOverrides: {
          root: {
            border: `1px solid ${warmGrey}`,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "primary",
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
            minWidth: "100px",
          },
        },
        variants: [
          {
            props: { variant: "primary" },
            style: {
              backgroundColor: globalTheme.palette.secondary.main,
              color: globalTheme.palette.secondary.contrastText,
            },
          },
          {
            props: { variant: "primary", disabled: false },
            style: {
              boxShadow: globalTheme.shadows[1],
              ":hover": {
                backgroundColor: globalTheme.palette.secondary.dark,
              },
            },
          },
          {
            props: { variant: "primary", disabled: true },
            style: {
              backgroundColor: globalTheme.palette.action.disabledBackground,
              color: globalTheme.palette.action.disabled,
            },
          },
          {
            props: { variant: "primary", size: "small" },
            style: {
              padding: `${globalTheme.spacing(0.4)} ${globalTheme.spacing(2)}`,
              fontSize: globalTheme.typography.pxToRem(13),
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              fontWeight: 400,
              color: globalTheme.palette.primary.main,
              backgroundColor: "transparent",
              border: `1.5px solid ${alpha(
                globalTheme.palette.primary.main,
                0.5,
              )}`,
            },
          },
          {
            props: { variant: "secondary", disabled: false },
            style: {
              ":hover": {
                borderColor: globalTheme.palette.primary.main,
                backgroundColor: alpha(
                  globalTheme.palette.primary.main,
                  globalTheme.palette.action.hoverOpacity,
                ),
              },
            },
          },
          {
            props: { variant: "secondary", disabled: true },
            style: {
              color: globalTheme.palette.action.disabled,
              borderColor: globalTheme.palette.action.disabledBackground,
            },
          },
          {
            props: { variant: "secondary", size: "small" },
            style: {
              padding: `${globalTheme.spacing(0.4)} ${globalTheme.spacing(2)}`,
              fontSize: globalTheme.typography.pxToRem(13),
            },
          },
          {
            props: { variant: "tertiary" },
            style: {
              color: globalTheme.palette.text.secondary,
              textDecoration: "underline",
              textUnderlineOffset: "3px",
              backgroundColor: "transparent",
              fontWeight: 400,
              ":hover": {
                textDecoration: "underline",
                color: globalTheme.palette.text.primary,
                backgroundColor: alpha(
                  globalTheme.palette.primary.main,
                  globalTheme.palette.action.hoverOpacity,
                ),
              },
            },
          },
        ],
      },
      MuiLoadingButton: {
        defaultProps: {
          variant: "primary",
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
            minWidth: "100px",
          },
        },
        variants: [
          {
            props: { variant: "primary" },
            style: {
              backgroundColor: globalTheme.palette.secondary.main,
              color: globalTheme.palette.secondary.contrastText,
            },
          },
          {
            props: { variant: "primary", disabled: false },
            style: {
              boxShadow: globalTheme.shadows[1],
              ":hover": {
                backgroundColor: globalTheme.palette.secondary.dark,
              },
            },
          },
          {
            props: { variant: "primary", disabled: true },
            style: {
              backgroundColor: globalTheme.palette.action.disabledBackground,
              color: globalTheme.palette.action.disabled,
            },
          },
          {
            props: { variant: "primary", size: "small" },
            style: {
              padding: `${globalTheme.spacing(0.4)} ${globalTheme.spacing(2)}`,
              fontSize: globalTheme.typography.pxToRem(13),
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              fontWeight: 400,
              color: globalTheme.palette.primary.main,
              backgroundColor: "transparent",
              border: `1.5px solid ${alpha(
                globalTheme.palette.primary.main,
                0.5,
              )}`,
            },
          },
          {
            props: { variant: "secondary", disabled: false },
            style: {
              ":hover": {
                borderColor: globalTheme.palette.primary.main,
                backgroundColor: alpha(
                  globalTheme.palette.primary.main,
                  globalTheme.palette.action.hoverOpacity,
                ),
              },
            },
          },
          {
            props: { variant: "secondary", disabled: true },
            style: {
              color: globalTheme.palette.action.disabled,
              borderColor: globalTheme.palette.action.disabledBackground,
            },
          },
          {
            props: { variant: "secondary", size: "small" },
            style: {
              padding: `${globalTheme.spacing(0.4)} ${globalTheme.spacing(2)}`,
              fontSize: globalTheme.typography.pxToRem(13),
            },
          },
          {
            props: { variant: "tertiary" },
            style: {
              color: globalTheme.palette.text.secondary,
              textDecoration: "underline",
              textUnderlineOffset: "3px",
              backgroundColor: "transparent",
              fontWeight: 400,
              ":hover": {
                textDecoration: "underline",
                color: globalTheme.palette.text.primary,
                backgroundColor: alpha(
                  globalTheme.palette.primary.main,
                  globalTheme.palette.action.hoverOpacity,
                ),
              },
            },
          },
        ],
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: { variant: "h2" },
        },
        styleOverrides: {
          root: { paddingTop: "8px", paddingBottom: "8px" },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            pre: {
              background: white,
            },
            code: {
              fontFamily: "Roboto Mono, monospace",
              fontVariantLigatures: "none",
              fontSize: "15px",
            },
            body: {
              fontFamily: "mundial, sans-serif",
              backgroundColor: grey,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            // same as h2 typography style, matches card headers
            fontFamily: "mundial, sans-serif",
            textTransform: "uppercase",
            fontSize: "16px",
          },
        },
      },
      MuiDrawer: {
        defaultProps: {
          elevation: 0,
        },
        // overrides MuiPaper styles
        styleOverrides: {
          paper: {
            backgroundColor: darkBrown,
          },
          paperAnchorDockedLeft: {
            borderRight: "none",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "always",
        },
        styleOverrides: {
          root: {
            textUnderlineOffset: "3px",
            "&.MuiLink-root:hover": {
              color: blue,
            },
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          MenuListProps: {
            disablePadding: true,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        // this affects dialogs, tables, cards, drawer
        styleOverrides: {
          root: {
            backgroundColor: white,
          },
          rounded: {
            borderRadius: 7,
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          color: "secondary",
        },
      },
      MuiSwitch: {
        defaultProps: {
          color: "secondary",
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "@media (min-width:600px)": {
              minWidth: "90px",
            },
            textTransform: "none",
            fontFamily: "mundial, sans-serif",
            fontSize: "16px",
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: "secondary",
        },
        styleOverrides: {
          root: {
            backgroundColor: white,
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
            borderBottom: `1px solid ${warmGrey}`,
          },
        },
      },
      MuiDataGrid: {
        defaultProps: {
          slotProps: { baseButton: { variant: "secondary", size: "small" } },
        },
        styleOverrides: {
          root: {
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: "none",
              },
              "&:focus-within": {
                outline: "none",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: "none",
              },
              "&:focus-within": {
                outline: "none",
              },
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            // removes the border-bottom from the last row in a table
            "&:last-child td": {
              borderBottom: 0,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: "mundial, sans-serif",
            fontSize: "small",
          },
        },
      },
    },
  },
  globalTheme,
);
