/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AWSSecurityGroupCreateSchema } from './AWSSecurityGroupCreateSchema';
import {
    AWSSecurityGroupCreateSchemaFromJSON,
    AWSSecurityGroupCreateSchemaFromJSONTyped,
    AWSSecurityGroupCreateSchemaToJSON,
} from './AWSSecurityGroupCreateSchema';
import type { AWSSubnetCreateSchema } from './AWSSubnetCreateSchema';
import {
    AWSSubnetCreateSchemaFromJSON,
    AWSSubnetCreateSchemaFromJSONTyped,
    AWSSubnetCreateSchemaToJSON,
} from './AWSSubnetCreateSchema';
import type { VPCCreateSchema } from './VPCCreateSchema';
import {
    VPCCreateSchemaFromJSON,
    VPCCreateSchemaFromJSONTyped,
    VPCCreateSchemaToJSON,
} from './VPCCreateSchema';

/**
 * 
 * @export
 * @interface AWSRegionalInfraSchemaCreate
 */
export interface AWSRegionalInfraSchemaCreate {
    /**
     * 
     * @type {boolean}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    _default: boolean;
    /**
     * 
     * @type {string}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    region: string;
    /**
     * 
     * @type {boolean}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    managed: boolean;
    /**
     * 
     * @type {VPCCreateSchema}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    vpc?: VPCCreateSchema;
    /**
     * 
     * @type {Array<AWSSubnetCreateSchema>}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    subnets: Array<AWSSubnetCreateSchema>;
    /**
     * 
     * @type {AWSSecurityGroupCreateSchema}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    clusterSecurityGroup?: AWSSecurityGroupCreateSchema;
    /**
     * 
     * @type {AWSSecurityGroupCreateSchema}
     * @memberof AWSRegionalInfraSchemaCreate
     */
    schedulerSecurityGroup?: AWSSecurityGroupCreateSchema;
}

/**
 * Check if a given object implements the AWSRegionalInfraSchemaCreate interface.
 */
export function instanceOfAWSRegionalInfraSchemaCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "managed" in value;
    isInstance = isInstance && "subnets" in value;

    return isInstance;
}

export function AWSRegionalInfraSchemaCreateFromJSON(json: any): AWSRegionalInfraSchemaCreate {
    return AWSRegionalInfraSchemaCreateFromJSONTyped(json, false);
}

export function AWSRegionalInfraSchemaCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSRegionalInfraSchemaCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_default': json['default'],
        'region': json['region'],
        'managed': json['managed'],
        'vpc': !exists(json, 'vpc') ? undefined : VPCCreateSchemaFromJSON(json['vpc']),
        'subnets': ((json['subnets'] as Array<any>).map(AWSSubnetCreateSchemaFromJSON)),
        'clusterSecurityGroup': !exists(json, 'cluster_security_group') ? undefined : AWSSecurityGroupCreateSchemaFromJSON(json['cluster_security_group']),
        'schedulerSecurityGroup': !exists(json, 'scheduler_security_group') ? undefined : AWSSecurityGroupCreateSchemaFromJSON(json['scheduler_security_group']),
    };
}

export function AWSRegionalInfraSchemaCreateToJSON(value?: AWSRegionalInfraSchemaCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default': value._default,
        'region': value.region,
        'managed': value.managed,
        'vpc': VPCCreateSchemaToJSON(value.vpc),
        'subnets': ((value.subnets as Array<any>).map(AWSSubnetCreateSchemaToJSON)),
        'cluster_security_group': AWSSecurityGroupCreateSchemaToJSON(value.clusterSecurityGroup),
        'scheduler_security_group': AWSSecurityGroupCreateSchemaToJSON(value.schedulerSecurityGroup),
    };
}

