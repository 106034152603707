export enum LoginFailReasons {
  InvalidCredentials = "We were unable to log you in with those credentials.",
  SsoRequired = "SSO is required for this user.",
  Unknown = "Something went wrong when we tried to log you in.",
  ValidateEmail = "Your email is not yet verified. Please check your inbox.",
  LockedAccount = "Account locked: too many login attempts. Contact support@coiled.io to unlock your account.",
}

export enum ActivationFailReasons {
  InvalidActivationKey = "Invalid activation key provided. If you believe this is in error, please get in touch with us.",
  Unknown = "Something went wrong, please try again.",
  PasswordRequirements = "Must be at least 10 characters.",
  UsernameRequirements = "150 characters or fewer. Lowercase letters, digits and -/_ only.",
}

export enum PasswordResetFailReasons {
  InvalidActivationKey = "Invalid token provided. Please try again. If you believe this is in error, please get in touch with us.",
  Unknown = "Something went wrong, please try again.",
}
