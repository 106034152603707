/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadPackageRequestSchema
 */
export interface UploadPackageRequestSchema {
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequestSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequestSchema
     */
    md5: string;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequestSchema
     */
    wheelMd5: string;
    /**
     * 
     * @type {string}
     * @memberof UploadPackageRequestSchema
     */
    regionName?: string;
}

/**
 * Check if a given object implements the UploadPackageRequestSchema interface.
 */
export function instanceOfUploadPackageRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "md5" in value;
    isInstance = isInstance && "wheelMd5" in value;

    return isInstance;
}

export function UploadPackageRequestSchemaFromJSON(json: any): UploadPackageRequestSchema {
    return UploadPackageRequestSchemaFromJSONTyped(json, false);
}

export function UploadPackageRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadPackageRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'md5': json['md5'],
        'wheelMd5': json['wheel_md5'],
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
    };
}

export function UploadPackageRequestSchemaToJSON(value?: UploadPackageRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'md5': value.md5,
        'wheel_md5': value.wheelMd5,
        'region_name': value.regionName,
    };
}

