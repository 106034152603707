import { useQuery, UseQueryResult } from "react-query";
import {
  ClusterData,
  ClusterAnalyticsSummary,
} from "../../pages/Clusters/Analytics/ClusterStatistics";
import { useWorkspaceContextSlug } from "../../utils/hooks";
import { getClusterStatistics, getClusterAnalyticsSummary } from "./fetch";
import { TimeRange } from "../../pages/Clusters/types";

export const useClusterStatistics = (
  analyticsId: number | undefined,
): UseQueryResult<ClusterData> => {
  const accountSlug = useWorkspaceContextSlug();
  return useQuery(
    ["cluster-statistics", accountSlug, analyticsId],
    () => {
      return getClusterStatistics(accountSlug, analyticsId!);
    },
    {
      enabled: !!analyticsId,
    },
  );
};

export const useClusterAnalyticsSummary = (
  clusterId?: number,
  defaultTimeRange?: TimeRange,
): UseQueryResult<ClusterAnalyticsSummary> => {
  const accountSlug = useWorkspaceContextSlug();
  return useQuery(
    ["cluster-analytics-summary", accountSlug, clusterId, defaultTimeRange],
    () => {
      return getClusterAnalyticsSummary(accountSlug, clusterId!);
    },
    {
      enabled: !!clusterId,
      keepPreviousData: true,
    },
  );
};
