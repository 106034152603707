import { useQuery, UseQueryResult } from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  DeclarativeViewsUsageGetUsageByUserRequest,
  GroupUsageSchema,
} from "../../api-client";

export const useUsageByUser = (
  query: DeclarativeViewsUsageGetUsageByUserRequest,
): UseQueryResult<GroupUsageSchema> => {
  const result = useQuery(["usageByUser", query], () => {
    return ApiClient.declarativeViewsUsageGetUsageByUser(query);
  });
  return result;
};
