/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StateResponseSchema } from './StateResponseSchema';
import {
    StateResponseSchemaFromJSON,
    StateResponseSchemaFromJSONTyped,
    StateResponseSchemaToJSON,
} from './StateResponseSchema';

/**
 * 
 * @export
 * @interface StatesResponseSchema
 */
export interface StatesResponseSchema {
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    cluster: Array<StateResponseSchema>;
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    scheduler: Array<StateResponseSchema>;
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    schedulerInstance: Array<StateResponseSchema>;
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    worker: Array<StateResponseSchema>;
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    workerInstance: Array<StateResponseSchema>;
    /**
     * 
     * @type {Array<StateResponseSchema>}
     * @memberof StatesResponseSchema
     */
    clusterInfra: Array<StateResponseSchema>;
}

/**
 * Check if a given object implements the StatesResponseSchema interface.
 */
export function instanceOfStatesResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cluster" in value;
    isInstance = isInstance && "scheduler" in value;
    isInstance = isInstance && "schedulerInstance" in value;
    isInstance = isInstance && "worker" in value;
    isInstance = isInstance && "workerInstance" in value;
    isInstance = isInstance && "clusterInfra" in value;

    return isInstance;
}

export function StatesResponseSchemaFromJSON(json: any): StatesResponseSchema {
    return StatesResponseSchemaFromJSONTyped(json, false);
}

export function StatesResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatesResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cluster': ((json['cluster'] as Array<any>).map(StateResponseSchemaFromJSON)),
        'scheduler': ((json['scheduler'] as Array<any>).map(StateResponseSchemaFromJSON)),
        'schedulerInstance': ((json['scheduler_instance'] as Array<any>).map(StateResponseSchemaFromJSON)),
        'worker': ((json['worker'] as Array<any>).map(StateResponseSchemaFromJSON)),
        'workerInstance': ((json['worker_instance'] as Array<any>).map(StateResponseSchemaFromJSON)),
        'clusterInfra': ((json['cluster_infra'] as Array<any>).map(StateResponseSchemaFromJSON)),
    };
}

export function StatesResponseSchemaToJSON(value?: StatesResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cluster': ((value.cluster as Array<any>).map(StateResponseSchemaToJSON)),
        'scheduler': ((value.scheduler as Array<any>).map(StateResponseSchemaToJSON)),
        'scheduler_instance': ((value.schedulerInstance as Array<any>).map(StateResponseSchemaToJSON)),
        'worker': ((value.worker as Array<any>).map(StateResponseSchemaToJSON)),
        'worker_instance': ((value.workerInstance as Array<any>).map(StateResponseSchemaToJSON)),
        'cluster_infra': ((value.clusterInfra as Array<any>).map(StateResponseSchemaToJSON)),
    };
}

