import React, { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useSoftwareBuild } from "../../crud/software/hooks";
import { useTimezone, useWorkspaceContextSlug } from "../../utils/hooks";
import { getSoftwareEnvironmentBuildLogs } from "../../crud/software/fetch";
import { LinearProgress } from "@mui/material";
import { LogLine } from "../../api-client";
import { formatInTimeZone } from "date-fns-tz";

type SoftwareBuildLogsTableProps = {
  buildId: number;
};
export const SoftwareBuildLogsTable = ({
  buildId,
}: SoftwareBuildLogsTableProps): React.ReactElement => {
  const [displayTz] = useTimezone();
  const account = useWorkspaceContextSlug();
  const { data: build } = useSoftwareBuild(buildId);
  const { isLoading, isFetching, data, fetchNextPage } = useInfiniteQuery({
    queryKey: ["buildLogs", buildId],
    queryFn: ({ pageParam }) =>
      getSoftwareEnvironmentBuildLogs(
        account,
        buildId,
        pageParam as string | undefined,
      ),
    ...{
      refetchInterval:
        build?.built || build?.state === "error" ? undefined : 3000,
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.nextToken || undefined;
    },
  });
  useEffect(() => {
    // dealing with odd aws logs api
    // pause when we encounter data
    // otherwise keep paginating until we get a duplicate
    // token back
    if (data?.pages) {
      const lastPage = data?.pages[data.pages.length - 1];
      if (lastPage?.events.length === 0) {
        // we loaded an empty page!
        if (data?.pages.length >= 2) {
          // we have a previous page to compare to
          // if that token matches the current one, we have no more
          // lines to fetch
          const previousPage = data?.pages[data.pages.length - 2];
          if (
            lastPage?.events.length === 0 &&
            lastPage?.nextToken !== previousPage?.nextToken
          ) {
            fetchNextPage();
          }
        } else {
          // no previous page to compare to, we should fetch the next page!
          fetchNextPage();
        }
      }
    }
  }, [data, fetchNextPage]);

  const logs = data?.pages.map((v) => v.events).flat();
  const columns = useMemo<GridColDef<LogLine>[]>(
    () => [
      {
        field: "timestamp",
        type: "dateTime",
        headerName: "Time",
        valueFormatter: ({ value }) => {
          // condensed datetime string
          return formatInTimeZone(value, displayTz, "yyyy-MM-dd HH:mm:ss");
        },
        width: 200,
        sortable: false,
      },
      { field: "message", headerName: "Message", flex: 1, sortable: false },
    ],
    [],
  );
  return (
    <DataGridPro
      getRowId={(row) => row.eventId}
      sx={(theme) => ({
        border: "unset",
        "& .MuiDataGrid-cell": {
          fontFamily: "Roboto Mono, monospace",
          fontSize: theme.typography.pxToRem(13),
          textDecoration: "unset",
        },
        maxHeight: "87vh",
      })}
      loading={isLoading}
      rowBuffer={1000}
      scrollEndThreshold={1000}
      initialState={{
        sorting: {
          sortModel: [{ field: "timestamp", sort: "asc" }],
        },
      }}
      localeText={{
        noRowsLabel: "No logs",
      }}
      slots={{
        footer: LinearProgress,
      }}
      slotProps={{
        footer: { sx: { display: isFetching ? "block" : "none" } },
      }}
      hideFooterRowCount
      hideFooterPagination
      onRowsScrollEnd={() => fetchNextPage()}
      getRowHeight={() => "auto"}
      columns={columns}
      rows={logs || []}
    />
  );
};
