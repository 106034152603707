/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupUsageSchema
 */
export interface GroupUsageSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupUsageSchema
     */
    groups: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof GroupUsageSchema
     */
    usage: Array<{ [key: string]: number; }>;
}

/**
 * Check if a given object implements the GroupUsageSchema interface.
 */
export function instanceOfGroupUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "usage" in value;

    return isInstance;
}

export function GroupUsageSchemaFromJSON(json: any): GroupUsageSchema {
    return GroupUsageSchemaFromJSONTyped(json, false);
}

export function GroupUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': json['groups'],
        'usage': json['usage'],
    };
}

export function GroupUsageSchemaToJSON(value?: GroupUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': value.groups,
        'usage': value.usage,
    };
}

