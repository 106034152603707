/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecurityGroupSchema
 */
export interface SecurityGroupSchema {
    /**
     * 
     * @type {string}
     * @memberof SecurityGroupSchema
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityGroupSchema
     */
    securityGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityGroupSchema
     */
    vpcId: string;
}

/**
 * Check if a given object implements the SecurityGroupSchema interface.
 */
export function instanceOfSecurityGroupSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "vpcId" in value;

    return isInstance;
}

export function SecurityGroupSchemaFromJSON(json: any): SecurityGroupSchema {
    return SecurityGroupSchemaFromJSONTyped(json, false);
}

export function SecurityGroupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityGroupSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupId': json['group_id'],
        'securityGroupName': !exists(json, 'security_group_name') ? undefined : json['security_group_name'],
        'vpcId': json['vpc_id'],
    };
}

export function SecurityGroupSchemaToJSON(value?: SecurityGroupSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.groupId,
        'security_group_name': value.securityGroupName,
        'vpc_id': value.vpcId,
    };
}

