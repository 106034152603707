/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountUsageSchema
 */
export interface AccountUsageSchema {
    /**
     * 
     * @type {number}
     * @memberof AccountUsageSchema
     */
    numRunningCoresUser: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUsageSchema
     */
    numRunningCoresAccount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUsageSchema
     */
    numRunningWorkersUser: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUsageSchema
     */
    numRunningClustersAccount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUsageSchema
     */
    numRunningTasksUser: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUsageSchema
     */
    hasQuota: boolean;
}

/**
 * Check if a given object implements the AccountUsageSchema interface.
 */
export function instanceOfAccountUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numRunningCoresUser" in value;
    isInstance = isInstance && "numRunningCoresAccount" in value;
    isInstance = isInstance && "numRunningWorkersUser" in value;
    isInstance = isInstance && "numRunningClustersAccount" in value;
    isInstance = isInstance && "numRunningTasksUser" in value;
    isInstance = isInstance && "hasQuota" in value;

    return isInstance;
}

export function AccountUsageSchemaFromJSON(json: any): AccountUsageSchema {
    return AccountUsageSchemaFromJSONTyped(json, false);
}

export function AccountUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numRunningCoresUser': json['num_running_cores_user'],
        'numRunningCoresAccount': json['num_running_cores_account'],
        'numRunningWorkersUser': json['num_running_workers_user'],
        'numRunningClustersAccount': json['num_running_clusters_account'],
        'numRunningTasksUser': json['num_running_tasks_user'],
        'hasQuota': json['has_quota'],
    };
}

export function AccountUsageSchemaToJSON(value?: AccountUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_running_cores_user': value.numRunningCoresUser,
        'num_running_cores_account': value.numRunningCoresAccount,
        'num_running_workers_user': value.numRunningWorkersUser,
        'num_running_clusters_account': value.numRunningClustersAccount,
        'num_running_tasks_user': value.numRunningTasksUser,
        'has_quota': value.hasQuota,
    };
}

