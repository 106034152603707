export type UserInteractions = {
  hasSeenECSWarning: boolean;
  hasSeenUserInfoForm: boolean;
  hasSeenApiTokenWarning: boolean;
  hasSeenSpotDefaultChanged: boolean;
  hasRequestedActivation: boolean;
};

export type ServerInteractionTypesResponse = {
  results: InteractionSpec[];
};

export type ServerInteractionsResponse = {
  results: Interaction[];
};

export enum InteractionType {
  HasSeenECSWarning = "warned-about-ecs",
  HasSeenUserInfoForm = "user-info-on-sign-in",
  Unsubscribed = "unsubscribe",
  HasSeenApiTokenWarning = "api-token-message",
  HasSeenSpotChanged = "spot-default-changed",
  HasRequestedActivation = "requested-activation",
}

export type InteractionSpec = {
  name: InteractionType;
  description: string;
  expired: boolean;
};

export type Interaction = {
  interaction_type: InteractionType;
};

export type InteractionFlag = {
  name: InteractionFlagNames;
  completed: boolean;
};

export enum InteractionFlagNames {
  HasApiKey = "has-api-key",
  GetStarted = "get-started",
  AccessToAccountWithCompletedSetup = "access-to-account-with-completed-setup",
  HasCreatedCluster = "has-created-cluster",
  HasCreatedAtLeastThreeClusters = "has-created-at-least-three-clusters",
  HasSeenUserSourceDialog = "user-source-dialog",
  CompletedSetupOnce = "completed-setup-once",
}
