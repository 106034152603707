/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListClusterV2 } from './ListClusterV2';
import {
    ListClusterV2FromJSON,
    ListClusterV2FromJSONTyped,
    ListClusterV2ToJSON,
} from './ListClusterV2';

/**
 * 
 * @export
 * @interface PagedListClusterV2
 */
export interface PagedListClusterV2 {
    /**
     * 
     * @type {Array<ListClusterV2>}
     * @memberof PagedListClusterV2
     */
    items: Array<ListClusterV2>;
    /**
     * 
     * @type {number}
     * @memberof PagedListClusterV2
     */
    count: number;
}

/**
 * Check if a given object implements the PagedListClusterV2 interface.
 */
export function instanceOfPagedListClusterV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function PagedListClusterV2FromJSON(json: any): PagedListClusterV2 {
    return PagedListClusterV2FromJSONTyped(json, false);
}

export function PagedListClusterV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedListClusterV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ListClusterV2FromJSON)),
        'count': json['count'],
    };
}

export function PagedListClusterV2ToJSON(value?: PagedListClusterV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ListClusterV2ToJSON)),
        'count': value.count,
    };
}

