import React, { useContext } from "react";
import {
  FieldPath,
  PathValue,
  SubmitHandler,
  useForm,
  UseFormProps,
} from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import { FormHelperText, MenuItem, Stack } from "@mui/material";
import { UserContext } from "../../crud/user/context";
import { HookSelect } from "../../shared-components/HookSelect";

type Props<TFormShape extends FieldValues> = {
  formActive: boolean;
  submitting: boolean;
  error: boolean;
  errorMessage: string;
  formOptions: UseFormProps<TFormShape>;
  fieldName: FieldPath<TFormShape>;
  value: PathValue<TFormShape, FieldPath<TFormShape>>;
  inputType?: string;
  handleCancel: () => void;
  handleSubmit: SubmitHandler<TFormShape>;
};

export const SingleLineSelectForm = <
  TFormShape extends Record<string, unknown>,
>({
  formActive,
  submitting,
  error,
  errorMessage = "",
  formOptions,
  fieldName,
  value,
  inputType = "text",
  handleCancel,
  handleSubmit,
}: Props<TFormShape>): React.ReactElement => {
  const {
    formState: { errors: formErrors },
    handleSubmit: handleSubmitWrapper,
    setValue,
    clearErrors,
    control,
  } = useForm<TFormShape>(formOptions);

  const { memberships } = useContext(UserContext);
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleCancelButtonClick = () => {
    setValue(fieldName, value);
    clearErrors(fieldName);
    handleCancel();
  };

  error = error || Boolean(formErrors[fieldName]);
  if (formErrors[fieldName]) {
    errorMessage = "This field can't be blank";
  }
  return (
    <form ref={formRef} onSubmit={handleSubmitWrapper(handleSubmit)}>
      <Stack spacing={1}>
        <HookSelect
          name={fieldName}
          control={control}
          required
          disabled={!formActive}
        >
          {memberships.map((a) => (
            <MenuItem value={a.account.slug} key={a.account.slug}>
              {a.account.name}
            </MenuItem>
          ))}
        </HookSelect>
        <FormHelperText>{errorMessage || undefined}</FormHelperText>

        {formActive ? (
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleCancelButtonClick}
              disabled={false}
              size="small"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={false} size="small">
              {submitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Stack>
        ) : (
          ""
        )}
      </Stack>
    </form>
  );
};
