import React, { useEffect } from "react";

import { AWSGlobalInfraForm } from "./AWSGlobalInfraForm";
import { AWSRegionsForm } from "./AWSRegionsForm";
import {
  Stack,
  Alert,
  CardContent,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  DialogProps,
  IconButton,
} from "@mui/material";
import {
  useAWSGlobalSetup,
  useAWSRegionalInfra,
} from "../../../crud/setup/hooks";
import Confetti from "react-dom-confetti";
import Lottie from "lottie-react";
import animationData from "../../../assets/success.lottie.json";
import { Urls } from "../../../domain/urls";
import { Link } from "react-router-dom";
import { useOnboarding } from "./hooks";
import { useAnalytics } from "use-analytics";
import { Close } from "@mui/icons-material";

export const OnboardingSuccessDialog = (
  props: DialogProps,
): React.ReactElement => {
  const [fireConfetti, setFireConfetti] = React.useState(false);
  useEffect(() => {
    if (props.open) {
      setInterval(() => setFireConfetti(true), 300);
    } else {
      setFireConfetti(false);
    }
  }, [props.open]);
  return (
    <Dialog
      {...props}
      PaperProps={{ sx: { overflow: "visible" } }}
      onClose={(e, reason) => {
        if (reason === "escapeKeyDown") {
          props.onClose?.(e, reason);
        }
      }}
    >
      <DialogTitle>
        Setup complete
        <IconButton
          aria-label="close"
          onClick={(e) => props.onClose?.(e, "escapeKeyDown")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack alignItems={"center"}>
          <Confetti
            active={fireConfetti}
            config={{
              elementCount: 50,
              duration: 10000,
            }}
          />
          <Lottie animationData={animationData} height={300} width={300} />
          <Typography>
            {"Congratulations! You've completed the setup process 🎉"}
          </Typography>
          <Typography>
            {"Next, let's play with running some real code on cloud resources"}
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button component={Link} to={"/" + Urls.GetStarted}>
            Continue to Next Steps
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export const AWSInfrastructureForm = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}): React.ReactElement => {
  const [onboarding, setOnboarding] = useOnboarding();
  const analytics = useAnalytics();
  const [shownDialog, setShownDialog] = React.useState(false);
  const { data: awsGlobalInfra } = useAWSGlobalSetup(workspaceSlug);
  const { data: awsRegionalInfra } = useAWSRegionalInfra(workspaceSlug);
  const isComplete =
    awsGlobalInfra?.component?.state === "created" &&
    awsRegionalInfra?.regions &&
    awsRegionalInfra?.regions.length > 0 &&
    awsRegionalInfra?.regions.every(
      (region) => region.component?.state === "created",
    );
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (isComplete && onboarding && !shownDialog) {
      setShownDialog(true);
      setTimeout(() => setOpen(true), 1000);
      setOnboarding(false);
      analytics.track("ui-victory-screen", {
        backend: "vm_aws",
      });
    }
  }, [isComplete, onboarding, shownDialog, setOnboarding, analytics]);
  return (
    <>
      <OnboardingSuccessDialog open={open} onClose={() => setOpen(false)} />
      {onboarding && (
        <Alert
          severity={isComplete ? "success" : "warning"}
          sx={{ borderRadius: 0 }}
        >
          {isComplete && <Typography>{"You're all set!"}</Typography>}
          {!isComplete &&
            awsRegionalInfra &&
            awsRegionalInfra?.regions.length > 0 && (
              <Typography>
                {
                  "We're just setting up some things, this should take less than a minute."
                }
              </Typography>
            )}
          {!isComplete &&
            awsGlobalInfra &&
            awsRegionalInfra &&
            awsRegionalInfra?.regions.length === 0 && (
              <Typography>{"Add a region to get started."}</Typography>
            )}
          {!isComplete && !awsGlobalInfra && (
            <Typography>{"Create global infra to get started."}</Typography>
          )}
        </Alert>
      )}

      <CardContent>
        <Stack spacing={2}>
          <Typography>
            Coiled maintains some resources in your AWS account. You can manage
            it all from here.
          </Typography>
          <AWSGlobalInfraForm workspaceSlug={workspaceSlug} />
          <AWSRegionsForm
            disabled={!awsGlobalInfra}
            workspaceSlug={workspaceSlug}
          />
        </Stack>
      </CardContent>
    </>
  );
};
