import { useQuery, UseQueryResult } from "react-query";
import { ApiClient } from "../../apiUtils";
import { ConfigSchema, ResponseError } from "../../api-client";

export const useConfig = (): UseQueryResult<ConfigSchema, ResponseError> => {
  const result = useQuery<ConfigSchema, ResponseError>(
    "getConfig",
    ({ signal }) => ApiClient.cloudViewsNinjaConfigGetConfig({ signal }),
  );
  return result;
};
