import React, { ReactElement, useContext } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ApiError, apiErrorToMessage } from "../apiUtils";
import { datadogLogs } from "@datadog/browser-logs";
import { useTheme } from "@mui/material";
import { AuthenticationContext } from "../crud/auth/context";

type Props = {
  errorLines: (string | ApiError)[];
  formatErrorMessage?: boolean;
};

const ErrorMessage = ({
  errorLines,
  formatErrorMessage = true,
}: Props): ReactElement => {
  // The error message without the wrapping container
  const { setToken } = useContext(AuthenticationContext);
  let messages: (string | ApiError)[] = [ApiError.Unknown];
  if (!errorLines.length) {
    datadogLogs.logger.error("errorLines prop should not be empty");
  } else if (errorLines.length === 1) {
    const [error] = errorLines;
    if (error === ApiError.Unauthorized) {
      // This shouldn't happen, but just covering the bases
      messages = ["It seems you have been logged out, redirecting to login"];
      setTimeout(() => setToken(undefined), 3000);
    } else {
      messages = [formatErrorMessage ? apiErrorToMessage(error) : error];
    }
  } else {
    messages = [...errorLines];
  }
  return (
    <React.Fragment>
      {messages.map((m) => (
        <Typography key={m} sx={{ margin: "16px 0 16px 0" }}>
          {m}
        </Typography>
      ))}
      <ErrorOutlineIcon fontSize="large" />
    </React.Fragment>
  );
};

export const PageCouldntLoad = ({
  errorLines,
  formatErrorMessage = true,
}: Props): ReactElement => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "64px",
        fontSize: theme.typography.pxToRem(24),
      }}
    >
      <ErrorMessage
        errorLines={errorLines}
        formatErrorMessage={formatErrorMessage}
      />
    </Container>
  );
};
