import React, { useContext, useEffect } from "react";

import { Alert, Divider, Stack, TextField, Tooltip } from "@mui/material";
import {
  useGCPAccountSettings,
  useGCPRegionalInfra,
  useUpdateGCPAccountSettingsMutation,
} from "../../../crud/setup/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { GCPAccountSettings } from "../../../crud/setup/fetch";
import { HookSwitch } from "../../../shared-components/HookSwitch";
import { LoadingButton } from "@mui/lab";
import { useIsAccountAdmin } from "../../../crud/account/hooks";
import { UserContext } from "../../../crud/user/context";

export const GCPSettingsForm = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}): React.ReactElement => {
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const { data: current } = useGCPRegionalInfra(workspaceSlug);
  const { user } = useContext(UserContext);
  const hasSetupUnmanaged = !current?.regions.some((r) => r.managed);

  const { data: currentSettings } = useGCPAccountSettings(workspaceSlug);
  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<GCPAccountSettings>({
    defaultValues: currentSettings || {
      autoSetup: true,
      giveSchedulerPublicIp: true,
      giveWorkersPublicIp: true,
      customSoftwareBucketPrefix: "",
    },
  });
  const updateSettings = useUpdateGCPAccountSettingsMutation(workspaceSlug);
  const useSelfHostedBucket = watch("useSelfHostedBucket");
  const onSubmit: SubmitHandler<GCPAccountSettings> = (data) => {
    updateSettings.mutate(data);
  };
  useEffect(() => {
    reset(currentSettings, { keepDefaultValues: false });
  }, [currentSettings, reset]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ paddingTop: (theme) => theme.spacing(2) }}>
        <Stack spacing={1}>
          <Divider sx={{ maxWidth: "700px" }}>
            Advanced Network Settings
          </Divider>
          {hasSetupUnmanaged && (
            <Alert severity="info" sx={{ maxWidth: "600px" }}>
              These settings cannot be used with Coiled managed infrastructure
            </Alert>
          )}
          <HookSwitch
            label="Give Workers Public IPs"
            disabled={!isAdmin || !hasSetupUnmanaged}
            control={control}
            name="giveWorkersPublicIp"
          />
          <HookSwitch
            label="Give Schedulers Public IPs"
            disabled={!isAdmin || !hasSetupUnmanaged}
            control={control}
            name="giveSchedulerPublicIp"
          />
        </Stack>
        <Stack spacing={1}>
          <Divider sx={{ maxWidth: "700px" }}>
            Advanced Privacy Settings
          </Divider>
          <Alert severity="info" sx={{ maxWidth: "600px" }}>
            Please email{" "}
            <a href="mailto:support@coiled.io">support@coiled.io</a> for support
            to use this feature
          </Alert>
          <Tooltip title="Enabling this means Coiled will create a bucket in your account to store software environments.">
            <HookSwitch
              label="Use Self-Hosted Bucket for Package Sync"
              control={control}
              disabled={!isAdmin || !user.isStaff}
              name="useSelfHostedBucket"
            />
          </Tooltip>
          <TextField
            size="small"
            sx={{ maxWidth: "500px" }}
            InputLabelProps={{ shrink: true }}
            placeholder={`default: coiled-software-${workspaceSlug}`}
            label="Bucket Name Prefix"
            disabled={!isAdmin || !useSelfHostedBucket || !user.isStaff}
            fullWidth
            error={!!errors.customSoftwareBucketPrefix}
            helperText={
              errors.customSoftwareBucketPrefix?.message ||
              'Prefix to use for self-hosted bucket names, region will be appended (for example, "<prefix>-us-west2").'
            }
            {...register("customSoftwareBucketPrefix", {
              required: false,
              validate: (value) => {
                // https://cloud.google.com/storage/docs/buckets#naming
                if (!useSelfHostedBucket) return;
                if (!value?.length)
                  return "Prefix is required if using self-hosted bucket";
                if (value?.length > 45)
                  return "Prefix cannot be longer than 45 characters";
                if (value?.length && !RegExp("^[a-z0-9.-]+$").test(value))
                  return "Bucket name can only contain lowercase letters, numbers, dots (.), and hyphens (-)";
              },
            })}
          />
        </Stack>
        <LoadingButton
          loading={updateSettings.isLoading}
          disabled={!isAdmin || !isDirty}
          sx={{ maxWidth: "100px" }}
          type="submit"
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
};
