import React, { ReactElement } from "react";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material";

type Props = { delaySeconds?: number; children?: React.ReactNode };

export const Waiting = ({
  delaySeconds = 1.5,
  children,
}: Props): ReactElement => {
  // To avoid a quick flash every time someone changes views,
  // Don't render until :delaySeconds has passed
  const theme = useTheme();
  const [visible, setVisible] = React.useState(false);
  const _isMounted = React.useRef(true);
  React.useEffect(() => {
    if (delaySeconds) {
      setTimeout(() => {
        if (_isMounted.current) {
          // Don't perform a state update if
          // unmounted
          setVisible(true);
        }
      }, delaySeconds * 1000);
      return () => {
        _isMounted.current = false;
      };
    } else {
      setVisible(true);
    }
  }, [delaySeconds]);
  if (!visible) {
    return <React.Fragment />;
  }

  if (children) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <Container
      maxWidth="sm"
      sx={{
        [theme.breakpoints.up("lg")]: {
          marginLeft: "10%",
        },
      }}
    >
      <Skeleton
        variant="text"
        height="48px"
        animation="wave"
        sx={{ margin: "16px" }}
      />
    </Container>
  );
};
