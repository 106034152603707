import { useQuery, UseQueryResult } from "react-query";
import { fetchHistory, StateHistory } from "./crud";

export const useClusterHistory = (
  clusterId: string,
  account: string,
  token: string,
  enabled: boolean,
): UseQueryResult<StateHistory> => {
  return useQuery(
    ["fetchStateHistory", clusterId, account, token],
    () => fetchHistory(clusterId as string, account, token),
    {
      refetchInterval: 5000,
      enabled,
    },
  );
};
