import { QueryParamConfig } from "use-query-params";

export const makeObjectArrayParam = <
  T extends Record<string, string>[],
>(): QueryParamConfig<T | null | undefined, T | undefined> => {
  return {
    encode: (obj: T | null | undefined): string =>
      obj && obj.length > 0 ? JSON.stringify(obj) : "",
    decode: (
      str: string | (string | null)[] | null | undefined,
    ): T | undefined => {
      if (typeof str === "string") {
        try {
          if (str === "") {
            // Directly return an empty array with the correct type
            return [] as unknown as T;
          }
          const output: T = JSON.parse(str);
          return output || undefined;
        } catch (e) {
          return [] as unknown as T;
        }
      }
      // Log and return undefined if the input is not a string
      return undefined;
    },
  };
};
