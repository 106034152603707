import { sortBy } from "lodash";
import { GroupedMetric } from "../../../crud/metrics/types";
import { TimeRange } from "../types";

export enum SamplingMethod {
  MAX = "max",
  SUM = "sum",
}

export const sampleMetrics = (
  data?: GroupedMetric[],
  timeRange?: TimeRange,
  maxSamples?: number,
  samplingMethod = SamplingMethod.MAX,
  fillGaps = false,
): { data: GroupedMetric[]; maxValue: number } => {
  // Note: maxValue is only calculated when samplingMethod = SamplingMethod.MAX,
  // it'll be 0 for SamplingMethod.SUM
  let maxValue = 0;

  if (!timeRange || !data?.length) {
    return { data: [], maxValue };
  }

  const [start, end] = timeRange;

  // We dynamically derive the sampling interval from the data unless it's too small
  const dataInterval = getDataInterval(data);
  const minSampleInterval = maxSamples ? (end - start) / maxSamples : 0;

  let interval = Math.max(dataInterval, minSampleInterval);

  // Round interval up to nearest multiple of dataInterval
  if (interval !== dataInterval) {
    interval = Math.ceil(interval / dataInterval) * dataInterval;
  }

  let dataIndex = 0;

  const sampledData = [];
  let batch = [];
  let dataHasStarted = false;

  const dedupedData = dedupeMetrics(data);

  // Loops over sampling interval and collects data points within that interval
  for (let timestampMs = start; timestampMs <= end; timestampMs += interval) {
    // Collect all data points within the interval into a batch
    while (
      !!dedupedData?.[dataIndex] &&
      parseInt(dedupedData[dataIndex].timestamp, 10) * 1000 < timestampMs
    ) {
      if (parseInt(dedupedData[dataIndex].timestamp, 10) * 1000 >= start) {
        batch.push(dedupedData[dataIndex]);
      }
      dataHasStarted = true;
      dataIndex++;
    }

    const timestamp = `${timestampMs / 1000}`;
    const dataHasEnded = dataIndex >= dedupedData.length;

    // For line charts we only fill in missing samples before and after the data has started
    // so we don't break the continuity of the lines
    const shouldFillGap = fillGaps || !dataHasStarted || dataHasEnded;

    // Get max values for each data point in batch
    // Note: We might want to change the sampling method depending
    // on how the downsampled data looks in charts
    if (batch.length) {
      const obj = getSampledValues(batch, samplingMethod);
      const batchMax = Math.max(
        ...Object.values({ ...obj, timestamp: 0 }).flat(1),
      );

      if (batchMax > maxValue) {
        maxValue = batchMax;
      }

      sampledData.push({ ...obj, timestamp });
    } else if (shouldFillGap) {
      // Add placeholder samples to empty intervals so we can select the whole range
      sampledData.push({ timestamp });
    }

    batch = [];
  }

  return { data: sampledData, maxValue };
};

// When live fetching metrics, we might get multiple data points for the same timestamps due
// to overlapping timeranges, so we need to dedupe them and only keep the latest data point
const dedupeMetrics = (data: GroupedMetric[]) => {
  const obj: GroupedMetric = {};

  for (let i = 0; i < data.length; i++) {
    const { timestamp, ...values } = data[i];
    obj[timestamp] = values;
  }

  const dedupedMetrics = Object.entries(obj).map(([timestamp, values]) => ({
    timestamp,
    ...values,
  }));

  return sortBy(dedupedMetrics, "timestamp");
};

const DEFAULT_INTERVAL_MS = 1000 * 60 * 5; // 5 minutes
const MIN_INTERVAL_MS = 1000 * 5; // 5 seconds

// Returns the interval between two first data points in the data
const getDataInterval = (data: GroupedMetric[]) => {
  if (!data || data?.length < 2) return DEFAULT_INTERVAL_MS;

  const interval =
    parseInt(data[1].timestamp, 10) * 1000 -
    parseInt(data[0].timestamp, 10) * 1000;

  if (interval < MIN_INTERVAL_MS) {
    return MIN_INTERVAL_MS;
  }

  return interval;
};

// Iterates over a batch and calculates sampled values based on samplingMethod
const getSampledValues = (
  batch: GroupedMetric[],
  samplingMethod: SamplingMethod,
) => {
  const obj: GroupedMetric = {};
  for (let i = 0; i < batch.length; i++) {
    for (const [key, value] of Object.entries(batch[i])) {
      // value could be single number, or it could be array [low, high] for distribution
      if (Array.isArray(value)) {
        if (!obj[key]) obj[key] = [0, 0];
        switch (samplingMethod) {
          case SamplingMethod.SUM:
            obj[key][0] += parseFloat(value[0]);
            obj[key][1] += parseFloat(value[1]);
            break;
          default:
            obj[key][0] = Math.max(obj[key][0], value[0]);
            obj[key][1] = Math.max(obj[key][1], value[1]);
        }
      } else if (value > 0) {
        if (!obj[key]) obj[key] = 0;
        switch (samplingMethod) {
          case SamplingMethod.SUM:
            obj[key] += parseFloat(value);
            break;
          default:
            obj[key] = Math.max(obj[key], value);
        }
      }
    }
  }
  return obj;
};
