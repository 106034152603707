import Cookies from "js-cookie";

export const UPGRADE_COOKIE = `${import.meta.env.FE_TARGET_ENV}_upgrade`;
export const AUTH_COOKIE = "coiledkey";
export const VIEWEDACCOUNT_COOKIE = import.meta.env
  .FE_SELECTED_ACCOUNT_COOKIE_NAME;
export const REDIRECT_COOKIE = import.meta.env.FE_REDIRECT_COOKIE_NAME;

export const getDjangoCSRFCookie = (): string => Cookies.get("csrftoken") || "";

export const getHubspotCookie = (): string | undefined =>
  Cookies.get("hubspotutk");
