import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { parseError } from "../apiUtils";
import { Urls } from "../domain/urls";
import ky, { HTTPError } from "ky";
import { REDIRECT_URI_PARAM } from "./constants";
import { getDjangoCSRFCookie, REDIRECT_COOKIE } from "../cookieUtils";
import { useAnalytics } from "use-analytics";
import { ColumnDiv } from "./components";
import { AuthenticationContext } from "../crud/auth/context";
import { useCookie } from "../utils/hooks";
import { useMutation } from "react-query";
import { AccountBalance } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";

type RawResponse = {
  key: string;
  existing?: boolean;
};

const duplicateEmailError = "duplicateEmail";
const duplicateUsernameError = "duplicateUsername";
const notAllowlistedError = "notAllowlisted";
const ssoRequiredError = "ssoRequiredError";

const errorRegexMap = new Map([
  [
    duplicateEmailError,
    new RegExp("User is already registered with this e-mail address."),
  ],
  [
    duplicateUsernameError,
    new RegExp("User is already registered with this username."),
  ],
  [notAllowlistedError, new RegExp(".*is not allowed to register.*")],
]);
const errorKeys = Array.from(errorRegexMap.keys());

type TokenResponse = { token: string; existing?: boolean };

const getTokenForProviderCode = async (
  provider: string,
  code: string,
): Promise<TokenResponse> => {
  const spec = (await ky
    .post(`/users/${provider}/get_token`, {
      json: { code },
      headers: { "X-CSRFToken": getDjangoCSRFCookie() || "" },
    })
    .json()) as RawResponse;
  return {
    token: spec.key,
    existing: spec.existing,
  };
};

type Props = { provider: string; displayName: string };

export const HandleProviderResponse = ({
  provider,
  displayName,
}: Props): React.ReactElement => {
  const analytics = useAnalytics();
  const [redirectCookie, setRedirectCookie] = useCookie(REDIRECT_COOKIE);
  const { setToken } = useContext(AuthenticationContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code") || "/";
  const [error, setError] = React.useState<string | null>(null);
  const { mutate, isIdle } = useMutation(
    "login",
    (c: string) => getTokenForProviderCode(provider, c),
    {
      onError: async (err) => {
        analytics.track("login-error", { provider, error: err });
        if (err instanceof HTTPError) {
          if (err?.response?.status === 400) {
            let errors = null;
            try {
              const body = await err.response.json();
              errors = body?.non_field_errors || [];
            } catch (err2) {
              setError(parseError(err2));
            }
            for (const errorString of errors) {
              for (const key of errorKeys) {
                const regex = errorRegexMap.get(key) as RegExp;
                if (regex.test(errorString)) {
                  setError(key);
                }
              }
            }
          } else if (err?.response?.status === 401) {
            // custom error handling for an SSO required response from social auth login
            const body = await err.response.json();
            const detail = body?.detail;
            if (detail === "SSO required") {
              setError(ssoRequiredError);
            }
          }
        } else {
          setError(parseError(err));
        }
      },
      onSuccess: (data) => {
        setToken(data.token);
        const redirectURI =
          redirectCookie || searchParams.get(REDIRECT_URI_PARAM) || "/";
        setRedirectCookie(undefined);
        analytics.track(`login-success`, { provider });
        navigate(redirectURI, { replace: true });
        return;
      },
    },
  );
  useEffect(() => {
    if (isIdle && code) {
      mutate(code);
    }
  }, [
    isIdle,
    mutate,
    code,
    setToken,
    setRedirectCookie,
    navigate,
    analytics,
    provider,
    redirectCookie,
    searchParams,
  ]);

  let content;
  if (error) {
    if (error === duplicateEmailError) {
      content = (
        <React.Fragment>
          <Typography>
            You already have an account for the email associated with your{" "}
            {displayName} account, please use that login instead. Contact us if
            you need more help logging in.
          </Typography>
          <Link href={`/${Urls.Login}`} underline="hover">
            Go back to login
          </Link>
        </React.Fragment>
      );
    } else if (error === duplicateUsernameError) {
      content = (
        <React.Fragment>
          <Typography>
            This username is already in use by another account.
          </Typography>
          <Link href={`/${Urls.Login}`} underline="hover">
            Go back to login
          </Link>
        </React.Fragment>
      );
    } else if (error === ssoRequiredError) {
      content = (
        <Stack spacing={2}>
          <Typography>SSO is required for this user.</Typography>
          <Typography sx={{ backgroundColor: "white" }}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AccountBalance />}
              href={"/workos"}
              onClick={() =>
                analytics.track("login-clicked", { provider: "sso" })
              }
              fullWidth
            >
              Sign in with SSO
            </Button>
          </Typography>
        </Stack>
      );
    } else if (error === notAllowlistedError) {
      content = (
        <React.Fragment>
          <Typography>
            {
              "Free tier sign up is currently disabled. You do not currently have "
            }
            {"permission to enter the site. You can request access at "}
            <Link
              href="mailto:support@coiled.io"
              rel="noreferrer"
              target="_blank"
              underline="hover"
            >
              support@coiled.io
            </Link>
          </Typography>
          <Link href={`/${Urls.Login}`} underline="hover">
            Go back to login
          </Link>
        </React.Fragment>
      );
    } else {
      analytics.track("login-unhandled-error");
      content = (
        <React.Fragment>
          <Typography>
            There was an error logging in with {displayName}.
          </Typography>
          <Link href={`/${Urls.Login}`} underline="hover">
            Go back to login
          </Link>
        </React.Fragment>
      );
    }
  } else {
    content = (
      <React.Fragment>
        <Typography>Logging you in...</Typography>
        <CircularProgress />
      </React.Fragment>
    );
  }

  return (
    <Container maxWidth="xs">
      <ColumnDiv>{content}</ColumnDiv>
    </Container>
  );
};
