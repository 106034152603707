import React, { forwardRef, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { apiErrorToMessage } from "../apiUtils";
import { range } from "../javascriptSucksUtils";
import {
  Alert,
  Box,
  CardActionArea,
  CardContent,
  Divider,
  Collapse,
  Card,
  CardProps,
  CardHeader,
  Skeleton,
  Stack,
  CardContentProps,
  CardActions,
} from "@mui/material";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

type CardErrorContentProps = { error: string; formatUnknown?: boolean };

export const CardErrorContent = ({
  error,
  formatUnknown = true,
}: CardErrorContentProps): React.ReactElement => {
  return (
    <CardContent>
      <Alert severity="error">{apiErrorToMessage(error, formatUnknown)}</Alert>
    </CardContent>
  );
};

type CardWaitingProps = { numRectangles?: number };

export const CardWaitingContent = ({
  numRectangles = 2,
}: CardWaitingProps): React.ReactElement => {
  return (
    <React.Fragment>
      {range(0, numRectangles - 1).map(() => (
        <Box key={nanoid()}>
          <Skeleton animation="wave" variant="rectangular" height={30} />
        </Box>
      ))}
    </React.Fragment>
  );
};

type TitleCardProps = Omit<CardProps, "title"> & {
  title: string | React.ReactNode;
  action?: React.ReactNode;
  subheader?: string;
  children: React.ReactNode;
  className?: string;
  cardContentProps?: CardContentProps;
  cardActions?: React.ReactNode;
};

export const TitleCard = ({
  title,
  subheader,
  action,
  children,
  className = "",
  cardContentProps,
  cardActions,
  ...props
}: TitleCardProps): React.ReactElement => {
  return (
    <Card className={className} {...props}>
      <CardHeader title={title} subheader={subheader} action={action} />
      <Divider />
      <CardContent {...cardContentProps}>{children}</CardContent>
      <CardActions>{cardActions}</CardActions>
    </Card>
  );
};

type ExpandableProps = TitleCardProps & {
  isOpen?: boolean;
  iconAlign?: "left" | "right";
  expandIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  loading?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

export const ExpandableCard = forwardRef<HTMLDivElement, ExpandableProps>(
  (
    {
      title,
      iconAlign = "right",
      isOpen,
      handleClick,
      children,
      loading,
      className = "",
      expandIcon = <ExpandMoreOutlined fontSize="large" />,
      closeIcon = <ExpandLessOutlined fontSize="large" />,
      disabled,
      sx,
      ...props
    }: ExpandableProps,
    ref,
  ): React.ReactElement => {
    const [open, setOpen] = useState(isOpen ?? false);
    useEffect(() => {
      setOpen(isOpen ?? false);
    }, [isOpen]);
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (handleClick) {
        handleClick(e);
      } else {
        setOpen(!open);
      }
    };
    return (
      <Card
        className={className}
        sx={{
          filter: disabled ? "grayscale(100%)" : undefined,
          opacity: disabled ? 0.6 : 1,
          "& .MuiCardHeader-root": {
            flexGrow: 1,
          },
          ...sx,
        }}
        {...props}
        ref={ref}
      >
        <CardActionArea
          disableRipple
          disabled={loading}
          onClick={(e) => {
            if (!loading) {
              onClick(e);
            }
          }}
          sx={{
            borderBottomLeftRadius: open ? 0 : undefined,
            borderBottomRightRadius: 0 ? 0 : undefined,
          }}
        >
          <Stack direction="row" alignItems={"center"}>
            <CardHeader
              title={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {iconAlign === "left" && (
                    <Box sx={{ pr: 1 }}>{open ? closeIcon : expandIcon}</Box>
                  )}
                  {loading ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      flexGrow={1}
                      alignItems="center"
                    >
                      {title}
                      {loading && <Skeleton sx={{ flex: "1 1 auto" }} />}
                    </Stack>
                  ) : (
                    title
                  )}
                  {iconAlign === "right" && (open ? closeIcon : expandIcon)}
                </Stack>
              }
            />
          </Stack>
        </CardActionArea>
        <Collapse in={open}>
          <Divider />
          {children}
        </Collapse>
      </Card>
    );
  },
);

const CustomToolbar = ({
  header,
}: {
  header: React.ReactNode;
}): React.ReactElement => {
  return (
    <CardHeader
      title={header}
      sx={{
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "divider",
      }}
    />
  );
};

type DataGridProCardProps = Omit<CardProps, "title"> & {
  title?: string | React.ReactNode;
  dataGridProps: DataGridProProps;
};
export const DataGridProCard = ({
  dataGridProps: { sx: dataGridSx, slotProps, slots, ...dataGridProps },
  title,
  ...props
}: DataGridProCardProps): React.ReactElement => {
  return (
    <Card {...props}>
      <DataGridPro
        {...dataGridProps}
        slots={{
          ...(title ? { toolbar: CustomToolbar } : {}),
          ...slots,
        }}
        slotProps={{
          toolbar: {
            ...(title ? { header: title } : {}),
          },
          ...slotProps,
        }}
        sx={{
          border: "unset",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
          ...dataGridSx,
        }}
      />
    </Card>
  );
};
