/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ComponentStateEnum = {
    Pending: 'pending',
    Creating: 'creating',
    Created: 'created',
    Destroying: 'destroying',
    Destroyed: 'destroyed',
    Error: 'error'
} as const;
export type ComponentStateEnum = typeof ComponentStateEnum[keyof typeof ComponentStateEnum];


export function ComponentStateEnumFromJSON(json: any): ComponentStateEnum {
    return ComponentStateEnumFromJSONTyped(json, false);
}

export function ComponentStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentStateEnum {
    return json as ComponentStateEnum;
}

export function ComponentStateEnumToJSON(value?: ComponentStateEnum | null): any {
    return value as any;
}

