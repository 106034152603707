/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const BuildPhoneHomeStatusEnum = {
    Error: 'error',
    Built: 'built'
} as const;
export type BuildPhoneHomeStatusEnum = typeof BuildPhoneHomeStatusEnum[keyof typeof BuildPhoneHomeStatusEnum];


export function BuildPhoneHomeStatusEnumFromJSON(json: any): BuildPhoneHomeStatusEnum {
    return BuildPhoneHomeStatusEnumFromJSONTyped(json, false);
}

export function BuildPhoneHomeStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildPhoneHomeStatusEnum {
    return json as BuildPhoneHomeStatusEnum;
}

export function BuildPhoneHomeStatusEnumToJSON(value?: BuildPhoneHomeStatusEnum | null): any {
    return value as any;
}

