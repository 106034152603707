import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  TextField,
} from "@mui/material";

import { ArrowDropDown, KeyboardReturn, Search } from "@mui/icons-material";

type Option = {
  name: string;
  type?: string;
  [key: string]: any;
};
interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const AutocompletePopperDiv = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  return <Box {...other} />;
};

type SelectAutocompleteProps<T extends Option> = {
  options: T[];
  value?: T;
  onChange?: (option: T) => void;
  getOptionLabel?: (option: T) => string;
  renderOption?: (option: T, forSelect: boolean) => React.ReactElement;
  groupBy?: (option: T) => string;
  filterValueChanged?: (value: string) => void;
  isLoading?: boolean;
  placeHolder?: string;
  helperText?: string | React.ReactNode;
};

export const SelectAutocomplete = <T extends Option>({
  options,
  value,
  onChange,
  groupBy,
  getOptionLabel,
  renderOption,
  isLoading,
  filterValueChanged,
  placeHolder,
  helperText,
}: SelectAutocompleteProps<T>): React.ReactElement => {
  const [_option, setOption] = useState<T>();
  const option = value || _option;
  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Stack>
        <Stack
          ref={buttonRef}
          direction="row"
          disableRipple
          size="small"
          component={Button}
          variant="secondary"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            width: "450px",
            borderWidth: 1,
            pt: "8.5px",
            pb: "8.5px",
            pl: "14px",
            height: "40px",
            fontSize: (theme) => theme.typography.pxToRem(16), // match normal font size
            backgroundColor: (theme) => theme.palette.background.paper,
            borderColor: `rgba(0, 0, 0, 0.23)`,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.paper,
            },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          {isLoading && !value && <CircularProgress size="25px" />}
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {option && renderOption
              ? renderOption(option, false)
              : option?.name}
            {!option && (
              <Box
                sx={{
                  opacity: (theme) => theme.palette.action.disabledOpacity,
                }}
              >
                {placeHolder}
              </Box>
            )}
          </span>
          <ArrowDropDown color="action" />
        </Stack>
        {helperText}
      </Stack>
      <Popover
        open={open}
        slotProps={{
          paper: {
            elevation: 8,
            sx: {
              width: "300px",
              padding: 0,
              borderRadius: 1,
            },
          },
        }}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={buttonRef.current}
      >
        <Box sx={{ padding: 0 }}>
          <Autocomplete
            open
            disableClearable
            autoHighlight
            clearOnBlur
            loading={isLoading}
            selectOnFocus
            forcePopupIcon={false}
            onClose={() => {
              setAutocompleteValue("");
              setOpen(false);
            }}
            blurOnSelect
            ListboxProps={{
              sx: {
                maxHeight: "80vh",
              },
            }}
            PopperComponent={AutocompletePopperDiv}
            isOptionEqualToValue={(o: T, v: T) => {
              return (
                o.type === v.type &&
                (o.name === v.name || (o.id && o.id === v.id))
              );
            }}
            value={option}
            options={options}
            getOptionLabel={(o) =>
              getOptionLabel && option
                ? getOptionLabel(option)
                : option?.name || ""
            }
            filterOptions={(opts, state) => {
              const filtered = opts.filter((o) => {
                const matchInput = state.inputValue.toLowerCase();
                if (state.inputValue.includes("@")) {
                  // for email address matching, we'll rely on backend endpoint to filter orgs/workspaces
                  return o.type === "user"
                    ? o.email?.toLowerCase().includes(matchInput)
                    : o.type === "organization" || o.type === "account";
                }
                if (
                  state.inputValue &&
                  o.type === "organization" &&
                  o.workspaceNames
                ) {
                  if (
                    o.workspaceNames?.some((workspaceName: string) =>
                      workspaceName.toLowerCase().includes(matchInput),
                    )
                  ) {
                    return true;
                  }
                }
                if (state.inputValue) {
                  return o.name.toLowerCase().startsWith(matchInput);
                }
                return true;
              });
              return filtered;
            }}
            groupBy={groupBy}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
              },
              popper: {
                disablePortal: true,
                anchorEl: null,
                sx: {
                  position: "relative",
                  border: 0,
                  borderRadius: 0,
                },
              },
            }}
            renderOption={(props, o) => {
              return (
                <li {...props} key={`${o.name}-${o.type}`}>
                  {renderOption && o ? renderOption(o, true) : o.name || ""}
                </li>
              );
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                if (onChange) onChange(newValue);
                setOption(newValue);
              }
              setOpen(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                autoFocus
                value={autocompleteValue}
                onChange={(e) => {
                  setAutocompleteValue(e.target.value);
                  if (filterValueChanged) filterValueChanged(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <React.Fragment>
                      <Search />
                    </React.Fragment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      <KeyboardReturn />
                    </React.Fragment>
                  ),
                }}
                inputRef={inputRef}
                sx={{
                  padding: 1,
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
                onFocus={() => {
                  // using a timeout as a workaround
                  // otherwise it gets cleared somehow
                  setTimeout(() => {
                    inputRef.current?.select();
                  }, 1);
                }}
              />
            )}
          />
        </Box>
      </Popover>
    </>
  );
};
