import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Drawer, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Urls } from "../../domain/urls";
import { useUserInteractions } from "../../crud/interactions/hooks";
import { DataGridProCard } from "../../shared-components/Cards";
import {
  getUserInteractionKeys,
  getUserInteractionValues,
} from "../../crud/interactions/fetch";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import {
  Add,
  ChevronRight,
  Dashboard,
  Email,
  Person,
  Pets,
  ZoomIn,
} from "@mui/icons-material";
import { format, formatDistanceToNow } from "date-fns";
import { LONG_TIME_FORMAT } from "../Clusters/Clusters";
import { KeyValue, KeyValueAutocomplete } from "./AutocompleteCustom";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { TreeItem, TreeView, treeItemClasses } from "@mui/x-tree-view";
import { RefreshSelector } from "../../shared-components/RefreshSelector";
import {
  ScopeSelector,
  useScopedContext,
} from "../../shared-components/ScopeSelector";
import { UserInteractionListSchema } from "../../api-client";
import { usePersistentJsonWithJsonParam } from "../../utils/hooks";
import camelCase from "lodash/camelCase";

const InteractionDefaultFilters: KeyValue[] = [
  {
    key: "success",
    value: "False",
  },
  { key: "is_staff", value: "False" },
];

export const UserInteractionCard = (): React.ReactElement => {
  const [filters, setFilters] = usePersistentJsonWithJsonParam<KeyValue[]>({
    key: "userInteractionFilters",
    defaultValue: InteractionDefaultFilters,
  });
  const [scope, setScope] = useScopedContext();
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);
  const [refetchInterval, setRefetchInterval] = useQueryParam(
    "refreshRate",
    withDefault(NumberParam, 0),
  );
  const scopeFilters = {
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
  };
  const {
    data: userInteractions,
    isLoading,
    refetch,
  } = useUserInteractions(
    {
      ...filters.reduce(
        (obj, item) =>
          Object.assign(obj, { [camelCase(item.key)]: item.value }, {}),
        [],
      ),
      ...scopeFilters,
    },
    refetchInterval,
  );
  const columns = useMemo<GridColDef<UserInteractionListSchema>[]>(() => {
    return [
      {
        headerName: "Time",
        field: "timestamp",
        valueGetter: (p) => new Date(p.value),
        type: "dateTime",
        renderCell: (p) => (
          <Tooltip title={format(p.value, LONG_TIME_FORMAT)}>
            <span>
              {formatDistanceToNow(p.value, {
                addSuffix: true,
              })}
            </span>
          </Tooltip>
        ),
        width: 120,
      },
      {
        headerName: "Organization",
        field: "organization",
        valueGetter: (p) => p.row.organization?.name || "",
        renderCell: (p) => (
          <Link
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (p.row.organization) {
                setScope({
                  type: "organization",
                  name: p.row.organization.name,
                  id: p.row.organization.id,
                });
              }
            }}
          >
            {p.value}
          </Link>
        ),
      },
      {
        headerName: "Username/Account",
        field: "user",
        width: 300,
        valueGetter: (p) => p.value.username,
        renderCell: (p) => {
          return (
            <Tooltip title={`${p.value}/${p.row.additionalData?.account}`}>
              <Box>
                <Typography>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setScope({
                        type: "user",
                        name: p.row.user.username,
                        id: p.row.user.id,
                      });
                    }}
                  >
                    {p.value}
                  </Link>
                  /{p.row.additionalData?.account}
                </Typography>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        headerName: "Action",
        field: "action",
        width: 200,
        renderCell: (p) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const freshFilters = filters.filter((f) => f.key !== "action");
                setFilters([
                  ...freshFilters,
                  {
                    key: "action",
                    value: p.value,
                  },
                ]);
              }}
            >
              {p.value}
            </Link>
          );
        },
      },
      {
        field: "additionalData.errorMessage",
        headerName: "Error Message",
        valueGetter: (p) => p.row.additionalData?.errorMessage || "",
        renderCell: (p) => (
          <Tooltip sx={{ maxWidth: "none" }} title={p.value}>
            <span>{p.value}</span>
          </Tooltip>
        ),
        flex: 1,
      },
      {
        headerName: "Success",
        field: "success",
        type: "boolean",
        width: 75,
      },
      {
        field: "links",
        headerName: "Links",
        renderCell: (p) => [
          p.row.additionalData?.clusterId ? (
            <Tooltip title={"Cluster Details"} key={p.row.id}>
              <Link
                component={RouterLink}
                to={
                  "/" +
                  Urls.Clusters +
                  `/${p.row.additionalData?.clusterId}/information?account=${p.row.additionalData?.account}`
                }
              >
                <ZoomIn />
              </Link>
            </Tooltip>
          ) : null,
          p.row.additionalData?.clusterId ? (
            <Tooltip title={"Account Cluster Dashboard"} key={p.row.id}>
              <Link
                component={RouterLink}
                to={
                  "/" +
                  Urls.Clusters +
                  `?account=${p.row.additionalData?.account}`
                }
              >
                <Dashboard />
              </Link>
            </Tooltip>
          ) : null,
          <Tooltip
            key="mixpanel-profile-link"
            title={`Mixpanel Profile for ${p.row.user.username}`}
          >
            <Link
              key="mixpanel-link"
              href={`https://mixpanel.com/project/2738385/view/3274413/app/profile#distinct_id=${p.row.user.id}`}
              target="_blank"
              sx={{ maxWidth: "none" }}
              title={p.value}
            >
              <Person />
            </Link>
          </Tooltip>,
          <Tooltip key="email-link" title={`Email ${p.row.user.email}`}>
            <Link
              key="email-link"
              href={`mailto:${p.row.user.email}`}
              target="_blank"
              title={p.value}
            >
              <Email />
            </Link>
          </Tooltip>,
          <Tooltip key="datadog-link" title={`Datadog Sessions`}>
            <Link
              key="datadog-link"
              href={
                `https://app.datadoghq.com/rum/sessions` +
                `?query=%40type%3Asession env%3Aprod %40usr.username%3A${p.row.user.username}`
              }
              target="_blank"
              title={p.value}
            >
              <Pets />
            </Link>
          </Tooltip>,
        ],
        width: 150,
      },
    ];
  }, [filters, setFilters, setScope]);
  const selectedEvent = userInteractions?.items.find(
    (v) => v.id === selectedRow,
  );
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row" alignItems="flex-start" width={"100%"}>
        <KeyValueAutocomplete
          keyValuesChanged={setFilters}
          keyValues={filters}
          defaultFilters={InteractionDefaultFilters}
          fetchKeys={getUserInteractionKeys}
          fetchValues={getUserInteractionValues}
        />
        <ScopeSelector />
        <RefreshSelector
          query="userInteractions"
          onClick={() => refetch()}
          setRefreshInterval={setRefetchInterval}
          refreshInterval={refetchInterval}
        />
      </Stack>
      <div>
        <Drawer
          anchor="right"
          onClose={() => setSelectedRow(null)}
          open={!!selectedRow}
          sx={{ width: "500px" }}
        >
          {selectedEvent && (
            <TreeView
              defaultCollapseIcon={<ChevronRight />}
              defaultExpandIcon={<Add />}
              sx={{
                [`&& .${treeItemClasses.label}`]: {
                  color: (theme) => theme.palette.common.white,
                },
                [`&& .${treeItemClasses.content}`]: {
                  color: (theme) => theme.palette.grey[400],
                },
                height: 240,
                width: "500px",
              }}
            >
              {selectedRow && ObjectToListItemArray(selectedEvent)}
            </TreeView>
          )}
        </Drawer>
        <DataGridProCard
          sx={{ height: "1000px" }}
          title="Events"
          dataGridProps={{
            loading: isLoading,
            rowSelectionModel: selectedRow ? [selectedRow] : [],
            onRowSelectionModelChange: (p, details) => {
              if (p.length > 0) {
                setSelectedRow(p[0]);
              } else {
                setSelectedRow(null);
              }
            },
            rows: userInteractions?.items || [],
            columns,
          }}
        />
      </div>
    </Stack>
  );
};

const ObjectToListItemArray = (obj: object): React.ReactElement[] => {
  return Object.entries(obj).map(([k, v]) => {
    if (v !== null && typeof v === "object") {
      return (
        <TreeItem nodeId={k} key={k} label={k}>
          {...ObjectToListItemArray(v)}
        </TreeItem>
      );
    } else {
      return (
        <TreeItem
          nodeId={k}
          key={k}
          label={
            <Typography color="inherit">
              {k}:{" "}
              <Typography component="span" variant="subtitle2">
                {ElementForType(v)}
              </Typography>
            </Typography>
          }
        />
      );
    }
  });
};

const ElementForType = (v: string | boolean): React.ReactElement => {
  if (typeof v === "string") {
    return (
      <Typography
        component="span"
        variant="subtitle2"
        sx={{
          whiteSpace: "pre-line",
          color: "inherit",
        }}
      >
        {v}
      </Typography>
    );
  } else if (typeof v === "boolean") {
    return (
      <Typography component="span" sx={{ color: "inherit" }}>
        {v ? "True" : "False"}
      </Typography>
    );
  }
  return (
    <Typography
      component="span"
      sx={{ whiteSpace: "pre-line", color: "inherit" }}
    >
      {v}
    </Typography>
  );
};
