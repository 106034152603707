import { v4 as uuid } from "uuid";
import { datadogLogs } from "@datadog/browser-logs";
import { getDjangoCSRFCookie } from "./cookieUtils";
import camelCase from "lodash/camelCase";
import { Configuration, DefaultApi, ResponseError } from "./api-client";
import { HTTPError } from "ky";
const coiledSessionId = uuid();

export enum ApiError {
  Unknown = "Unknown",
  Unauthorized = "Unauthorized",
  Conflict = "Conflict",
  NoNetwork = "NoNetwork",
  NotFound = "NotFound",
  Timeout = "Timeout",
}

type ErrorParser = {
  f: (err: any) => boolean;
  e: ApiError;
};

const noNetworkMessages = [
  // chrome
  "failed to fetch",
  // firefox
  "networkerror",
  // safari
  "could not connect",
];

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const parseError = (err: any): ApiError => {
  const checkers: ErrorParser[] = [
    {
      f: (e: any) => e?.response?.status === 409,
      e: ApiError.Conflict,
    },
    {
      f: (e: any) => e?.response?.status === 401,
      e: ApiError.Unauthorized,
    },
    {
      f: (e: any) => e?.response?.status === 404,
      e: ApiError.NotFound,
    },
    {
      f: (e: any) => {
        if (e.name === "TypeError") {
          const message = e.message && e.message.toLowerCase();
          if (message) {
            return Boolean(noNetworkMessages.find((m) => message.includes(m)));
          }
        }
        return false;
      },
      e: ApiError.NoNetwork,
    },
    {
      f: (e: any) => {
        return e?.name === "TimeoutError";
      },
      e: ApiError.Timeout,
    },
    {
      f: (e: any) => {
        let errorData;
        try {
          const { columnNumber, lineNumber, fileName, message, stack } = e;
          errorData = {
            errorString: e.toString(),
            columnNumber,
            lineNumber,
            fileName,
            message,
            stack,
          };
        } catch {
          errorData = { errorString: e.toString() };
        }
        datadogLogs.logger.error("Unhandled exception in frontend", errorData);
        return true;
      },
      e: ApiError.Unknown,
    },
  ];
  // @ts-ignore  the last condition should always return true
  return checkers.find(({ f }) => f(err)).e;
};

export const apiErrorToMessage = (
  e: ApiError | string,
  formatUnknown = true,
): string => {
  switch (e) {
    case ApiError.NoNetwork:
      return "Can't connect to the server";
    case ApiError.NotFound:
      return "404: Could not find that page or resource";
    case ApiError.Unauthorized:
      return "It seems you have been logged out, redirecting to login";
    case ApiError.Timeout:
      return "A network call timed out";
    default:
      if (formatUnknown) {
        return "An unknown error occurred";
      }
      return e;
  }
};

export const getStandardHeaders = (): Record<string, string> => ({
  "X-CSRFToken": getDjangoCSRFCookie() || "",
  "Client-Version": "coiled-frontend-js",
  "coiled-session-id": coiledSessionId,
  "coiled-operation-id": uuid(),
  "coiled-request-id": uuid(),
});

export const ApiClient = new DefaultApi(
  new Configuration({
    middleware: [
      {
        pre: async (context) => {
          // using middleware to set headers to
          // keep the cookie fresh!
          context.init.headers = {
            ...getStandardHeaders(),
            ...context.init.headers,
          };
          return context;
        },
      },
    ],
    basePath: window.location.origin,
  }),
);

/// https://stackoverflow.com/a/50620653
export const camelizeKeys: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const is404 = (err: Error): boolean => {
  if (err instanceof HTTPError && err.response.status === 404) {
    return true;
  } else if (err instanceof ResponseError && err.response.status === 404) {
    return true;
  } else {
    return false;
  }
};
