import React from "react";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ClusterFrontendSchema } from "../../api-client";

type DashboardButtonType = {
  cluster?: ClusterFrontendSchema;
};

// This is a hack to fix an issue that occurs when bokeh is not installed in the environment.
// In that case, users should see the dask dashboard message saying they need bokeh.
// Instead, (if they go to "/" rather than "/status") via clicking on the link, the
// token is lost in redirects, and they see a confusing error about needing authentication.
export const dashboardUrlRedirect = (url: string): string =>
  url?.replace("?token", "/status?token");

export const DashboardButton = ({
  cluster,
}: DashboardButtonType): React.ReactElement => {
  const showDashboard = cluster?.scheduler.currentState.state === "started";
  const isNotebook =
    cluster?.scheduler.spec.tags["coiled-cluster-type"] === "notebook";
  const dashUrl = dashboardUrlRedirect(
    cluster?.scheduler.dashboardAddress || "",
  );
  const url = isNotebook
    ? dashUrl.replace("/status?", "?").replace("?", "/jupyter/lab/?")
    : dashUrl;
  const label = isNotebook ? "Jupyter Lab" : "Dask Dashboard";
  return (
    <Button
      component="a"
      endIcon={<OpenInNewIcon />}
      size="small"
      variant="secondary"
      disabled={!showDashboard}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {label}
    </Button>
  );
};
