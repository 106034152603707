import React, { createContext, ReactElement, ReactNode } from "react";

interface AuthenticatedContextInterface {
  token: string;
}

interface AuthenticationContextInterface {
  token?: string;
  setToken: (token: string | undefined) => void;
}

export const AuthenticatedContext =
  createContext<AuthenticatedContextInterface>({
    token: "",
  });

export const AuthenticationContext =
  createContext<AuthenticationContextInterface>({
    token: "",
    setToken: () => undefined,
  });

type AuthenticatedContextProviderProps = {
  token: string;
  children: ReactNode;
};

type AuthenticationContextProviderProps = {
  token?: string;
  setToken: (token: string | undefined) => void;
  children: ReactNode;
};

export const AuthenticatedContextProvider = ({
  token,
  children,
}: AuthenticatedContextProviderProps): ReactElement => {
  return (
    <AuthenticatedContext.Provider
      value={{
        token,
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
};
export const AuthenticationContextProvider = ({
  token,
  setToken,
  children,
}: AuthenticationContextProviderProps): ReactElement => {
  return (
    <AuthenticationContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
