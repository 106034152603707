import { Box, Link, Tooltip } from "@mui/material";
import React, { ReactElement, useContext, useMemo } from "react";
import { SoftwareAliasContext } from "../../../crud/software/context";
import {
  useSoftwareAliasBuilds,
  useSoftwareAliasSpecs,
} from "../../../crud/software/hooks";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Cloud, SelectAllOutlined } from "@mui/icons-material";

import { BuildStatusChip } from "../../../shared-components/Software/common";
import { formatRelative } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { Page } from "../../../shared-components/Page";
import { Urls } from "../../../domain/urls";
import { AWSIcon } from "../../../icons/AWSIcon";
import { BackendTypeServer } from "../../../domain/people";
import { GCPIcon } from "../../../icons/GCPIcon";
import { formatBytes } from "../../../utils";
import { DataGridProCard } from "../../../shared-components/Cards";
import {
  SoftwareEnvBuildLight,
  SoftwareEnvironmentSpecLightSchema,
} from "../../../api-client";
import { drawerWidth } from "../../../Layouts/MainLayout";

type flatBuilds = Omit<SoftwareEnvironmentSpecLightSchema, "id"> & {
  id: string;
  specId: number;
  build?: SoftwareEnvBuildLight;
};
export const AliasView = (): ReactElement => {
  const { id: aliasId, latestSpec, name } = useContext(SoftwareAliasContext);
  const { data: specs, isSuccess: specsLoaded } =
    useSoftwareAliasSpecs(aliasId);
  const { data: builds, isSuccess: buildsLoaded } =
    useSoftwareAliasBuilds(aliasId);
  const flat = useMemo(
    () =>
      specs?.items?.flatMap((spec) => {
        const specBuilds = builds?.filter((b) => b.specId === spec.id);
        if (specBuilds?.length) {
          return specBuilds.map((build) => {
            return {
              ...spec,
              id: `${spec.id}${build.id}`,
              specId: spec.id,
              created: build.created,
              build,
            } as flatBuilds;
          });
        } else {
          return {
            ...spec,
            id: `${spec.id}`,
            specId: spec.id,
            build: undefined,
          } as flatBuilds;
        }
      }),
    [builds, specs],
  );
  const columns = useMemo<GridColDef<flatBuilds>[]>(
    () => [
      {
        field: "created",
        headerName: "Created",
        minWidth: 180,
        maxWidth: 230,
        flex: 1,
        renderCell: (params) =>
          params.row.build ? (
            <Link
              component={RouterLink}
              to={`build/${params.row.build.id}`}
              key={`build/${params.row.build.id}`}
            >
              {formatRelative(params.row.created, new Date())}
            </Link>
          ) : (
            formatRelative(params.row.created, new Date())
          ),
        sortable: false,
      },
      {
        field: "active",
        headerName: "Active",
        type: "boolean",
        valueGetter: (params) =>
          params.row.build?.id === latestSpec.latestBuild?.id &&
          params.row.specId === latestSpec.id,
        width: 65,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        width: 65,
        renderCell: (params) => (
          <BuildStatusChip
            status={params.row.build?.state}
            reason={params.row.build?.reason}
          />
        ),
        sortable: false,
      },
      {
        field: "backendType",
        headerName: "Cloud",
        valueGetter: (params) => params.row.build?.backendType,
        renderCell: (params) => {
          switch (params.value) {
            case BackendTypeServer.AWS_HOSTED:
              return <AWSIcon />;
            case BackendTypeServer.GCP_HOSTED:
              return <GCPIcon />;
          }
        },
        width: 60,
        sortable: false,
      },
      {
        field: "regionName",
        headerName: "Region",
        valueGetter: (params) => params.row.build?.regionName,
        minWidth: 90,
        maxWidth: 130,
        flex: 1,
        sortable: false,
      },
      {
        field: "type",
        headerName: "Type",
        width: 55,
        renderCell: (params) =>
          params.row.containerUri ? (
            <Tooltip title="Container">
              <SelectAllOutlined />
            </Tooltip>
          ) : (
            <Tooltip title="Coiled Managed">
              <Cloud />
            </Tooltip>
          ),
        sortable: false,
      },
      {
        field: "hasRawPip",
        headerName: "pip",
        width: 50,
        type: "boolean",
        sortable: false,
      },
      {
        field: "hasRawConda",
        headerName: "conda",
        width: 65,
        type: "boolean",
        sortable: false,
      },
      {
        field: "gpuEnabled",
        headerName: "GPU Support",
        description:
          "If this is set, Coiled uses the GPU version of conda packages, where applicable. (Not relevant for docker-based environments.)",
        type: "boolean",
        sortable: false,
        flex: 1,
        minWidth: 70,
        maxWidth: 130,
      },
      {
        field: "architecture",
        headerName: "Architecture",
        flex: 1,
        sortable: false,
        minWidth: 75,
        maxWidth: 110,
      },
      {
        field: "containerUri",
        headerName: "Container URI",
        flex: 1,
        valueFormatter: (params) => (params.value ? params.value : "N/A"),
        sortable: false,
      },
      {
        field: "useEntrypoint",
        headerName: "Use Entrypoint",
        type: "boolean",
        flex: 1,
        maxWidth: 140,
        sortable: false,
      },
      {
        field: "sizeBytes",
        headerName: "Size",
        valueGetter: (params) => params.row.build?.sizeBytes,
        valueFormatter: (params) =>
          params.value ? formatBytes(params.value) : null,
        flex: 1,
        maxWidth: 100,
      },
      {
        field: "md5",
        headerName: "Specification MD5",
        flex: 1,
        maxWidth: 330,
        sortable: false,
      },
    ],
    [latestSpec],
  );
  const breadCrumbs = useMemo(
    () => [
      {
        text: "Software Environments",
        link: `/${Urls.Software}`,
      },
      {
        text: (
          <Tooltip title={name}>
            <Box
              sx={{
                maxWidth: `calc(100vw - ${drawerWidth + 560}px)`,
                minWidth: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </Box>
          </Tooltip>
        ),
      },
    ],
    [name],
  );
  return (
    <Page title={`Software Environments - ${name}`} breadCrumbs={breadCrumbs}>
      <DataGridProCard
        title="Builds"
        dataGridProps={{
          sortModel: [
            {
              field: "active",
              sort: "desc",
            },
            {
              field: "created",
              sort: "desc",
            },
          ],
          loading: !buildsLoaded || !specsLoaded,
          columns,
          rows: flat || [],
        }}
      />
    </Page>
  );
};
