import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import { OrganizationSchema } from "../api-client";
import {
  useCustomGroupList,
  useSetOrgGroupsMutation,
} from "../crud/organizations/hooks";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type MultipleSelectChipProps = {
  organization: OrganizationSchema;
};

export const SelectOrgTags = (
  props: MultipleSelectChipProps,
): React.ReactElement => {
  const { data: customGroupOptions } = useCustomGroupList();
  const { mutate: setOrgGroups } = useSetOrgGroupsMutation();

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const newGroupIds: number[] = event.target.value as number[];
    setOrgGroups({
      setOrgGroupsSchema: {
        organizationId: props.organization.id,
        groupIds: newGroupIds,
      },
    });
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: "100%", paddingRight: "1rem" }}>
        <InputLabel id="select-org-groups-label" shrink>
          Organization Tags
        </InputLabel>
        <Select
          labelId="select-org-groups-label"
          id="select-org-groups"
          multiple
          value={(props.organization.customGroups || []).map((opt) => opt.id)}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Organization Tags"
              notched
            />
          }
          renderValue={() => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {props.organization.customGroups.map((opt) => (
                <Chip
                  key={opt.name}
                  label={opt.name}
                  sx={{
                    color: opt.color.includes("dark") ? "white" : "black",
                    backgroundColor: opt.color,
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {(customGroupOptions || []).map((opt) => (
            <MenuItem
              key={opt.name}
              value={opt.id}
              style={{ backgroundColor: "white" }}
            >
              <Checkbox
                checked={props.organization.customGroups
                  .map((g) => g.id)
                  .includes(opt.id)}
              />
              <Chip
                key={opt.name}
                label={opt.name}
                sx={{
                  color: opt.color.includes("dark") ? "white" : "black",
                  backgroundColor: opt.color,
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
