import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { SoftwareSpecContext } from "../../crud/software/context";

import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { ScanResultPackageSchema } from "../../api-client";
import { SimpleTable } from "../SimpleTable";

declare module "@mui/x-data-grid-pro" {
  interface FooterPropsOverrides {
    specId: number | undefined;
  }
}

const gridColumns: GridColDef<ScanResultPackageSchema>[] = [
  {
    field: "name",
    headerName: "Package Name",
    flex: 1,
    valueGetter: (params) => {
      return params.row.condaName || params.row.name || "";
    },
  },
  { field: "version", headerName: "Version", flex: 1 },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    valueGetter: (params) =>
      params.row.wheelTarget ? "File" : params.row.source,
  },
  {
    field: "channel",
    headerName: "Channel",
    flex: 1,
    valueGetter: (params) => params.row.channel || "",
  },
  {
    field: "wheelTarget",
    headerName: "Wheel Target",
    flex: 1,
    valueGetter: (params) => params.row.wheelTarget || "",
  },
];

export const ScanResultTable = (): React.ReactElement => {
  const spec = useContext(SoftwareSpecContext);
  return (
    <DataGridPro
      sx={{ height: "100%", border: "unset" }}
      pagination={false}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      columns={gridColumns}
      rows={spec.scanResult?.packages || []}
      getRowId={(row) => `${row.name}-${row.source}`}
    />
  );
};

export const ScanResultDetails = (): React.ReactElement => {
  const spec = useContext(SoftwareSpecContext);
  const firstPackage = spec.scanResult?.packages[0];
  const pythonVersion = `${firstPackage?.pythonMajorVersion}.${firstPackage?.pythonMinorVersion}.${firstPackage?.pythonPatchVersion}`;
  return (
    <Stack spacing={2}>
      {spec.scanResult && (
        <SimpleTable
          text={[
            ["Platform", spec.scanResult.platform || "Unknown"],
            ["Prefix (Base)", spec.scanResult.basePrefix || "Unknown"],
            ["Prefix (Virtualenv)", spec.scanResult.prefix || "Unknown"],
            ["PyPI URLS", (spec.scanResult.indexUrls || []).join("\n")],
            ["Python Version", pythonVersion],
          ]}
        />
      )}
      {!!spec.scanResult?.packages.length && (
        <Stack spacing={2}>
          <ScanResultTable />
        </Stack>
      )}
      {!spec.scanResult && (
        <div>No local environment information available</div>
      )}
    </Stack>
  );
};
