/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const OrganizationRoles = {
    Admin: 'admin',
    Member: 'member'
} as const;
export type OrganizationRoles = typeof OrganizationRoles[keyof typeof OrganizationRoles];


export function OrganizationRolesFromJSON(json: any): OrganizationRoles {
    return OrganizationRolesFromJSONTyped(json, false);
}

export function OrganizationRolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationRoles {
    return json as OrganizationRoles;
}

export function OrganizationRolesToJSON(value?: OrganizationRoles | null): any {
    return value as any;
}

