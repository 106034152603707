import { Stack, SvgIconProps, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Circle } from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import Confetti from "react-dom-confetti";
import { ComponentSchema } from "../../../crud/setup/types";

type StateIconProps = SvgIconProps & {
  disabled?: boolean;
  confetti?: boolean;
  coiledComponent?: ComponentSchema;
  animateIn?: boolean;
  reset?: boolean;
};

export const ComponentIcon = ({
  coiledComponent,
  confetti,
  animateIn,
  disabled,
  ...props
}: StateIconProps): React.ReactElement => {
  const state = coiledComponent?.state;
  const initialStateRef = useRef(state);
  const initialState = initialStateRef.current;
  const previousState = useRef<string | undefined>(state);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [styles, api] = useSpring(() => {}, [
    animateIn,
    setFireConfetti,
    confetti,
  ]);
  useEffect(() => {
    if (previousState.current !== state) {
      if (state === "created" && initialState !== "created") {
        api.start({
          to: { scale: 1.0 },
          from: { scale: 0.5 },
          reset: true,
          config: { tension: 500, friction: 20 },
          onStart: () => (confetti ? setFireConfetti(true) : undefined),
        });
      }
      if (state === undefined) {
        initialStateRef.current = undefined;
        setFireConfetti(false);
      }
      previousState.current = state;
    }
  }, [state, api, setFireConfetti, confetti, initialState]);
  const AnimatedCircle = animated(Circle);

  const spin = useSpring({
    from: {
      scale: 0,
      opacity: 0.8,
    },
    to: { scale: 1.5, opacity: 0 },
    loop: true,
    config: {
      duration: 1500,
    },
  });
  const spin2 = useSpring({
    from: {
      scale: 0,
      opacity: 0.8,
    },
    to: { scale: 1.5, opacity: 0 },
    loop: true,
    config: {
      duration: 1500,
    },
    delay: 100,
  });
  return (
    <animated.span style={{ ...styles }}>
      <Confetti
        active={fireConfetti}
        config={{
          duration: 500,
          elementCount: 5,
          startVelocity: 10,
          spread: 180,
          angle: 0,
        }}
      />
      <Tooltip
        title={
          <React.Fragment>
            {coiledComponent?.coiledManaged && (
              <Typography color="inherit">
                State: {coiledComponent?.coiledManaged ? state : "Self Managed"}
              </Typography>
            )}
            {coiledComponent && !coiledComponent.coiledManaged && (
              <Typography color="inherit">{"Self Managed"}</Typography>
            )}
            {!coiledComponent && (
              <Typography color="inherit">{"To be created"}</Typography>
            )}
            <em>
              {coiledComponent && !coiledComponent.coiledManaged && (
                <em>{"This component is managed by you."}</em>
              )}
              {coiledComponent?.state === "error" && (
                <>
                  <p>
                    <em>{"This component has the following error:"}</em>
                  </p>
                  <p>
                    <em style={{ whiteSpace: "pre-line" }}>
                      {coiledComponent?.reason}
                    </em>
                  </p>
                </>
              )}
            </em>
          </React.Fragment>
        }
      >
        <Stack justifyContent={"center"}>
          {coiledComponent?.coiledManaged && (
            <>
              {!state && (
                <Circle
                  color={"disabled"}
                  {...props}
                  style={{ transform: "scale(0.60)" }}
                />
              )}
              {state === "error" && (
                <>
                  <AnimatedCircle
                    {...props}
                    color="error"
                    style={{ ...spin, position: "absolute" }}
                  />
                  <AnimatedCircle
                    {...props}
                    style={{ ...spin2, position: "absolute" }}
                  />
                  <AnimatedCircle
                    color="error"
                    style={{
                      transform: "scale(0.60)",
                      color: "error",
                      opacity: 0.8,
                    }}
                  />
                </>
              )}
              {(state === "pending" || state === "creating") && (
                <>
                  <AnimatedCircle
                    {...props}
                    style={{ ...spin, position: "absolute", color: "#F1C40F" }}
                  />
                  <AnimatedCircle
                    {...props}
                    style={{ ...spin2, position: "absolute", color: "#F1C40F" }}
                  />
                  <AnimatedCircle
                    style={{
                      transform: "scale(0.60)",
                      color: "#F1C40F",
                      opacity: 0.8,
                    }}
                  />
                </>
              )}
              {state === "created" && (
                <Circle
                  {...props}
                  color="success"
                  style={{ transform: "scale(0.60)", opacity: 0.8 }}
                />
              )}
            </>
          )}
          {!coiledComponent?.coiledManaged && (
            <Circle
              {...props}
              color="disabled"
              style={{ transform: "scale(0.60)", opacity: 0.8 }}
            />
          )}
        </Stack>
      </Tooltip>
    </animated.span>
  );
};
