import React, { useContext } from "react";
import { ClusterData, ClusterStatistics } from "./ClusterStatistics";
import {
  ComputationListingData,
  ComputationListing,
} from "./ComputationListing";
import { AnalyticsPane } from "./AnalyticsPane";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { WorkspaceContext } from "../../../utils/context";

export const AnalyticsView = (): React.ReactElement => {
  const analyticsId = parseInt(useParams().analyticsId as string, 10);

  const viewedAccount = useContext(WorkspaceContext);
  const analyticsBase = `api/v1/${viewedAccount.slug}/analytics/cluster/${analyticsId}`;
  return (
    <Stack spacing={2}>
      <AnalyticsPane<ClusterData>
        title="Details"
        path={`${analyticsBase}/cluster-statistics`}
        account={viewedAccount.slug}
        clusterId={analyticsId}
        containerWidth={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        render={ClusterStatistics}
      />
      <AnalyticsPane<ComputationListingData>
        path={`${analyticsBase}/computations`}
        title="Computations"
        subTitle="All computations executed on the cluster (click to expand)"
        account={viewedAccount.slug}
        clusterId={analyticsId}
        containerWidth={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        render={ComputationListing}
      />
    </Stack>
  );
};
