/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Valid states for ClusterInfra.
 * @export
 */
export const ClusterInfraStateEnum = {
    Queued: 'queued',
    Creating: 'creating',
    Error: 'error',
    Created: 'created',
    Destroying: 'destroying',
    Destroyed: 'destroyed'
} as const;
export type ClusterInfraStateEnum = typeof ClusterInfraStateEnum[keyof typeof ClusterInfraStateEnum];


export function ClusterInfraStateEnumFromJSON(json: any): ClusterInfraStateEnum {
    return ClusterInfraStateEnumFromJSONTyped(json, false);
}

export function ClusterInfraStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterInfraStateEnum {
    return json as ClusterInfraStateEnum;
}

export function ClusterInfraStateEnumToJSON(value?: ClusterInfraStateEnum | null): any {
    return value as any;
}

