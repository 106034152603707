import { Card, CardContent } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  SoftwareAliasContext,
  SoftwareSpecContextProvider,
} from "../../../crud/software/context";
import {
  useSoftwareBuild,
  useSoftwareSpec,
} from "../../../crud/software/hooks";
import { Urls } from "../../../domain/urls";
import { LoadingScreen } from "../../../shared-components/LoadingScreen";
import { Page } from "../../../shared-components/Page";
import { BuildStatusChip } from "../../../shared-components/Software/common";
import { SoftwareSpecificationDetails } from "../../../shared-components/Software/details";
import { InstalledSoftwareTable } from "../../../shared-components/Software/installed";
import { SoftwareBuildLogsTable } from "../../../shared-components/Software/logs";
import {
  VerticalTabPanel,
  VerticalTabs,
} from "../../../shared-components/VerticalTabs";
import { SoftwareEnvironmentBuildStateEnum } from "../../../api-client";
import { useWorkspaceContextSlug } from "../../../utils/hooks";
import { ScanResultDetails } from "../../../shared-components/Software/scan";

enum TabValue {
  details = "details",
  specification = "specification",
  installed = "installed",
  logs = "logs",
  builds = "builds",
  scan = "scan",
}

export const SoftwareBuildView = (): React.ReactElement => {
  const buildId = parseInt(useParams().buildId as string, 10);
  const workspaceSlug = useWorkspaceContextSlug();
  const { id: aliasId, name } = useContext(SoftwareAliasContext);
  const { data: build, isSuccess: buildLoaded } = useSoftwareBuild(buildId);
  const { data: fullSpec, isSuccess: specLoaded } = useSoftwareSpec(
    build?.specId,
  );
  const breadCrumbs = useMemo(
    () => [
      {
        text: "Software Environments",
        link: `/${Urls.Software}`,
      },
      {
        text: name,
        link: `/${Urls.Software}/alias/${aliasId}/account/${workspaceSlug}`,
      },
      { text: "build" },
    ],
    [name, aliasId, workspaceSlug],
  );

  return (
    <Page title={`Software Environments - ${name}`} breadCrumbs={breadCrumbs}>
      {!buildLoaded && !specLoaded && <LoadingScreen />}
      {specLoaded && buildLoaded && (
        <>
          {fullSpec.containerUri && (
            <Card>
              <CardContent>
                This environment is an image located at {fullSpec.containerUri}{" "}
                and is not managed by Coiled
              </CardContent>
            </Card>
          )}
          {fullSpec.latestBuildId && (
            <>
              <SoftwareSpecContextProvider spec={fullSpec}>
                <VerticalTabs
                  tabExtra={
                    <BuildStatusChip
                      sx={{
                        margin: (theme) => theme.spacing(1),
                      }}
                      status={build.state}
                      reason={build.reason}
                    />
                  }
                  defaultTab={
                    build.state !== SoftwareEnvironmentBuildStateEnum.Built
                      ? TabValue.details
                      : TabValue.installed
                  }
                >
                  <VerticalTabPanel
                    value={TabValue.scan}
                    label="Local Environment"
                  >
                    <CardContent>
                      <ScanResultDetails />
                    </CardContent>
                  </VerticalTabPanel>
                  <VerticalTabPanel
                    value={TabValue.details}
                    label="Requirements"
                  >
                    <CardContent>
                      <SoftwareSpecificationDetails />
                    </CardContent>
                  </VerticalTabPanel>
                  <VerticalTabPanel value={TabValue.logs} label="Build Logs">
                    <SoftwareBuildLogsTable buildId={buildId} />
                  </VerticalTabPanel>
                  <VerticalTabPanel
                    disabled={
                      build.state !== SoftwareEnvironmentBuildStateEnum.Built
                    }
                    value={TabValue.installed}
                    label="Installed"
                  >
                    <InstalledSoftwareTable packages={build.packages} />
                  </VerticalTabPanel>
                </VerticalTabs>
              </SoftwareSpecContextProvider>
            </>
          )}
        </>
      )}
    </Page>
  );
};
