/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateWorkspaceInviteSchema } from './UpdateWorkspaceInviteSchema';
import {
    UpdateWorkspaceInviteSchemaFromJSON,
    UpdateWorkspaceInviteSchemaFromJSONTyped,
    UpdateWorkspaceInviteSchemaToJSON,
} from './UpdateWorkspaceInviteSchema';

/**
 * 
 * @export
 * @interface UpdateOrganizationInviteSchema
 */
export interface UpdateOrganizationInviteSchema {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationInviteSchema
     */
    role: string;
    /**
     * 
     * @type {Array<UpdateWorkspaceInviteSchema>}
     * @memberof UpdateOrganizationInviteSchema
     */
    workspaces: Array<UpdateWorkspaceInviteSchema>;
}

/**
 * Check if a given object implements the UpdateOrganizationInviteSchema interface.
 */
export function instanceOfUpdateOrganizationInviteSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "workspaces" in value;

    return isInstance;
}

export function UpdateOrganizationInviteSchemaFromJSON(json: any): UpdateOrganizationInviteSchema {
    return UpdateOrganizationInviteSchemaFromJSONTyped(json, false);
}

export function UpdateOrganizationInviteSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationInviteSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'workspaces': ((json['workspaces'] as Array<any>).map(UpdateWorkspaceInviteSchemaFromJSON)),
    };
}

export function UpdateOrganizationInviteSchemaToJSON(value?: UpdateOrganizationInviteSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'workspaces': ((value.workspaces as Array<any>).map(UpdateWorkspaceInviteSchemaToJSON)),
    };
}

