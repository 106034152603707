import React from "react";

/**
 * Returns a nicely formatted label for legends and tooltips
 */
export const getLabel = (entries: { value: string }[]): JSX.Element => {
  const group = removeParentheses(entries[0].value)[1];
  const names = entries
    .map((entry) => removeParentheses(entry.value)[0])
    .join(", ")
    .replaceAll("-", "–"); // en-dash instead of hyphen

  return group ? (
    <>
      {group} <span style={{ color: "#908e89" }}>({names})</span>
    </>
  ) : (
    <span style={{ color: "#908e89" }}>{names}</span>
  );
};

/**
 * Removes parentheses and their content from a string, returns the string
 * without the parentheses and the content of the first parentheses.
 * e.g. "foo (bar)-baz (bar)" => ["foo-baz", "bar"]
 */
export const removeParentheses = (
  value: string,
): [string, string | undefined] => {
  // regex that matches parentheses and their content
  const pattern = /\s*\(([^)]*)\)\s*/g;
  const groups: string[] = [];
  const newValue = value.replace(pattern, (match, group) => {
    groups.push(group.trim());
    return "";
  });
  return [newValue.trim(), groups.length ? groups[0] : undefined];
};
