import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { userIsAdmin } from "../../auth";
import { useContext, useMemo } from "react";
import { UserContext } from "../user/context";
import { ApiClient } from "../../apiUtils";
import {
  OrganizationRoles,
  PagedWorkspaceMemberSchema,
  UsersViewsInvitesDeleteWorkspaceInviteRequest,
  UsersViewsWorkspacesWorkspaceDetailRequest,
  UsersViewsWorkspacesWorkspaceRemoveMembershipRequest,
  WorkspaceInviteSchema,
  WorkspaceSchema,
} from "../../api-client";
import { userIsAdminById } from "../../auth/accountUtils";

export enum AccountQueryKeys {
  ACCOUNT_DETAILS = "getAccountDetails",
  ACCOUNT_OPTIONS = "getAccountOptions",
  ACCOUNT_REGISTRY = "getAccountRegistry",
  ACCOUNT_PUBLIC = "getAccountPublic",
}

export const useAccountDetails = (
  query: UsersViewsWorkspacesWorkspaceDetailRequest,
): UseQueryResult<WorkspaceSchema> => {
  return useQuery<WorkspaceSchema>(
    [
      AccountQueryKeys.ACCOUNT_DETAILS,
      query.workspaceId || query.workspaceSlug,
    ],
    ({ signal }) =>
      ApiClient.usersViewsWorkspacesWorkspaceDetail(query, {
        signal,
      }),
    {
      retry: false,
      refetchInterval: (q) => (q?.activeBackend ? false : 3000),
    },
  );
};

export const useIsAccountAdmin = (account: string): boolean => {
  const { memberships } = useContext(UserContext);
  return useMemo(
    () => userIsAdmin(memberships, account),
    [memberships, account],
  );
};

export const useIsOrgAdmin = (organizationId?: number): boolean => {
  const { organizationMemberships } = useContext(UserContext);
  return useMemo(
    () =>
      organizationMemberships.some(
        (m) =>
          m.organizationId === organizationId &&
          m.role === OrganizationRoles.Admin,
      ),
    [organizationMemberships, organizationId],
  );
};

export const useIsAnyKindOfAdmin = (
  organizationId?: number,
  accounts?: (string | number)[],
): boolean => {
  const isOrgAdmin = useIsOrgAdmin(organizationId);
  const { memberships } = useContext(UserContext);
  const isAccountAdmin =
    accounts?.some((account) =>
      typeof account === "string"
        ? userIsAdmin(memberships, account)
        : userIsAdminById(memberships, account),
    ) || false;
  return isOrgAdmin || isAccountAdmin;
};

export const useWorkspaceMembers = (
  workspaceSlug?: string,
): UseQueryResult<PagedWorkspaceMemberSchema> => {
  return useQuery(
    ["getWorkspaceMembers", workspaceSlug],
    () =>
      ApiClient.declarativeViewsWorkspaceListMembers({
        workspace: workspaceSlug as string,
      }),
    {
      enabled: !!workspaceSlug,
    },
  );
};

export const useWorkspaceInvites = (
  workspaceId: number,
): UseQueryResult<WorkspaceInviteSchema[]> => {
  const query = useQuery(["getWorkspaceInvites", workspaceId], () =>
    ApiClient.usersViewsInvitesWorkspaceInvites({
      workspaceId,
    }),
  );
  return query;
};

export const useRemoveMembershipMutation = (): UseMutationResult<
  void,
  unknown,
  UsersViewsWorkspacesWorkspaceRemoveMembershipRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (request: UsersViewsWorkspacesWorkspaceRemoveMembershipRequest) => {
      return ApiClient.usersViewsWorkspacesWorkspaceRemoveMembership(request);
    },
    {
      onSuccess: (_, { membershipId }) => {
        client.setQueriesData(
          "getWorkspaceMembers",
          (oldData: PagedWorkspaceMemberSchema | undefined) => {
            return oldData
              ? {
                  ...oldData,
                  items: oldData.items.filter(
                    (member) => member.id !== membershipId,
                  ),
                }
              : { count: 0, items: [] };
          },
        );
      },
    },
  );
};

export const useDeleteInviteMutation = (): UseMutationResult<
  void,
  unknown,
  UsersViewsInvitesDeleteWorkspaceInviteRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (request: UsersViewsInvitesDeleteWorkspaceInviteRequest) => {
      return ApiClient.usersViewsInvitesDeleteWorkspaceInvite(request);
    },
    {
      onSuccess: (_, { inviteId }) => {
        client.setQueriesData(
          "getWorkspaceInvites",
          (oldData: WorkspaceInviteSchema[] | undefined) => {
            return oldData
              ? oldData.filter((invite) => invite.id !== inviteId)
              : [];
          },
        );
      },
    },
  );
};
