export enum FormSteps {
  SELECT_BACKEND = 0,
}
enum AWSFormSteps {
  BEFORE = 1,
  CREDENTIALS = 2,
  GLOBAL_INFRA = 3,
  REGIONAL_INFRA = 4,
}

enum GCPFormSteps {
  BEFORE = 1,
  CREDENTIALS = 2,
}

enum AzureFormSteps {
  TALK_TO_US = 1,
}

export const AWSSteps = {
  ...FormSteps,
  ...AWSFormSteps,
};

export const GCPSteps = {
  ...FormSteps,
  ...GCPFormSteps,
};

export const AzureSteps = {
  ...FormSteps,
  ...AzureFormSteps,
};
