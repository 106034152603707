import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { TimeRange } from "../../pages/Clusters/types";
import { useToken, useWorkspaceContextSlug } from "../../utils/hooks";
import { getClusterLogs, getClusterInfraEvents } from "./fetch";
import { useEffect, useState } from "react";
import { getLatestTimestamp } from "../../pages/Clusters/Information/utils";
import { LogEntry, LogPage } from "./types";
import { REFRESH_LOGS_INTERVAL } from "../../pages/Clusters/Information/const";

const LOG_INCREMENT_MS = 1000 * 60 * 10; // 10 minutes

export const previousTimeInterval = (
  internalTimeRange: TimeRange,
): TimeRange => {
  return [internalTimeRange[0] - LOG_INCREMENT_MS, internalTimeRange[0] - 1];
};

export const useClusterLogs = (
  clusterId: string,
  dask: boolean,
  system: boolean,
  timeRange?: TimeRange,
  liveUpdates?: boolean,
): UseInfiniteQueryResult<LogPage, Error> => {
  const [latestTimer, setLatestTimer] = useState<number>();
  const accountSlug = useWorkspaceContextSlug();
  const token = useToken();

  const query = useInfiniteQuery({
    queryKey: ["clusterLogs", accountSlug, token, clusterId, dask, system],
    queryFn: async ({ signal, pageParam }) => {
      // If nextTimeRange is undefined it means we are fetching the first page,
      // let's start fetching backwards from the end of the current time range
      const startTimeRange = timeRange
        ? previousTimeInterval([timeRange![1], timeRange![1]] as TimeRange)
        : undefined;
      const requestTimeRange =
        pageParam?.nextTimeRange ||
        pageParam?.previousTimeRange ||
        startTimeRange ||
        undefined;

      const logsPromise = getClusterLogs(
        accountSlug,
        token,
        clusterId,
        dask,
        system,
        requestTimeRange[0],
        requestTimeRange[1],
        undefined,
        signal,
      );

      // When the cluster is running, we'll trigger a refetch by setting a timer
      // Setting latestTimestamp will trigger a refetch after both the previous
      // query has finished and REFRESH_LOGS_INTERVAL has passed
      if (liveUpdates) {
        (async () => {
          if (!pageParam?.previousTimeRange) {
            let timer;
            const intervalPromise = new Promise((res) => {
              timer = setTimeout(res, REFRESH_LOGS_INTERVAL);
            });
            await Promise.allSettled([logsPromise, intervalPromise]);
            setLatestTimer(timer);
          }
        })();
      }

      const logs = await logsPromise;

      // Reverse the logs so that the most recent ones are at the top
      return {
        timeRange: requestTimeRange,
        data: logs.reverse(),
      };
    },
    getNextPageParam: (_, pages) => {
      const ts = getLatestTimestamp(pages);
      return {
        nextTimeRange: [ts ? ts + 1 : timeRange![0], Date.now()],
      };
    },
    enabled: !!timeRange,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });

  // This effect is responsible for fetching live log data
  useEffect(() => {
    if (latestTimer) {
      query.fetchNextPage();
    }

    return () => {
      clearTimeout(latestTimer);
    };
  }, [latestTimer]);

  return query as UseInfiniteQueryResult<LogPage, Error>;
};

export const useClusterInfraEvents = (
  clusterId: string,
  defaultTimeRange?: TimeRange,
): UseQueryResult<LogEntry[]> => {
  const accountSlug = useWorkspaceContextSlug();
  const token = useToken();

  return useQuery({
    queryKey: [
      "clusterInfraEvents",
      accountSlug,
      token,
      clusterId,
      defaultTimeRange,
    ],
    queryFn: async ({ signal }) => {
      const logs = await getClusterInfraEvents(
        accountSlug,
        token,
        clusterId,
        signal,
      );
      return logs;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};
