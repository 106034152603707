import React from "react";
import {
  VerticalTabPanel,
  VerticalTabs,
} from "../../shared-components/VerticalTabs";
import { UserInteractionCard } from "./UserEvents";
import { OrganizationList } from "./OrganizationList";
import { BillingEventListView } from "./BillingEventListView";
import { RedisCacheListView } from "./RedisCacheListView";

export const StaffMainView = (): React.ReactElement => {
  return (
    <VerticalTabs>
      <VerticalTabPanel value="User Events" withPaper={false}>
        <UserInteractionCard />
      </VerticalTabPanel>
      <VerticalTabPanel value="Organizations" withPaper={false}>
        <OrganizationList />
      </VerticalTabPanel>
      <VerticalTabPanel value="Billing Events" withPaper={false}>
        <BillingEventListView />
      </VerticalTabPanel>
      <VerticalTabPanel value="Redis Cache" withPaper={false}>
        <RedisCacheListView />
      </VerticalTabPanel>
    </VerticalTabs>
  );
};
