/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationInviteSchema } from './OrganizationInviteSchema';
import {
    OrganizationInviteSchemaFromJSON,
    OrganizationInviteSchemaFromJSONTyped,
    OrganizationInviteSchemaToJSON,
} from './OrganizationInviteSchema';
import type { OrganizationMembershipSchema } from './OrganizationMembershipSchema';
import {
    OrganizationMembershipSchemaFromJSON,
    OrganizationMembershipSchemaFromJSONTyped,
    OrganizationMembershipSchemaToJSON,
} from './OrganizationMembershipSchema';

/**
 * 
 * @export
 * @interface AddResultSchema
 */
export interface AddResultSchema {
    /**
     * 
     * @type {OrganizationMembershipSchema}
     * @memberof AddResultSchema
     */
    newMembership?: OrganizationMembershipSchema;
    /**
     * 
     * @type {OrganizationInviteSchema}
     * @memberof AddResultSchema
     */
    newInvite?: OrganizationInviteSchema;
}

/**
 * Check if a given object implements the AddResultSchema interface.
 */
export function instanceOfAddResultSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddResultSchemaFromJSON(json: any): AddResultSchema {
    return AddResultSchemaFromJSONTyped(json, false);
}

export function AddResultSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddResultSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newMembership': !exists(json, 'new_membership') ? undefined : OrganizationMembershipSchemaFromJSON(json['new_membership']),
        'newInvite': !exists(json, 'new_invite') ? undefined : OrganizationInviteSchemaFromJSON(json['new_invite']),
    };
}

export function AddResultSchemaToJSON(value?: AddResultSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_membership': OrganizationMembershipSchemaToJSON(value.newMembership),
        'new_invite': OrganizationInviteSchemaToJSON(value.newInvite),
    };
}

