import React, { createContext, ReactElement, ReactNode } from "react";
import { InteractionFlag, InteractionFlagNames } from "./types";

export type AllFlags = {
  [key in InteractionFlagNames]: InteractionFlag;
};
export const InteractionFlagsContext = createContext<AllFlags>({} as AllFlags);

type InteractionFlagsContextProviderProps = {
  flags: AllFlags;
  children?: ReactNode;
};

export const InteractionFlagsContextProvider = ({
  flags,
  children,
}: InteractionFlagsContextProviderProps): ReactElement => {
  return (
    <InteractionFlagsContext.Provider value={flags}>
      {children}
    </InteractionFlagsContext.Provider>
  );
};
