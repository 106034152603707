/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstanceSchema } from './InstanceSchema';
import {
    InstanceSchemaFromJSON,
    InstanceSchemaFromJSONTyped,
    InstanceSchemaToJSON,
} from './InstanceSchema';
import type { InstanceSpecSchema } from './InstanceSpecSchema';
import {
    InstanceSpecSchemaFromJSON,
    InstanceSpecSchemaFromJSONTyped,
    InstanceSpecSchemaToJSON,
} from './InstanceSpecSchema';
import type { ProcessStateSchema } from './ProcessStateSchema';
import {
    ProcessStateSchemaFromJSON,
    ProcessStateSchemaFromJSONTyped,
    ProcessStateSchemaToJSON,
} from './ProcessStateSchema';

/**
 * 
 * @export
 * @interface WorkerSchema
 */
export interface WorkerSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkerSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkerSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkerSchema
     */
    updated: Date;
    /**
     * 
     * @type {InstanceSpecSchema}
     * @memberof WorkerSchema
     */
    spec: InstanceSpecSchema;
    /**
     * 
     * @type {string}
     * @memberof WorkerSchema
     */
    name: string;
    /**
     * 
     * @type {InstanceSchema}
     * @memberof WorkerSchema
     */
    instance?: InstanceSchema;
    /**
     * 
     * @type {ProcessStateSchema}
     * @memberof WorkerSchema
     */
    currentState: ProcessStateSchema;
    /**
     * 
     * @type {string}
     * @memberof WorkerSchema
     */
    dashboardAddress?: string;
}

/**
 * Check if a given object implements the WorkerSchema interface.
 */
export function instanceOfWorkerSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "spec" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function WorkerSchemaFromJSON(json: any): WorkerSchema {
    return WorkerSchemaFromJSONTyped(json, false);
}

export function WorkerSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'spec': InstanceSpecSchemaFromJSON(json['spec']),
        'name': json['name'],
        'instance': !exists(json, 'instance') ? undefined : InstanceSchemaFromJSON(json['instance']),
        'currentState': ProcessStateSchemaFromJSON(json['current_state']),
        'dashboardAddress': !exists(json, 'dashboard_address') ? undefined : json['dashboard_address'],
    };
}

export function WorkerSchemaToJSON(value?: WorkerSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'spec': InstanceSpecSchemaToJSON(value.spec),
        'name': value.name,
        'instance': InstanceSchemaToJSON(value.instance),
        'current_state': ProcessStateSchemaToJSON(value.currentState),
        'dashboard_address': value.dashboardAddress,
    };
}

