/* eslint-disable @typescript-eslint/naming-convention */
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { Urls } from "../domain/urls";
import { validatePassword } from "../utils/validation";
import { passwordReset } from "./auth";
import { AuthLayout } from "./AuthLayout";
import { ActivationFailReasons } from "./types";
import { Section } from "./CardSection";
import { AuthForm, ErrorMessage, InternalLink, Text } from "./components";
import { Button } from "@mui/material";

type Inputs = {
  token?: string;
  uid?: string;
  new_password1: string;
  new_password2: string;
};

type Tokens = {
  uid: string;
  token: string;
};

export const ResetPassword = (): React.ReactElement => {
  const navigate = useNavigate();

  const { token, uid } = useParams() as { token: string; uid: string };

  const [tokens] = React.useState<Tokens>({
    uid,
    token,
  });
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [isSuccess, setSuccess] = React.useState<boolean>(false);

  const [nonFieldError, setNonFieldError] = React.useState<string>();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({ mode: "onBlur" });

  React.useEffect(() => {
    if (tokens.token && tokens.uid) {
      setValue("token", tokens.token);
      setValue("uid", tokens.uid);
    }
  }, [tokens.token, tokens.uid, setValue]);

  const onSubmit = async ({ new_password1, new_password2 }: Inputs) => {
    setSubmitted(true);
    const result = await passwordReset(
      tokens.uid,
      tokens.token,
      new_password1,
      new_password2,
    );
    if (result.type === "success") {
      setTimeout(() => navigate("/login?open=true"), 3000);
      setSuccess(true);
    }
    if (result.type === "error") {
      setSubmitted(false);
      const responseErrs = result?.errors;
      if (responseErrs?.new_password1) {
        setError("new_password1", {
          type: "manual",
          message: responseErrs.new_password1.join(","),
        });
      }
      if (responseErrs?.new_password2) {
        setError("new_password2", {
          type: "manual",
          message: responseErrs.new_password2.join(","),
        });
      }
      if (responseErrs?.non_field_errors) {
        setNonFieldError(responseErrs.non_field_errors[0]);
      } else if (responseErrs?.unknownError) {
        setNonFieldError(responseErrs.unknownError);
      }
    }
  };
  if (isSuccess === true) {
    return (
      <AuthLayout>
        <Section title="Password Reset Confirmed">
          <Text>
            You have sucessfully changed your password. You will be redirected
            shortly to the{" "}
            <InternalLink
              to={{ pathname: `/${Urls.Login}`, search: `?open=true` }}
            >
              login page
            </InternalLink>
            .
          </Text>
        </Section>
      </AuthLayout>
    );
  }
  if (nonFieldError) {
    return (
      <AuthLayout>
        <Section title="Reset Email">
          <ErrorMessage variant="body1" align="center" color="error">
            {nonFieldError}
          </ErrorMessage>
        </Section>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Section title="Reset Password">
        <Text>
          Your password must be at least 8 characters long, with at least one
          symbol, one number and one upper case character.
        </Text>
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("new_password1", {
              required: "password is required",
              validate: (value) =>
                validatePassword(value) === true ||
                ActivationFailReasons.PasswordRequirements,
            })}
            error={!!errors.new_password1}
            helperText={
              errors.new_password1 ? errors.new_password1.message : ""
            }
            fullWidth
            variant="outlined"
            placeholder="password"
            type="password"
          />
          <TextField
            {...register("new_password2", {
              required: "matching password is required",
              validate: (value) =>
                value === getValues("new_password1") || "passwords must match",
            })}
            error={!!errors.new_password2}
            helperText={
              errors.new_password2 ? errors.new_password2.message : ""
            }
            fullWidth
            variant="outlined"
            placeholder="confirm password"
            type="password"
          />
          <Button variant="primary" type="submit">
            {submitted ? <CircularProgress size={24} /> : "submit"}
          </Button>
        </AuthForm>
      </Section>
    </AuthLayout>
  );
};
