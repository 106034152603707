import {
  Alert,
  CardContent,
  Link,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from "@mui/material";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { BackendTypeServer } from "../../../domain/people";

import { useAnalytics } from "use-analytics";
import { Urls } from "../../../domain/urls";
import {
  StepperContext,
  StepperProvider,
} from "../../../shared-components/StepperContext";
import { AWSAccountSettingsStep } from "./AWSBeforeForm";
import { AWSCredentialsForm, AWSCredentialsStep } from "./AWSCredentialsForm";
import { GCPBeforeStep } from "./GCPBeforeForm";
import { GCPCredentialsForm, GCPCredentialsStep } from "./GCPCredentialsForm";
import { AzureTalkToUsStep, AzureTalkToUsInfo } from "./AzureTalkToUs";
import { BackendFormValues, SelectBackendForm } from "./SelectBackendForm";
import { AWSSteps, AzureSteps, FormSteps, GCPSteps } from "./const";
import { BackendTypesEnum, WorkspaceSchema } from "../../../api-client";
import { useIsAccountAdmin } from "../../../crud/account/hooks";

export const CredentialsForm = ({
  workspace,
}: {
  workspace: WorkspaceSchema;
}): React.ReactElement => {
  const isAdmin = useIsAccountAdmin(workspace.slug);

  const initialBackendRef = useRef(workspace.activeBackend);
  const initialBackend = initialBackendRef.current;
  if (workspace.activeBackend === undefined && initialBackend !== undefined) {
    initialBackendRef.current = undefined;
  }
  const isAwsAlreadySetup =
    workspace.activeBackend === BackendTypesEnum.VmAws &&
    initialBackend !== null;
  const isGcpAlreadySetup =
    workspace.activeBackend === BackendTypesEnum.VmGcp &&
    initialBackend !== null;
  const isAzureAlreadySetup =
    workspace.activeBackend === BackendTypesEnum.VmAzure &&
    initialBackend !== null;
  const isAlreadySetup =
    isAwsAlreadySetup || isGcpAlreadySetup || isAzureAlreadySetup;
  return (
    <>
      {!isAdmin && (
        <Alert severity="error" sx={{ borderRadius: 0 }}>
          Editing has been disabled as only workspace admins can manage the
          credentials associated with a workspace. Find your admins on the{" "}
          <Link
            component={RouterLink}
            to={`/${Urls.Team}?account=${workspace.slug}`}
          >
            teams
          </Link>{" "}
          page.
        </Alert>
      )}
      <CardContent>
        <Stack spacing={2}>
          {isAwsAlreadySetup && (
            <AWSCredentialsForm workspaceSlug={workspace.slug} />
          )}
          {isGcpAlreadySetup && (
            <GCPCredentialsForm workspaceSlug={workspace.slug} />
          )}
          {isAzureAlreadySetup && <AzureTalkToUsInfo alreadySetup />}
          {!isAlreadySetup && <CredentialsSetup workspace={workspace} />}
        </Stack>
      </CardContent>
    </>
  );
};

const CredentialsSetup = ({
  workspace,
}: {
  workspace: WorkspaceSchema;
}): React.ReactElement => {
  const { watch, setValue } = useForm<BackendFormValues>({
    defaultValues: {
      backend: BackendTypeServer.AWS_HOSTED,
    },
  });
  const selectedBackend = watch("backend");
  const analytics = useAnalytics();
  const steps = [
    [
      "Choose Cloud Provider",
      "",
      React.createElement(SelectBackendForm, {
        workspaceSlug: workspace.slug,
        workspace,
        index: FormSteps.SELECT_BACKEND,
        defaultBackend: BackendTypeServer.AWS_HOSTED,
        onBackendSelected: (backend: BackendTypeServer) => {
          setValue("backend", backend);
          analytics.track("setup-provider-chosen", {
            provider: backend,
          });
        },
      }),
    ],
    ...(selectedBackend === BackendTypeServer.AWS_HOSTED
      ? [
          [
            "Before you start",
            "",
            React.createElement(AWSAccountSettingsStep, {
              index: AWSSteps.BEFORE,
              workspaceSlug: workspace.slug,
            }),
          ],
          [
            "Credentials",
            "We need access to your cloud account.",
            React.createElement(AWSCredentialsStep, {
              index: AWSSteps.CREDENTIALS,
              workspaceSlug: workspace.slug,
            }),
          ],
        ]
      : []),
    ...(selectedBackend === BackendTypeServer.AZURE_HOSTED
      ? [
          [
            "Azure for Early-Adopters",
            "",
            React.createElement(AzureTalkToUsStep, {
              index: AzureSteps.TALK_TO_US,
              workspaceSlug: workspace.slug,
            }),
          ],
        ]
      : []),
    ...(selectedBackend === BackendTypeServer.GCP_HOSTED
      ? [
          [
            "Before you start",
            "",
            React.createElement(GCPBeforeStep, {
              index: GCPSteps.BEFORE,
              workspaceSlug: workspace.slug,
            }),
          ],
          [
            "Credentials",
            "We need access to your cloud account",
            React.createElement(GCPCredentialsStep, {
              index: GCPSteps.CREDENTIALS,
              workspaceSlug: workspace.slug,
            }),
          ],
        ]
      : []),
  ];

  return (
    <StepperProvider
      initialState={{
        completedSteps: [],
        expandedSteps: steps.map((_, index) => index),
      }}
    >
      <StepperContext.Consumer>
        {({ state: { completedSteps, expandedSteps }, dispatch }) => (
          <Stepper orientation="vertical" nonLinear>
            {steps.map(([label, optional, step], index) => {
              return (
                <Step
                  key={index}
                  active={expandedSteps.includes(index)}
                  completed={completedSteps.includes(index)}
                  expanded={expandedSteps.includes(index)}
                >
                  <StepButton
                    optional={optional}
                    color="inherit"
                    onClick={() => {
                      dispatch({
                        type: "TOGGLE_STEP_EXPANSION",
                        payload: index,
                      });
                    }}
                  >
                    {label}
                  </StepButton>
                  <StepContent
                    TransitionProps={{
                      unmountOnExit: false,
                    }}
                    sx={{
                      opacity:
                        completedSteps.includes(index - 1) || index === 0
                          ? 1
                          : 0.6,
                    }}
                  >
                    {step}
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        )}
      </StepperContext.Consumer>
    </StepperProvider>
  );
};
