import React, { useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { StyledAlert } from "./StyledAlert";
import { Urls } from "../../domain/urls";
import { styled } from "@mui/material";
import { useHasSeenInteractionMutation } from "../../crud/interactions/hooks";
import { InteractionType } from "../../crud/interactions/types";
import { UserContext } from "../../crud/user/context";

const MessageSection = styled(Typography)(({ theme }) => {
  return {
    marginTop: theme.spacing(2),
    maxWidth: "600px",
  };
});

export const ApiTokenAlert = (): React.ReactElement | null => {
  const { interactions } = useContext(UserContext);
  const mutateHasSeenInteraction = useHasSeenInteractionMutation();
  if (interactions.hasSeenApiTokenWarning) {
    return null;
  }
  const onClose = () => {
    mutateHasSeenInteraction.mutate(InteractionType.HasSeenApiTokenWarning);
  };
  return (
    <StyledAlert
      title="Long-lived API tokens are here"
      message={
        <div>
          <MessageSection>
            {
              "We now offer long-lived API tokens, which will replace our current"
            }
            {" token system. Go to your "}
            <Link component={NavLink} to={`/${Urls.Profile}`} underline="hover">
              Profile
            </Link>
            {" page to create one."}
          </MessageSection>
          <MessageSection>
            {"See the "}
            <Link
              href="https://coiled.io/security"
              target="_blank"
              underline="hover"
            >
              docs
            </Link>
            {" for more information."}
          </MessageSection>
        </div>
      }
      severity="info"
      onClose={onClose}
    />
  );
};
