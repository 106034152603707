/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClusterInfraState } from './ClusterInfraState';
import {
    ClusterInfraStateFromJSON,
    ClusterInfraStateFromJSONTyped,
    ClusterInfraStateToJSON,
} from './ClusterInfraState';

/**
 * 
 * @export
 * @interface ClusterInfrastructureLite
 */
export interface ClusterInfrastructureLite {
    /**
     * 
     * @type {ClusterInfraState}
     * @memberof ClusterInfrastructureLite
     */
    currentState: ClusterInfraState;
    /**
     * 
     * @type {boolean}
     * @memberof ClusterInfrastructureLite
     */
    giveSchedulerPublicIp: boolean;
}

/**
 * Check if a given object implements the ClusterInfrastructureLite interface.
 */
export function instanceOfClusterInfrastructureLite(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentState" in value;
    isInstance = isInstance && "giveSchedulerPublicIp" in value;

    return isInstance;
}

export function ClusterInfrastructureLiteFromJSON(json: any): ClusterInfrastructureLite {
    return ClusterInfrastructureLiteFromJSONTyped(json, false);
}

export function ClusterInfrastructureLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterInfrastructureLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentState': ClusterInfraStateFromJSON(json['current_state']),
        'giveSchedulerPublicIp': json['give_scheduler_public_ip'],
    };
}

export function ClusterInfrastructureLiteToJSON(value?: ClusterInfrastructureLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_state': ClusterInfraStateToJSON(value.currentState),
        'give_scheduler_public_ip': value.giveSchedulerPublicIp,
    };
}

