import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../../apiUtils";

type StatefulObject = {
  name?: string;
};

export type StateResponse = {
  id: number | string;
  state: string;
  reason: string;
  created: Date;
  updated: Date;
  statefulObject: StatefulObject;
};
type StateResponseRaw = {
  id: number | string;
  state: string;
  reason: string;
  created: number;
  updated: number;
  statefulObject: StatefulObject;
};

type StateHistoryRaw = {
  cluster: StateResponseRaw[];
  scheduler: StateResponseRaw[];
  schedulerInstance: StateResponseRaw[];
  worker: StateResponseRaw[];
  workerInstance: StateResponseRaw[];
  clusterInfra: StateResponseRaw[];
};
export type StateHistory = {
  cluster: StateResponse[];
  scheduler: StateResponse[];
  schedulerInstance: StateResponse[];
  worker: StateResponse[];
  workerInstance: StateResponse[];
  clusterInfra: StateResponse[];
};

const convertRawState = (rawState: StateResponseRaw) => ({
  ...rawState,
  created: new Date(rawState.created),
  updated: new Date(rawState.updated),
});

export const fetchHistory = async (
  clusterId: string,
  accountSlug: string,
  token: string,
): Promise<StateHistory> => {
  const route = `/api/v2/clusters/account/${accountSlug}/id/${clusterId}/states`;
  const stateHistoryRaw = await ky
    .get(route, {
      headers: getStandardHeaders(),
    })
    .json();
  const camelized = camelizeKeys(stateHistoryRaw) as StateHistoryRaw;
  return {
    cluster: camelized.cluster.map(convertRawState),
    scheduler: camelized.scheduler.map(convertRawState),
    schedulerInstance: camelized.schedulerInstance.map(convertRawState),
    worker: camelized.worker.map(convertRawState),
    workerInstance: camelized.workerInstance.map(convertRawState),
    clusterInfra: camelized.clusterInfra.map(convertRawState),
  } as StateHistory;
};
