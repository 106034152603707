import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Box, Container } from "@mui/material";

import { ThemedAppBar } from "./components";

type Props = {
  children: any;
};

export const MinimalLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Box>
      <ThemedAppBar position="fixed">
        <Toolbar />
      </ThemedAppBar>
      <Box component="main">
        <Toolbar />
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
