import React, { useContext } from "react";
import ky, { HTTPError } from "ky";
import Button from "@mui/material/Button";
import { parseError, getStandardHeaders, ApiError } from "../../apiUtils";
import { SingleLineForm } from "./SingleLineForm";
import { Stack } from "@mui/material";
import { UserContext } from "../../crud/user/context";

enum PatchApiError {
  InvalidSlug = "InvalidSlug",
  AlreadyExists = "AlreadyExists",
}

type PatchUserResult =
  | { error: undefined; username: string }
  | { error: ApiError | PatchApiError };

const patchUser = async (username: string): Promise<PatchUserResult> => {
  try {
    const result = await ky.patch(`/api/v1/users/me/`, {
      json: {
        username,
      },
      headers: getStandardHeaders(),
    });
    const body = await result.json<any>();
    return { error: undefined, username: body.username };
  } catch (err) {
    if (err instanceof HTTPError) {
      if (err?.response?.status === 409) {
        return { error: PatchApiError.AlreadyExists };
      }
      if (err?.response?.status >= 400) {
        const body = await err.response.json();
        if (body.username[0].startsWith("Enter a valid “slug”")) {
          return { error: PatchApiError.InvalidSlug };
        }
      }
    }
    return { error: parseError(err) };
  }
};

type EditState =
  | { type: "none" }
  | { type: "editing" }
  | { type: "submitting" }
  | { type: "submissionError"; error: ApiError | PatchApiError };

type FormShape = {
  username: string;
};

export const UsernameForm = (): React.ReactElement => {
  const { user } = useContext(UserContext);
  const [editState, setEditState] = React.useState<EditState>({
    type: "none",
  });

  const onSubmit = async (data: FormShape) => {
    setEditState({ type: "submitting" });
    const result = await patchUser(data.username);
    if (result.error) {
      setEditState({ type: "submissionError", error: result.error });
      return;
    } else {
      setEditState({ type: "none" });
    }
  };

  const handleEditButtonClick = () => setEditState({ type: "editing" });
  const handleCancelButtonClick = () => {
    setEditState({ type: "none" });
  };

  const formActive =
    ["editing", "submissionError"].indexOf(editState.type) !== -1;

  let errorMessage = "";
  if (editState.type === "submissionError") {
    if (editState.error === PatchApiError.InvalidSlug) {
      errorMessage =
        'Enter a valid "slug" consisting of letters, numbers, underscores or hyphens.';
    } else if (editState.error === PatchApiError.AlreadyExists) {
      errorMessage = "That username already exists";
    } else {
      errorMessage = "An unknown error occurred";
    }
  }

  let showInputAsError = false;
  if (editState.type === "submissionError") {
    showInputAsError = true;
  }

  return (
    <Stack sx={{ maxWidth: "350px" }} spacing={2}>
      <SingleLineForm
        formActive={formActive}
        submitting={editState.type === "submitting"}
        error={showInputAsError}
        errorMessage={errorMessage}
        formOptions={{
          defaultValues: {
            username: user.username,
          },
          mode: "onBlur",
        }}
        fieldName="username"
        value={user.username}
        handleCancel={handleCancelButtonClick}
        handleSubmit={onSubmit}
      />
      {editState.type === "none" && (
        <Button
          onClick={handleEditButtonClick}
          disabled={formActive}
          size="small"
          variant="secondary"
          sx={{ maxWidth: "100px" }}
        >
          edit
        </Button>
      )}
    </Stack>
  );
};
