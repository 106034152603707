import { Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { Urls } from "../domain/urls";
import { verifyEmail, resendEmailConfirmation } from "./auth";
import { AuthLayout } from "./AuthLayout";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validation";
import { Section } from "./CardSection";
import {
  AuthForm,
  ErrorMessage as StatusMessage,
  ExternalLink,
  InternalLink,
  Text,
} from "./components";

type Inputs = {
  email: string;
};

export const VerifyEmail = (): React.ReactElement => {
  const { key } = useParams<{ key: string }>();
  const isVerifyEmailSent = useMatch(Urls.VerifyEmailSent);
  const isVerifyEmailReSend = useMatch(Urls.VerifyEmailReSend);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const [successActivation, setSuccessActivation] =
    React.useState<boolean>(false);

  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async ({ email }: Inputs) => {
    setSubmitted(true);
    const result = await resendEmailConfirmation(email);
    if (result.type === "success") {
      setResendSuccess(true);
      setSubmitted(false);
    }
    if (result.type === "error") {
      setError(result.error);
      setSubmitted(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (key) {
        setLoading(true);
        const result = await verifyEmail(key);
        setLoading(false);
        if (result.type === "success") {
          setSuccessActivation(true);
          setTimeout(() => navigate(`${Urls.Root}?open=true`), 3000);
        } else {
          setError(result.error);
          setSuccessActivation(false);
        }
      }
    })();
  }, [key, navigate]);

  if (isVerifyEmailSent || resendSuccess === true) {
    // show view if
    // - url is for verification email sent
    // - resend verification email is successful
    return (
      <AuthLayout>
        <Section title="Verify Your Email Address">
          <Text>
            We have sent an email to you for verification. Follow the link
            provided to finalize the signup process. If you do not receive it
            within a few minutes, you can request another{" "}
            <InternalLink to={`/${Urls.VerifyEmailReSend}`}>
              confirmation email
            </InternalLink>
            .
          </Text>
        </Section>
      </AuthLayout>
    );
  } else if (isVerifyEmailReSend) {
    // show if url is for requesting resend of verification email
    return (
      <AuthLayout>
        <Section title="Resend Email Verification">
          {error && (
            <StatusMessage variant="body1" align="center" color="error">
              {error}
            </StatusMessage>
          )}

          <Text>
            Please provide your email for us to send you another email
            confirmation. Follow the link provided to finalize the signup
            process.
          </Text>
          <Text>
            Please{" "}
            <ExternalLink
              target={"_blank"}
              rel="noopener noreferrer"
              href={"mailto:support@coiled.io"}
            >
              contact us
            </ExternalLink>{" "}
            if you do not receive it within a few minutes.
          </Text>

          <AuthForm onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("email", {
                required: "email is required",
                validate: (value) =>
                  validateEmail(value) === true || "invalid email",
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              fullWidth
              variant="outlined"
              placeholder="email"
            />

            <Button variant="primary" type="submit">
              {submitted ? <CircularProgress size={24} /> : "submit"}
            </Button>
          </AuthForm>
        </Section>
      </AuthLayout>
    );
  } else if (successActivation) {
    return (
      <AuthLayout>
        <Section title="Email Verified">
          <Text>
            You have sucessfully verified your email. You will be redirected
            shortly to the{" "}
            <InternalLink
              to={{ pathname: `${Urls.Root}`, search: `?open=true` }}
            >
              home page
            </InternalLink>
            .
          </Text>
        </Section>
      </AuthLayout>
    );
  } else {
    return (
      // activate confirmation email flow
      // confirm the key is correct
      <AuthLayout>
        <Section title="Activate Account">
          {loading && <CircularProgress size={36} />}
          {error && (
            <StatusMessage variant="body1" align="center" color="error">
              {error}
            </StatusMessage>
          )}
          {successActivation && (
            <StatusMessage variant="body1" align="center" color="textPrimary">
              Success! Redirecting you to login.
            </StatusMessage>
          )}
        </Section>
      </AuthLayout>
    );
  }
};
