/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkerOptionsSchema
 */
export interface WorkerOptionsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof WorkerOptionsSchema
     */
    spot: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkerOptionsSchema
     */
    spotOnDemandFallback: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkerOptionsSchema
     */
    acceleratorType?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkerOptionsSchema
     */
    acceleratorCount?: number;
}

/**
 * Check if a given object implements the WorkerOptionsSchema interface.
 */
export function instanceOfWorkerOptionsSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spot" in value;
    isInstance = isInstance && "spotOnDemandFallback" in value;

    return isInstance;
}

export function WorkerOptionsSchemaFromJSON(json: any): WorkerOptionsSchema {
    return WorkerOptionsSchemaFromJSONTyped(json, false);
}

export function WorkerOptionsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerOptionsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spot': json['spot'],
        'spotOnDemandFallback': json['spot_on_demand_fallback'],
        'acceleratorType': !exists(json, 'accelerator_type') ? undefined : json['accelerator_type'],
        'acceleratorCount': !exists(json, 'accelerator_count') ? undefined : json['accelerator_count'],
    };
}

export function WorkerOptionsSchemaToJSON(value?: WorkerOptionsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spot': value.spot,
        'spot_on_demand_fallback': value.spotOnDemandFallback,
        'accelerator_type': value.acceleratorType,
        'accelerator_count': value.acceleratorCount,
    };
}

