import { AccountBalanceRounded } from "@mui/icons-material";
import { Avatar, Badge, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

type BaseProps = {
  type: "members" | "invites";
  orgAdmin: boolean;
};

type MemberProps = BaseProps & {
  type: "members";
  user: {
    profile: {
      avatarUrl?: string;
    };
    username: string;
    fullName?: string;
    preferredName?: string;
  };
};

type InviteProps = BaseProps & {
  type: "invites";
  email: string;
};

type Props = MemberProps | InviteProps;

export const getAvatarCell = (props: Props): React.ReactElement => {
  return (
    <Stack direction="row" alignItems={"center"}>
      <Badge
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            padding: 0,
            margin: 0,
            backgroundColor: (theme) => theme.palette.grey[200],
            border: (theme) => `1px solid ${theme.palette.background.paper}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        badgeContent={
          <Tooltip title="Organization Admin" placement="top">
            <AccountBalanceRounded sx={{ fontSize: "12px" }} color="primary" />
          </Tooltip>
        }
        invisible={!props.orgAdmin}
      >
        <Avatar
          sizes="small"
          sx={{ marginRight: 1 }}
          src={
            props.type === "members" ? props.user.profile.avatarUrl : undefined
          }
          alt={props.type === "members" ? props.user.username : undefined}
        />
      </Badge>
      <Stack>
        <Typography variant="h3" noWrap>
          {props.type === "members" && `${props.user.fullName}`}
          {props.type === "invites" && props.email}
        </Typography>
        <Typography
          variant="subtitle2"
          noWrap
          color={props.type === "invites" ? "error" : undefined}
        >
          {props.type === "members" && `${props.user.username}`}
          {props.type === "invites" && "Invitation not yet accepted"}
        </Typography>
      </Stack>
    </Stack>
  );
};
