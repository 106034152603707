import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  StepProps,
  StepperContext,
} from "../../../shared-components/StepperContext";
import { useAnalytics } from "use-analytics";
import { BackendTypeServer } from "../../../domain/people";
import { InfoOutlined } from "@mui/icons-material";

export const AWSAccountSettingsStep = ({
  index,
}: StepProps): React.ReactElement => {
  const { dispatch } = useContext(StepperContext);
  const analytics = useAnalytics();
  return (
    <AWSBeforeForm
      onSuccess={() => {
        dispatch({ type: "STEP_COMPLETED", payload: index });
        dispatch({ type: "COLLAPSE_STEP", payload: index });
        analytics.track("setup-before-step-completed", {
          provider: BackendTypeServer.AWS_HOSTED,
        });
      }}
    />
  );
};
type AWSAccountSettingsFormProps = {
  disabled?: boolean;
  onSuccess: () => void;
};

const AWSBeforeForm = ({
  onSuccess,
}: AWSAccountSettingsFormProps): React.ReactElement => {
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSuccess();
      }}
    >
      <Stack alignItems={"flex-start"}>
        <Dialog
          open={resourceDialogOpen}
          onClose={() => setResourceDialogOpen(false)}
        >
          <DialogTitle>Resources</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Coiled will create these resources in your AWS account</p>
              <p>
                <strong>
                  Note: We only create resources that do not incur any charges
                  from your cloud provider
                </strong>
              </p>
            </DialogContentText>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Coiled IAM Role"
                  secondary="Grants Coiled the ability to create/manage clusters."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cluster IAM Role"
                  secondary="Grants clusters logging permissions."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cloudwatch log group"
                  secondary="Used to store logs from your cluster."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Security groups"
                  secondary="Allows us to control ingress/egress traffic to/from your cluster."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="VPC + Subnets"
                  secondary="A network to place your clusters in."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Internet Gateway"
                  secondary="This allows your cluster to access the internet."
                />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
        <List>
          <ListItem>
            <ListItemText
              primary="You'll need an AWS account and admin credentials."
              secondary={
                <>
                  {"If you don’t have these, consider the free tier "}
                  <Link href="https://aws.amazon.com/free" target="_blank">
                    AWS free tier
                  </Link>
                  {" or talk to a colleague who does."}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="This will not create resources that cost money." />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Stack direction={"row"} spacing={1}>
                  <span>
                    {"This will not grant Coiled access to your data. "}
                  </span>
                  <Link href="https://www.coiled.io/security" target="_blank">
                    <InfoOutlined fontSize="small" />
                  </Link>
                </Stack>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  {"Here are some details of the"}{" "}
                  <Link onClick={() => setResourceDialogOpen(true)}>
                    resources
                  </Link>
                  {" that will be created."}
                </span>
              }
            />
          </ListItem>
        </List>
        <Button type="submit">Next</Button>
      </Stack>
    </form>
  );
};
