import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import ky from "ky";
import { datadogLogs } from "@datadog/browser-logs";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  apiErrorToMessage,
  getStandardHeaders,
  parseError,
} from "../../apiUtils";
import { UserContext } from "../../crud/user/context";
import { useQueryClient } from "react-query";

const updateProfileContactInfo = async (
  fullName: string,
  preferredName: string,
  title: string,
  company: string,
  workEmail: string,
  phoneNumber: string,
  canContact: boolean,
): Promise<{ error?: string }> => {
  /* eslint-disable @typescript-eslint/naming-convention */
  try {
    await ky.patch(`/api/v1/users/me/profile/`, {
      json: {
        full_name: fullName,
        preferred_name: preferredName,
        title,
        company,
        work_email: workEmail,
        phone_number: phoneNumber,
        can_contact: canContact,
      },
      headers: getStandardHeaders(),
    });
    return {};
    /* eslint-enable @typescript-eslint/naming-convention */
  } catch (err) {
    datadogLogs.logger.error("Error patching user profile");
    return { error: parseError(err) };
  }
};

interface UserInfoFormInput {
  fullName: string;
  preferredName: string;
  title: string;
  company: string;
  workEmail: string;
  phoneNumber: string;
  canContact: boolean;
}

interface UserInfoFormProps {
  open?: boolean;
  handleClose: () => void;
  formTitle: string;
  showCanContact?: boolean;
}

type SubmitState =
  | { type: "none" }
  | { type: "submitting" }
  | { type: "error"; error: string };

export const UserInfoForm = ({
  handleClose,
  open = true,
  formTitle,
  showCanContact = true,
}: UserInfoFormProps): React.ReactElement => {
  const { user } = useContext(UserContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UserInfoFormInput>({
    mode: "onBlur",
  });
  const [submissionState, setSubmissionState] = useState<SubmitState>({
    type: "none",
  });
  const queryClient = useQueryClient();

  const onSubmit = async (data: UserInfoFormInput) => {
    setSubmissionState({ type: "submitting" });
    const result = await updateProfileContactInfo(
      data.fullName,
      data.preferredName,
      data.title,
      data.company,
      data.workEmail,
      data.phoneNumber,
      data.canContact,
    );
    if (result.error) {
      setSubmissionState({
        type: "error",
        error: apiErrorToMessage(result.error),
      });
    } else {
      setSubmissionState({ type: "none" });
      queryClient.invalidateQueries();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{formTitle}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={2}>
            <Typography>All fields are optional.</Typography>
            <TextField
              label="Full Name"
              variant="outlined"
              defaultValue={user.fullName}
              {...register("fullName")}
            />
            <TextField
              label="Preferred Name"
              variant="outlined"
              defaultValue={user.preferredName}
              {...register("preferredName")}
            />
            <TextField
              label="Job Title"
              variant="outlined"
              defaultValue={user.profile.title}
              {...register("title")}
            />
            <TextField
              label="Company"
              variant="outlined"
              defaultValue={user.profile.company}
              {...register("company")}
            />
            <TextField
              error={Boolean(errors.workEmail)}
              helperText={
                errors.workEmail ? "Please enter a valid email address" : ""
              }
              type="email"
              label={user.hasUsablePassword ? "Email" : "Work Email"}
              variant="outlined"
              defaultValue={user.profile.workEmail}
              {...register("workEmail", {
                required: user.hasUsablePassword,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            <TextField
              error={Boolean(errors.phoneNumber)}
              helperText={
                errors.phoneNumber ? "Can contain numbers and hyphens" : ""
              }
              label="Phone Number"
              variant="outlined"
              defaultValue={user.profile.phoneNumber}
              // accepts numbers, spaces, dashes, and optional leading "+" sign
              {...register("phoneNumber", { pattern: /^\+?(\s|-|[0-9\b]+)+$/ })}
            />
            {showCanContact && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={user.profile.canContact}
                    {...register("canContact")}
                    color="primary"
                  />
                }
                label="Coiled can contact me"
                labelPlacement="end"
              />
            )}
          </Stack>
          <Typography>
            You can edit these any time from your profile page.
          </Typography>
          {submissionState.type === "error" ? (
            <Typography color="error">{submissionState.error}</Typography>
          ) : null}
          <DialogActions>
            <Button type="button" variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit">
              {submissionState.type === "submitting" ? (
                <CircularProgress size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
