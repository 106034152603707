/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkerPhoneHomeResponse
 */
export interface WorkerPhoneHomeResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkerPhoneHomeResponse
     */
    schedulerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkerPhoneHomeResponse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkerPhoneHomeResponse
     */
    schedulerReady: boolean;
}

/**
 * Check if a given object implements the WorkerPhoneHomeResponse interface.
 */
export function instanceOfWorkerPhoneHomeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "schedulerReady" in value;

    return isInstance;
}

export function WorkerPhoneHomeResponseFromJSON(json: any): WorkerPhoneHomeResponse {
    return WorkerPhoneHomeResponseFromJSONTyped(json, false);
}

export function WorkerPhoneHomeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerPhoneHomeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedulerAddress': !exists(json, 'scheduler-address') ? undefined : json['scheduler-address'],
        'name': json['name'],
        'schedulerReady': json['scheduler-ready'],
    };
}

export function WorkerPhoneHomeResponseToJSON(value?: WorkerPhoneHomeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduler-address': value.schedulerAddress,
        'name': value.name,
        'scheduler-ready': value.schedulerReady,
    };
}

