import React, { useContext } from "react";
import ky from "ky";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { parseError, ApiError, getStandardHeaders } from "../../apiUtils";
import { SingleLineForm } from "./SingleLineForm";
import Link from "@mui/material/Link";
import { Stack } from "@mui/material";
import { useToken } from "../../utils/hooks";
import { UserContext } from "../../crud/user/context";

type PatchUserResult = { error?: ApiError };

const submitAccessKey = async (
  token: string,
  userId: number,
  gitAccessToken: string | null,
): Promise<PatchUserResult> => {
  try {
    const result = await ky.patch(`/api/v1/users/${userId}/access_token/`, {
      json: {
        git_access_token: gitAccessToken, // eslint-disable-line @typescript-eslint/naming-convention
      },
      headers: getStandardHeaders(),
    });
    await result.json();
    return { error: undefined };
  } catch (err) {
    return { error: parseError(err) };
  }
};

type FormShape = {
  gitAccessToken: string;
};

type EditState =
  | { type: "none" }
  | { type: "editing" }
  | { type: "submitting" }
  | { type: "error"; error: ApiError };

type Props = {
  onSuccessfulSubmit: () => void;
};

export const GithubKeyForm = ({
  onSuccessfulSubmit,
}: Props): React.ReactElement => {
  const { user } = useContext(UserContext);
  const token = useToken();

  const [editState, setEditState] = React.useState<EditState>({ type: "none" });
  const handleAddButtonClick = () => {
    setEditState({ type: "editing" });
  };
  const handleRemoveButtonClick = async () => {
    const res = await submitAccessKey(token, user.id, null);
    if (res.error) {
      setEditState({ type: "error", error: res.error });
      return;
    }
    onSuccessfulSubmit();
  };
  const handleCancel = () => {
    setEditState({ type: "none" });
  };

  const handleSubmit = async ({ gitAccessToken }: FormShape) => {
    setEditState({ type: "submitting" });
    const res = await submitAccessKey(token, user.id, gitAccessToken);
    if (res.error) {
      setEditState({ type: "error", error: res.error });
      return;
    }
    onSuccessfulSubmit();
    setTimeout(setEditState, 1000, { type: "none" });
  };

  if (editState.type === "none") {
    if (user.profile.hasGitAccessToken) {
      return (
        <Stack spacing={2}>
          <Typography>We have a GitHub Access Token stored for you</Typography>
          <Button
            onClick={handleRemoveButtonClick}
            size="small"
            sx={{ maxWidth: "150px" }}
            variant="secondary"
          >
            Remove
          </Button>
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Typography>
          {
            "Add an access token to access software in private GitHub repositories. "
          }
          <Link
            color="primary"
            underline="always"
            target="_blank"
            href="https://docs.coiled.io/user_guide/software_environment_creation.html#private-repositories"
          >
            {"Learn more"}
          </Link>
        </Typography>
        <Button
          variant="secondary"
          onClick={handleAddButtonClick}
          size="small"
          sx={{ maxWidth: "100px" }}
        >
          Add
        </Button>
      </Stack>
    );
  }

  let errorMessage = "";
  if (editState.type === "error") {
    errorMessage = "An unknown error occurred";
  }

  return (
    <SingleLineForm
      formActive
      submitting={editState.type === "submitting"}
      error={editState.type === "error"}
      errorMessage={errorMessage}
      formOptions={{
        defaultValues: {
          gitAccessToken: "",
        },
        mode: "onBlur",
      }}
      fieldName="gitAccessToken"
      inputType="password"
      value=""
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
    />
  );
};
