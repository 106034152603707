/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Valid states for Clusters
 * @export
 */
export const ClusterStateEnum = {
    Pending: 'pending',
    Starting: 'starting',
    Scaling: 'scaling',
    Ready: 'ready',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Error: 'error'
} as const;
export type ClusterStateEnum = typeof ClusterStateEnum[keyof typeof ClusterStateEnum];


export function ClusterStateEnumFromJSON(json: any): ClusterStateEnum {
    return ClusterStateEnumFromJSONTyped(json, false);
}

export function ClusterStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterStateEnum {
    return json as ClusterStateEnum;
}

export function ClusterStateEnumToJSON(value?: ClusterStateEnum | null): any {
    return value as any;
}

