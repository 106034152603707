import { createContext } from "react";
import { ApiError } from "../../apiUtils";
import { ClusterFrontendSchema } from "../../api-client";

interface ClusterContextInterface {
  loading: boolean;
  clusterId: string;
  error?: ApiError;
  cluster?: ClusterFrontendSchema;
}
export const ClusterContext = createContext<ClusterContextInterface>({
  loading: true,
  clusterId: "",
});
