/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComputationUpdate } from './ComputationUpdate';
import {
    ComputationUpdateFromJSON,
    ComputationUpdateFromJSONTyped,
    ComputationUpdateToJSON,
} from './ComputationUpdate';
import type { ExceptionUpdate } from './ExceptionUpdate';
import {
    ExceptionUpdateFromJSON,
    ExceptionUpdateFromJSONTyped,
    ExceptionUpdateToJSON,
} from './ExceptionUpdate';
import type { SpanUpdate } from './SpanUpdate';
import {
    SpanUpdateFromJSON,
    SpanUpdateFromJSONTyped,
    SpanUpdateToJSON,
} from './SpanUpdate';
import type { Tags } from './Tags';
import {
    TagsFromJSON,
    TagsFromJSONTyped,
    TagsToJSON,
} from './Tags';
import type { TaskGroupUpdate } from './TaskGroupUpdate';
import {
    TaskGroupUpdateFromJSON,
    TaskGroupUpdateFromJSONTyped,
    TaskGroupUpdateToJSON,
} from './TaskGroupUpdate';

/**
 * Class for the metrics received from the scheduler
 * @export
 * @interface MetricsSchema
 */
export interface MetricsSchema {
    /**
     * 
     * @type {number}
     * @memberof MetricsSchema
     */
    ident: number;
    /**
     * 
     * @type {{ [key: string]: ComputationUpdate; }}
     * @memberof MetricsSchema
     */
    computations?: { [key: string]: ComputationUpdate; };
    /**
     * 
     * @type {{ [key: string]: TaskGroupUpdate; }}
     * @memberof MetricsSchema
     */
    taskGroups?: { [key: string]: TaskGroupUpdate; };
    /**
     * 
     * @type {{ [key: string]: ExceptionUpdate; }}
     * @memberof MetricsSchema
     */
    exceptions?: { [key: string]: ExceptionUpdate; };
    /**
     * 
     * @type {{ [key: string]: SpanUpdate; }}
     * @memberof MetricsSchema
     */
    spans?: { [key: string]: SpanUpdate; };
    /**
     * 
     * @type {number}
     * @memberof MetricsSchema
     */
    threads?: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsSchema
     */
    nTasks?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof MetricsSchema
     */
    workerPlugins?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof MetricsSchema
     */
    nannyPlugins?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof MetricsSchema
     */
    idleSince?: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsSchema
     */
    idleTimeout?: number;
    /**
     * 
     * @type {{ [key: string]: Tags; }}
     * @memberof MetricsSchema
     */
    tags?: { [key: string]: Tags; };
}

/**
 * Check if a given object implements the MetricsSchema interface.
 */
export function instanceOfMetricsSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ident" in value;

    return isInstance;
}

export function MetricsSchemaFromJSON(json: any): MetricsSchema {
    return MetricsSchemaFromJSONTyped(json, false);
}

export function MetricsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ident': json['ident'],
        'computations': !exists(json, 'computations') ? undefined : (mapValues(json['computations'], ComputationUpdateFromJSON)),
        'taskGroups': !exists(json, 'task_groups') ? undefined : (mapValues(json['task_groups'], TaskGroupUpdateFromJSON)),
        'exceptions': !exists(json, 'exceptions') ? undefined : (mapValues(json['exceptions'], ExceptionUpdateFromJSON)),
        'spans': !exists(json, 'spans') ? undefined : (mapValues(json['spans'], SpanUpdateFromJSON)),
        'threads': !exists(json, 'threads') ? undefined : json['threads'],
        'nTasks': !exists(json, 'n_tasks') ? undefined : json['n_tasks'],
        'workerPlugins': !exists(json, 'worker_plugins') ? undefined : json['worker_plugins'],
        'nannyPlugins': !exists(json, 'nanny_plugins') ? undefined : json['nanny_plugins'],
        'idleSince': !exists(json, 'idle_since') ? undefined : json['idle_since'],
        'idleTimeout': !exists(json, 'idle_timeout') ? undefined : json['idle_timeout'],
        'tags': !exists(json, 'tags') ? undefined : (mapValues(json['tags'], TagsFromJSON)),
    };
}

export function MetricsSchemaToJSON(value?: MetricsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ident': value.ident,
        'computations': value.computations === undefined ? undefined : (mapValues(value.computations, ComputationUpdateToJSON)),
        'task_groups': value.taskGroups === undefined ? undefined : (mapValues(value.taskGroups, TaskGroupUpdateToJSON)),
        'exceptions': value.exceptions === undefined ? undefined : (mapValues(value.exceptions, ExceptionUpdateToJSON)),
        'spans': value.spans === undefined ? undefined : (mapValues(value.spans, SpanUpdateToJSON)),
        'threads': value.threads,
        'n_tasks': value.nTasks,
        'worker_plugins': value.workerPlugins,
        'nanny_plugins': value.nannyPlugins,
        'idle_since': value.idleSince,
        'idle_timeout': value.idleTimeout,
        'tags': value.tags === undefined ? undefined : (mapValues(value.tags, TagsToJSON)),
    };
}

