import omit from "lodash/omit";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { CoiledException } from "../../utils/errors";
import { useWorkspaceContextSlug } from "../../utils/hooks";
import { getClusterMetrics, Metric } from "./fetch";
import { GroupedMetric, GroupKey, MetricQuery, MetricsPage } from "./types";
import { TimeRange } from "../../pages/Clusters/types";
import { Domain } from "../../pages/Clusters/Information/charts/types";
import { REFRESH_METRICS_INTERVAL } from "../../pages/Clusters/Information/const";

/**
 * Returns metrics for a number of queries for a given cluster.
 */
export const useClusterMetrics = (
  clusterId: string,
  groupKey: GroupKey,
  query: MetricQuery[],
  variation?: string,
  domains?: Domain[],
  defaultTimeRange?: TimeRange,
): UseInfiniteQueryResult<MetricsPage, Error> => {
  const accountSlug = useWorkspaceContextSlug();
  const cacheKey = query.map(({ name }) => name).join();

  const queryFn = async ({
    pageParam: previousTimeRangeEnd,
  }: {
    pageParam?: number;
  }) => {
    const start = previousTimeRangeEnd
      ? getNextStartTime(previousTimeRangeEnd, defaultTimeRange)
      : undefined;

    const data = await getMultipleMetrics(
      accountSlug,
      clusterId,
      groupKey,
      query,
      domains,
      start,
    );
    return { data, timeRange: defaultTimeRange };
  };

  return useInfiniteQuery({
    queryKey: [`${cacheKey}-${variation}`, accountSlug, clusterId],
    queryFn,
    enabled: !!defaultTimeRange,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

export const useTaskPrefixMetrics = (
  clusterId: string,
  timeRange?: TimeRange,
  liveUpdates?: boolean,
): UseInfiniteQueryResult<MetricsPage, Error> => {
  const accountSlug = useWorkspaceContextSlug();

  const query = [
    {
      name: "!cluster.task_prefix_compute_durations",
    },
  ];
  const cacheKey = query.map(({ name }) => name).join();
  const groupKey = "taskPrefixName";

  const queryFn = async ({
    pageParam: previousTimeRangeEnd,
  }: {
    pageParam?: number;
  }) => {
    const start = previousTimeRangeEnd
      ? getNextStartTime(previousTimeRangeEnd, timeRange)
      : undefined;

    let data: GroupedMetric[] = [];
    try {
      data = await getMultipleMetrics(
        accountSlug,
        clusterId,
        groupKey,
        query,
        undefined,
        start,
      );
    } catch (err) {
      // no-op: Just hide task prefix timeline if we can't get data
    }

    const keys = getKeys(data);
    return { data, keys, timeRange };
  };

  // Cache live data with a key that stays the same as timeRange expands: "<startTs>,inf"
  const timeKey = liveUpdates && timeRange ? [timeRange[0], "inf"] : timeRange;

  return useInfiniteQuery({
    queryKey: [cacheKey, accountSlug, clusterId, (timeKey || []).join()],
    queryFn,

    enabled: !!timeRange,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

const getNextStartTime = (
  previousTimeRangeEnd: number,
  defaultTimeRange?: TimeRange,
) => {
  const rangeStart = defaultTimeRange
    ? Math.floor(defaultTimeRange[0] / 1000)
    : 0;
  const previousEnd =
    previousTimeRangeEnd - (REFRESH_METRICS_INTERVAL * 2) / 1000;
  return Math.max(previousEnd, rangeStart);
};

const getMultipleMetrics = async (
  accountSlug: string,
  clusterId: string,
  groupKey: GroupKey,
  queries: MetricQuery[],
  domains?: Domain[],
  start?: number,
) => {
  const promises = queries.map((query) => {
    return getClusterMetrics(
      accountSlug,
      clusterId,
      query.name,
      query.sort,
      start,
    );
  });

  const settledPromises = await Promise.allSettled<Metric[]>(promises);

  // Backfill the type label for each metric if it's provided
  const responses = settledPromises.map((settledPromise, index) => {
    if (settledPromise.status === "rejected") {
      throw new CoiledException(settledPromise.reason);
    }

    const type = queries[index].typeLabel;

    if (!type) {
      return settledPromise.value;
    }

    return settledPromise.value.map((metric) => {
      return {
        ...metric,
        metric: { [groupKey]: type },
      };
    });
  });

  return groupByTimestamp(responses.flat(1), groupKey, domains);
};

/**
 * Group metrics by timestamp into a single array of objects with object keys being the metric name.
 */
const groupByTimestamp = (
  metrics: Metric[],
  groupKey: GroupKey,
  domains?: Domain[],
): GroupedMetric[] => {
  const groupedMetrics: GroupedMetric[] = [];

  // Let's use a map to make sure timestamps align
  const timestampMap: Record<string, GroupedMetric> = {};
  for (const metric of metrics) {
    const key = metric.metric[groupKey];

    if (!key) continue;

    for (const [timestamp, value] of metric.values) {
      if (!timestampMap[timestamp]) timestampMap[timestamp] = {};
      // convert "0" to null so we can filter out of tooltip but still sync hover and show time on axis
      timestampMap[timestamp][key] = value > 0 ? value : null;
    }
  }

  const ranges = getRanges(domains);

  Object.entries(timestampMap).map(([timestamp, values]) => {
    // If a domain is a range e.g [min, max], we need to stitch the values together
    ranges.forEach((range) => {
      const start = values[range[0]];
      const end = values[range[1]];
      values[range.join("-")] = [parseFloat(start || 0), parseFloat(end || 0)];

      // Delete original values
      delete values[range[0]];
      delete values[range[1]];
    });

    if (!!Object.keys(values).length) {
      groupedMetrics.push({ ...values, timestamp });
    }
  });

  return groupedMetrics;
};

/**
 * Extract all ranges from a list of domains.
 */
const getRanges = (domains?: Domain[]) => {
  const ranges: Record<number, string[]> = {};

  for (const { name, range } of domains || []) {
    if (range) {
      if (!ranges[range]) ranges[range] = [];
      ranges[range].push(name);
    }
  }

  return Object.values(ranges);
};

/**
 * Extract all keys from a list of grouped metrics.
 */
const getKeys = (metrics: GroupedMetric[]) => {
  const keyMap: Record<string, number> = {};

  for (const metric of metrics) {
    const values = omit(metric, ["timestamp"]);
    for (const key in values) {
      if (!metric.hasOwnProperty(key)) continue;

      if (values[key] > 0) {
        keyMap[key] = 1;
      }
    }
  }

  return Object.keys(keyMap);
};
