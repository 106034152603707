import {
  UseInfiniteQueryResult,
  UseMutationResult,
  useInfiniteQuery,
  useMutation,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  BillingEventListSchema,
  CreateBillingEventSchema,
  PagedBillingEventListSchema,
  PricingNinjaViewsBillingEventsListBillingEventsRequest,
} from "../../api-client";
import { sum } from "lodash";

export const useBillingEvents = (
  query: PricingNinjaViewsBillingEventsListBillingEventsRequest,
  refetchInterval?: number,
): UseInfiniteQueryResult<PagedBillingEventListSchema> => {
  return useInfiniteQuery(
    ["billingEvents", query],
    async ({ pageParam }) =>
      ApiClient.pricingNinjaViewsBillingEventsListBillingEvents({
        ...query,
        ...pageParam,
      }),

    {
      refetchInterval,
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = sum(allPages.map((page) => page.items.length));
        if (totalCount < lastPage.count) {
          return {
            ...query,
            offset: totalCount,
            limit: query.limit,
          };
        } else {
          return undefined;
        }
      },
    },
  );
};

export const useCreateBillingEventMutation = (): UseMutationResult<
  BillingEventListSchema,
  unknown,
  CreateBillingEventSchema,
  unknown
> => {
  return useMutation(
    ["createBillingEvent"],
    async (query: CreateBillingEventSchema) =>
      ApiClient.pricingNinjaViewsBillingEventsCreateBillingEvent({
        createBillingEventSchema: query,
      }),
  );
};
