import { datadogLogs } from "@datadog/browser-logs";
import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../apiUtils";
import { handleError } from "../../utils/errors";
import { LogEntry } from "./types";

// Let's start by limiting the number of logs we fetch to 5000
// to keep the frontend snappy.
const LOG_ENTRY_LIMIT = 5000;

type LogSearchParams = {
  dask?: boolean;
  system?: boolean;
  limit?: number;
  since_ms?: number;
  until_ms?: number;
  filter_pattern?: string;
};

export const getClusterLogs = async (
  accountSlug: string,
  token: string,
  clusterId: string,
  dask: boolean,
  system: boolean,
  sinceMs?: number,
  untilMs?: number,
  filterPattern?: string,
  signal?: AbortSignal,
): Promise<LogEntry[]> => {
  try {
    const searchParams: LogSearchParams = {
      dask,
      system,
      limit: LOG_ENTRY_LIMIT,
    };

    if (sinceMs) {
      searchParams.since_ms = sinceMs;
    }

    if (untilMs) {
      searchParams.until_ms = untilMs;
    }

    if (filterPattern) {
      searchParams.filter_pattern = filterPattern;
    }

    const buildPayload = await ky
      .get(
        `/api/v2/clusters/account/${accountSlug}/id/${clusterId}/better-logs`,
        {
          headers: getStandardHeaders(),
          searchParams,
          timeout: 30000,
          // Use AbortController.signal to cancel ongoing request if the user changes the filter
          signal,
        },
      )
      .json();
    const data = camelizeKeys(buildPayload);
    return data.map((item: LogEntry, id: number) => ({
      ...item,
      id,
    })) as LogEntry[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get cluster logs for cluster ${clusterId} in the workspace ${accountSlug} - Reason: ${err}`,
    );

    await handleError(err as Error);
    throw err;
  }
};

export const getClusterInfraEvents = async (
  accountSlug: string,
  token: string,
  clusterId: string,
  signal?: AbortSignal,
): Promise<LogEntry[]> => {
  try {
    const buildPayload = await ky
      .get(
        `/api/v2/clusters/account/${accountSlug}/id/${clusterId}/infra-events-log`,
        {
          headers: getStandardHeaders(),
          // Use AbortController.signal to cancel ongoing request if the user changes the filter
          signal,
        },
      )
      .json();
    const data = camelizeKeys(buildPayload);
    return data.map((item: LogEntry, id: number) => ({
      ...item,
      id,
    })) as LogEntry[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get cluster infra events for cluster ${clusterId} in the workspace ${accountSlug} - Reason: ${err}`,
    );

    await handleError(err as Error);
    throw err;
  }
};
