import React from "react";
import { GridColDef, DataGridPro } from "@mui/x-data-grid-pro";
import { InstalledPackage } from "../../api-client";
const BuildColumns: GridColDef<InstalledPackage>[] = [
  {
    field: "name",
    headerName: "Package Name",
    flex: 1,
    valueGetter: (params) => {
      return params.row.condaName || params.row.name || "";
    },
  },
  { field: "version", headerName: "Version", flex: 1 },
  { field: "source", headerName: "Source", flex: 1 },
  {
    field: "channel",
    headerName: "Channel",
    flex: 1,
    valueGetter: (params) => {
      return params.row.channel || "";
    },
  },
];

type InstalledSoftwareTableProps = {
  packages: InstalledPackage[];
};

export const InstalledSoftwareTable = ({
  packages,
}: InstalledSoftwareTableProps): React.ReactElement => {
  return (
    <DataGridPro
      sx={{ border: "unset" }}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      columns={BuildColumns}
      rows={packages}
    />
  );
};
