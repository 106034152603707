import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

type Props = { className?: string };

export const LogoutIcon = ({ className = "" }: Props): React.ReactElement => {
  return (
    <SvgIcon
      className={className}
      viewBox="-4 -4 28 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-/-logout-/-dark"
          stroke="currentColor"
          transform="translate(0.000000, 0.000000)"
        >
          <rect id="Rectangle-Copy" x="0.5" y="0.5" width="12" height="12" />
          <polyline
            id="Rectangle-Copy-2"
            transform="translate(17.378680, 6.500000) rotate(-45.000000) translate(-17.378680, -6.500000) "
            points="20.8786797 3 20.8786797 10 13.8786797 10"
          />
          <line
            x1="6.5"
            y1="6.5"
            x2="21.5"
            y2="6.5"
            id="Line-7"
            strokeLinecap="square"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
