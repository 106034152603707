/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalData } from './AdditionalData';
import {
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';

/**
 * 
 * @export
 * @interface UserFlagInteractionSchema
 */
export interface UserFlagInteractionSchema {
    /**
     * 
     * @type {string}
     * @memberof UserFlagInteractionSchema
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserFlagInteractionSchema
     */
    completed: boolean;
    /**
     * 
     * @type {{ [key: string]: AdditionalData; }}
     * @memberof UserFlagInteractionSchema
     */
    additionalData?: { [key: string]: AdditionalData; };
}

/**
 * Check if a given object implements the UserFlagInteractionSchema interface.
 */
export function instanceOfUserFlagInteractionSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "completed" in value;

    return isInstance;
}

export function UserFlagInteractionSchemaFromJSON(json: any): UserFlagInteractionSchema {
    return UserFlagInteractionSchemaFromJSONTyped(json, false);
}

export function UserFlagInteractionSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFlagInteractionSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'completed': json['completed'],
        'additionalData': !exists(json, 'additional_data') ? undefined : (mapValues(json['additional_data'], AdditionalDataFromJSON)),
    };
}

export function UserFlagInteractionSchemaToJSON(value?: UserFlagInteractionSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'completed': value.completed,
        'additional_data': value.additionalData === undefined ? undefined : (mapValues(value.additionalData, AdditionalDataToJSON)),
    };
}

