/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkerErrorCountSchema } from './WorkerErrorCountSchema';
import {
    WorkerErrorCountSchemaFromJSON,
    WorkerErrorCountSchemaFromJSONTyped,
    WorkerErrorCountSchemaToJSON,
} from './WorkerErrorCountSchema';

/**
 * 
 * @export
 * @interface WorkerByTypeSchema
 */
export interface WorkerByTypeSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkerByTypeSchema
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof WorkerByTypeSchema
     */
    spot: number;
    /**
     * 
     * @type {string}
     * @memberof WorkerByTypeSchema
     */
    stateName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkerByTypeSchema
     */
    vmType?: string;
    /**
     * 
     * @type {WorkerErrorCountSchema}
     * @memberof WorkerByTypeSchema
     */
    errorCount?: WorkerErrorCountSchema;
}

/**
 * Check if a given object implements the WorkerByTypeSchema interface.
 */
export function instanceOfWorkerByTypeSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "spot" in value;
    isInstance = isInstance && "stateName" in value;

    return isInstance;
}

export function WorkerByTypeSchemaFromJSON(json: any): WorkerByTypeSchema {
    return WorkerByTypeSchemaFromJSONTyped(json, false);
}

export function WorkerByTypeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerByTypeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'spot': json['spot'],
        'stateName': json['state_name'],
        'vmType': !exists(json, 'vm_type') ? undefined : json['vm_type'],
        'errorCount': !exists(json, 'error_count') ? undefined : WorkerErrorCountSchemaFromJSON(json['error_count']),
    };
}

export function WorkerByTypeSchemaToJSON(value?: WorkerByTypeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'spot': value.spot,
        'state_name': value.stateName,
        'vm_type': value.vmType,
        'error_count': WorkerErrorCountSchemaToJSON(value.errorCount),
    };
}

