import React from "react";
import { ChartProps, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { Tooltip, YAxis } from "recharts";
import { YAXIS_WIDTH } from "./const";
import { GroupedTooltip } from "./GroupedTooltip";
import { useTimezone } from "../../../../utils/hooks";
import { formatInTimeZone } from "date-fns-tz";

export const GilChart = (props: ChartProps): React.ReactElement => {
  const [displayTz] = useTimezone();
  const variations: Variation[] = [
    {
      name: "GIL Contention",
      groupKey: "type",
      queries: [
        {
          name: "worker_gil_contention_rate | max | *100",
          typeLabel: "max",
        },
        {
          name: "worker_gil_contention_rate | min | *100",
          typeLabel: "min",
        },
        {
          name: "worker_gil_contention_rate | pct50 | *100",
          typeLabel: "median",
        },
        {
          name: "scheduler_gil_contention_rate | *100",
          typeLabel: "scheduler",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        {
          name: "median",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
        {
          name: "scheduler",
          color: { stroke: "black", fill: "", opacity: 0 },
          stack: 1,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GIL Contention"
        variations={variations}
        {...props}
        yAxis={
          <YAxis
            domain={[0, 100]}
            unit="%"
            width={YAXIS_WIDTH}
            allowDataOverflow
          />
        }
        tooltip={
          <Tooltip
            content={
              <GroupedTooltip
                formatter={(value, name: string) => {
                  return [
                    parseFloat(value as string).toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                    }),
                    name,
                  ];
                }}
                labelFormatter={(v) =>
                  formatInTimeZone(new Date(v), displayTz, "LLL do, HH:mm:ss O")
                }
              />
            }
            wrapperStyle={{
              outline: "none",
              zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
          />
        }
      />
    </>
  );
};

export const MiscMetricChart = (props: ChartProps): React.ReactElement => {
  const [displayTz] = useTimezone();
  const variations: Variation[] = [
    {
      name: "Adaptive",
      groupKey: "type",
      queries: [
        {
          name: "desired_workers",
          typeLabel: "desired",
        },
        {
          name: "worker_states | sum",
          typeLabel: "actual",
        },
      ],
      domains: [
        {
          name: "desired",
          color: { stroke: "#2159FF", fill: "#2159FF", opacity: 0.1 },
          stack: 0,
        },
        {
          name: "actual",
          color: { stroke: "black", fill: "", opacity: 0 },
          stack: 1,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Adaptive (staff)"
        variations={variations}
        {...props}
        yAxis={<YAxis width={YAXIS_WIDTH} />}
        tooltip={
          <Tooltip
            content={
              <GroupedTooltip
                formatter={(value, name: string) => {
                  return [
                    parseFloat(value as string).toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                    }),
                    name,
                  ];
                }}
                labelFormatter={(v) =>
                  formatInTimeZone(new Date(v), displayTz, "LLL do, HH:mm:ss O")
                }
              />
            }
            wrapperStyle={{
              outline: "none",
              zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
          />
        }
      />
    </>
  );
};
