import React, { createContext, ReactElement, ReactNode } from "react";
import { Banner } from "./types";

export const BannersContext = createContext<Banner[]>([]);

type BannersContextProviderProps = {
  banners: Banner[];
  children?: ReactNode;
};

export const BannersContextProvider = ({
  banners,
  children,
}: BannersContextProviderProps): ReactElement => {
  return (
    <BannersContext.Provider value={banners}>
      {children}
    </BannersContext.Provider>
  );
};
