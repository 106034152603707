/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClusterSSHSchema
 */
export interface ClusterSSHSchema {
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    privateKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    schedulerAddressToUse?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    schedulerPublicAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    schedulerPrivateAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    schedulerHostname?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    schedulerState: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSSHSchema
     */
    workerAddress?: string;
}

/**
 * Check if a given object implements the ClusterSSHSchema interface.
 */
export function instanceOfClusterSSHSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "schedulerState" in value;

    return isInstance;
}

export function ClusterSSHSchemaFromJSON(json: any): ClusterSSHSchema {
    return ClusterSSHSchemaFromJSONTyped(json, false);
}

export function ClusterSSHSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterSSHSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'privateKey': !exists(json, 'private_key') ? undefined : json['private_key'],
        'schedulerAddressToUse': !exists(json, 'scheduler_address_to_use') ? undefined : json['scheduler_address_to_use'],
        'schedulerPublicAddress': !exists(json, 'scheduler_public_address') ? undefined : json['scheduler_public_address'],
        'schedulerPrivateAddress': !exists(json, 'scheduler_private_address') ? undefined : json['scheduler_private_address'],
        'schedulerHostname': !exists(json, 'scheduler_hostname') ? undefined : json['scheduler_hostname'],
        'schedulerState': json['scheduler_state'],
        'workerAddress': !exists(json, 'worker_address') ? undefined : json['worker_address'],
    };
}

export function ClusterSSHSchemaToJSON(value?: ClusterSSHSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'private_key': value.privateKey,
        'scheduler_address_to_use': value.schedulerAddressToUse,
        'scheduler_public_address': value.schedulerPublicAddress,
        'scheduler_private_address': value.schedulerPrivateAddress,
        'scheduler_hostname': value.schedulerHostname,
        'scheduler_state': value.schedulerState,
        'worker_address': value.workerAddress,
    };
}

