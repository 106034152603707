/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompoundScopes } from './CompoundScopes';
import {
    CompoundScopesFromJSON,
    CompoundScopesFromJSONTyped,
    CompoundScopesToJSON,
} from './CompoundScopes';

/**
 * 
 * @export
 * @interface CompoundScopeSchema
 */
export interface CompoundScopeSchema {
    /**
     * 
     * @type {string}
     * @memberof CompoundScopeSchema
     */
    type?: CompoundScopeSchemaTypeEnum;
    /**
     * 
     * @type {CompoundScopes}
     * @memberof CompoundScopeSchema
     */
    name: CompoundScopes;
}


/**
 * @export
 */
export const CompoundScopeSchemaTypeEnum = {
    Compound: 'compound'
} as const;
export type CompoundScopeSchemaTypeEnum = typeof CompoundScopeSchemaTypeEnum[keyof typeof CompoundScopeSchemaTypeEnum];


/**
 * Check if a given object implements the CompoundScopeSchema interface.
 */
export function instanceOfCompoundScopeSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CompoundScopeSchemaFromJSON(json: any): CompoundScopeSchema {
    return CompoundScopeSchemaFromJSONTyped(json, false);
}

export function CompoundScopeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompoundScopeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': CompoundScopesFromJSON(json['name']),
    };
}

export function CompoundScopeSchemaToJSON(value?: CompoundScopeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': CompoundScopesToJSON(value.name),
    };
}

