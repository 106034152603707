/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackendTypesEnum } from './BackendTypesEnum';
import {
    BackendTypesEnumFromJSON,
    BackendTypesEnumFromJSONTyped,
    BackendTypesEnumToJSON,
} from './BackendTypesEnum';
import type { WorkspaceOrganizationSchema } from './WorkspaceOrganizationSchema';
import {
    WorkspaceOrganizationSchemaFromJSON,
    WorkspaceOrganizationSchemaFromJSONTyped,
    WorkspaceOrganizationSchemaToJSON,
} from './WorkspaceOrganizationSchema';

/**
 * 
 * @export
 * @interface WorkspaceSchema
 */
export interface WorkspaceSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSchema
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSchema
     */
    avatarUrl: string;
    /**
     * 
     * @type {WorkspaceOrganizationSchema}
     * @memberof WorkspaceSchema
     */
    organization?: WorkspaceOrganizationSchema;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSchema
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceSchema
     */
    earlyAdopter: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceSchema
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceSchema
     */
    configuredCustomerHosted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceSchema
     */
    canUseCoiledHosted: boolean;
    /**
     * 
     * @type {BackendTypesEnum}
     * @memberof WorkspaceSchema
     */
    activeBackend?: BackendTypesEnum;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceSchema
     */
    setupComplete: boolean;
}

/**
 * Check if a given object implements the WorkspaceSchema interface.
 */
export function instanceOfWorkspaceSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "avatarUrl" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "earlyAdopter" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "configuredCustomerHosted" in value;
    isInstance = isInstance && "canUseCoiledHosted" in value;
    isInstance = isInstance && "setupComplete" in value;

    return isInstance;
}

export function WorkspaceSchemaFromJSON(json: any): WorkspaceSchema {
    return WorkspaceSchemaFromJSONTyped(json, false);
}

export function WorkspaceSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'avatarUrl': json['avatar_url'],
        'organization': !exists(json, 'organization') ? undefined : WorkspaceOrganizationSchemaFromJSON(json['organization']),
        'name': json['name'],
        'creditLimit': json['credit_limit'],
        'earlyAdopter': json['early_adopter'],
        'active': json['active'],
        'configuredCustomerHosted': json['configured_customer_hosted'],
        'canUseCoiledHosted': json['can_use_coiled_hosted'],
        'activeBackend': !exists(json, 'active_backend') ? undefined : BackendTypesEnumFromJSON(json['active_backend']),
        'setupComplete': json['setup_complete'],
    };
}

export function WorkspaceSchemaToJSON(value?: WorkspaceSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'avatar_url': value.avatarUrl,
        'organization': WorkspaceOrganizationSchemaToJSON(value.organization),
        'name': value.name,
        'credit_limit': value.creditLimit,
        'early_adopter': value.earlyAdopter,
        'active': value.active,
        'configured_customer_hosted': value.configuredCustomerHosted,
        'can_use_coiled_hosted': value.canUseCoiledHosted,
        'active_backend': BackendTypesEnumToJSON(value.activeBackend),
        'setup_complete': value.setupComplete,
    };
}

