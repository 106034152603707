/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClusterLogInfoSchema
 */
export interface ClusterLogInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof ClusterLogInfoSchema
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterLogInfoSchema
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterLogInfoSchema
     */
    logGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterLogInfoSchema
     */
    schedulerStream?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClusterLogInfoSchema
     */
    workerStreams?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ClusterLogInfoSchema interface.
 */
export function instanceOfClusterLogInfoSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClusterLogInfoSchemaFromJSON(json: any): ClusterLogInfoSchema {
    return ClusterLogInfoSchemaFromJSONTyped(json, false);
}

export function ClusterLogInfoSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterLogInfoSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'logGroup': !exists(json, 'log_group') ? undefined : json['log_group'],
        'schedulerStream': !exists(json, 'scheduler_stream') ? undefined : json['scheduler_stream'],
        'workerStreams': !exists(json, 'worker_streams') ? undefined : json['worker_streams'],
    };
}

export function ClusterLogInfoSchemaToJSON(value?: ClusterLogInfoSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'region': value.region,
        'log_group': value.logGroup,
        'scheduler_stream': value.schedulerStream,
        'worker_streams': value.workerStreams,
    };
}

