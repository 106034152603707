/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AWSSubnetCreateSchema
 */
export interface AWSSubnetCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof AWSSubnetCreateSchema
     */
    availabilityZone?: string;
    /**
     * 
     * @type {string}
     * @memberof AWSSubnetCreateSchema
     */
    subnetId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AWSSubnetCreateSchema
     */
    forWorkers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AWSSubnetCreateSchema
     */
    forSchedulers: boolean;
}

/**
 * Check if a given object implements the AWSSubnetCreateSchema interface.
 */
export function instanceOfAWSSubnetCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "forWorkers" in value;
    isInstance = isInstance && "forSchedulers" in value;

    return isInstance;
}

export function AWSSubnetCreateSchemaFromJSON(json: any): AWSSubnetCreateSchema {
    return AWSSubnetCreateSchemaFromJSONTyped(json, false);
}

export function AWSSubnetCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSSubnetCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availabilityZone': !exists(json, 'availability_zone') ? undefined : json['availability_zone'],
        'subnetId': !exists(json, 'subnet_id') ? undefined : json['subnet_id'],
        'forWorkers': json['for_workers'],
        'forSchedulers': json['for_schedulers'],
    };
}

export function AWSSubnetCreateSchemaToJSON(value?: AWSSubnetCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availability_zone': value.availabilityZone,
        'subnet_id': value.subnetId,
        'for_workers': value.forWorkers,
        'for_schedulers': value.forSchedulers,
    };
}

