/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const SoftwareEnvironmentBuildStateEnum = {
    Queued: 'queued',
    Starting: 'starting',
    Building: 'building',
    Built: 'built',
    Error: 'error'
} as const;
export type SoftwareEnvironmentBuildStateEnum = typeof SoftwareEnvironmentBuildStateEnum[keyof typeof SoftwareEnvironmentBuildStateEnum];


export function SoftwareEnvironmentBuildStateEnumFromJSON(json: any): SoftwareEnvironmentBuildStateEnum {
    return SoftwareEnvironmentBuildStateEnumFromJSONTyped(json, false);
}

export function SoftwareEnvironmentBuildStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentBuildStateEnum {
    return json as SoftwareEnvironmentBuildStateEnum;
}

export function SoftwareEnvironmentBuildStateEnumToJSON(value?: SoftwareEnvironmentBuildStateEnum | null): any {
    return value as any;
}

