/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VPCCreateSchema
 */
export interface VPCCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof VPCCreateSchema
     */
    vpcId: string;
}

/**
 * Check if a given object implements the VPCCreateSchema interface.
 */
export function instanceOfVPCCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vpcId" in value;

    return isInstance;
}

export function VPCCreateSchemaFromJSON(json: any): VPCCreateSchema {
    return VPCCreateSchemaFromJSONTyped(json, false);
}

export function VPCCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VPCCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vpcId': json['vpc_id'],
    };
}

export function VPCCreateSchemaToJSON(value?: VPCCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vpc_id': value.vpcId,
    };
}

