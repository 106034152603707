import React, { useMemo } from "react";
import {
  Avatar,
  AvatarGroup,
  Divider,
  Link,
  Stack,
  Tooltip,
} from "@mui/material/";
import { useIsAnyKindOfAdmin } from "../../crud/account/hooks";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid-pro";
import { Delete, Edit, Mail } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  useOrganization,
  useOrganizationMemberships,
  useRemoveOrganizationMemberMutation,
} from "../../crud/organizations/hooks";
import {
  OrganizationInviteSchema,
  OrganizationMembershipSchema,
  OrganizationRoles,
  ResponseError,
} from "../../api-client";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../domain/urls";
import {
  useDeleteOrganizationeInviteMutation,
  useOrganizationInvites,
  useResendOrganizationInviteMutation,
} from "../../crud/invites/hooks";
import { useWorkspaces } from "../../crud/workspaces/hooks";
import { getAvatarCell } from "./util";

type RowBase = {
  index: number;
  type: "members" | "invites";
  fieldName: `members.${number}` | `invites.${number}`;
};

type MemberRow = RowBase &
  OrganizationMembershipSchema & {
    type: "members";
  };
type InviteRow = RowBase &
  OrganizationInviteSchema & {
    type: "invites";
  };

const CustomFooter = (
  props: NonNullable<GridSlotsComponentsProps["footer"]>,
): React.ReactElement => {
  // TODO: Add this back somewhere on team page
  return (
    <div>
      <Divider />
      <Stack direction="row" sx={{ p: (theme) => theme.spacing(2) }}>
        <Link href="https://docs.coiled.io/user_guide/users/index.html#permissions">
          See here for more details about teams and team member permissions.
        </Link>
      </Stack>
    </div>
  );
};

export const OrganizationTeamGrid = ({
  organizationId,
}: {
  organizationId: number;
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: organization } = useOrganization(organizationId);
  const { data: workspaces } = useWorkspaces({ organizationId });
  const { data: organizationMembers, isLoading: membersLoading } =
    useOrganizationMemberships(organizationId);
  const { data: orgInvites, isLoading: invitesLoading } =
    useOrganizationInvites(organizationId);
  const { mutate: resendInvite } = useResendOrganizationInviteMutation();
  const { mutate: deleteInvite } = useDeleteOrganizationeInviteMutation();
  const { mutate: deleteMembership } = useRemoveOrganizationMemberMutation();
  const isAdmin = useIsAnyKindOfAdmin(
    organizationId,
    workspaces?.map((workspace) => workspace.slug) || [],
  );

  const fieldArray = [
    ...((orgInvites?.map((i, index) => ({
      ...i,
      type: "invites",
      index,
      fieldName: `invites.${index}`,
    })) as InviteRow[]) || []),
    ...((organizationMembers?.items?.map((m, index) => ({
      ...m,
      type: "members",
      index,
      fieldName: `members.${index}`,
    })) as MemberRow[]) || []),
  ];
  const columns = useMemo<GridColDef<MemberRow | InviteRow>[]>(() => {
    return [
      {
        field: "user.username",
        valueGetter: (params) =>
          params.row.type === "members" ? params.row.user.username : "",
        renderCell: (params) => {
          if (params.row.type === "members") {
            return getAvatarCell({
              type: "members",
              orgAdmin: params.row.role === OrganizationRoles.Admin,
              user: {
                profile: {
                  avatarUrl: params.row.user.profile.avatarUrl,
                },
                username: params.row.user.username,
                fullName: params.row.user.fullName,
                preferredName: params.row.user.preferredName,
              },
            });
          }
          return getAvatarCell({
            type: "invites",
            orgAdmin: false,
            email: params.row.invite.email,
          });
        },
        headerName: "Username / Email",
        flex: 1,
      },
      {
        field: "role",
        headerName: "Admin",
        width: 100,
        type: "boolean",
        valueGetter: (params) => params.row.role === OrganizationRoles.Admin,
      },
      {
        field: "workspaces",
        headerName: "Workspaces",
        flex: 1,
        renderCell: (params) => {
          if (params.row.type === "invites") {
            return (
              <AvatarGroup max={4}>
                {params.row.workspaceInvites?.map((workspaceInvite) => {
                  const workspace = workspaces?.find(
                    (orgWorkspace) =>
                      orgWorkspace.id === workspaceInvite.workspaceId,
                  );
                  if (!workspace) {
                    return null;
                  }
                  return (
                    <Tooltip key={workspace.id} title={workspace.name}>
                      <Avatar
                        key={workspaceInvite.workspaceId}
                        src={workspace.avatarUrl}
                        alt={workspace.name}
                      >
                        {workspace.name[0].toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </AvatarGroup>
            );
          }
          return (
            <AvatarGroup max={4}>
              {params.row.workspaceMemberships?.map((workspaceMembership) => {
                const workspace = workspaces?.find(
                  (orgWorkspace) =>
                    orgWorkspace.id === workspaceMembership.workspaceId,
                );
                if (!workspace) {
                  return null;
                }
                return (
                  <Tooltip key={workspace.id} title={workspace.name}>
                    <Avatar
                      key={workspaceMembership.workspaceId}
                      src={workspace.avatarUrl}
                      alt={workspace.name}
                    >
                      {workspace.name[0].toUpperCase()}
                    </Avatar>
                  </Tooltip>
                );
              })}
            </AvatarGroup>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 80,
        getActions: (params) => {
          let editUrl = "";
          if (params.row.type === "members") {
            editUrl = `/${Urls.Team}/${organization?.id}/edit-member/${params.row?.id}`;
          }
          if (params.row.type === "invites") {
            editUrl = `/${Urls.Team}/${organization?.id}/edit-invite/${params.row.id}`;
          }
          return [
            <Tooltip key="edit" title="Edit">
              <GridActionsCellItem
                key="edit"
                icon={<Edit />}
                label="Edit"
                component={RouterLink}
                // @ts-expect-error
                to={editUrl}
                color="inherit"
              />
            </Tooltip>,
            <GridActionsCellItem
              key="remove"
              icon={<Delete />}
              label="Remove"
              onClick={() => {
                if (params.row.type === "members") {
                  deleteMembership(
                    {
                      membershipId: params.row.id,
                    },
                    {
                      onError: async (err) => {
                        if (err instanceof ResponseError) {
                          const { message } = await err.response.json();
                          enqueueSnackbar({
                            message,
                            variant: "error",
                          });
                        }
                      },
                    },
                  );
                } else {
                  deleteInvite(params.row.id);
                }
              }}
              color="inherit"
              showInMenu
            />,
            ...(params.row.type === "invites"
              ? [
                  <GridActionsCellItem
                    key="resend"
                    icon={<Mail />}
                    label="Resend Invite"
                    color="inherit"
                    showInMenu
                    onClick={() => {
                      resendInvite(params.row.id, {
                        onSuccess: () => {
                          enqueueSnackbar({
                            message: `Invite email resent`,
                            variant: "success",
                          });
                        },
                      });
                    }}
                  />,
                ]
              : []),
          ];
        },
      },
    ];
  }, [
    deleteInvite,
    deleteMembership,
    enqueueSnackbar,
    organization?.id,
    workspaces,
    resendInvite,
  ]);
  return (
    <DataGridPro
      autoHeight
      sx={{
        borderColor: (theme) => theme.palette.custom.grey.warm,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      columns={columns}
      slots={{ footer: CustomFooter }}
      loading={membersLoading || invitesLoading}
      hideFooterRowCount
      disableRowSelectionOnClick
      cellModesModel={{}}
      columnVisibilityModel={{
        actions: isAdmin,
      }}
      rows={fieldArray}
    />
  );
};
