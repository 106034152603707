/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceSpecLiteSchema
 */
export interface InstanceSpecLiteSchema {
    /**
     * 
     * @type {number}
     * @memberof InstanceSpecLiteSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSpecLiteSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSpecLiteSchema
     */
    updated: Date;
    /**
     * 
     * @type {number}
     * @memberof InstanceSpecLiteSchema
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecLiteSchema
     */
    backendType: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecLiteSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecLiteSchema
     */
    privateIpAddress?: string;
}

/**
 * Check if a given object implements the InstanceSpecLiteSchema interface.
 */
export function instanceOfInstanceSpecLiteSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "backendType" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function InstanceSpecLiteSchemaFromJSON(json: any): InstanceSpecLiteSchema {
    return InstanceSpecLiteSchemaFromJSONTyped(json, false);
}

export function InstanceSpecLiteSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSpecLiteSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'accountId': json['account_id'],
        'backendType': json['backend_type'],
        'name': json['name'],
        'privateIpAddress': !exists(json, 'private_ip_address') ? undefined : json['private_ip_address'],
    };
}

export function InstanceSpecLiteSchemaToJSON(value?: InstanceSpecLiteSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'account_id': value.accountId,
        'backend_type': value.backendType,
        'name': value.name,
        'private_ip_address': value.privateIpAddress,
    };
}

