import React, { useMemo } from "react";
import {
  useAccountDetails,
  useDeleteInviteMutation,
  useIsAnyKindOfAdmin,
  useRemoveMembershipMutation,
  useWorkspaceInvites,
  useWorkspaceMembers,
} from "../../crud/account/hooks";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { HTTPError } from "ky";
import { useOrganization } from "../../crud/organizations/hooks";
import {
  OrganizationMembershipSchema,
  WorkspaceInviteSchema,
  WorkspaceMemberSchema,
} from "../../api-client";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { getAvatarCell } from "./util";

type RowBase = {
  index: number;
  type: "members" | "invites";
  fieldName: `members.${number}` | `invites.${number}`;
};

type MemberRow = RowBase &
  WorkspaceMemberSchema & {
    type: "members";
  };
type InviteRow = RowBase &
  WorkspaceInviteSchema & {
    type: "invites";
  };

const isOrgAdmin = (
  member: WorkspaceMemberSchema,
  orgMembers: OrganizationMembershipSchema[],
): boolean => {
  return orgMembers.some(
    (m) => m.user.id === member.user.id && m.role === "admin",
  );
};

export const WorkspaceTeamGrid = ({
  workspaceId,
}: {
  workspaceId: number;
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: workspace } = useAccountDetails({ workspaceId });
  const { data: organization } = useOrganization(workspace?.organization?.id);
  const { data: membersData, isLoading: membersLoading } = useWorkspaceMembers(
    workspace?.slug,
  );
  const { data: invitesData, isLoading: invitesLoading } =
    useWorkspaceInvites(workspaceId);
  const { mutate: deleteInvite } = useDeleteInviteMutation();
  const { mutate: deleteMembership } = useRemoveMembershipMutation();
  const isAdmin = useIsAnyKindOfAdmin(
    workspace?.organization?.id,
    workspace ? [workspace.slug] : [],
  );

  const fieldArray = [
    ...((invitesData?.map((i, index) => ({
      ...i,
      type: "invites",
      index,
      fieldName: `invites.${index}`,
    })) as InviteRow[]) || []),
    ...((membersData?.items?.map((m, index) => ({
      ...m,
      type: "members",
      index,
      fieldName: `members.${index}`,
    })) as MemberRow[]) || []),
  ];
  const columns = useMemo<GridColDef<MemberRow | InviteRow>[]>(() => {
    return [
      {
        field: "user.username",
        valueGetter: (params) =>
          params.row.type === "members" ? params.row.user.username : "",
        renderCell: (params) => {
          if (params.row.type === "members") {
            return getAvatarCell({
              type: "members",
              user: {
                profile: {
                  avatarUrl: params.row.user.avatarUrl,
                },
                username: params.row.user.username,
                fullName: params.row.user.fullName,
                preferredName: params.row.user.preferredName,
              },
              orgAdmin: membersData
                ? isOrgAdmin(params.row, organization?.memberSet || [])
                : false,
            });
          } else {
            return getAvatarCell({
              type: "invites",
              email: params.row.email,
              orgAdmin: false,
            });
          }
        },
        headerName: "Username / Email",
        flex: 1,
      },
      {
        field: "coreLimit",
        headerName: "Core Limit",
        flex: 1,
        type: "number",
      },
      {
        field: "creditLimit",
        headerName: "Credit Limit",
        flex: 1,
        type: "number",
      },
      {
        field: "isAdmin",
        headerName: "Admin",
        type: "boolean",
        editable: true,
        width: 100,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 80,
        getActions: (params) => {
          let editUrl = "";
          if (params.row.type === "members") {
            const organizationMembership = organization?.memberSet.find(
              (m) =>
                params.row.type === "members" &&
                m.user.id === params.row.user.id,
            );
            editUrl = `/${Urls.Team}/${organization?.id}/edit-member/${organizationMembership?.id}`;
          }
          if (params.row.type === "invites") {
            editUrl = `/${Urls.Team}/${organization?.id}/edit-invite/${params.row.organizationInviteId}`;
          }
          return [
            <GridActionsCellItem
              key="edit"
              icon={<Edit />}
              label="Edit"
              component={RouterLink}
              // @ts-expect-error
              to={editUrl}
              color="inherit"
            />,
            <GridActionsCellItem
              key="remove"
              icon={<Delete />}
              label="Remove"
              onClick={() => {
                if (params.row.type === "members") {
                  deleteMembership(
                    {
                      membershipId: params.row.id,
                    },
                    {
                      onError: async (err) => {
                        if (err instanceof HTTPError) {
                          const errorJson = await err.response.json();
                          if (errorJson && errorJson.detail) {
                            enqueueSnackbar({
                              message: errorJson.detail,
                              variant: "error",
                            });
                          }
                        }
                      },
                    },
                  );
                } else {
                  deleteInvite({
                    inviteId: params.row.id,
                  });
                }
              }}
              color="inherit"
              showInMenu
            />,
          ];
        },
      },
    ];
  }, [
    deleteInvite,
    deleteMembership,
    enqueueSnackbar,
    organization?.memberSet,
    organization?.id,
    membersData,
  ]);
  return (
    <DataGridPro
      autoHeight
      sx={{
        border: "unset",
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
      columns={columns}
      hideFooter
      loading={membersLoading || invitesLoading}
      hideFooterRowCount
      disableRowSelectionOnClick
      cellModesModel={{}}
      columnVisibilityModel={{
        actions: isAdmin,
      }}
      rows={fieldArray}
    />
  );
};
