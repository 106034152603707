// StepperContext.tsx
import React, { createContext, useReducer, ReactNode } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

interface StepperState {
  completedSteps: number[];
  expandedSteps: number[];
}

interface StepperContextProps {
  state: StepperState;
  dispatch: React.Dispatch<Action>;
}

export interface StepProps {
  index: number;
  workspaceSlug: string;
}
export const StepperContext = createContext<StepperContextProps>({
  state: {
    completedSteps: [],
    expandedSteps: [],
  },
  dispatch: () => {},
});

const defaultInitialState: StepperState = {
  completedSteps: [],
  expandedSteps: [],
};

type Action =
  | { type: "STEP_COMPLETED"; payload: number }
  | { type: "REVOKE_STEP_COMPLETION"; payload: number }
  | { type: "EXPAND_STEP"; payload: number }
  | { type: "COLLAPSE_STEP"; payload: number }
  | { type: "NEXT_STEP"; payload: number }
  | { type: "PREVIOUS_STEP"; payload: number }
  | { type: "TOGGLE_STEP_EXPANSION"; payload: number };

const stepperReducer = (state: StepperState, action: Action) => {
  switch (action.type) {
    case "STEP_COMPLETED":
      if (state.completedSteps.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload],
      };
    case "REVOKE_STEP_COMPLETION":
      return {
        ...state,
        completedSteps: state.completedSteps.filter(
          (step) => step !== action.payload,
        ),
      };
    case "EXPAND_STEP":
      if (state.expandedSteps.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        expandedSteps: [...state.expandedSteps, action.payload],
      };
    case "COLLAPSE_STEP":
      return {
        ...state,
        expandedSteps: state.expandedSteps.filter(
          (step) => step !== action.payload,
        ),
      };
    case "NEXT_STEP":
      return {
        ...state,
        expandedSteps: [
          ...state.expandedSteps.filter((step) => step !== action.payload),
        ],
      };
    case "PREVIOUS_STEP":
      return {
        ...state,
        expandedSteps: [
          ...state.expandedSteps.filter((step) => step !== action.payload),
          action.payload - 1,
        ],
      };
    case "TOGGLE_STEP_EXPANSION":
      if (state.expandedSteps.includes(action.payload)) {
        return {
          ...state,
          expandedSteps: state.expandedSteps.filter(
            (step) => step !== action.payload,
          ),
        };
      }
      return {
        ...state,
        expandedSteps: [...state.expandedSteps, action.payload],
      };
    default:
      throw new Error();
  }
};

interface StepperProviderProps {
  children: ReactNode;
  initialState?: StepperState;
}

export const StepperProvider = ({
  children,
  initialState,
}: StepperProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(
    stepperReducer,
    initialState || defaultInitialState,
  );

  return (
    <StepperContext.Provider value={{ state, dispatch }}>
      {children}
    </StepperContext.Provider>
  );
};
