import React from "react";
import { Box, Paper, Stack, alpha } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel, TabPanelProps } from "@mui/lab";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

type VerticalTabPanelProps = TabPanelProps & {
  withPaper?: boolean;
  disabled?: boolean;
  label?: string;
};

export const VerticalTabPanel = ({
  sx,
  withPaper = true,
  label,
  ...props
}: VerticalTabPanelProps): React.ReactElement => {
  return (
    <TabPanel
      {...props}
      sx={{
        padding: 0,
        ...sx,
      }}
    >
      {withPaper ? (
        <Paper
          sx={{
            height: "100%",
            border: 1,
            borderColor: (theme) => theme.palette.custom.grey.warm,
          }}
        >
          {props.children}
        </Paper>
      ) : (
        props.children
      )}
    </TabPanel>
  );
};

type NavTabsProps = {
  defaultTab?: string;
  tabExtra?: React.ReactNode;
  children: React.ReactElement<VerticalTabPanelProps>[];
  contentWidth?: string;
  tabsWidth?: string;
};

export const VerticalTabs = ({
  defaultTab,
  children,
  tabExtra,
  contentWidth,
  tabsWidth,
}: NavTabsProps): React.ReactElement => {
  const defaultTabChildValue = defaultTab
    ? children?.find((c) => c.props.value === defaultTab)?.props.value
    : undefined;
  if (!!defaultTab && !defaultTabChildValue) {
    throw new Error("Default tab not found");
  }
  const [currentTab, setTab] = useQueryParam(
    "tab",
    withDefault(
      StringParam,
      defaultTabChildValue || children?.[0]?.props.value,
    ),
  );
  return (
    <TabContext value={currentTab}>
      <Stack direction="row" spacing={1} sx={{ width: contentWidth || "100%" }}>
        <TabList
          orientation="vertical"
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          sx={{
            width: tabsWidth || undefined,
            borderRadius: 1,
            border: 1,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            flexShrink: 0,
            height: "100%",
            borderColor: (theme) => theme.palette.custom.grey.warm,
            "& .MuiTab-root": {
              "&.Mui-selected": {
                fontWeight: "bold",
                backgroundColor: (theme) =>
                  alpha(
                    theme.palette.secondary.main,
                    theme.palette.action.selectedOpacity,
                  ),
              },
            },
          }}
        >
          {children.map((t, i) => (
            <Tab
              key={i}
              value={t.props.value}
              label={t.props.label || t.props.value}
              disabled={t.props.disabled}
            />
          ))}
          {tabExtra && tabExtra}
        </TabList>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            borderColor: (theme) => theme.palette.custom.grey.warm,
          }}
        >
          {children}
        </Box>
      </Stack>
    </TabContext>
  );
};
