import React, { ReactElement } from "react";
import { ZoomableAreaChart, ZoomableAreaChartProps } from "./ZoomableAreaChart";
import { UseQueryResult } from "react-query";
import { GroupedMetric } from "../../../../crud/metrics/types";

type ZoomableAreaChartWithQueryProps = Omit<
  ZoomableAreaChartProps,
  "data" | "isLoading" | "isError"
> & {
  metricsQuery?: UseQueryResult<GroupedMetric[]>;
};

/**
 * A zoomable area chart that uses a react-query query result as its data source.
 */
export const ZoomableAreaChartWithQuery = (
  props: ZoomableAreaChartWithQueryProps,
): ReactElement => {
  return (
    <ZoomableAreaChart
      {...props}
      data={props.metricsQuery?.data}
      isLoading={!!props.metricsQuery?.isLoading}
      isError={!!props.metricsQuery?.isError}
    />
  );
};
