/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const FeatureTier = {
    Essentials: 'essentials',
    Professional: 'professional'
} as const;
export type FeatureTier = typeof FeatureTier[keyof typeof FeatureTier];


export function FeatureTierFromJSON(json: any): FeatureTier {
    return FeatureTierFromJSONTyped(json, false);
}

export function FeatureTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureTier {
    return json as FeatureTier;
}

export function FeatureTierToJSON(value?: FeatureTier | null): any {
    return value as any;
}

