/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';

/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {number}
     * @memberof Dataset
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Dataset
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Dataset
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof Dataset
     */
    component: ComponentSchema;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    datasetRef: string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof Dataset
     */
    defaultTableExpirationMs: number;
    /**
     * 
     * @type {number}
     * @memberof Dataset
     */
    defaultPartitionExpirationMs: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Dataset
     */
    labels: { [key: string]: string; };
}

/**
 * Check if a given object implements the Dataset interface.
 */
export function instanceOfDataset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "datasetRef" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "defaultTableExpirationMs" in value;
    isInstance = isInstance && "defaultPartitionExpirationMs" in value;
    isInstance = isInstance && "labels" in value;

    return isInstance;
}

export function DatasetFromJSON(json: any): Dataset {
    return DatasetFromJSONTyped(json, false);
}

export function DatasetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dataset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'link': !exists(json, 'link') ? undefined : json['link'],
        'datasetRef': json['dataset_ref'],
        'description': json['description'],
        'defaultTableExpirationMs': json['default_table_expiration_ms'],
        'defaultPartitionExpirationMs': json['default_partition_expiration_ms'],
        'labels': json['labels'],
    };
}

export function DatasetToJSON(value?: Dataset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'link': value.link,
        'dataset_ref': value.datasetRef,
        'description': value.description,
        'default_table_expiration_ms': value.defaultTableExpirationMs,
        'default_partition_expiration_ms': value.defaultPartitionExpirationMs,
        'labels': value.labels,
    };
}

