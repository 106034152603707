import { Stack, Typography } from "@mui/material";
import React from "react";

export const SelectSomething = ({
  message,
}: {
  message: string;
}): React.ReactElement => {
  return (
    <div style={{ height: "400px" }}>
      <Stack
        height="100%"
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        justifyItems={"center"}
      >
        <Typography variant="h1" align="center" color="GrayText">
          {message}
        </Typography>
      </Stack>
    </div>
  );
};
