import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { AuthenticationContext } from "./context";
import { login, logout } from "./fetch";

type loginProps = {
  username: string;
  password: string;
};

export const useLoginMutation = (): UseMutationResult<
  string,
  unknown,
  loginProps,
  unknown
> => {
  return useMutation(({ username, password }: loginProps) => {
    return login(username, password);
  });
};

export const useLogoutMutation = (): UseMutationResult<Response, unknown> => {
  const { setToken } = useContext(AuthenticationContext);
  return useMutation(() => {
    setToken(undefined);
    return logout();
  });
};
