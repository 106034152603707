import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const EyeIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      width="24px"
      height="24px"
      viewBox="-3 -6 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon-/-view" stroke="currentColor">
          <path
            d="M9.1793,0.4999 C5.3063,0.4999 1.9823,2.8449 0.5443,6.1919 C1.9823,9.5389 5.3063,11.8839 9.1793,11.8839 C13.0533,11.8839 16.3773,9.5389 17.8153,6.1919 C16.3773,2.8449 13.0533,0.4999 9.1793,0.4999 Z"
            id="Stroke-1"
          />
          <path
            d="M12.8522,6.1918 C12.8522,8.2198 11.2072,9.8638 9.1792,9.8638 C7.1522,9.8638 5.5072,8.2198 5.5072,6.1918 C5.5072,4.1638 7.1522,2.5198 9.1792,2.5198 C11.2072,2.5198 12.8522,4.1638 12.8522,6.1918 Z"
            id="Stroke-3"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
