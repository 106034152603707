import { validateEmail } from "../../../../utils/validation";

export const validateServiceAccountEmail = (email?: string): boolean => {
  if (email) {
    // TODO: Should we check that service accounts end in iam.gserviceaccount.com?
    if (email.indexOf("iam.gserviceaccount.com") !== -1) {
      return validateEmail(email.replace(/\s/g, ""));
    }
    return false;
  } else {
    // Returning true if no email is provided
    return true;
  }
};
