import { UseMutationResult, useMutation } from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  ResponseError,
  SubscriptionsViewsSubscriptionsBillingPortalRequest,
  SubscriptionsViewsSubscriptionsCheckoutSessionRequest,
} from "../../api-client";

export const useCheckoutSessionMutation = (): UseMutationResult<
  string,
  ResponseError,
  SubscriptionsViewsSubscriptionsCheckoutSessionRequest,
  unknown
> => {
  return useMutation({
    mutationFn: async (
      query: SubscriptionsViewsSubscriptionsCheckoutSessionRequest,
    ) => ApiClient.subscriptionsViewsSubscriptionsCheckoutSession(query),
  });
};

export const useBillingPortalMutation = (): UseMutationResult<
  string,
  ResponseError,
  SubscriptionsViewsSubscriptionsBillingPortalRequest,
  unknown
> => {
  return useMutation({
    mutationFn: async (
      query: SubscriptionsViewsSubscriptionsBillingPortalRequest,
    ) => ApiClient.subscriptionsViewsSubscriptionsBillingPortal(query),
  });
};
