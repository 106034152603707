/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const TaskStateEnum = {
    Pending: 'pending',
    Assigned: 'assigned',
    Done: 'done',
    Error: 'error'
} as const;
export type TaskStateEnum = typeof TaskStateEnum[keyof typeof TaskStateEnum];


export function TaskStateEnumFromJSON(json: any): TaskStateEnum {
    return TaskStateEnumFromJSONTyped(json, false);
}

export function TaskStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStateEnum {
    return json as TaskStateEnum;
}

export function TaskStateEnumToJSON(value?: TaskStateEnum | null): any {
    return value as any;
}

