/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobFileSchema
 */
export interface JobFileSchema {
    /**
     * 
     * @type {string}
     * @memberof JobFileSchema
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof JobFileSchema
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof JobFileSchema
     */
    mode?: number;
}

/**
 * Check if a given object implements the JobFileSchema interface.
 */
export function instanceOfJobFileSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function JobFileSchemaFromJSON(json: any): JobFileSchema {
    return JobFileSchemaFromJSONTyped(json, false);
}

export function JobFileSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFileSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': json['path'],
        'content': json['content'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
    };
}

export function JobFileSchemaToJSON(value?: JobFileSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'content': value.content,
        'mode': value.mode,
    };
}

