import React from "react";
import { Legend, YAxis } from "recharts";
import prettyBytes from "pretty-bytes";
import { ChartProps, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { GroupedLegend } from "./GroupedLegend";
import { YAXIS_WIDTH } from "./const";

export const WorkerNetChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Cluster Total Rate",
      groupKey: "type",
      queries: [
        {
          name: "sent_rate | sum",
          typeLabel: "sent",
        },
        {
          name: "recv_rate | sum",
          typeLabel: "received",
        },
        // 5Gbps = 625000000 bytes/s, t3 baseline
        {
          name: "cpu_rate | >0 | sum_by(instance) | count | *625000000",
          typeLabel: "5Gbps/worker (t3 limit)",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
        {
          name: "5Gbps/worker (t3 limit)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
    {
      name: "Cluster Total Data",
      groupKey: "type",
      queries: [
        {
          name: "sent | sum",
          typeLabel: "sent",
        },
        {
          name: "recv | sum",
          typeLabel: "received",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
      ],
    },
    {
      name: "Distribution",
      groupKey: "type",
      queries: [
        {
          name: "recv_rate | min",
          typeLabel: "min (recv)",
        },
        {
          name: "recv_rate | max",
          typeLabel: "max (recv)",
        },
        {
          name: "recv_rate | pct20",
          typeLabel: "pct20 (recv)",
        },
        {
          name: "recv_rate | pct80",
          typeLabel: "pct80 (recv)",
        },
        {
          name: "recv_rate | pct50",
          typeLabel: "median (recv)",
        },

        {
          name: "sent_rate | min",
          typeLabel: "min (sent)",
        },
        {
          name: "sent_rate | max",
          typeLabel: "max (sent)",
        },
        {
          name: "sent_rate | pct20",
          typeLabel: "pct20 (sent)",
        },
        {
          name: "sent_rate | pct80",
          typeLabel: "pct80 (sent)",
        },
        {
          name: "sent_rate | pct50",
          typeLabel: "median (sent)",
        },
      ],
      domains: [
        { name: "min (recv)", color: null, stack: 0, range: 1 },
        { name: "max (recv)", color: null, stack: 0, range: 1 },
        { name: "pct20 (recv)", color: null, stack: 0, range: 2 },
        { name: "pct80 (recv)", color: null, stack: 0, range: 2 },
        { name: "min (sent)", color: null, stack: 0, range: 3 },
        { name: "max (sent)", color: null, stack: 0, range: 3 },
        { name: "pct20 (sent)", color: null, stack: 0, range: 4 },
        { name: "pct80 (sent)", color: null, stack: 0, range: 4 },
        {
          name: "median (recv)",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20 (recv)-pct80 (recv)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.4 },
        },
        {
          name: "min (recv)-max (recv)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "median (sent)",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 1,
        },
        {
          name: "pct20 (sent)-pct80 (sent)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
        {
          name: "min (sent)-max (sent)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Received+Sent Distribution",
      groupKey: "type",
      queries: [
        {
          name: "recv_rate+sent_rate | min",
          typeLabel: "min",
        },
        {
          name: "recv_rate+sent_rate | max",
          typeLabel: "max",
        },
        {
          name: "recv_rate+sent_rate | pct20",
          typeLabel: "pct20",
        },
        {
          name: "recv_rate+sent_rate | pct80",
          typeLabel: "pct80",
        },
        {
          name: "recv_rate+sent_rate | pct50",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#eeca3b", fill: "white" },
          stack: 0,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Worker Network"
        variations={variations}
        {...props}
        yAxis={
          <YAxis
            domain={[0, 625000000]} // 5Gbps as soft max
            tickFormatter={(value: number) =>
              Number.isFinite(value) ? prettyBytes(value) : "0 B"
            }
            width={YAXIS_WIDTH}
          />
        }
      />
    </>
  );
};

export const CombinedNetChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Cluster Total Rate",
      groupKey: "type",
      queries: [
        {
          name: "all:sent_rate | sum",
          typeLabel: "sent",
        },
        {
          name: "all:recv_rate | sum",
          typeLabel: "received",
        },
        // 5Gbps = 625000000 bytes/s, t3 baseline
        {
          name: "all:cpu_rate | >0 | sum_by(instance) | count | *625000000",
          typeLabel: "5Gbps/worker (t3 limit)",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
        {
          name: "5Gbps/worker (t3 limit)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
    {
      name: "Cluster Total Data",
      groupKey: "type",
      queries: [
        {
          name: "all:sent | sum",
          typeLabel: "sent",
        },
        {
          name: "all:recv | sum",
          typeLabel: "received",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
      ],
    },
    {
      name: "Distribution",
      groupKey: "type",
      queries: [
        {
          name: "all:recv_rate | min",
          typeLabel: "min (recv)",
        },
        {
          name: "all:recv_rate | max",
          typeLabel: "max (recv)",
        },
        {
          name: "all:recv_rate | pct20",
          typeLabel: "pct20 (recv)",
        },
        {
          name: "all:recv_rate | pct80",
          typeLabel: "pct80 (recv)",
        },
        {
          name: "all:recv_rate | pct50",
          typeLabel: "median (recv)",
        },

        {
          name: "all:sent_rate | min",
          typeLabel: "min (sent)",
        },
        {
          name: "all:sent_rate | max",
          typeLabel: "max (sent)",
        },
        {
          name: "all:sent_rate | pct20",
          typeLabel: "pct20 (sent)",
        },
        {
          name: "all:sent_rate | pct80",
          typeLabel: "pct80 (sent)",
        },
        {
          name: "all:sent_rate | pct50",
          typeLabel: "median (sent)",
        },
      ],
      domains: [
        { name: "min (recv)", color: null, stack: 0, range: 1 },
        { name: "max (recv)", color: null, stack: 0, range: 1 },
        { name: "pct20 (recv)", color: null, stack: 0, range: 2 },
        { name: "pct80 (recv)", color: null, stack: 0, range: 2 },
        { name: "min (sent)", color: null, stack: 0, range: 3 },
        { name: "max (sent)", color: null, stack: 0, range: 3 },
        { name: "pct20 (sent)", color: null, stack: 0, range: 4 },
        { name: "pct80 (sent)", color: null, stack: 0, range: 4 },
        {
          name: "median (recv)",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20 (recv)-pct80 (recv)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.4 },
        },
        {
          name: "min (recv)-max (recv)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "median (sent)",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 1,
        },
        {
          name: "pct20 (sent)-pct80 (sent)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
        {
          name: "min (sent)-max (sent)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Received+Sent Distribution",
      groupKey: "type",
      queries: [
        {
          name: "all:recv_rate+sent_rate | min",
          typeLabel: "min",
        },
        {
          name: "all:recv_rate+sent_rate | max",
          typeLabel: "max",
        },
        {
          name: "all:recv_rate+sent_rate | pct20",
          typeLabel: "pct20",
        },
        {
          name: "all:recv_rate+sent_rate | pct80",
          typeLabel: "pct80",
        },
        {
          name: "all:recv_rate+sent_rate | pct50",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#eeca3b", fill: "white" },
          stack: 0,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#eeca3b", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Cluster Network"
        variations={variations}
        {...props}
        yAxis={
          <YAxis
            domain={[0, 625000000]} // 5Gbps as soft max
            tickFormatter={(value: number) =>
              Number.isFinite(value) ? prettyBytes(value) : "0 B"
            }
            width={YAXIS_WIDTH}
          />
        }
      />
    </>
  );
};

export const SingleNodeNetChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Total Rate",
      groupKey: "type",
      queries: [
        {
          name: "all:sent_rate | sum",
          typeLabel: "sent",
        },
        {
          name: "all:recv_rate | sum",
          typeLabel: "received",
        },
        {
          name: "all:sent_rate+recv_rate | sum",
          typeLabel: "sent+recv",
        },
        // 5Gbps = 625000000 bytes/s, t3 baseline
        {
          name: "all:cpu_rate | >0 | sum_by(instance) | count | *625000000",
          typeLabel: "5Gbps/node (t3 limit)",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
        {
          name: "sent+recv",
          color: { stroke: "black", fill: "white", opacity: 0 },
          stack: 1,
        },
        {
          name: "5Gbps/node (t3 limit)",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
    },
    {
      name: "Total Data",
      groupKey: "type",
      queries: [
        {
          name: "all:sent | sum",
          typeLabel: "sent",
        },
        {
          name: "all:recv | sum",
          typeLabel: "received",
        },
      ],
      domains: [
        { name: "sent", color: "#2159FF", stack: 0 },
        { name: "received", color: "#8fe38f", stack: 0 },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Network"
        variations={variations}
        {...props}
        yAxis={
          <YAxis
            domain={[0, 625000000]} // 5Gbps as soft max
            tickFormatter={(value: number) =>
              Number.isFinite(value) ? prettyBytes(value) : "0 B"
            }
            width={YAXIS_WIDTH}
          />
        }
      />
    </>
  );
};
