import React, { useContext } from "react";
import ky, { HTTPError } from "ky";
import Button from "@mui/material/Button";
import { parseError, getStandardHeaders, ApiError } from "../../apiUtils";
import { Stack } from "@mui/material";
import { UserContext } from "../../crud/user/context";
import { BaseAccount } from "../../crud/account/types";
import { SingleLineSelectForm } from "./SingleLineSelectForm";
import Typography from "@mui/material/Typography";

enum PatchApiError {
  InvalidAccount = "InvalidAccount",
  AlreadyExists = "AlreadyExists",
}

type PatchUserResult =
  | { error: undefined; defaultAccount: BaseAccount }
  | { error: ApiError | PatchApiError };

const patchUser = async (
  defaultAccount: BaseAccount,
): Promise<PatchUserResult> => {
  try {
    const result = await ky.patch(`/api/v1/users/me/`, {
      json: {
        default_account: defaultAccount.id,
      },
      headers: getStandardHeaders(),
    });
    const body = await result.json<any>();
    return { error: undefined, defaultAccount: body.default_account };
  } catch (err) {
    if (err instanceof HTTPError) {
      if (err?.response?.status === 409) {
        return { error: PatchApiError.AlreadyExists };
      }
      if (err?.response?.status >= 400) {
        const body = await err.response.json();
        if (body.default_account[0].startsWith("User ")) {
          return { error: PatchApiError.InvalidAccount };
        }
      }
    }
    return { error: parseError(err) };
  }
};

type EditState =
  | { type: "none" }
  | { type: "editing" }
  | { type: "submitting" }
  | { type: "submissionError"; error: ApiError | PatchApiError };

type FormShape = {
  defaultAccount: string;
};

export const DefaultAccountForm = (): React.ReactElement => {
  const { user } = useContext(UserContext);
  const { memberships } = useContext(UserContext);
  const [editState, setEditState] = React.useState<EditState>({
    type: "none",
  });

  const onSubmit = async (data: FormShape) => {
    setEditState({ type: "submitting" });
    const membership = memberships.find(
      (m) => m.account.slug === data.defaultAccount,
    );
    if (membership === undefined) {
      setEditState({
        type: "submissionError",
        error: PatchApiError.InvalidAccount,
      });
    } else {
      const result = await patchUser(membership.account);
      if (result.error) {
        setEditState({ type: "submissionError", error: result.error });
        return;
      } else {
        setEditState({ type: "none" });
      }
    }
  };

  const handleEditButtonClick = () => setEditState({ type: "editing" });
  const handleCancelButtonClick = () => {
    setEditState({ type: "none" });
  };

  const formActive =
    ["editing", "submissionError"].indexOf(editState.type) !== -1;

  let errorMessage = "";
  if (editState.type === "submissionError") {
    if (editState.error === PatchApiError.InvalidAccount) {
      errorMessage =
        "You do not have permission to access that workspace. Please select a different workspace.";
    } else if (editState.error === PatchApiError.AlreadyExists) {
      errorMessage = "That workspace is already your default";
    } else {
      errorMessage = "An unknown error occurred";
    }
  }

  let showInputAsError = false;
  if (editState.type === "submissionError") {
    showInputAsError = true;
  }

  return (
    <Stack sx={{ maxWidth: "350px" }} spacing={2}>
      <Typography>
        This is the default workspace in which to create your clusters.
      </Typography>

      <SingleLineSelectForm
        formActive={formActive}
        submitting={editState.type === "submitting"}
        error={showInputAsError}
        errorMessage={errorMessage}
        formOptions={{
          defaultValues: {
            defaultAccount: user.defaultAccount.slug,
          },
          mode: "onBlur",
        }}
        fieldName="defaultAccount"
        value={user.defaultAccount.slug}
        handleCancel={handleCancelButtonClick}
        handleSubmit={onSubmit}
      />
      {editState.type === "none" && (
        <Button
          onClick={handleEditButtonClick}
          disabled={formActive}
          variant="secondary"
          size="small"
          sx={{ maxWidth: "100px" }}
        >
          edit
        </Button>
      )}
    </Stack>
  );
};
