/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstanceStateSchema } from './InstanceStateSchema';
import {
    InstanceStateSchemaFromJSON,
    InstanceStateSchemaFromJSONTyped,
    InstanceStateSchemaToJSON,
} from './InstanceStateSchema';

/**
 * 
 * @export
 * @interface InstanceSchema
 */
export interface InstanceSchema {
    /**
     * 
     * @type {number}
     * @memberof InstanceSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSchema
     */
    updated: Date;
    /**
     * 
     * @type {number}
     * @memberof InstanceSchema
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    backendId: string;
    /**
     * 
     * @type {InstanceStateSchema}
     * @memberof InstanceSchema
     */
    currentState: InstanceStateSchema;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    zoneName?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    publicIpAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    privateIpAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    instanceTypeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceSchema
     */
    spot?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstanceSchema
     */
    retried?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceSchema
     */
    retryReason?: string;
}

/**
 * Check if a given object implements the InstanceSchema interface.
 */
export function instanceOfInstanceSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "backendId" in value;
    isInstance = isInstance && "currentState" in value;

    return isInstance;
}

export function InstanceSchemaFromJSON(json: any): InstanceSchema {
    return InstanceSchemaFromJSONTyped(json, false);
}

export function InstanceSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'accountId': json['account_id'],
        'name': json['name'],
        'backendId': json['backend_id'],
        'currentState': InstanceStateSchemaFromJSON(json['current_state']),
        'zoneName': !exists(json, 'zone_name') ? undefined : json['zone_name'],
        'publicIpAddress': !exists(json, 'public_ip_address') ? undefined : json['public_ip_address'],
        'privateIpAddress': !exists(json, 'private_ip_address') ? undefined : json['private_ip_address'],
        'instanceTypeId': !exists(json, 'instance_type_id') ? undefined : json['instance_type_id'],
        'spot': !exists(json, 'spot') ? undefined : json['spot'],
        'retried': !exists(json, 'retried') ? undefined : json['retried'],
        'retryReason': !exists(json, 'retry_reason') ? undefined : json['retry_reason'],
    };
}

export function InstanceSchemaToJSON(value?: InstanceSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'account_id': value.accountId,
        'name': value.name,
        'backend_id': value.backendId,
        'current_state': InstanceStateSchemaToJSON(value.currentState),
        'zone_name': value.zoneName,
        'public_ip_address': value.publicIpAddress,
        'private_ip_address': value.privateIpAddress,
        'instance_type_id': value.instanceTypeId,
        'spot': value.spot,
        'retried': value.retried,
        'retry_reason': value.retryReason,
    };
}

