/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrganizationSchema
 */
export interface CreateOrganizationSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationSchema
     */
    name: string;
}

/**
 * Check if a given object implements the CreateOrganizationSchema interface.
 */
export function instanceOfCreateOrganizationSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateOrganizationSchemaFromJSON(json: any): CreateOrganizationSchema {
    return CreateOrganizationSchemaFromJSONTyped(json, false);
}

export function CreateOrganizationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrganizationSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function CreateOrganizationSchemaToJSON(value?: CreateOrganizationSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}

