import { UseQueryResult } from "react-query";
import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
} from "recharts";
import { useTaskPrefixMetrics } from "../../../../crud/metrics/hooks";
import { SamplingMethod } from "../sampleMetrics";
import _, { groupBy, isObject } from "lodash";
import { getLatestTimestamp } from "../utils";
import { GroupedMetric } from "../../../../crud/metrics/types";
import { ZoomableAreaChartWithQuery } from "./ZoomableAreaChartWithQuery";
import { YAXIS_WIDTH } from "./const";
import { GroupedTooltip } from "./GroupedTooltip";
import { ChartProps, Domain } from "./types";
import { useTimezone } from "../../../../utils/hooks";
import { formatInTimeZone } from "date-fns-tz";

export const TaskPrefixChart = ({
  clusterId,
  timeRange,
  defaultTimeRange,
  setTimeRange,
  refAreaLeft,
  refAreaRight,
  setRefAreaLeft,
  setRefAreaRight,
  clusterIsRunning,
}: ChartProps): React.ReactElement => {
  const liveUpdates = timeRange === defaultTimeRange && clusterIsRunning;
  const query = useTaskPrefixMetrics(clusterId, defaultTimeRange, liveUpdates);

  const fetchNextPage = useCallback(query.fetchNextPage, [query]);

  // Fetch new data when defaultTimeRange updates
  useEffect(() => {
    if (defaultTimeRange && clusterIsRunning) {
      const latestTimestamp = getLatestTimestamp(query.data?.pages.slice(-10));

      if (latestTimestamp || defaultTimeRange) {
        fetchNextPage({
          pageParam: latestTimestamp || Math.floor(defaultTimeRange![0] / 1000),
        });
      }
    }
  }, [defaultTimeRange, clusterIsRunning]);

  const metricsQuery = useMemo(
    () =>
      ({
        ...query,
        data: query.data?.pages?.flatMap((p) => p.data) || [],
      }) as UseQueryResult<GroupedMetric[]>,
    [query.data],
  );

  const {
    palette: {
      custom: { chartColors },
    },
  } = useTheme();

  // We're incrementally fetching data in separate requests, so we need to combines keys from all requests
  const allKeys = _.uniq(
    query.data?.pages?.flatMap((p) => p.keys) || [],
  ) as string[];

  const domains: Domain[] = allKeys.map((name, i) => ({
    name,
    color: chartColors[i % chartColors.length],
    stack: 0,
  }));

  const hasSingleMetric = metricsQuery?.data?.length === 1;

  // Disable zooming if we only have one datapoint
  const domain = hasSingleMetric ? ["auto", "auto"] : timeRange;

  const pieData = useMemo(
    () => getPieChartData(metricsQuery?.data || [], domains),
    [metricsQuery?.data, domains],
  );

  const [displayTz] = useTimezone();

  return (
    <Box
      sx={{
        userSelect: "none",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Grid container spacing={1} wrap="nowrap">
        <Grid item width={YAXIS_WIDTH}>
          <PieChart
            width={YAXIS_WIDTH}
            height={YAXIS_WIDTH}
            margin={{
              top: -10,
              right: 5,
              left: -10,
              bottom: 0,
            }}
          >
            <Pie
              data={pieData}
              nameKey="name"
              dataKey="value"
              isAnimationActive={false}
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    isObject(entry.color)
                      ? entry.color.fill
                      : entry.color
                        ? entry.color
                        : "transparent"
                  }
                />
              ))}
            </Pie>
            <Tooltip
              content={
                <GroupedTooltip
                  formatter={tooltipValueFormatter}
                  labelFormatter={(value) => "Total Task Prefix Duration"}
                />
              }
              position={{ x: YAXIS_WIDTH - 10, y: 12 }}
              wrapperStyle={{
                outline: "none",
                backgroundColor: "white",
                zIndex: 2, // Making sure the task prefix tooltip doesn't cover the other tooltips
                minWidth: 260,
              }}
            />
          </PieChart>
        </Grid>
        <Grid item xs>
          <ZoomableAreaChartWithQuery
            emptyDataMessage={"No Task Duration Data"}
            metricsQuery={metricsQuery}
            samplingMethod={SamplingMethod.SUM}
            domains={domains}
            yAxis={<div />}
            xAxis={
              <XAxis
                minTickGap={50}
                dataKey={({ timestamp }) => timestamp * 1000}
                type="number"
                scale="time"
                domain={domain}
                tickFormatter={(v) =>
                  formatInTimeZone(new Date(v), displayTz, "HH:mm:ss")
                }
                allowDuplicatedCategory
                allowDataOverflow
              />
            }
            tooltip={
              <Tooltip
                content={
                  <GroupedTooltip
                    formatter={tooltipValueFormatter}
                    labelFormatter={(value) =>
                      `Task Prefix Duration - ${formatInTimeZone(
                        new Date(value),
                        displayTz,
                        "LLL do, HH:mm:ss O",
                      )}`
                    }
                  />
                }
                filterNull
                wrapperStyle={{
                  outline: "none",
                  zIndex: 2, // Making sure the task prefix tooltip doesn't cover the other tooltips
                }}
                isAnimationActive={false}
                itemSorter={({ value }) => (value as number) * -1}
              />
            }
            cartesianGrid={
              <CartesianGrid stroke="none" fill="rgba(0, 0, 0, 0.04)" />
            }
            Chart={BarChart}
            Cartesian={Bar}
            height={120}
            legend={<div />}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            refAreaLeft={refAreaLeft}
            refAreaRight={refAreaRight}
            setRefAreaLeft={setRefAreaLeft}
            setRefAreaRight={setRefAreaRight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const getPieChartData = (items: GroupedMetric[], domains: Domain[]) => {
  const sums: Record<string, number> = {};

  // Iterate over all items and sum up the values for each key
  items.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (key !== "timestamp") {
        const current = parseFloat(value ?? "0");
        const sum = sums[key] ?? 0;
        sums[key] = current + sum;
      }
    });
  });

  // Fetch color for each summed key from domains
  const groupedDomains = groupBy(domains, "name");
  return Object.entries(sums).map(([name, value]) => ({
    name,
    value,
    color: groupedDomains[name] ? groupedDomains[name][0].color : "#DDD",
  }));
};

const tooltipValueFormatter = (value: string, name: string) => {
  const numberValue = value as unknown as number;
  const formattedValue = `${numberValue.toFixed(2)} seconds`;
  return [formattedValue, name];
};
