/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';

/**
 * 
 * @export
 * @interface InstanceProfileSchema
 */
export interface InstanceProfileSchema {
    /**
     * 
     * @type {number}
     * @memberof InstanceProfileSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof InstanceProfileSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstanceProfileSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof InstanceProfileSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {string}
     * @memberof InstanceProfileSchema
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceProfileSchema
     */
    instanceProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceProfileSchema
     */
    instanceProfileName: string;
}

/**
 * Check if a given object implements the InstanceProfileSchema interface.
 */
export function instanceOfInstanceProfileSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "instanceProfileName" in value;

    return isInstance;
}

export function InstanceProfileSchemaFromJSON(json: any): InstanceProfileSchema {
    return InstanceProfileSchemaFromJSONTyped(json, false);
}

export function InstanceProfileSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceProfileSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'instanceProfileId': !exists(json, 'instance_profile_id') ? undefined : json['instance_profile_id'],
        'instanceProfileName': json['instance_profile_name'],
    };
}

export function InstanceProfileSchemaToJSON(value?: InstanceProfileSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'arn': value.arn,
        'instance_profile_id': value.instanceProfileId,
        'instance_profile_name': value.instanceProfileName,
    };
}

