/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleInfoSchema
 */
export interface RoleInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof RoleInfoSchema
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof RoleInfoSchema
     */
    roleArn: string;
    /**
     * 
     * @type {string}
     * @memberof RoleInfoSchema
     */
    credsSource?: RoleInfoSchemaCredsSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleInfoSchema
     */
    defaultRegion?: string;
}


/**
 * @export
 */
export const RoleInfoSchemaCredsSourceEnum = {
    Cloudformation: 'cloudformation',
    CliRoleAssumption: 'cli-role-assumption'
} as const;
export type RoleInfoSchemaCredsSourceEnum = typeof RoleInfoSchemaCredsSourceEnum[keyof typeof RoleInfoSchemaCredsSourceEnum];


/**
 * Check if a given object implements the RoleInfoSchema interface.
 */
export function instanceOfRoleInfoSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalId" in value;
    isInstance = isInstance && "roleArn" in value;

    return isInstance;
}

export function RoleInfoSchemaFromJSON(json: any): RoleInfoSchema {
    return RoleInfoSchemaFromJSONTyped(json, false);
}

export function RoleInfoSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleInfoSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': json['external_id'],
        'roleArn': json['role_arn'],
        'credsSource': !exists(json, 'creds_source') ? undefined : json['creds_source'],
        'defaultRegion': !exists(json, 'default_region') ? undefined : json['default_region'],
    };
}

export function RoleInfoSchemaToJSON(value?: RoleInfoSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.externalId,
        'role_arn': value.roleArn,
        'creds_source': value.credsSource,
        'default_region': value.defaultRegion,
    };
}

