/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComputationSummary } from './ComputationSummary';
import {
    ComputationSummaryFromJSON,
    ComputationSummaryFromJSONTyped,
    ComputationSummaryToJSON,
} from './ComputationSummary';
import type { DurationsByType } from './DurationsByType';
import {
    DurationsByTypeFromJSON,
    DurationsByTypeFromJSONTyped,
    DurationsByTypeToJSON,
} from './DurationsByType';
import type { PrefixDurations } from './PrefixDurations';
import {
    PrefixDurationsFromJSON,
    PrefixDurationsFromJSONTyped,
    PrefixDurationsToJSON,
} from './PrefixDurations';

/**
 * 
 * @export
 * @interface ClusterAnalyticsSummary
 */
export interface ClusterAnalyticsSummary {
    /**
     * 
     * @type {DurationsByType}
     * @memberof ClusterAnalyticsSummary
     */
    durationsTotal: DurationsByType;
    /**
     * 
     * @type {Array<PrefixDurations>}
     * @memberof ClusterAnalyticsSummary
     */
    durationsByPrefix: Array<PrefixDurations>;
    /**
     * 
     * @type {Array<ComputationSummary>}
     * @memberof ClusterAnalyticsSummary
     */
    computations: Array<ComputationSummary>;
}

/**
 * Check if a given object implements the ClusterAnalyticsSummary interface.
 */
export function instanceOfClusterAnalyticsSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "durationsTotal" in value;
    isInstance = isInstance && "durationsByPrefix" in value;
    isInstance = isInstance && "computations" in value;

    return isInstance;
}

export function ClusterAnalyticsSummaryFromJSON(json: any): ClusterAnalyticsSummary {
    return ClusterAnalyticsSummaryFromJSONTyped(json, false);
}

export function ClusterAnalyticsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterAnalyticsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'durationsTotal': DurationsByTypeFromJSON(json['durations_total']),
        'durationsByPrefix': ((json['durations_by_prefix'] as Array<any>).map(PrefixDurationsFromJSON)),
        'computations': ((json['computations'] as Array<any>).map(ComputationSummaryFromJSON)),
    };
}

export function ClusterAnalyticsSummaryToJSON(value?: ClusterAnalyticsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'durations_total': DurationsByTypeToJSON(value.durationsTotal),
        'durations_by_prefix': ((value.durationsByPrefix as Array<any>).map(PrefixDurationsToJSON)),
        'computations': ((value.computations as Array<any>).map(ComputationSummaryToJSON)),
    };
}

