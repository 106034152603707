/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';

/**
 * 
 * @export
 * @interface CloudWatchLogGroupSchema
 */
export interface CloudWatchLogGroupSchema {
    /**
     * 
     * @type {number}
     * @memberof CloudWatchLogGroupSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof CloudWatchLogGroupSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CloudWatchLogGroupSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof CloudWatchLogGroupSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {string}
     * @memberof CloudWatchLogGroupSchema
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudWatchLogGroupSchema
     */
    logGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof CloudWatchLogGroupSchema
     */
    region: string;
}

/**
 * Check if a given object implements the CloudWatchLogGroupSchema interface.
 */
export function instanceOfCloudWatchLogGroupSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "logGroupName" in value;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function CloudWatchLogGroupSchemaFromJSON(json: any): CloudWatchLogGroupSchema {
    return CloudWatchLogGroupSchemaFromJSONTyped(json, false);
}

export function CloudWatchLogGroupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudWatchLogGroupSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'logGroupName': json['log_group_name'],
        'region': json['region'],
    };
}

export function CloudWatchLogGroupSchemaToJSON(value?: CloudWatchLogGroupSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'arn': value.arn,
        'log_group_name': value.logGroupName,
        'region': value.region,
    };
}

