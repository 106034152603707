/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionStatus } from './SubscriptionStatus';
import {
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './SubscriptionStatus';

/**
 * 
 * @export
 * @interface SubscriptionSchema
 */
export interface SubscriptionSchema {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionSchema
     */
    currentPeriodEnd: Date;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof SubscriptionSchema
     */
    status: SubscriptionStatus;
}

/**
 * Check if a given object implements the SubscriptionSchema interface.
 */
export function instanceOfSubscriptionSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "currentPeriodEnd" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SubscriptionSchemaFromJSON(json: any): SubscriptionSchema {
    return SubscriptionSchemaFromJSONTyped(json, false);
}

export function SubscriptionSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'currentPeriodEnd': (new Date(json['current_period_end'])),
        'status': SubscriptionStatusFromJSON(json['status']),
    };
}

export function SubscriptionSchemaToJSON(value?: SubscriptionSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'current_period_end': (value.currentPeriodEnd.toISOString()),
        'status': SubscriptionStatusToJSON(value.status),
    };
}

