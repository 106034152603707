/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuildPhoneHomeStatusEnum } from './BuildPhoneHomeStatusEnum';
import {
    BuildPhoneHomeStatusEnumFromJSON,
    BuildPhoneHomeStatusEnumFromJSONTyped,
    BuildPhoneHomeStatusEnumToJSON,
} from './BuildPhoneHomeStatusEnum';
import type { EnvSpec } from './EnvSpec';
import {
    EnvSpecFromJSON,
    EnvSpecFromJSONTyped,
    EnvSpecToJSON,
} from './EnvSpec';

/**
 * 
 * @export
 * @interface BuildPhoneHomeSchema
 */
export interface BuildPhoneHomeSchema {
    /**
     * 
     * @type {string}
     * @memberof BuildPhoneHomeSchema
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildPhoneHomeSchema
     */
    returnCode?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildPhoneHomeSchema
     */
    timestampMs?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildPhoneHomeSchema
     */
    durationMs?: number;
    /**
     * 
     * @type {BuildPhoneHomeStatusEnum}
     * @memberof BuildPhoneHomeSchema
     */
    status: BuildPhoneHomeStatusEnum;
    /**
     * 
     * @type {EnvSpec}
     * @memberof BuildPhoneHomeSchema
     */
    envSpec?: EnvSpec;
    /**
     * 
     * @type {number}
     * @memberof BuildPhoneHomeSchema
     */
    sizeBytes?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildPhoneHomeSchema
     */
    chunks?: number;
}

/**
 * Check if a given object implements the BuildPhoneHomeSchema interface.
 */
export function instanceOfBuildPhoneHomeSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function BuildPhoneHomeSchemaFromJSON(json: any): BuildPhoneHomeSchema {
    return BuildPhoneHomeSchemaFromJSONTyped(json, false);
}

export function BuildPhoneHomeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildPhoneHomeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'returnCode': !exists(json, 'return_code') ? undefined : json['return_code'],
        'timestampMs': !exists(json, 'timestamp_ms') ? undefined : json['timestamp_ms'],
        'durationMs': !exists(json, 'duration_ms') ? undefined : json['duration_ms'],
        'status': BuildPhoneHomeStatusEnumFromJSON(json['status']),
        'envSpec': !exists(json, 'env_spec') ? undefined : EnvSpecFromJSON(json['env_spec']),
        'sizeBytes': !exists(json, 'size_bytes') ? undefined : json['size_bytes'],
        'chunks': !exists(json, 'chunks') ? undefined : json['chunks'],
    };
}

export function BuildPhoneHomeSchemaToJSON(value?: BuildPhoneHomeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'return_code': value.returnCode,
        'timestamp_ms': value.timestampMs,
        'duration_ms': value.durationMs,
        'status': BuildPhoneHomeStatusEnumToJSON(value.status),
        'env_spec': EnvSpecToJSON(value.envSpec),
        'size_bytes': value.sizeBytes,
        'chunks': value.chunks,
    };
}

