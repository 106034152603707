import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const SlackIcon = (): React.ReactElement => {
  return (
    <SvgIcon>
      <svg width="24" height="24" version="1.1" viewBox="0 0 24 24">
        <g transform="matrix(.19544 0 0 .19544 -14.384 -14.384)">
          <path
            style={{
              fill: "#E01E5A",
            }}
            d="m99.4 151.2c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9 5.8-12.9 12.9-12.9h12.9z"
          />
          <path
            style={{
              fill: "#E01E5A",
            }}
            d="m105.9 151.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9z"
          />
          <path
            style={{
              fill: "#36C5F0",
            }}
            d="m118.8 99.4c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9 12.9 5.8 12.9 12.9v12.9z"
          />
          <path
            style={{
              fill: "#36C5F0",
            }}
            d="m118.8 105.9c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9h-32.3c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9z"
          />

          <path
            style={{
              fill: "#2EB67D",
            }}
            d="m170.6 118.8c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9h-12.9z"
          />
          <path
            style={{
              fill: "#2EB67D",
            }}
            d="m164.1 118.8c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9v-32.3c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9z"
          />

          <path
            style={{
              fill: "#ECB22E",
            }}
            d="m151.2 170.6c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9v-12.9z"
          />
          <path
            style={{
              fill: "#ECB22E",
            }}
            d="m151.2 164.1c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
