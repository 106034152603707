import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  Link,
  Tooltip,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Container,
} from "@mui/material";

import { useAnalytics } from "use-analytics";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Urls } from "../domain/urls";
import {
  validateEmail,
  validatePassword,
  validateUserName,
} from "../utils/validation";
import { signUp } from "./auth";
import { AuthLayout } from "./AuthLayout";
import { ActivationFailReasons } from "./types";
import { GithubIcon } from "../icons/GithubIcon";
import { GoogleIcon } from "../icons/GoogleIcon";
import { Page } from "../shared-components/Page";
import {
  AuthForm,
  ErrorMessage,
  HalfDivider,
  Split,
  LeftSection,
} from "./components";

type Inputs = {
  fullName: string;
  preferredName: string;
  username: string;
  password1: string;
  password2: string;
  email: string;
};

export const SignUpForm = (): React.ReactElement => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [isSuccess, setSuccess] = React.useState<boolean>(false);
  const [emailVerified, setEmailVerified] = React.useState<boolean>(false);
  const [acceptedTos, setAcceptedTos] = React.useState<boolean>(false);
  const [nonFieldError, setNonFieldError] = React.useState<string>();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onBlur" });

  const onSubmit = async ({
    fullName,
    preferredName,
    username,
    password1,
    password2,
    email,
  }: Inputs) => {
    setSubmitted(true);
    const result = await signUp({
      fullName,
      preferredName,
      username,
      email,
      password1,
      password2,
    });
    if (result.type === "success") {
      setSuccess(true);
    }
    if (result.type === "alreadyVerified") {
      setEmailVerified(true);
    }
    if (result.type === "error") {
      setSubmitted(false);
      const responseErrs = result?.errors;
      if (responseErrs?.full_name) {
        setError("fullName", {
          type: "manual",
          message: responseErrs.full_name.join(","),
        });
      }
      if (responseErrs?.preferred_name) {
        setError("preferredName", {
          type: "manual",
          message: responseErrs.preferred_name.join(","),
        });
      }

      if (responseErrs?.username) {
        setError("username", {
          type: "manual",
          message: responseErrs.username.join(","),
        });
      }
      if (responseErrs?.email) {
        setError("email", {
          type: "manual",
          message: responseErrs.email.join(","),
        });
      }
      if (responseErrs?.password1) {
        setError("password1", {
          type: "manual",
          message: responseErrs.password1.join(","),
        });
      }
      if (responseErrs?.password2) {
        setError("password2", {
          type: "manual",
          message: responseErrs.password2.join(","),
        });
      }
      if (responseErrs?.non_field_errors) {
        setNonFieldError(responseErrs.non_field_errors[0]);
      } else if (responseErrs?.unknownError) {
        setNonFieldError(responseErrs.unknownError);
      }
    }
  };

  if (emailVerified === true) {
    navigate({ pathname: `${Urls.Root}` });
  }

  if (isSuccess === true) {
    navigate({ pathname: `/${Urls.VerifyEmailSent}` });
  }

  const analytics = useAnalytics();
  const breadCrumbs = useMemo(() => [], []);
  return (
    <Page title="Sign Up" breadCrumbs={breadCrumbs}>
      <Helmet>
        <script
          defer
          data-domain="coiled.io,cloud.coiled.io"
          src="https://plausible.io/js/script.js"
        />
      </Helmet>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML:
              '!function () { var e, t, n; e = "860e119beb3f451", t = function () { Reo.init({ clientID: "860e119beb3f451" }) }, (n = document.createElement("script")).src = "https://static.reo.dev/" + e + "/reo.js", n.async = !0, n.onload = t, document.head.appendChild(n) }();',
          },
        ]}
      />
      <AuthLayout>
        <Split>
          <LeftSection
            subtitle="Sign Up"
            analyticsEvent="cloud-signup-aws-cli-quickstart"
            href="https://docs.coiled.io/user_guide/setup/index.html"
            description="Read the quickstart in the Coiled docs"
          />
          <Container
            maxWidth="xs"
            sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          >
            <Card sx={{ paddingTop: "2rem" }}>
              <CardHeader
                sx={{ textAlign: "center" }}
                title="Register for an Account"
              />
              <CardContent>
                <AuthForm onSubmit={handleSubmit(onSubmit)}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GithubIcon />}
                    href={`/${Urls.GithubInitLogin}`}
                    onClick={() =>
                      analytics.track("signup-clicked", {
                        provider: "github",
                      })
                    }
                    fullWidth
                  >
                    Sign up with GitHub
                  </Button>
                  {/* https://developers.google.com/identity/branding-guidelines -- they _really_ prefer white background */}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GoogleIcon />}
                    onClick={() =>
                      analytics.track("signup-clicked", {
                        provider: "google",
                      })
                    }
                    href={`/${Urls.GoogleInitLogin}`}
                    fullWidth
                  >
                    Sign up with Google
                  </Button>
                  <HalfDivider>Or</HalfDivider>
                  <TextField
                    {...register("fullName", {
                      required: "full name is required",
                      // easier to use this to have good error messages than built in maxLength
                      validate: (value) =>
                        value.length <= 254 ||
                        "full name is limited to 254 or less characters",
                    })}
                    error={!!errors.fullName}
                    helperText={errors.fullName ? errors.fullName.message : ""}
                    fullWidth
                    variant="outlined"
                    placeholder="full name"
                  />
                  <TextField
                    {...register("preferredName", {
                      // easier to use this to have good error messages than built in maxLength
                      validate: (value) =>
                        value.length <= 150 ||
                        "preferred name is limited to 150 or less characters",
                    })}
                    error={!!errors.preferredName}
                    helperText={
                      errors.preferredName ? errors.preferredName.message : ""
                    }
                    fullWidth
                    variant="outlined"
                    placeholder="preferred name (optional)"
                    required={false}
                  />
                  <TextField
                    {...register("username", {
                      required: "username is required",
                      validate: (value) =>
                        validateUserName(value) === true ||
                        ActivationFailReasons.UsernameRequirements,
                    })}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ""}
                    fullWidth
                    variant="outlined"
                    placeholder="username"
                  />
                  <TextField
                    {...register("email", {
                      required: "email is required",
                      validate: (value) =>
                        validateEmail(value) === true || "invalid email",
                    })}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    fullWidth
                    variant="outlined"
                    placeholder="email"
                  />
                  <TextField
                    {...register("password1", {
                      required: "password is required",
                      validate: (value) =>
                        validatePassword(value) === true ||
                        ActivationFailReasons.PasswordRequirements,
                    })}
                    error={!!errors.password1}
                    helperText={
                      errors.password1 ? errors.password1.message : ""
                    }
                    fullWidth
                    variant="outlined"
                    placeholder="password"
                    type="password"
                  />
                  <TextField
                    {...register("password2", {
                      required: "matching password is required",
                      validate: (value) =>
                        value === getValues("password1") ||
                        "passwords must match",
                    })}
                    error={!!errors.password2}
                    helperText={
                      errors.password2 ? errors.password2.message : ""
                    }
                    fullWidth
                    variant="outlined"
                    placeholder="confirm password"
                    type="password"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        name="acceptTos"
                        color="primary"
                        onChange={() => setAcceptedTos(!acceptedTos)}
                        inputProps={{
                          "aria-label": "Checkbox Terms of Service",
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={(theme) => ({
                          paddingTop: "4px",
                          marginTop: "8px",
                          fontSize: theme.typography.pxToRem(14),
                          fontWeight: theme.typography.fontWeightLight,
                        })}
                        variant="body1"
                        align="center"
                        component="div"
                      >
                        <Typography display="inline" variant="subtitle1">
                          {`I accept the `}
                        </Typography>
                        <Link
                          variant="subtitle1"
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={
                            "https://coiled.io/coiled-subscription-services-agreement/"
                          }
                          underline="hover"
                        >
                          {`terms of service`}
                        </Link>
                        <Typography display="inline" variant="subtitle1">
                          {`, `}
                        </Typography>
                        <Link
                          variant="subtitle1"
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={"https://coiled.io/cookie-policy/"}
                          underline="hover"
                        >
                          {`cookie policy,`}
                        </Link>
                        <Typography display="inline" variant="subtitle1">
                          {` and `}
                        </Typography>
                        <Link
                          variant="subtitle1"
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={"https://coiled.io/privacy-policy/"}
                          underline="hover"
                        >
                          privacy policy
                        </Link>
                        . {/* ugly so period not part of link */}
                      </Typography>
                    }
                  />
                  <Tooltip
                    disableInteractive={acceptedTos}
                    title={
                      acceptedTos ? "" : "Please accept terms of service, etc"
                    }
                  >
                    <span>
                      <Button type="submit" variant="primary">
                        {submitted ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                  {nonFieldError && (
                    <ErrorMessage variant="body1" align="center" color="error">
                      {nonFieldError}
                    </ErrorMessage>
                  )}
                </AuthForm>
              </CardContent>
            </Card>
          </Container>
        </Split>
      </AuthLayout>
    </Page>
  );
};
