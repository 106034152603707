import React from "react";

import { Alert, AlertTitle, Skeleton, Typography } from "@mui/material";

import type { ClusterAlertBannerProps } from "./types";

export const ClusterAlertsLoadingBanner = (): React.ReactElement => (
  <Alert severity={"info"}>
    <AlertTitle>Loading...</AlertTitle>
    <div style={{ padding: "16px", width: "60vw" }}>
      <Skeleton animation="wave" variant="rectangular" height={30} />
    </div>
  </Alert>
);

export const ClusterAlertBanner = (
  props: ClusterAlertBannerProps,
): React.ReactElement => {
  const { level, title, originalMessage, coiledMessage, recommendation } =
    props;
  return (
    <Alert
      // @ts-ignore: MUI doesn't like strings?
      severity={level ? level : "warning"}
    >
      <AlertTitle>{title}</AlertTitle>
      <Typography>{coiledMessage ? coiledMessage : originalMessage}</Typography>
      {recommendation ? (
        <Typography>{`Coiled Recommendation: ${recommendation}`}</Typography>
      ) : null}
    </Alert>
  );
};
