/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Valid states for Processes
 * @export
 */
export const ProcessStateEnum = {
    Pending: 'pending',
    Starting: 'starting',
    Started: 'started',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Error: 'error'
} as const;
export type ProcessStateEnum = typeof ProcessStateEnum[keyof typeof ProcessStateEnum];


export function ProcessStateEnumFromJSON(json: any): ProcessStateEnum {
    return ProcessStateEnumFromJSONTyped(json, false);
}

export function ProcessStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessStateEnum {
    return json as ProcessStateEnum;
}

export function ProcessStateEnumToJSON(value?: ProcessStateEnum | null): any {
    return value as any;
}

