import React, { useContext } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { stopCluster } from "./crud";
import { useMutation, useQueryClient } from "react-query";
import { UserContext } from "../../crud/user/context";

type StopTarget = {
  accountSlug: string;
  clusterId: number;
  clusterCreatorId: number;
  clusterCreatorName: string;
};

export const StopClusterDialog = ({
  open,
  stopTarget,
  onClose,
}: {
  open: boolean;
  stopTarget: StopTarget;
  onClose: () => void;
}): React.ReactElement => {
  const {
    user: { username, id: userId },
  } = useContext(UserContext);
  const client = useQueryClient();
  const { mutate, isLoading: stoppingCluster } = useMutation(
    (clusterId: number) =>
      stopCluster(
        clusterId,
        stopTarget.accountSlug,
        `${username} requested stop from dashboard`,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries("clusterList");
        onClose();
      },
    },
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Stop cluster?</DialogTitle>
      <DialogContent>
        {userId !== stopTarget.clusterCreatorId && (
          <Alert severity="warning">
            This cluster was started by user{" "}
            <b>{stopTarget.clusterCreatorName}</b>
          </Alert>
        )}
        <DialogContentText>
          Are you sure you want to stop this cluster?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          disabled={stoppingCluster}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          autoFocus
          color="error"
          loading={stoppingCluster}
          onClick={() => mutate(stopTarget.clusterId)}
        >
          Stop
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
