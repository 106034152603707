import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { StepProps } from "../../../shared-components/StepperContext";
import { CodeBlock } from "../../../shared-components/CodeBlock";

export const AzureTalkToUsStep = ({ index }: StepProps): React.ReactElement => {
  return <AzureTalkToUsInfo />;
};
type AzureTalkToUsProps = {
  alreadySetup?: boolean;
};

export const AzureTalkToUsInfo = ({
  alreadySetup,
}: AzureTalkToUsProps): React.ReactElement => {
  return (
    <Stack alignItems={"flex-start"}>
      {alreadySetup ? (
        <Typography>
          This Coiled workspace is currently configured to use Azure.
        </Typography>
      ) : (
        <Stack spacing={2}>
          <Typography>
            Coiled on Azure is currently in public beta. Please contact{" "}
            <Link
              href="mailto:support@coiled.io"
              rel="noreferrer"
              target="_blank"
            >
              support@coiled.io
            </Link>{" "}
            if you have any questions or problems.
          </Typography>
          <Typography>
            Install the{" "}
            <Link
              href="https://learn.microsoft.com/cli/azure/install-azure-cli"
              target="_blank"
            >
              Azure CLI
            </Link>
            , then run these commands to get started with Coiled:
          </Typography>
          <CodeBlock isTerminal snippet="pip install 'coiled[azure]'" />
          <CodeBlock isTerminal snippet="coiled setup azure" />
          <Typography>
            For more details about setting up and using Azure, see{" "}
            <Link
              href="https://docs.coiled.io/user_guide/setup/azure/cli.html"
              target="_blank"
            >
              our docs
            </Link>
            .
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
