import React from "react";
import Select, { SelectProps } from "@mui/material/Select";
import { Control, Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

interface HookSelectProps extends SelectProps {
  name: string;
  control: Control<any>;
  rules?: object;
}

export const HookSelect = ({
  control,
  name,
  rules,
  ...props
}: HookSelectProps): React.ReactElement => {
  const labelId = props.labelId || `${name}-label`;
  const error = control.getFieldState(name)?.error;
  return (
    <FormControl size={props.size}>
      <InputLabel disabled={props.disabled} id={labelId}>
        {props.label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules ? rules : {}}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value ? value : props.multiple ? [] : ""}
            {...props}
            onChange={(event, child) => {
              onChange(event);
              if (props.onChange) {
                props.onChange(event, child);
              }
            }}
          />
        )}
      />
      <FormHelperText disabled={props.disabled} error>
        {error?.message}
      </FormHelperText>
    </FormControl>
  );
};
