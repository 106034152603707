/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskGroupUpdate
 */
export interface TaskGroupUpdate {
    /**
     * 
     * @type {string}
     * @memberof TaskGroupUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskGroupUpdate
     */
    prefixName: string;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    stop: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    nbytes: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    nTasks: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    durationCompute: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    durationDiskWrite: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    durationDiskRead: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    durationDeserialize: number;
    /**
     * 
     * @type {number}
     * @memberof TaskGroupUpdate
     */
    durationTransfer: number;
    /**
     * 
     * @type {object}
     * @memberof TaskGroupUpdate
     */
    durationOther: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskGroupUpdate
     */
    types: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskGroupUpdate
     */
    dependencies: Array<string>;
}

/**
 * Check if a given object implements the TaskGroupUpdate interface.
 */
export function instanceOfTaskGroupUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "prefixName" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "stop" in value;
    isInstance = isInstance && "nbytes" in value;
    isInstance = isInstance && "nTasks" in value;
    isInstance = isInstance && "durationCompute" in value;
    isInstance = isInstance && "durationDiskWrite" in value;
    isInstance = isInstance && "durationDiskRead" in value;
    isInstance = isInstance && "durationDeserialize" in value;
    isInstance = isInstance && "durationTransfer" in value;
    isInstance = isInstance && "durationOther" in value;
    isInstance = isInstance && "types" in value;
    isInstance = isInstance && "dependencies" in value;

    return isInstance;
}

export function TaskGroupUpdateFromJSON(json: any): TaskGroupUpdate {
    return TaskGroupUpdateFromJSONTyped(json, false);
}

export function TaskGroupUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskGroupUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'prefixName': json['prefix_name'],
        'start': json['start'],
        'stop': json['stop'],
        'nbytes': json['nbytes'],
        'nTasks': json['n_tasks'],
        'durationCompute': json['duration_compute'],
        'durationDiskWrite': json['duration_disk_write'],
        'durationDiskRead': json['duration_disk_read'],
        'durationDeserialize': json['duration_deserialize'],
        'durationTransfer': json['duration_transfer'],
        'durationOther': json['duration_other'],
        'types': json['types'],
        'dependencies': json['dependencies'],
    };
}

export function TaskGroupUpdateToJSON(value?: TaskGroupUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'prefix_name': value.prefixName,
        'start': value.start,
        'stop': value.stop,
        'nbytes': value.nbytes,
        'n_tasks': value.nTasks,
        'duration_compute': value.durationCompute,
        'duration_disk_write': value.durationDiskWrite,
        'duration_disk_read': value.durationDiskRead,
        'duration_deserialize': value.durationDeserialize,
        'duration_transfer': value.durationTransfer,
        'duration_other': value.durationOther,
        'types': value.types,
        'dependencies': value.dependencies,
    };
}

