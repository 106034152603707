/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkerCreateSchema
 */
export interface WorkerCreateSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkerCreateSchema
     */
    nWorkers: number;
    /**
     * 
     * @type {string}
     * @memberof WorkerCreateSchema
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkerCreateSchema
     */
    target?: number;
}

/**
 * Check if a given object implements the WorkerCreateSchema interface.
 */
export function instanceOfWorkerCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nWorkers" in value;

    return isInstance;
}

export function WorkerCreateSchemaFromJSON(json: any): WorkerCreateSchema {
    return WorkerCreateSchemaFromJSONTyped(json, false);
}

export function WorkerCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nWorkers': json['n_workers'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function WorkerCreateSchemaToJSON(value?: WorkerCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'n_workers': value.nWorkers,
        'reason': value.reason,
        'target': value.target,
    };
}

