import React from "react";
import { Chip, Tooltip } from "@mui/material";
import Linkify from "linkify-react";
import { ClusterStateSchema } from "../../../api-client";

export const ClusterState = ({
  state,
}: {
  state: Omit<ClusterStateSchema, "id" | "updated" | "created">;
}): React.ReactElement => {
  const hoverContent = <Linkify as="div">{state.reason}</Linkify>;
  return (
    <Tooltip sx={{ verticalAlign: "middle" }} title={hoverContent}>
      <Chip
        size="small"
        label={state.state}
        color={clusterStateColor(state.state)}
      />
    </Tooltip>
  );
};

const clusterStateColor = (state: string) => {
  switch (state) {
    case "pending":
    case "starting":
    case "scaling":
      return "info"; // blue
    case "ready":
      return "success"; // green
    case "error":
      return "error"; // red
    default:
      return "default"; // grey
  }
};
