import React from "react";
import { Box, Chip } from "@mui/material";

export const LiveChip = (): React.ReactElement => {
  return (
    <Chip
      label={
        <Box
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              height: "8px",
              width: "8px",
              backgroundColor: "#2e7d32",
              borderRadius: "50%",
              marginRight: "5px",
              animation: "pulse 2s infinite",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(0.9)",
                  opacity: "0.6",
                },
                "66%": {
                  transform: "scale(1.1)",
                  opacity: "0.3",
                },
                "100%": {
                  transform: "scale(0.9)",
                  opacity: "0.6",
                },
              },
            }}
          />
          Live
        </Box>
      }
      color="success"
      variant="outlined"
    />
  );
};
