/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxTheme from "../../../shared-components/syntaxTheme";
import syntaxHighlighterStyle from "../../../shared-components/syntaxHighlighterStyle";
import {
  Alert,
  Box,
  CardContent,
  Chip,
  darken,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useClusterAnalyticsSummary } from "../../../crud/analytics/hooks";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { ComputationStatistics } from "./ComputationsStatistics";
import { ComputationTimeline } from "./charts/ComputationTimeline";
import {
  StackCode,
  StackSpecificFrame,
  ComputationSummary,
  ExceptionSummary,
} from "../Analytics/ClusterStatistics";
import { theme } from "../../../theme";
import { ExceptionAlert } from "./ExceptionAlert";
import { ExceptionDialog } from "./ExceptionDialog";
import { timeRangeToString } from "./utils";
import { TimeRange } from "../types";

/*
A table listing the computations for a cluster.
*/
export const ComputationListing = ({
  clusterId,
  focusedComputation,
  setFocusedComputation,
  timeRange,
  setTimeRange,
  defaultTimeRange,
  setRefAreaLeft,
  setRefAreaRight,
  zoomOut,
}: {
  clusterId?: number;
  focusedComputation?: string;
  setFocusedComputation: (computation?: string) => void;
  timeRange?: TimeRange;
  setTimeRange: (timeRange?: TimeRange) => void;
  defaultTimeRange?: TimeRange;
  setRefAreaLeft: (refAreaLeft: number) => void;
  setRefAreaRight: (refAreaRight: number) => void;
  zoomOut: () => void;
}): React.ReactElement => {
  const [selectedException, setSelectedException] =
    React.useState<ExceptionSummary | null>(null);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 0));
  const [hoveredComputation, setHoveredComputation] = useState<
    string | undefined
  >();

  const doComputationHover = (c?: ComputationSummary) => {
    if (c) {
      setHoveredComputation(c.identifier);

      setRefAreaLeft(c.start ? Math.floor(c.start * 1000) : 0);
      setRefAreaRight(c.stop ? Math.ceil(c.stop * 1000) : 0);
    } else {
      setHoveredComputation(undefined);
      setRefAreaLeft(0);
      setRefAreaRight(0);
    }
  };

  const doComputationClick = (c?: ComputationSummary) => {
    if (c) {
      setFocusedComputation(c.identifier);
      if (c.start && c.stop) {
        setTimeRange([Math.floor(c.start * 1000), Math.ceil(c.stop * 1000)]);
      }
    } else {
      zoomOut();
    }
  };

  const rowsPerPage = 20;

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleModalClose = useCallback(() => {
    setSelectedException(null);
  }, [setSelectedException]);

  const { data, isLoading } = useClusterAnalyticsSummary(
    clusterId,
    defaultTimeRange,
  );

  const computations = data?.computations || [];
  const filteredComputations = computations.filter((c) => {
    if (timeRange && c.start && c.stop) {
      const startTs = new Date(c.start * 1000).getTime();
      const stopTs = new Date(c.stop * 1000).getTime();

      // check if the computation start or stop overlaps with the selected time range
      if (isOverlapping([startTs, stopTs], timeRange)) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  });
  const hasZoomed = timeRange !== defaultTimeRange;
  const focusedComputations = filteredComputations.filter(
    (c) => !focusedComputation || c.identifier === focusedComputation,
  );

  return (
    <Box id="computation-listing">
      {computations.length === 0 && !isLoading ? (
        <Alert
          severity="info"
          sx={{
            m: 2,
          }}
        >
          No Dask computations detected on this cluster. See{" "}
          <a href="https://docs.coiled.io/user_guide/examples/index.html">
            Run your Dask computation
          </a>{" "}
          to get started.
        </Alert>
      ) : null}
      {computations.length > 0 ? (
        <CardContent>
          <ComputationTimeline
            computations={filteredComputations}
            timeRange={timeRange}
            focusedComputation={focusedComputation}
            hoveredComputation={hoveredComputation}
            doComputationClick={doComputationClick}
            doComputationHover={doComputationHover}
          />
        </CardContent>
      ) : null}
      {timeRange && hasZoomed && (
        <CardContent sx={{ pb: 0 }}>
          <span>
            Showing {focusedComputation ? "1 computation" : "computations"}{" "}
            between
          </span>{" "}
          <Chip label={timeRangeToString(timeRange)} onDelete={zoomOut} />
        </CardContent>
      )}
      <TableContainer>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableBody>
            {focusedComputations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((c) => {
                return (
                  <ComputationRow
                    key={c.identifier}
                    focused={focusedComputation === c.identifier}
                    computation={c}
                    setSelectedException={setSelectedException}
                    hoveredComputation={hoveredComputation}
                    doComputationHover={doComputationHover}
                    doComputationFocus={doComputationClick}
                  />
                );
              })}
          </TableBody>
        </Table>
        {!focusedComputation ? (
          <TablePagination
            component={"div"}
            count={focusedComputations.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        ) : null}
      </TableContainer>
      <ExceptionDialog
        open={!!selectedException}
        exception={selectedException}
        handleClose={handleModalClose}
      />
    </Box>
  );
};

const isOverlapping = (timeRange1: TimeRange, timeRange2: TimeRange) =>
  Math.max(timeRange1[0] as number, timeRange2[0] as number) <=
  Math.min(timeRange1[1] as number, timeRange2[1] as number);

const CodeFrameCode = (props: {
  code: StackCode;
  codeIdx: number;
  label: string | undefined;
}) => {
  const highlightLines = props.code.frames.map(
    (frame: StackSpecificFrame) => frame.relativeLine,
  );
  const showSpanName = props.label && props.label !== "default";
  return (
    <>
      <b>
        {showSpanName ? (
          <>
            Span <u>{props.label}</u>
          </>
        ) : props.codeIdx ? (
          <br />
        ) : (
          ""
        )}
        {props.code.filename &&
        !props.code.filename.includes("ipython") &&
        !props.code.filename.includes("ipykernel")
          ? showSpanName
            ? ` in ${props.code.filename}`
            : props.code.filename
          : ""}
      </b>
      <SyntaxHighlighter
        language="python"
        style={syntaxTheme}
        customStyle={syntaxHighlighterStyle}
        lineNumberStyle={{ display: "none" }}
        showLineNumbers
        wrapLines
        lineProps={(lineNumber: number) => {
          // subtract 1 since line numbers start at 1 rather than 0
          const style = highlightLines.includes(lineNumber - 1)
            ? {
                display: "block",
                fontWeight: "bold",
                backgroundColor:
                  highlightLines[highlightLines.length - 1] === lineNumber - 1
                    ? "#ddddff"
                    : "#ddd",
              }
            : { display: "block" };
          return { style };
        }}
      >
        {props.code.code}
      </SyntaxHighlighter>
    </>
  );
};

/*
A single table row that can expand to show more information about a computation.
*/
const ComputationRow = (props: {
  computation: ComputationSummary;
  focused: boolean;
  setSelectedException: (exception: ExceptionSummary | null) => void;
  hoveredComputation: string | undefined;
  doComputationHover: (c?: ComputationSummary) => void;
  doComputationFocus: (c: ComputationSummary) => void;
}) => {
  const { computation, hoveredComputation, doComputationHover } = props;

  const isHovered = hoveredComputation === computation.identifier;

  return (
    <TableRow>
      <TableCell style={{ verticalAlign: "top" }}>
        <Box
          onClick={() => props.doComputationFocus(computation)}
          onMouseEnter={() => doComputationHover(computation)}
          onMouseLeave={() => doComputationHover(undefined)}
          sx={{
            fontSize: theme.typography.body1.fontSize,
            p: 2,
            borderRadius: 1,
            cursor: "pointer",
            border: props.focused
              ? `1px solid ${darken(theme.palette.custom.blue.pale, 0.1)}`
              : isHovered
                ? `1px solid ${darken(theme.palette.custom.blue.pale, 0.1)}`
                : `1px solid transparent`,
            background:
              props.focused || isHovered
                ? darken(theme.palette.custom.blue.pale, 0.02)
                : theme.palette.custom.blue.pale,
            "&:hover": {
              border: `1px solid ${darken(
                theme.palette.custom.blue.pale,
                0.1,
              )}`,
              background: darken(theme.palette.custom.blue.pale, 0.02),
            },
          }}
          overflow={"auto"}
        >
          {computation.callstackDeduped.map((frame, index) => (
            <CodeFrameCode
              key={index}
              label={computation.label}
              code={frame}
              codeIdx={index}
            />
          ))}
        </Box>
        <Box sx={{ pl: 4 }}>
          {computation.exceptions.map((exception) => (
            <ExceptionAlert
              key={exception.exception}
              exception={exception}
              setSelectedException={props.setSelectedException}
            />
          ))}
        </Box>
      </TableCell>
      <TableCell width="340px" style={{ verticalAlign: "top" }}>
        <ComputationStatistics computation={computation} />
      </TableCell>
    </TableRow>
  );
};
