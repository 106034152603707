/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExceptionUpdate
 */
export interface ExceptionUpdate {
    /**
     * 
     * @type {string}
     * @memberof ExceptionUpdate
     */
    identifier: string;
    /**
     * 
     * @type {number}
     * @memberof ExceptionUpdate
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionUpdate
     */
    taskGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionUpdate
     */
    spanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionUpdate
     */
    exception?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionUpdate
     */
    traceback?: string;
}

/**
 * Check if a given object implements the ExceptionUpdate interface.
 */
export function instanceOfExceptionUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ExceptionUpdateFromJSON(json: any): ExceptionUpdate {
    return ExceptionUpdateFromJSONTyped(json, false);
}

export function ExceptionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExceptionUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'count': json['count'],
        'taskGroupName': !exists(json, 'task_group_name') ? undefined : json['task_group_name'],
        'spanId': !exists(json, 'span_id') ? undefined : json['span_id'],
        'exception': !exists(json, 'exception') ? undefined : json['exception'],
        'traceback': !exists(json, 'traceback') ? undefined : json['traceback'],
    };
}

export function ExceptionUpdateToJSON(value?: ExceptionUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'count': value.count,
        'task_group_name': value.taskGroupName,
        'span_id': value.spanId,
        'exception': value.exception,
        'traceback': value.traceback,
    };
}

