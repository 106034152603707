import React, { useContext } from "react";

import { Alert, CardContent, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AWSSettingsForm } from "./AWSSettingsForm";
import { GCPSettingsForm } from "./GCPSettingsForm";
import { Urls } from "../../../domain/urls";
import { BackendTypesEnum, WorkspaceSchema } from "../../../api-client";
import { UserContext } from "../../../crud/user/context";
import { userIsAdmin } from "../../../auth";

export const SettingsForm = ({
  workspace,
}: {
  workspace: WorkspaceSchema;
}): React.ReactElement => {
  const { memberships } = useContext(UserContext);
  const isAdmin = userIsAdmin(memberships, workspace.slug);
  return (
    <>
      {!isAdmin && (
        <Alert severity="error" sx={{ borderRadius: 0 }}>
          Editing has been disabled as only workspace admins can configure
          workspace settings. Find your admins on the{" "}
          <Link
            component={RouterLink}
            to={`/${Urls.Team}?account=${workspace.slug}`}
          >
            teams
          </Link>{" "}
          page.
        </Alert>
      )}
      <CardContent>
        <Typography>
          Manage workspace wide settings specific to your cloud provider.
        </Typography>
        {workspace.activeBackend === BackendTypesEnum.VmAws && (
          <AWSSettingsForm workspaceSlug={workspace.slug} />
        )}
        {workspace.activeBackend === BackendTypesEnum.VmGcp && (
          <GCPSettingsForm workspaceSlug={workspace.slug} />
        )}
      </CardContent>
    </>
  );
};
