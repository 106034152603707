import { ErrbackResult } from "../../../../javascriptSucksUtils";
import type { ErrorOption } from "react-hook-form/dist/types/errors";

/* eslint-disable @typescript-eslint/naming-convention */
type GCPServiceCreds = {
  // This is not exhaustive, documenting
  // the things we explicitly expect to be in
  // there for validation
  type: "service_account";
  project_id: string;
};
/* eslint-enable @typescript-eslint/naming-convention */

type ParseResult = ErrbackResult<GCPServiceCreds, ErrorOption>;

export const parseServiceCreds = async (file: File): Promise<ParseResult> => {
  // It is odd to have all of this logic outside of a component like this,
  // but I'm hoping it's a lesser evil. This handles reading the file, parsing
  // to a format we like, and validating. If we were to stay pure to the react-hook-form
  // way, we would have to juggle a lot more state variables to keep validation as
  // a separate step and hang on to the parsed value. Furthermore, we'd have to explicitly
  // handle the fileRead state in an async way. This way we can simply await one
  // function, and call setError() if we need to.
  let data: string;
  let serviceCreds: GCPServiceCreds;
  try {
    data = await file.text();
  } catch (err) {
    return {
      error: {
        type: "validate",
        message: "Unable to read credentials file" + String(err),
      },
      result: undefined,
    };
  }

  try {
    serviceCreds = JSON.parse(data);
  } catch (err) {
    return {
      error: {
        type: "validate",
        message: "Unable to parse JSON in credentials file" + String(err),
      },
      result: undefined,
    };
  }

  const credsType = serviceCreds.type;
  if (credsType !== "service_account") {
    return {
      error: {
        type: "validate",
        message:
          "Service account credentials file seems to be malformed: type is not 'service_account'",
      },
      result: undefined,
    };
  }
  if (!serviceCreds.project_id) {
    return {
      error: {
        type: "validate",
        message:
          "Service account credentials file seems to be malformed: no project_id",
      },
      result: undefined,
    };
  }

  return { error: undefined, result: serviceCreds };
};
