import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { AuthLayout } from "./AuthLayout";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validation";
import { requestPasswordReset } from "./auth";
import { Urls } from "../domain/urls";
import { Section } from "./CardSection";
import { Text, ExternalLink, BackToSignInButton, AuthForm } from "./components";
import { Button } from "@mui/material";

type Inputs = {
  email: string;
};

export const ForgotPassword = (): React.ReactElement => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async ({ email }: Inputs) => {
    setSubmitted(true);
    const result = await requestPasswordReset(email);
    if (result.type === "success") {
      setSubmitted(false);
      setSuccess(true);
    }
    if (result.type === "error") {
      setSubmitted(false);
      setError(result?.error);
    }
  };

  if (success) {
    return (
      <AuthLayout>
        <Section title="Reset Password">
          <Text>
            We have sent you an email with a link to reset your password.
          </Text>
          <Text>
            Please{" "}
            <ExternalLink
              target={"_blank"}
              rel="noopener noreferrer"
              href={"mailto:support@coiled.io"}
            >
              contact us
            </ExternalLink>{" "}
            if you do not receive it within a few minutes.
          </Text>
          <BackToSignInButton
            color="primary"
            variant="text"
            href={`/${Urls.Login}?open=true`}
          >
            Back to sign in
          </BackToSignInButton>
        </Section>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Section title="Reset Password">
        {error && (
          <Typography
            sx={{ marginTop: "16px" }}
            variant="body1"
            align="center"
            color="error"
          >
            {error}
          </Typography>
        )}
        <Text>
          Enter the email associated with your account and {"we'll"} send and
          email with instructions to reset your password.
        </Text>

        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("email", {
              required: "email is required",
              validate: (value) =>
                validateEmail(value) === true || "invalid email",
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            fullWidth
            variant="outlined"
            placeholder="email"
          />
          <Button variant="primary" type="submit">
            {submitted ? <CircularProgress size={24} /> : "submit"}
          </Button>
        </AuthForm>
      </Section>
    </AuthLayout>
  );
};
