import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { validatePassword } from "../utils/validation";
import { changePassword } from "./auth";
import { ActivationFailReasons } from "./types";
import { useLogoutMutation } from "../crud/auth/hooks";
import { useNavigate } from "react-router-dom";
import { Urls } from "../domain/urls";

const ContainerDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 1,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: "12px",
}));

type Inputs = {
  token: string;
  newPassword1: string;
  newPassword2: string;
};

type Props = {
  onSuccessCallBack: () => void;
};

export const ChangePassword = ({
  onSuccessCallBack,
}: Props): React.ReactElement => {
  const logoutMutation = useLogoutMutation();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [isSuccess, setSuccess] = React.useState<boolean>(false);

  const [nonFieldError, setNonFieldError] = React.useState<string>();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onBlur" });

  const sucessCloseModal = () => {
    setTimeout(() => onSuccessCallBack(), 2000);
  };

  // note that form fields are camelCase but API returns snake_case field errors
  // be careful not to confuse what is a Form error (camelCase) and
  // what is an API error (snake_case)
  const onSubmit = async ({ newPassword1, newPassword2 }: Inputs) => {
    setSubmitted(true);
    const result = await changePassword(newPassword1, newPassword2);
    if (result.type === "success") {
      sucessCloseModal();
      setSuccess(true);
      // backend logs you out on password change
      logoutMutation.mutate({});
      navigate(`/${Urls.Login}`);
    }
    if (result.type === "error") {
      setSubmitted(false);
      const responseErrs = result?.errors;
      // API returns snakecase field error
      if (responseErrs?.new_password1) {
        // form expects camel case errors set Here
        setError("newPassword1", {
          type: "manual",
          // access the snake_case field error here
          message: responseErrs.new_password1.join(", "),
        });
      }
      if (responseErrs?.new_password2) {
        setError("newPassword2", {
          type: "manual",
          message: responseErrs.new_password2.join(", "),
        });
      }
      if (responseErrs?.non_field_errors) {
        setNonFieldError(responseErrs.non_field_errors[0]);
      } else if (responseErrs?.unknownError) {
        setNonFieldError(responseErrs.unknownError);
      }
    }
  };
  if (isSuccess === true) {
    return (
      <ContainerDiv>
        <Heading>Success</Heading>

        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightLight,
            paddingTop: "8px",
            paddingBottom: "16px",
          })}
        >
          You have successfully changed your password.
        </Typography>
      </ContainerDiv>
    );
  }
  if (nonFieldError) {
    return (
      <ContainerDiv>
        <Heading>Reset Email</Heading>
        <Typography
          sx={{ marginTop: "16px" }}
          variant="body1"
          align="center"
          color="error"
        >
          {nonFieldError}
        </Typography>
      </ContainerDiv>
    );
  }

  return (
    <Card
      sx={{
        maxWidth: "500px",
      }}
    >
      <CardHeader title="Change Password" />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <TextField
              {...register("newPassword1", {
                required: "new password is required",
                validate: (value) =>
                  validatePassword(value) === true ||
                  ActivationFailReasons.PasswordRequirements,
              })}
              error={!!errors.newPassword1}
              helperText={
                errors.newPassword1 ? errors.newPassword1.message : ""
              }
              variant="outlined"
              name="newPassword1"
              placeholder="new password"
              type="password"
            />
            <TextField
              {...register("newPassword2", {
                required: "matching password is required",
                validate: (value) =>
                  value === getValues("newPassword1") || "passwords must match",
              })}
              error={!!errors.newPassword2}
              helperText={
                errors.newPassword2 ? errors.newPassword2.message : ""
              }
              variant="outlined"
              placeholder="confirm new password"
              type="password"
            />

            <Button type="submit">
              {submitted ? <CircularProgress size={24} /> : "submit"}
            </Button>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
