/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseInviteSchema } from './BaseInviteSchema';
import {
    BaseInviteSchemaFromJSON,
    BaseInviteSchemaFromJSONTyped,
    BaseInviteSchemaToJSON,
} from './BaseInviteSchema';
import type { OrganizationRoles } from './OrganizationRoles';
import {
    OrganizationRolesFromJSON,
    OrganizationRolesFromJSONTyped,
    OrganizationRolesToJSON,
} from './OrganizationRoles';
import type { WorkspaceInviteSchema } from './WorkspaceInviteSchema';
import {
    WorkspaceInviteSchemaFromJSON,
    WorkspaceInviteSchemaFromJSONTyped,
    WorkspaceInviteSchemaToJSON,
} from './WorkspaceInviteSchema';

/**
 * 
 * @export
 * @interface OrganizationInviteSchema
 */
export interface OrganizationInviteSchema {
    /**
     * 
     * @type {number}
     * @memberof OrganizationInviteSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationInviteSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationInviteSchema
     */
    updated: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationInviteSchema
     */
    expiration: Date;
    /**
     * 
     * @type {number}
     * @memberof OrganizationInviteSchema
     */
    organizationId: number;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof OrganizationInviteSchema
     */
    role: OrganizationRoles;
    /**
     * 
     * @type {BaseInviteSchema}
     * @memberof OrganizationInviteSchema
     */
    invite: BaseInviteSchema;
    /**
     * 
     * @type {Array<WorkspaceInviteSchema>}
     * @memberof OrganizationInviteSchema
     */
    workspaceInvites: Array<WorkspaceInviteSchema>;
}

/**
 * Check if a given object implements the OrganizationInviteSchema interface.
 */
export function instanceOfOrganizationInviteSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "expiration" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "invite" in value;
    isInstance = isInstance && "workspaceInvites" in value;

    return isInstance;
}

export function OrganizationInviteSchemaFromJSON(json: any): OrganizationInviteSchema {
    return OrganizationInviteSchemaFromJSONTyped(json, false);
}

export function OrganizationInviteSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInviteSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'expiration': (new Date(json['expiration'])),
        'organizationId': json['organization_id'],
        'role': OrganizationRolesFromJSON(json['role']),
        'invite': BaseInviteSchemaFromJSON(json['invite']),
        'workspaceInvites': ((json['workspace_invites'] as Array<any>).map(WorkspaceInviteSchemaFromJSON)),
    };
}

export function OrganizationInviteSchemaToJSON(value?: OrganizationInviteSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'expiration': (value.expiration.toISOString()),
        'organization_id': value.organizationId,
        'role': OrganizationRolesToJSON(value.role),
        'invite': BaseInviteSchemaToJSON(value.invite),
        'workspace_invites': ((value.workspaceInvites as Array<any>).map(WorkspaceInviteSchemaToJSON)),
    };
}

