import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  useDeleteGCPGlobalSetup,
  useFirewallTargetTags,
  useGCPGlobalSetup,
  useNetworks,
  useRequestGCPGlobalSetup,
} from "../../../crud/setup/hooks";
import { GCPGlobalInfraSchema } from "../../../crud/setup/types";

import { Delete } from "@mui/icons-material";
import { ExpandableCard } from "../../../shared-components/Cards";
import { LoadingButton } from "@mui/lab";
import { ComponentIcon } from "./StateIcon";
import { ConfirmationDialog } from "./AWSRegionalInfraForm";
import { HookSwitch } from "../../../shared-components/HookSwitch";
import { useOnboarding } from "./hooks";
import { useAnalytics } from "use-analytics";
import { BackendTypeServer } from "../../../domain/people";
import { useIsAccountAdmin } from "../../../crud/account/hooks";

const DEFAULT_VALUES: GCPGlobalInfraSchema = {
  id: 1,
  network: {
    id: 1,

    name: "",
    link: "",
  },
  managed: true,
  component: {
    coiledManaged: true,
    state: "",
    reason: "",
  },
};

type GlobalFormProps = {
  allowUnmanaged?: boolean;
  workspaceSlug: string;
};
export const GCPGlobalInfraForm = ({
  allowUnmanaged,
  workspaceSlug,
}: GlobalFormProps): React.ReactElement => {
  const {
    data: currentValues,
    isLoading,
    isSuccess,
  } = useGCPGlobalSetup(workspaceSlug);
  const isAdmin = useIsAccountAdmin(workspaceSlug);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const previousGlobalInfraState = useRef(currentValues?.component?.state);
  const { reset, handleSubmit, control, watch, register, setValue } =
    useForm<GCPGlobalInfraSchema>({
      defaultValues: DEFAULT_VALUES,
    });

  const requestSetup = useRequestGCPGlobalSetup(workspaceSlug);
  const delSetup = useDeleteGCPGlobalSetup(workspaceSlug);
  const { data: userNetworks } = useNetworks(workspaceSlug);
  const [editMode, setEditMode] = useState(true);
  const managed = watch("managed");
  const schedulerNetworkTags = watch("schedulerNetworkTags");
  const workerNetworkTags = watch("schedulerNetworkTags");
  const networkName = watch("network.name");
  const analytics = useAnalytics();
  const [onboarding] = useOnboarding();

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    } else reset(DEFAULT_VALUES);
    if (currentValues?.component?.state !== previousGlobalInfraState.current) {
      previousGlobalInfraState.current = currentValues?.component?.state;
      if (onboarding && currentValues?.component?.state === "created") {
        // they successfully created the global infra
        // close the form after a short delay
        setTimeout(() => setEditMode(false), 200);
        analytics.track("setup-global-infra-created", {
          provider: BackendTypeServer.GCP_HOSTED,
        });
      }
    }
  }, [currentValues, onboarding, reset, analytics]);

  const onSubmit: SubmitHandler<GCPGlobalInfraSchema> = (data, e) => {
    requestSetup.mutate(data, {
      onSuccess: () => {
        analytics.track("setup-global-infra-requested", {
          provider: BackendTypeServer.GCP_HOSTED,
          managed: data.managed,
        });
      },
      onError: () => {
        analytics.track("setup-global-infra-request-error", {
          provider: BackendTypeServer.GCP_HOSTED,
          managed: data.managed,
        });
      },
    });
    e?.preventDefault();
    e?.stopPropagation();
  };
  const network = watch("network");
  const { data: availableTags } = useFirewallTargetTags(
    workspaceSlug,
    network?.link,
  );

  useEffect(() => {
    if (managed && !currentValues) {
      setValue("network", undefined);
    }
  }, [managed, setValue, currentValues]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableCard
        loading={isLoading}
        title={
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              {isSuccess && (
                <ComponentIcon
                  coiledComponent={currentValues?.component}
                  confetti
                  animateIn
                />
              )}
              <span>Global Infrastructure</span>
            </Stack>
            {!isLoading && currentValues && (
              <IconButton
                sx={{ marginLeft: "auto" }}
                disabled={!isAdmin || delSetup.isLoading}
                onClick={(e) => {
                  setDeleteConfirmation(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Remove global infrastructure">
                  <Delete />
                </Tooltip>
              </IconButton>
            )}
          </Stack>
        }
        isOpen={editMode}
        handleClick={() => setEditMode(!editMode)}
        elevation={2}
      >
        <ConfirmationDialog
          title={"Delete global infrastructure?"}
          open={deleteConfirmation}
          onClose={(success) => {
            if (success) {
              delSetup.mutate({});
            }
            setDeleteConfirmation(false);
          }}
        >
          Are you sure you want to delete the global infrastructure? This will
          means Coiled to be unable to create new clusters.
        </ConfirmationDialog>
        {currentValues?.component?.state === "error" && (
          <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>
            <AlertTitle>Error </AlertTitle>
            {currentValues?.component?.reason}
          </Alert>
        )}
        <CardContent>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Stack direction="row" alignItems={"center"}>
              <Tooltip
                title={
                  !!currentValues
                    ? "This can only be toggled when creating a new region"
                    : allowUnmanaged
                      ? "Toggle between custom and managed regions"
                      : "To switch to unmanaged, first remove any managed regions"
                }
              >
                <div style={{ marginRight: "12px" }}>
                  <HookSwitch
                    control={control}
                    name="managed"
                    disabled={!isAdmin || !!currentValues || !allowUnmanaged}
                  />
                </div>
              </Tooltip>
              <Typography>Coiled Managed</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <ComponentIcon
                coiledComponent={currentValues?.network?.component}
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input hidden {...register("network.name")} />
                    <Autocomplete
                      disabled={!!currentValues || managed}
                      onChange={(event, item) => {
                        if (typeof item === "string") {
                          const existingNetwork = userNetworks?.find(
                            (n) => n.link === item,
                          );
                          onChange({
                            ...existingNetwork,
                            id: -1,
                          });
                        } else {
                          const existingNetwork = userNetworks?.find(
                            (n) => n.link === item?.link,
                          );
                          onChange({ ...existingNetwork, id: -1 });
                        }
                      }}
                      value={{ ...value, id: "1" }}
                      sx={{ minWidth: "400px" }}
                      freeSolo
                      autoComplete
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        } else {
                          return option.name || "";
                        }
                      }}
                      options={userNetworks || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ maxWidth: "500px" }}
                          value={value?.name || ""}
                          InputLabelProps={{ shrink: true }}
                          placeholder={
                            managed ? "Will be created" : "Network name"
                          }
                          label="Network Name"
                          variant="outlined"
                        />
                      )}
                    />
                  </>
                )}
                name="network"
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <ComponentIcon
                coiledComponent={currentValues?.dataset?.component}
              />
              <TextField
                label="Bigquery Logs Dataset"
                disabled
                size="small"
                placeholder="Will be created automatically"
                InputLabelProps={{ shrink: true }}
                value={currentValues?.dataset?.datasetRef || ""}
              />
            </Stack>
            {!managed && (
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Box sx={{ width: "24px", height: "24px" }} />

                <Controller
                  control={control}
                  name="schedulerNetworkTags"
                  rules={{ required: "Tag is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      onChange={(event, item) => {
                        if (item) {
                          onChange(item);
                        }
                      }}
                      value={value}
                      disabled={!!currentValues?.id}
                      sx={{ minWidth: "300px" }}
                      freeSolo
                      autoComplete
                      options={availableTags || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Network tags to apply to schedulers"
                          label="Scheduler Network Tags"
                          sx={{ maxWidth: "400px" }}
                          InputLabelProps={{ shrink: true }}
                          value={value || ""}
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            )}
            {!managed && (
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Box sx={{ width: "24px", height: "24px" }} />
                <Controller
                  control={control}
                  name="clusterNetworkTags"
                  rules={{ required: "Tag is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      onChange={(event, item) => {
                        if (item) {
                          onChange(item);
                        }
                      }}
                      value={value}
                      sx={{ minWidth: "300px" }}
                      freeSolo
                      disabled={!!currentValues?.id}
                      autoComplete
                      options={availableTags || []}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={value || ""}
                          variant="outlined"
                          size="small"
                          sx={{ maxWidth: "400px" }}
                          placeholder="Network tags to apply to the entire cluster"
                          label="Cluster Network Tags"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            )}
            {!isLoading && !currentValues && (
              <LoadingButton
                type="submit"
                sx={{ maxWidth: "100px" }}
                disabled={
                  !isAdmin &&
                  !managed &&
                  !(
                    !managed &&
                    networkName &&
                    schedulerNetworkTags?.length &&
                    workerNetworkTags?.length
                  )
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
                loading={requestSetup.isLoading}
              >
                Create
              </LoadingButton>
            )}
          </Stack>
        </CardContent>
      </ExpandableCard>
    </form>
  );
};
