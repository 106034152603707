/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuildDownloadLink
 */
export interface BuildDownloadLink {
    /**
     * 
     * @type {string}
     * @memberof BuildDownloadLink
     */
    state: BuildDownloadLinkStateEnum;
    /**
     * 
     * @type {string}
     * @memberof BuildDownloadLink
     */
    stateReason: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuildDownloadLink
     */
    downloadUrls?: Array<string>;
}


/**
 * @export
 */
export const BuildDownloadLinkStateEnum = {
    Building: 'building',
    Ready: 'ready',
    Error: 'error'
} as const;
export type BuildDownloadLinkStateEnum = typeof BuildDownloadLinkStateEnum[keyof typeof BuildDownloadLinkStateEnum];


/**
 * Check if a given object implements the BuildDownloadLink interface.
 */
export function instanceOfBuildDownloadLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "stateReason" in value;

    return isInstance;
}

export function BuildDownloadLinkFromJSON(json: any): BuildDownloadLink {
    return BuildDownloadLinkFromJSONTyped(json, false);
}

export function BuildDownloadLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildDownloadLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'stateReason': json['state_reason'],
        'downloadUrls': !exists(json, 'download_urls') ? undefined : json['download_urls'],
    };
}

export function BuildDownloadLinkToJSON(value?: BuildDownloadLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'state_reason': value.stateReason,
        'download_urls': value.downloadUrls,
    };
}

