import { useTheme, Button, Container, Stack } from "@mui/material";
import React from "react";
import { CoiledLogo } from "../icons/CoiledLogoWithSlogan";
import { Link, useLocation } from "react-router-dom";
import { Urls } from "../domain/urls";

type Props = {
  children: React.ReactNode;
};

export const AuthLayout = (props: Props): React.ReactElement => {
  const theme = useTheme();
  const { pathname } = useLocation();

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between">
        <Link to={Urls.Root} style={{ color: theme.palette.primary.dark }}>
          <CoiledLogo sx={{ width: "180px", height: "180px" }} />
        </Link>
        <Stack direction="row" alignItems="center" spacing={2}>
          {pathname !== "/signup" && (
            <Button component={Link} to={{ pathname: `/${Urls.SignUp}` }}>
              Sign Up
            </Button>
          )}
          {pathname !== "/login" && (
            <Button component={Link} to={{ pathname: `/${Urls.Login}` }}>
              Login
            </Button>
          )}
        </Stack>
      </Stack>
      {props.children}
    </Container>
  );
};
