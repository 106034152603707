import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { AliasView } from "./alias";
import { SoftwareAliasContextProvider as SoftwareAliasContextProvider } from "../../../crud/software/context";
import { useSoftwareAlias } from "../../../crud/software/hooks";
import { LoadingScreen } from "../../../shared-components/LoadingScreen";
import { SoftwareBuildView } from "./build";
import { useAccountDetails } from "../../../crud/account/hooks";
import { WorkspaceContextProvider } from "../../../utils/context";

const AliasScopedRoutes = (): React.ReactElement => {
  const aliasId = parseInt(useParams().aliasId as string, 10);
  const { data: alias, isSuccess } = useSoftwareAlias(aliasId);
  return (
    <Stack spacing={2} direction="column">
      {!isSuccess && <LoadingScreen />}
      {isSuccess && (
        <SoftwareAliasContextProvider alias={alias}>
          <Routes>
            <Route path="/" element={<AliasView />} />
            <Route path="/build/:buildId" element={<SoftwareBuildView />} />
          </Routes>
        </SoftwareAliasContextProvider>
      )}
    </Stack>
  );
};
export const SoftwareEnvironmentView = (): React.ReactElement => {
  const accountSlug = useParams().accountSlug as string;
  const { data: workspace, isSuccess: workspaceLoaded } = useAccountDetails({
    workspaceSlug: accountSlug,
  });
  return (
    <Stack spacing={2} direction="column">
      {!workspaceLoaded && <LoadingScreen />}
      {workspaceLoaded && (
        <WorkspaceContextProvider workspace={workspace}>
          <AliasScopedRoutes />
        </WorkspaceContextProvider>
      )}
    </Stack>
  );
};
