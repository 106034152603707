/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountMiniSchema } from './AccountMiniSchema';
import {
    AccountMiniSchemaFromJSON,
    AccountMiniSchemaFromJSONTyped,
    AccountMiniSchemaToJSON,
} from './AccountMiniSchema';
import type { OrganizationMiniSchema } from './OrganizationMiniSchema';
import {
    OrganizationMiniSchemaFromJSON,
    OrganizationMiniSchemaFromJSONTyped,
    OrganizationMiniSchemaToJSON,
} from './OrganizationMiniSchema';
import type { UserMiniSchema } from './UserMiniSchema';
import {
    UserMiniSchemaFromJSON,
    UserMiniSchemaFromJSONTyped,
    UserMiniSchemaToJSON,
} from './UserMiniSchema';

/**
 * Class for the received interaction
 * @export
 * @interface UserInteractionListSchema
 */
export interface UserInteractionListSchema {
    /**
     * 
     * @type {number}
     * @memberof UserInteractionListSchema
     */
    id: number;
    /**
     * 
     * @type {UserMiniSchema}
     * @memberof UserInteractionListSchema
     */
    user: UserMiniSchema;
    /**
     * 
     * @type {AccountMiniSchema}
     * @memberof UserInteractionListSchema
     */
    account?: AccountMiniSchema;
    /**
     * 
     * @type {OrganizationMiniSchema}
     * @memberof UserInteractionListSchema
     */
    organization?: OrganizationMiniSchema;
    /**
     * 
     * @type {Date}
     * @memberof UserInteractionListSchema
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionListSchema
     */
    action: string;
    /**
     * 
     * @type {number}
     * @memberof UserInteractionListSchema
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionListSchema
     */
    source: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInteractionListSchema
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionListSchema
     */
    errorMessage?: string;
    /**
     * 
     * @type {any}
     * @memberof UserInteractionListSchema
     */
    additionalData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionListSchema
     */
    additionalText?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionListSchema
     */
    coiledVersion?: string;
}

/**
 * Check if a given object implements the UserInteractionListSchema interface.
 */
export function instanceOfUserInteractionListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function UserInteractionListSchemaFromJSON(json: any): UserInteractionListSchema {
    return UserInteractionListSchemaFromJSONTyped(json, false);
}

export function UserInteractionListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInteractionListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserMiniSchemaFromJSON(json['user']),
        'account': !exists(json, 'account') ? undefined : AccountMiniSchemaFromJSON(json['account']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationMiniSchemaFromJSON(json['organization']),
        'timestamp': (new Date(json['timestamp'])),
        'action': json['action'],
        'version': json['version'],
        'source': json['source'],
        'success': json['success'],
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
        'additionalData': !exists(json, 'additional_data') ? undefined : json['additional_data'],
        'additionalText': !exists(json, 'additional_text') ? undefined : json['additional_text'],
        'coiledVersion': !exists(json, 'coiled_version') ? undefined : json['coiled_version'],
    };
}

export function UserInteractionListSchemaToJSON(value?: UserInteractionListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserMiniSchemaToJSON(value.user),
        'account': AccountMiniSchemaToJSON(value.account),
        'organization': OrganizationMiniSchemaToJSON(value.organization),
        'timestamp': (value.timestamp.toISOString()),
        'action': value.action,
        'version': value.version,
        'source': value.source,
        'success': value.success,
        'error_message': value.errorMessage,
        'additional_data': value.additionalData,
        'additional_text': value.additionalText,
        'coiled_version': value.coiledVersion,
    };
}

