import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { ReactElement, useCallback, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Alert, Box, Snackbar, Tooltip } from "@mui/material";
import Linkify from "linkify-react";
import { useToken, useWorkspaceContextSlug } from "../../../utils/hooks";
import { fetchLogs } from "./crud";
import { stateStyles, stateToClassName } from "./stateStyles";
import { WorkerFrontendSchema } from "../../../api-client";

const SimpleToolBar = (): ReactElement => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);
const SimplerToolBar = (): ReactElement => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);

const downloadWorkerLogs = async (
  instanceName: string,
  accountSlug: string,
  token: string,
  handleLogDownloadError: (instanceName: string) => void,
) => {
  const logsResponse = await fetchLogs(instanceName, accountSlug, token);
  if (logsResponse.type === "good") {
    const logText = logsResponse.logs.map((item) => item.message).join("\n");
    const link = document.createElement("a");
    link.href = "data:text/plain," + encodeURIComponent(logText);
    link.download = `${instanceName}.txt`;
    link.click();
  } else {
    handleLogDownloadError(instanceName);
  }
};

type ProcessCardProps = {
  title: string;
  processes: WorkerFrontendSchema[];
  loading: boolean;
};
export const ProcessDataGrid = ({
  title,
  processes,
  loading,
}: ProcessCardProps): React.ReactElement => {
  const account = useWorkspaceContextSlug();
  const token = useToken();
  const [logError, setLogError] = useState<string | undefined>(undefined);
  const [errorOpen, setErrorOpen] = useState(false);
  const handleLogDownloadError = (instanceName: string) => {
    setLogError(`Could not download logs for ${instanceName}`);
    setErrorOpen(true);
  };

  const downloadLogs = useCallback(
    (instanceName: string | undefined) => {
      return instanceName
        ? () =>
            downloadWorkerLogs(
              instanceName,
              account,
              token,
              handleLogDownloadError,
            )
        : () => undefined;
    },
    [account, token],
  );

  const instanceColumns = React.useMemo<GridColDef<WorkerFrontendSchema>[]>(
    () => [
      { field: "name", headerName: "Name", flex: 2 },
      {
        field: "privateIP",
        headerName: "Internal IP",
        valueGetter: (params) => params.row.instance?.privateIpAddress || "",
        flex: 1,
      },
      {
        field: "publicIP",
        headerName: "External IP",
        valueGetter: (params) => params.row.instance?.publicIpAddress || "",
        flex: 1,
      },
      {
        field: "State",
        headerName: "Dask",
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                  <Linkify as="span">{params.row.currentState.reason}</Linkify>
                </Box>
              }
              key={params.row.id + "daskState"}
            >
              <span>{params.row.currentState.state}</span>
            </Tooltip>
          );
        },
        flex: 0.5,
      },
      {
        field: "Instance",
        headerName: "Instance",
        renderCell: (params) => {
          const s = (params.row.instance?.retried || 0) > 1 ? "s" : "";
          const retry = params.row.instance?.retryReason
            ? `. Retried ${params.row.instance?.retried} time${s} - ${params.row.instance?.retryReason}`
            : "";
          const instReason =
            (params.row.instance?.currentState.reason || "") + retry;
          return (
            <Tooltip
              title={
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                  <Linkify as="span">{instReason}</Linkify>
                </Box>
              }
              key={params.row.id + "instanceState"}
            >
              <span>{params.row.instance?.currentState.state}</span>
            </Tooltip>
          );
        },
        flex: 0.5,
      },
      {
        field: "InstanceID",
        headerName: "Instance ID",
        valueGetter: (params) => params.row.instance?.backendId,
        flex: 1,
      },
      {
        field: "Instance Type",
        headerName: "Instance Type",
        valueGetter: (params) => params.row.instance?.instanceTypeId,
        flex: 0.5,
      },
      {
        field: "Spot",
        headerName: "Lifecycle",
        valueGetter: (params) =>
          params.row.instance?.spot ? "Spot" : "On-Demand",
        flex: 0.5,
      },
      {
        field: "zone",
        headerName: "Zone",
        valueGetter: (params) => params.row.instance?.zoneName,
        flex: 0.5,
      },
      {
        field: "actions",
        headerName: "Logs",
        type: "actions",
        getActions: (params) => {
          return params.row.instance
            ? [
                <GridActionsCellItem
                  key={params.row.name}
                  icon={
                    <DownloadIcon
                      sx={(theme) => ({ color: theme.palette.primary.light })}
                    />
                  }
                  onClick={downloadLogs(params.row.instance?.name)}
                  label="Download Logs"
                />,
              ]
            : [];
        },
        width: 100,
      },
    ],
    [downloadLogs],
  );

  return (
    <>
      <Snackbar open={errorOpen} autoHideDuration={6000}>
        <Alert severity="error">{logError}</Alert>
      </Snackbar>
      <DataGridPro
        // slotProps={{ baseButton: { variant: "secondary", size: "small" } }}
        slots={{
          toolbar: processes.length === 1 ? SimplerToolBar : SimpleToolBar,
        }}
        sx={(theme) => ({
          height: processes.length >= 25 ? "1000px" : "100%",
          "& .MuiDataGrid-cell": {
            fontFamily: "Roboto Mono, monospace",
          },
          "& .MuiDataGrid-row": {
            background: theme.palette.text.primary,
            "&:hover": {
              background: theme.palette.text.primary,
            },
            ...stateStyles,
          },
        })}
        getRowClassName={(params) => {
          const prefix = params.row.currentState.state.includes("scheduler")
            ? "scheduler "
            : "worker ";
          return prefix + stateToClassName(params.row.currentState.state);
        }}
        disableRowSelectionOnClick
        autoHeight={processes.length < 25}
        getRowHeight={() => "auto"}
        loading={loading}
        columns={instanceColumns}
        rows={processes}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: false,
              publicIP: false,
              InstanceID: false,
              zone: false,
            },
          },
        }}
      />
    </>
  );
};
