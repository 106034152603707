/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventSchema } from './EventSchema';
import {
    EventSchemaFromJSON,
    EventSchemaFromJSONTyped,
    EventSchemaToJSON,
} from './EventSchema';

/**
 * 
 * @export
 * @interface EventsSchema
 */
export interface EventsSchema {
    /**
     * 
     * @type {number}
     * @memberof EventsSchema
     */
    ident: number;
    /**
     * 
     * @type {Array<EventSchema>}
     * @memberof EventsSchema
     */
    events: Array<EventSchema>;
}

/**
 * Check if a given object implements the EventsSchema interface.
 */
export function instanceOfEventsSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ident" in value;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function EventsSchemaFromJSON(json: any): EventsSchema {
    return EventsSchemaFromJSONTyped(json, false);
}

export function EventsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ident': json['ident'],
        'events': ((json['events'] as Array<any>).map(EventSchemaFromJSON)),
    };
}

export function EventsSchemaToJSON(value?: EventsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ident': value.ident,
        'events': ((value.events as Array<any>).map(EventSchemaToJSON)),
    };
}

