/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';
import type { InstanceProfileSchema } from './InstanceProfileSchema';
import {
    InstanceProfileSchemaFromJSON,
    InstanceProfileSchemaFromJSONTyped,
    InstanceProfileSchemaToJSON,
} from './InstanceProfileSchema';
import type { PolicySchema } from './PolicySchema';
import {
    PolicySchemaFromJSON,
    PolicySchemaFromJSONTyped,
    PolicySchemaToJSON,
} from './PolicySchema';
import type { RoleSchema } from './RoleSchema';
import {
    RoleSchemaFromJSON,
    RoleSchemaFromJSONTyped,
    RoleSchemaToJSON,
} from './RoleSchema';

/**
 * 
 * @export
 * @interface AWSGlobalInfraSchema
 */
export interface AWSGlobalInfraSchema {
    /**
     * 
     * @type {number}
     * @memberof AWSGlobalInfraSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof AWSGlobalInfraSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof AWSGlobalInfraSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof AWSGlobalInfraSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {InstanceProfileSchema}
     * @memberof AWSGlobalInfraSchema
     */
    instanceProfile?: InstanceProfileSchema;
    /**
     * 
     * @type {RoleSchema}
     * @memberof AWSGlobalInfraSchema
     */
    role?: RoleSchema;
    /**
     * 
     * @type {PolicySchema}
     * @memberof AWSGlobalInfraSchema
     */
    policy?: PolicySchema;
}

/**
 * Check if a given object implements the AWSGlobalInfraSchema interface.
 */
export function instanceOfAWSGlobalInfraSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function AWSGlobalInfraSchemaFromJSON(json: any): AWSGlobalInfraSchema {
    return AWSGlobalInfraSchemaFromJSONTyped(json, false);
}

export function AWSGlobalInfraSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSGlobalInfraSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'instanceProfile': !exists(json, 'instance_profile') ? undefined : InstanceProfileSchemaFromJSON(json['instance_profile']),
        'role': !exists(json, 'role') ? undefined : RoleSchemaFromJSON(json['role']),
        'policy': !exists(json, 'policy') ? undefined : PolicySchemaFromJSON(json['policy']),
    };
}

export function AWSGlobalInfraSchemaToJSON(value?: AWSGlobalInfraSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'instance_profile': InstanceProfileSchemaToJSON(value.instanceProfile),
        'role': RoleSchemaToJSON(value.role),
        'policy': PolicySchemaToJSON(value.policy),
    };
}

