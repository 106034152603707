import { Route, Routes, useParams } from "react-router-dom";
import React from "react";
import { AddOrganizationMemberForm } from "./OrganizationAddMemberForm";
import { TeamView } from "./Team";
import { CreateWorkspaceForm } from "./CreateWorkspaceForm";
import { CreateOrganizationForm } from "./CreateOrganizationForm";
import { OrganizationEditMemberform } from "./OrganizationEditMemberForm";

const AddMember = (): React.ReactElement => {
  const organizationId = useParams().organizationId;
  if (!organizationId) {
    throw new Error("Organization ID is required");
  }
  return <AddOrganizationMemberForm organizationId={Number(organizationId)} />;
};

const EditMember = (): React.ReactElement => {
  const organizationId = useParams().organizationId;
  const memberId = useParams().memberId;
  if (!organizationId) {
    throw new Error("Organization ID is required");
  }
  if (!memberId) {
    throw new Error("Member ID is required");
  }
  return (
    <OrganizationEditMemberform
      organizationId={Number(organizationId)}
      membershipId={Number(memberId)}
      type="Membership"
    />
  );
};

const EditInvite = (): React.ReactElement => {
  const organizationId = useParams().organizationId;
  const inviteId = useParams().inviteId;
  if (!organizationId) {
    throw new Error("Organization ID is required");
  }
  if (!inviteId) {
    throw new Error("Invite ID is required");
  }
  return (
    <OrganizationEditMemberform
      organizationId={Number(organizationId)}
      membershipId={Number(inviteId)}
      type="Invite"
    />
  );
};
const CreateWorkspace = (): React.ReactElement => {
  const organizationId = useParams().organizationId;
  if (!organizationId) {
    throw new Error("Organization ID is required");
  }
  return <CreateWorkspaceForm organizationId={Number(organizationId)} />;
};

export const Team = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<TeamView />} />
      <Route path="/:organizationId/add-member" element={<AddMember />} />
      <Route
        path="/:organizationId/create-workspace"
        element={<CreateWorkspace />}
      />
      <Route path="/create-organization" element={<CreateOrganizationForm />} />
      <Route
        path="/:organizationId/edit-member/:memberId"
        element={<EditMember />}
      />
      <Route
        path="/:organizationId/edit-invite/:inviteId"
        element={<EditInvite />}
      />
    </Routes>
  );
};
