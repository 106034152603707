import React from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxTheme from "../../../shared-components/syntaxTheme";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { EyeIcon } from "../../../icons/EyeIcon";

const TABLE_HEADERS = ["Exception", "Count", ""];

export type Exception = {
  task_group: string;
  code?: string;
  exception: string;
  traceback: string;
  count: number;
};
/*
A table listing exceptions for a task group. The table has an "eyeball"
icon which, when clicked, opens a modal showing the full traceback for
the exception.
*/
export const ExceptionListing = (props: {
  exceptions: Exception[];
}): React.ReactElement => {
  const { exceptions } = props;

  const [page, setPage] = React.useState<number>(0);
  const rowsPerPage = 10;
  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  // State for whether the exception modal is open, and which exception is
  // currently selected. We could model these with a single type like
  // { open, exception }, since we shouldn't ever have a closed modal with
  // a selected exception, but don't bother for the time being.
  const [open, setOpen] = React.useState<boolean>(false);
  const [exception, setException] = React.useState<Exception | null>(null);

  const handleViewException = (e: Exception) => {
    setException(e);
    setOpen(true);
  };
  const handleModalClose = () => {
    setException(null);
    setOpen(true);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {exceptions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((e, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell
                      size={"small"}
                      onClick={handleViewException.bind(null, e)}
                    >
                      <SyntaxHighlighter
                        language="python"
                        // Can't use a MUI style here for some reason.
                        customStyle={{
                          maxWidth: "420px",
                          overflow: "auto",
                        }}
                        style={syntaxTheme}
                      >
                        {e.exception}
                      </SyntaxHighlighter>
                    </TableCell>

                    <TableCell size={"small"}>{e.count}</TableCell>

                    <TableCell size={"small"}>
                      <Tooltip title="View Exception Traceback">
                        <IconButton
                          onClick={handleViewException.bind(null, e)}
                          size="large"
                        >
                          <EyeIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component={"div"}
          count={exceptions.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
      <ViewExceptionDialog
        open={open}
        exception={exception}
        handleClose={handleModalClose}
      />
    </div>
  );
};

/*
Simple modal for showing an exception traceback.
*/
const ViewExceptionDialog = (props: {
  exception: Exception | null;
  open: boolean;
  handleClose: () => void;
}): React.ReactElement | null => {
  const { exception, open, handleClose } = props;
  if (!exception) {
    return null;
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <div
        style={{
          minHeight: "300px",
          minWidth: "300px",
        }}
      >
        <DialogTitle>
          Exception while computing {exception.task_group}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: "1rem",
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Code</Typography>
          <SyntaxHighlighter language="python" style={syntaxTheme}>
            {exception.code}
          </SyntaxHighlighter>
          <Typography variant="body1">Traceback</Typography>
          <SyntaxHighlighter language="python" style={syntaxTheme}>
            {`${exception.traceback}\n${exception.exception}`}
          </SyntaxHighlighter>
        </DialogContent>
      </div>
    </Dialog>
  );
};
