import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SelectAutocomplete } from "./SelectAutocomplete";
import { useValidScopes } from "../crud/user/hooks";
import { AccountScopeSchema } from "../api-client";
import { useScopedContext } from "../shared-components/ScopeSelector";
import { Box, Skeleton } from "@mui/material";
import { useWorkspaces } from "../crud/workspaces/hooks";

type WorkspaceSelectorProps = {
  onChange: (account: AccountScopeSchema | undefined) => void;
};

type WorkspaceInnerProps = WorkspaceSelectorProps & {
  defaultWorkspace?: AccountScopeSchema;
};

const WorkspaceSelectorInner = ({
  onChange,
  defaultWorkspace,
}: WorkspaceInnerProps): React.ReactElement => {
  const [, setGlobalScope] = useScopedContext();
  const [workspaceFilter, setWorkspaceFilter] = useState<string>("");
  const { data: scopes, isLoading } = useValidScopes({
    account: workspaceFilter,
  });
  const workspaces = useMemo(() => scopes?.accounts || [], [scopes]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    AccountScopeSchema | undefined
  >(defaultWorkspace);

  // hacky way to get full information about the workspace if all we have is name (from URL query params)
  const { data: matchScopes } = useValidScopes({
    account: selectedWorkspace?.name || "NOTHING-TO-MATCH",
  });
  const matchWorkspaces = useMemo(
    () => matchScopes?.accounts || [],
    [matchScopes],
  );

  const orgRef = useRef<AccountScopeSchema | undefined>(undefined);
  const _onChange = useCallback(
    (e: AccountScopeSchema | undefined) => {
      if (orgRef.current !== e) {
        orgRef.current = e;
        onChange(e);
      }
    },
    [onChange],
  );
  useEffect(() => {
    if (!selectedWorkspace && workspaces && workspaces.length === 1) {
      setSelectedWorkspace(workspaces[0]);
    } else if (
      selectedWorkspace?.name &&
      !selectedWorkspace?.id &&
      matchWorkspaces
    ) {
      const match = matchWorkspaces.filter(
        (workspace) => workspace.name === selectedWorkspace.name,
      );
      if (match.length === 1) {
        setSelectedWorkspace(match[0]);
      }
    }
  }, [workspaces, selectedWorkspace, setSelectedWorkspace, matchWorkspaces]);
  useEffect(() => {
    _onChange(selectedWorkspace);
  }, [selectedWorkspace, _onChange]);
  return (
    <SelectAutocomplete
      isLoading={isLoading}
      placeHolder="Search for a workspace."
      options={scopes?.accounts || []}
      onChange={(e) => {
        setGlobalScope(e);
        setSelectedWorkspace(e);
        onChange(e);
      }}
      filterValueChanged={(e) => setWorkspaceFilter(e)}
      value={selectedWorkspace}
      renderOption={(option) => (
        <span>
          Workspace:{" "}
          <Box
            component="span"
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            {option.name}
          </Box>
        </span>
      )}
    />
  );
};

export const WorkspaceSelector = ({
  onChange,
}: WorkspaceSelectorProps): React.ReactElement => {
  const [globalScope] = useScopedContext();
  const { data: workspaces, isSuccess } = useWorkspaces({});
  return (
    <>
      {globalScope.type === "account" && !isSuccess && <Skeleton />}
      {globalScope.type === "account" && isSuccess && (
        <WorkspaceSelectorInner
          onChange={onChange}
          defaultWorkspace={globalScope}
        />
      )}
      {globalScope.type !== "account" && (
        <WorkspaceSelectorInner
          onChange={onChange}
          defaultWorkspace={
            workspaces?.length === 1 ? workspaces[0] : undefined
          }
        />
      )}
    </>
  );
};
