/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLimitsSchemaStaff
 */
export interface UpdateLimitsSchemaStaff {
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchemaStaff
     */
    coreLimitMembers?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchemaStaff
     */
    coreLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchemaStaff
     */
    gpusLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchemaStaff
     */
    memberLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchemaStaff
     */
    nodeLimit?: number;
}

/**
 * Check if a given object implements the UpdateLimitsSchemaStaff interface.
 */
export function instanceOfUpdateLimitsSchemaStaff(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLimitsSchemaStaffFromJSON(json: any): UpdateLimitsSchemaStaff {
    return UpdateLimitsSchemaStaffFromJSONTyped(json, false);
}

export function UpdateLimitsSchemaStaffFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLimitsSchemaStaff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coreLimitMembers': !exists(json, 'core_limit_members') ? undefined : json['core_limit_members'],
        'coreLimit': !exists(json, 'core_limit') ? undefined : json['core_limit'],
        'gpusLimit': !exists(json, 'gpus_limit') ? undefined : json['gpus_limit'],
        'memberLimit': !exists(json, 'member_limit') ? undefined : json['member_limit'],
        'nodeLimit': !exists(json, 'node_limit') ? undefined : json['node_limit'],
    };
}

export function UpdateLimitsSchemaStaffToJSON(value?: UpdateLimitsSchemaStaff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'core_limit_members': value.coreLimitMembers,
        'core_limit': value.coreLimit,
        'gpus_limit': value.gpusLimit,
        'member_limit': value.memberLimit,
        'node_limit': value.nodeLimit,
    };
}

