/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcessStateEnum } from './ProcessStateEnum';
import {
    ProcessStateEnumFromJSON,
    ProcessStateEnumFromJSONTyped,
    ProcessStateEnumToJSON,
} from './ProcessStateEnum';

/**
 * 
 * @export
 * @interface ProcessStateSchema
 */
export interface ProcessStateSchema {
    /**
     * 
     * @type {number}
     * @memberof ProcessStateSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ProcessStateSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProcessStateSchema
     */
    updated: Date;
    /**
     * 
     * @type {ProcessStateEnum}
     * @memberof ProcessStateSchema
     */
    state: ProcessStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessStateSchema
     */
    reason: string;
}

/**
 * Check if a given object implements the ProcessStateSchema interface.
 */
export function instanceOfProcessStateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ProcessStateSchemaFromJSON(json: any): ProcessStateSchema {
    return ProcessStateSchemaFromJSONTyped(json, false);
}

export function ProcessStateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessStateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'state': ProcessStateEnumFromJSON(json['state']),
        'reason': json['reason'],
    };
}

export function ProcessStateSchemaToJSON(value?: ProcessStateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'state': ProcessStateEnumToJSON(value.state),
        'reason': value.reason,
    };
}

