import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../analytics";

export type BreadCrumbPart = {
  link?: string;
  // TODO: Rename 'text' to something to reflect that it can also be a component
  text: string | React.ReactElement;
  onClick?: () => void;
};
type PageContextInterface = {
  title: string;
  breadCrumbs: BreadCrumbPart[];
  docsUrl?: string;
  setTitle: (t: string) => void;
  setDocsUrl: (t: string | undefined) => void;
  setBreadCrumbs: (t: BreadCrumbPart[]) => void;
};

export const PageContext = createContext<PageContextInterface>({
  title: "Coiled",
  breadCrumbs: [{ text: "Coiled", link: "/" }],
  docsUrl: "",
  setTitle: () => {},
  setDocsUrl: () => {},
  setBreadCrumbs: () => {},
});

type PageProviderProps = {
  children?: ReactNode;
};

export const PageProvider = ({
  children,
}: PageProviderProps): React.ReactElement => {
  // This component exists to organize title changes
  // before the analytics page view is reported, just hooking
  // location and submitting page views when it changes is inadequate
  const { pathname } = useLocation();
  const [title, setTitle] = useState("Coiled");
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbPart[]>([]);
  const [docsUrl, setDocsUrl] = useState<string | undefined>("");
  const [pathCache, setPathCache] = useState<string | undefined>(undefined);
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    if (pathname !== pathCache) {
      trackPageView(pathname);
      setPathCache(pathname);
    }
  }, [pathCache, pathname, setPathCache]);
  return (
    <PageContext.Provider
      value={{
        title,
        setTitle,
        docsUrl,
        setDocsUrl,
        breadCrumbs,
        setBreadCrumbs,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
type PageProps = {
  title: string;
  breadCrumbs: BreadCrumbPart[];
  docsUrl?: string;
  children?: React.ReactNode;
};

export const Page = ({
  children,
  title,
  breadCrumbs,
  docsUrl,
}: PageProps): React.ReactElement => {
  const { setBreadCrumbs, setTitle, setDocsUrl } = useContext(PageContext);
  useEffect(() => {
    setBreadCrumbs(breadCrumbs);
    setTitle(title);
    setDocsUrl(docsUrl);
  }, [title, breadCrumbs, setBreadCrumbs, setTitle, setDocsUrl, docsUrl]);
  return <>{children}</>;
};
