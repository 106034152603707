import { KeyboardReturn, Search } from "@mui/icons-material";
import {
  TextField,
  Chip,
  Stack,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  IconButton,
  Box,
  alpha,
  InputAdornment,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryParam, withDefault } from "use-query-params";
import { makeObjectArrayParam } from "../../utils/makeObjectArrayParam";

export type KeyValue = {
  key: string;
  value: string;
};

const InteractionParams = makeObjectArrayParam<KeyValue[]>();

type CustomAutoCompleteProps = {
  fetchKeys: (key: string) => Promise<string[]>;
  fetchValues: (key: string, startsWith: string) => Promise<string[]>;
  keyValuesChanged: (keyValues: KeyValue[]) => void;
  keyValues?: KeyValue[];
  defaultFilters?: KeyValue[];
  disabledKeyValues?: KeyValue[];
};

type KeyValueState = {
  formInput: string;
  highlightedOptionIndex: number | null;
  suggestionsOpen: boolean;
};

export const KeyValueAutocomplete = ({
  fetchKeys,
  keyValues: controlledKeyValues,
  fetchValues,
  keyValuesChanged,
  defaultFilters,
  disabledKeyValues,
}: CustomAutoCompleteProps): React.ReactElement => {
  const lastInformedChange = React.useRef<KeyValue[] | undefined>(undefined);
  const popperAnchor = React.useRef<HTMLDivElement | null>(null);
  const paramDef = useMemo(
    () => withDefault(InteractionParams, defaultFilters || []),
    [defaultFilters],
  );
  const [_keyValues, _setKeyValues] = useQueryParam("filters", paramDef);
  const keyValues = controlledKeyValues || _keyValues;
  const setKeyValues = useCallback(
    (newKeyValues: KeyValue[]) => {
      keyValuesChanged(newKeyValues);
      _setKeyValues(newKeyValues);
      lastInformedChange.current = newKeyValues;
    },
    [_setKeyValues, keyValuesChanged],
  );
  useEffect(() => {
    // Ensure that the parent component has the current state of things
    // We use the lastInformChange ref to track what we last told the parent
    // Mostly useful to notify the parent that we loaded filters from the queryparam ect
    if (lastInformedChange.current !== keyValues) {
      lastInformedChange.current = keyValues;
      keyValuesChanged(keyValues);
    }
  }, [lastInformedChange, keyValuesChanged, keyValues]);
  const [{ formInput, highlightedOptionIndex, suggestionsOpen }, setState] =
    React.useState<KeyValueState>({
      formInput: "",
      highlightedOptionIndex: null,
      suggestionsOpen: false,
    });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inProgressKey, inProgressValue = ""] = formInput.split(":");
  const { data: keys } = useQuery(
    ["keys", inProgressKey],
    () => fetchKeys(inProgressKey),
    {
      keepPreviousData: true,
    },
  );
  const keyComplete = useMemo(() => {
    if (inProgressValue || formInput.endsWith(":")) {
      return true;
    }
    return false;
  }, [inProgressValue, formInput]);

  const keyValid = useMemo(() => {
    if (keyComplete && keys?.includes(inProgressKey)) {
      return true;
    }
    if (
      inProgressKey &&
      keys &&
      keys?.filter((k) => k.startsWith(inProgressKey)).length === 0
    ) {
      return false;
    }
    return true;
  }, [inProgressKey, keys, keyComplete]);

  const { data: values, isLoading: valuesLoading } = useQuery(
    ["values", inProgressKey, inProgressValue],
    () => fetchValues(inProgressKey, inProgressValue as string),
    {
      enabled: keyComplete && keyValid,
      keepPreviousData: true,
    },
  );
  const options = useMemo(
    () => (keyComplete && values ? values : keys || []),
    [values, keys, keyComplete],
  );

  const reset = () => {
    setState({
      formInput: "",
      highlightedOptionIndex: null,
      suggestionsOpen: false,
    });
  };

  useEffect(() => {
    if (inProgressKey)
      setState((prev) => ({
        ...prev,
        suggestionsOpen: true,
      }));
  }, [inProgressKey]);

  const handleNewInputValue = (newInputValue: string) => {
    setState((prev) => ({
      ...prev,
      formInput: newInputValue,
      highlightedOptionIndex: null,
    }));
  };
  const handleOptionSelected = (option: string, close: boolean = false) => {
    if (keyComplete && keyValid) {
      setKeyValues([
        ...keyValues.filter(
          (kv) => kv.key !== inProgressKey && kv.value !== option,
        ),
        { key: inProgressKey, value: option },
      ]);
      setState((prev) => ({
        ...prev,
        formInput: "",
        highlightedOptionIndex: null,
        suggestionsOpen: close ? false : true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        formInput: `${option}:`,
      }));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "Tab":
      case "Enter":
        event.preventDefault();
        event.stopPropagation();
        if (highlightedOptionIndex !== null) {
          let finalize = true;
          if (event.key === "Tab") finalize = false;
          const option = options[highlightedOptionIndex];
          if (finalize) {
            handleOptionSelected(option, finalize);
          } else {
            handleNewInputValue(
              keyComplete ? `${inProgressKey}:${option}` : option,
            );
          }
        } else {
          if (keyComplete && keyValid) {
            setKeyValues([
              ...keyValues,
              { key: inProgressKey, value: inProgressValue as string },
            ]);
            reset();
          }
        }
        break;
      case "ArrowDown":
        event.preventDefault();
        event.stopPropagation();
        // loop around
        setState((prev) => ({
          ...prev,
          highlightedOptionIndex:
            prev.highlightedOptionIndex !== null
              ? (prev.highlightedOptionIndex + 1) % options.length
              : 0,
        }));
        break;
      case "ArrowUp":
        event.preventDefault();
        event.stopPropagation();
        // loop around
        setState((prev) => ({
          ...prev,
          highlightedOptionIndex:
            prev.highlightedOptionIndex !== null
              ? (prev.highlightedOptionIndex - 1 + options.length) %
                options.length
              : options.length - 1,
        }));
        break;
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        reset();
        break;
      case "Backspace":
        if (formInput === "") {
          event.preventDefault();
          event.stopPropagation();
          setKeyValues(keyValues.slice(0, keyValues.length - 1));
        }
        break;
      default:
        break;
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        inputRef.current?.focus();
        inputRef.current?.select();
      }}
    >
      <TextField
        size="small"
        inputRef={inputRef}
        onClick={(event) => {
          setState((prev) => ({
            ...prev,
            suggestionsOpen: true,
          }));
        }}
        error={!keyValid}
        onKeyDown={handleKeyDown}
        onBlur={(e) => {
          setState((prev) => ({
            ...prev,
            suggestionsOpen: false,
          }));
        }}
        onFocus={(e) => {
          setState((prev) => ({
            ...prev,
            suggestionsOpen: true,
          }));
        }}
        fullWidth
        InputProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.paper,
            // matching mui default autocomplete padding
            paddingLeft: "6px",
          },
          ref: popperAnchor,
          endAdornment: suggestionsOpen ? <KeyboardReturn /> : undefined,
          startAdornment: (
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
              {disabledKeyValues?.map(({ key, value }) => (
                <Chip
                  disabled
                  sx={{
                    borderRadius: 1,
                  }}
                  variant="outlined"
                  size="small"
                  key={key}
                  label={`${key}:${value}`}
                />
              ))}
              {keyValues.map(({ key, value }) => (
                <Chip
                  size="small"
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: (theme) => theme.palette.action.selected,
                  }}
                  key={key}
                  label={`${key}:${value}`}
                  onDelete={() => {
                    setKeyValues(keyValues.filter((kv) => kv.key !== key));
                  }}
                />
              ))}
              <span />
            </Stack>
          ),
        }}
        value={formInput}
        onChange={(event) => {
          handleNewInputValue(event.target.value);
        }}
      />
      <Popper
        open={suggestionsOpen}
        anchorEl={popperAnchor.current}
        id={suggestionsOpen ? "popper" : undefined}
        placement="bottom-start"
        style={{
          width: popperAnchor.current?.clientWidth,
          zIndex: 1000,
          borderRadius: 0,
        }}
      >
        <Paper elevation={8}>
          <List dense>
            {!keyValid && (
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ color: "error" }}
                  primary={`"${inProgressKey}" is not a valid search term`}
                />
              </ListItem>
            )}
            {valuesLoading && (
              <ListItem>
                <ListItemIcon>
                  <CircularProgress size="1rem" />
                </ListItemIcon>
                <ListItemText primary="Loading..." />
              </ListItem>
            )}
            {((keyComplete && keyValid) || (!keyComplete && keyValid)) && (
              <>
                {options.length === 0 && (
                  <ListItem>
                    <ListItemText primary="No Results" />
                  </ListItem>
                )}
                {options.map((option, index) => (
                  <ListItem
                    key={option}
                    onClick={(e) => handleOptionSelected(option)}
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) =>
                          alpha(
                            theme.palette.primary.main,
                            theme.palette.action.hoverOpacity,
                          ),
                        cursor: "pointer",
                      },
                      backgroundColor: (theme) =>
                        index === highlightedOptionIndex
                          ? alpha(
                              theme.palette.primary.main,
                              theme.palette.action.selectedOpacity,
                            )
                          : undefined,
                    }}
                    secondaryAction={
                      index === highlightedOptionIndex ? (
                        <IconButton edge="end">
                          <KeyboardReturn />
                        </IconButton>
                      ) : undefined
                    }
                  >
                    <ListItemText
                      primary={
                        keyComplete && keyValid
                          ? highlightSubstring(option, inProgressValue)
                          : highlightSubstring(`${option}:`, formInput)
                      }
                      primaryTypographyProps={{
                        style: { whiteSpace: "normal", overflow: "auto" },
                      }}
                    />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
};

const highlightSubstring = (e: string, val: string) => {
  const index = e.indexOf(val);
  if (index === -1) {
    return val;
  } else {
    return (
      <>
        <strong>{e.slice(index, val.length)}</strong>
        {e.slice(val.length)}
      </>
    );
  }
};
