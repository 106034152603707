import React from "react";
import { Stack, Tooltip } from "@mui/material";
import {
  Memory,
  Error,
  SaveAs,
  NetworkCheck,
  CellTower,
  InfoOutlined,
  HourglassEmpty,
  Speed,
} from "@mui/icons-material";
import { ClusterMetricFlag } from "./types";
import capitalize from "lodash/capitalize";

const getFlagIcon = (label: string) => {
  switch (label) {
    case "task errors":
      return <Error color="error" />;

    case "cluster memory pressure":
      return <Memory color="warning" />;
    case "individual high memory":
      return <Memory color="error" />;

    case "disk used":
      return <SaveAs color="warning" />;

    case "network traffic":
      return <NetworkCheck />;
    case "external network traffic":
      return <CellTower />;

    case "low cpu utilization":
      return <Speed />;
    case "idle workers":
      return <HourglassEmpty />;
    default:
      return <InfoOutlined />;
  }
};

const getFlagText = (label: string) => {
  switch (label) {
    case "cluster memory pressure":
      return (
        <>
          Cluster Memory Pressure
          <br />
          <br />
          The cluster does not have enough memory available to process the
          workload efficiently. This might result in spilling data to disk which
          causes slowdowns or in killed workers because they run out of memory.
          Further data is available on the Metrics tab. We recommend increasing
          the number of workers or the amount of memory per worker.
        </>
      );
    default:
      return <>{capitalize(label)}</>;
  }
};

export const MetricFlags = ({
  metricFlags,
}: {
  metricFlags?: ClusterMetricFlag[];
}): React.ReactElement => {
  return (
    <Stack direction="row" flexWrap="nowrap" spacing={1}>
      {(metricFlags || []).map((i) => {
        return (
          <Tooltip key={i.label} title={getFlagText(i.label)}>
            {getFlagIcon(i.label)}
          </Tooltip>
        );
      })}
    </Stack>
  );
};
