import React, { useContext } from "react";
import { useLocation, useMatch, Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { SlackIcon } from "../icons/SlackIcon";
import { Urls } from "../domain/urls";
import { DashboardIcon } from "../icons/DashboardIcon";
import { SoftwareIcon } from "../icons/SoftwareIcon";
import { useMustDoSetup } from "../auth/accountUtils";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import {
  Timeline,
  Checklist,
  Cloud,
  CreditCard,
  Group,
} from "@mui/icons-material";
import {
  ListItemButton,
  ListItemButtonProps,
  Tooltip,
  styled,
  useTheme,
} from "@mui/material";

import { UserContext } from "../crud/user/context";
import { InteractionFlagsContext } from "../crud/interactions/context";
import { calendlyLink } from "../utils";

const NavItem = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.grey[400],
  height: "50px",
  ":hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  "& .MuiListItemText-primary": {
    color: "inherit",
  },
  "& .MuiListItemIcon-root": {
    color: "inherit",
    minWidth: "40px",
  },
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    ":hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
})) as typeof ListItemButton;

type SideBarLinkProps = ListItemButtonProps & {
  text: string;
  id: string;
  type?: "external" | "internal";
  url: string;
  tooltip?: string | React.ReactElement;
  postText?: React.ReactElement;
  Icon?: React.ReactElement;
};

const SideBarLink = ({
  text,
  type = "internal",
  url,
  Icon,
  tooltip,
  postText,
  sx,
  ...props
}: SideBarLinkProps): React.ReactElement => {
  const match = useMatch(url + "/" + "*");
  const location = useLocation();

  let selected = false;
  const { pathname } = location;
  if (match) {
    selected = true;
  } else if (url !== "/" && pathname.startsWith(url)) {
    selected = true;
  }

  if (type === "external") {
    return (
      <NavItem
        component="a"
        target="_blank"
        rel="noreferrer"
        href={url}
        sx={sx}
        {...props}
      >
        {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
        <ListItemText primary={text} />
      </NavItem>
    );
  }
  return (
    <Tooltip title={tooltip} placement="right" arrow>
      <NavItem component={Link} selected={selected} to={url} sx={sx} {...props}>
        {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
        <ListItemText primary={text} />
        {postText}
      </NavItem>
    </Tooltip>
  );
};

export const SideBar = (): React.ReactElement => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const isStaff = user.isStaff;
  const doSetup = useMustDoSetup();

  const { "has-created-cluster": getStarted } = useContext(
    InteractionFlagsContext,
  );
  const animate = !getStarted?.completed;

  const links: SideBarLinkProps[] = [
    {
      id: "sidebar-get-started",
      url: Urls.GetStarted,
      text: "Get Started",
      Icon: <Checklist />,
      sx: {
        "@keyframes gradientBackground": {
          "0%": {
            backgroundPosition: "200% 0%",
          },
          "50%": {
            backgroundPosition: "00% 0%",
          },
          "100%": {
            backgroundPosition: "0% 0%",
          },
        },
        background: animate
          ? `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`
          : undefined,
        "&.Mui-selected": {
          color: theme.palette.common.white,
          background: theme.palette.primary.dark,
        },
        ":hover": {
          background: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
        backgroundSize: animate ? "200% 100%" : undefined,
        animation: animate
          ? "gradientBackground 3s ease-out infinite"
          : undefined,
        animationDelay: "1.5s",
        transition: "0.3s",
      },
    },

    {
      id: "sidebar-dashboard",
      text: "Dashboard",
      url: Urls.Clusters,
      Icon: <DashboardIcon />,
    },

    {
      id: "sidebar-software",
      text: "Software",
      url: Urls.Software,
      Icon: <SoftwareIcon color="inherit" />,
    },

    {
      id: "sidebar-cloud-provider",
      text: "Cloud Provider",
      url: Urls.Setup,
      tooltip: doSetup
        ? "Connect your cloud provider to get started."
        : undefined,
      Icon: <Cloud />,
    },

    {
      id: "sidebar-billing",
      text: "Billing",
      url: Urls.Billing,
      Icon: <CreditCard />,
    },

    {
      id: "sidebar-usage",
      text: "Usage",
      url: Urls.Usage,

      Icon: <Timeline />,
    },

    {
      id: "sidebar-team",
      text: "Team",
      url: Urls.Team,
      Icon: <Group />,
    },
    ...(isStaff
      ? [
          {
            id: "sidebar-staff",
            text: "Staff",
            url: Urls.CoiledStaff,
            Icon: <SupervisorAccountIcon />,
          },
        ]
      : []),
    {
      id: "sidebar-slack",
      text: "Join us in Slack",
      type: "external",
      url: "https://join.slack.com/t/coiled-users/shared_invite/zt-hx1fnr7k-In~Q8ui3XkQfvQon0yN5WQ",
      Icon: <SlackIcon />,
    },
    {
      id: "sidebar-slack",
      text: "Talk to an Engineer",
      type: "external",
      url: calendlyLink,
      Icon: <LocalPhoneRoundedIcon />,
    },
  ];
  const { pathname } = useLocation();
  const index = links.findIndex((p) => pathname.substring(1).startsWith(p.url));
  return (
    <List style={{ position: "relative", paddingTop: "0" }}>
      {index !== -1 && (
        <div
          style={{
            zIndex: 500,
            position: "absolute",
            width: theme.spacing(0.5),
            left: 0,
            top: `${50 * index}px`,
            height: "50px",
            backgroundColor: theme.palette.secondary.main,
            transition: "all 0.2s ease-in-out",
          }}
        />
      )}
      {links.map((p) => (
        <SideBarLink key={p.url} {...p} />
      ))}
    </List>
  );
};
