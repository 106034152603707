/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoiledSpanSchema
 */
export interface CoiledSpanSchema {
    /**
     * 
     * @type {Date}
     * @memberof CoiledSpanSchema
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CoiledSpanSchema
     */
    stop?: Date;
    /**
     * 
     * @type {Array<object>}
     * @memberof CoiledSpanSchema
     */
    callstack?: Array<object>;
}

/**
 * Check if a given object implements the CoiledSpanSchema interface.
 */
export function instanceOfCoiledSpanSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CoiledSpanSchemaFromJSON(json: any): CoiledSpanSchema {
    return CoiledSpanSchemaFromJSONTyped(json, false);
}

export function CoiledSpanSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoiledSpanSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
        'callstack': !exists(json, 'callstack') ? undefined : json['callstack'],
    };
}

export function CoiledSpanSchemaToJSON(value?: CoiledSpanSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
        'callstack': value.callstack,
    };
}

