/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcessLogsSchema } from './ProcessLogsSchema';
import {
    ProcessLogsSchemaFromJSON,
    ProcessLogsSchemaFromJSONTyped,
    ProcessLogsSchemaToJSON,
} from './ProcessLogsSchema';

/**
 * 
 * @export
 * @interface ClusterLogsSchema
 */
export interface ClusterLogsSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterLogsSchema
     */
    issues?: Array<string>;
    /**
     * 
     * @type {ProcessLogsSchema}
     * @memberof ClusterLogsSchema
     */
    scheduler: ProcessLogsSchema;
    /**
     * 
     * @type {Array<ProcessLogsSchema>}
     * @memberof ClusterLogsSchema
     */
    workers: Array<ProcessLogsSchema>;
}

/**
 * Check if a given object implements the ClusterLogsSchema interface.
 */
export function instanceOfClusterLogsSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scheduler" in value;
    isInstance = isInstance && "workers" in value;

    return isInstance;
}

export function ClusterLogsSchemaFromJSON(json: any): ClusterLogsSchema {
    return ClusterLogsSchemaFromJSONTyped(json, false);
}

export function ClusterLogsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterLogsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issues': !exists(json, 'issues') ? undefined : json['issues'],
        'scheduler': ProcessLogsSchemaFromJSON(json['scheduler']),
        'workers': ((json['workers'] as Array<any>).map(ProcessLogsSchemaFromJSON)),
    };
}

export function ClusterLogsSchemaToJSON(value?: ClusterLogsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issues': value.issues,
        'scheduler': ProcessLogsSchemaToJSON(value.scheduler),
        'workers': ((value.workers as Array<any>).map(ProcessLogsSchemaToJSON)),
    };
}

