import React from "react";
import { Legend, Tooltip, YAxis } from "recharts";
import { format } from "date-fns";
import { ChartProps, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { GroupedTooltip } from "./GroupedTooltip";
import { YAXIS_WIDTH } from "./const";
import { GroupedLegend } from "./GroupedLegend";

const distributionDomain = [
  {
    name: "total",
    color: { stroke: "#000000", fill: "white", opacity: 0 },
    stack: 1,
  },
  { name: "min", color: null, stack: 0, range: 1 },
  { name: "max", color: null, stack: 0, range: 1 },
  { name: "pct20", color: null, stack: 0, range: 2 },
  { name: "pct80", color: null, stack: 0, range: 2 },
  {
    name: "median",
    color: { stroke: "#2159FF", fill: "white" },
    stack: 0,
  },
  {
    name: "pct20-pct80",
    color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
  },
  {
    name: "min-max",
    color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
  },
];

const gbFormat = (value: string) =>
  parseFloat(value as string).toLocaleString(undefined, {
    maximumFractionDigits: 3,
  });

const memoryTooltip = (
  <Tooltip
    content={
      <GroupedTooltip
        formatter={(value, name: string) => {
          let formattedValue: string;
          if (Array.isArray(value)) {
            formattedValue = `${gbFormat(value[0])}–${gbFormat(value[1])} GB`;
          } else {
            formattedValue = `${gbFormat(value)}GB`;
          }
          return [formattedValue, name];
        }}
        // TODO tz conversion
        labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
      />
    }
    wrapperStyle={{
      outline: "none",
      zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
    }}
    isAnimationActive={false}
  />
);

const utilTooltip = (
  <Tooltip
    content={
      <GroupedTooltip
        formatter={(value, name: string) => {
          let formattedValue: string;
          if (Array.isArray(value)) {
            formattedValue = `${Math.round(
              value[0] as number,
            )}%–${Math.round(value[1] as number)}%`;
          } else {
            formattedValue = `${Math.round(value as unknown as number)}%`;
          }
          return [formattedValue, name];
        }}
        // TODO tz conversion
        labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
      />
    }
    wrapperStyle={{
      outline: "none",
      zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
    }}
    isAnimationActive={false}
  />
);

export const ClusterGpuUtilChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Worker Util Distribution",
      groupKey: "type",
      queries: [
        {
          name: "worker:gpu_util | sum_by(instance,gpu) | min",
          typeLabel: "min",
        },
        {
          name: "worker:gpu_util | sum_by(instance,gpu) | max",
          typeLabel: "max",
        },
        {
          name: "worker:gpu_util | sum_by(instance,gpu) | pct20",
          typeLabel: "pct20",
        },
        {
          name: "worker:gpu_util | sum_by(instance,gpu) | pct80",
          typeLabel: "pct80",
        },
        {
          name: "worker:gpu_util | sum_by(instance,gpu) | pct50",
          typeLabel: "median",
        },
      ],
      domains: distributionDomain,
      legend: <Legend content={GroupedLegend} />,
      yAxis: <YAxis unit="%" domain={[0, 100]} width={YAXIS_WIDTH} />,
    },
    {
      name: "Scheduler GPU Utilization",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:gpu_util",
          typeLabel: "util",
        },
      ],
      domains: [
        {
          name: "util",
          color: "#8fe38f",
          stack: 0,
        },
      ],
      yAxis: <YAxis unit="%" domain={[0, 100]} width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Utilization"
        variations={variations}
        tooltip={utilTooltip}
        {...props}
      />
    </>
  );
};

export const ClusterGpuMemChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Worker Memory Distribution",
      groupKey: "type",
      queries: [
        {
          name: "worker:(gpu_fb_mem_used+gpu_fb_mem_free) | * .001 | max",
          typeLabel: "total",
        },
        {
          name: "worker:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | min",
          typeLabel: "min",
        },
        {
          name: "worker:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | max",
          typeLabel: "max",
        },
        {
          name: "worker:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct20",
          typeLabel: "pct20",
        },
        {
          name: "worker:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct80",
          typeLabel: "pct80",
        },
        {
          name: "worker:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct50",
          typeLabel: "median",
        },
      ],
      domains: distributionDomain,
      legend: <Legend content={GroupedLegend} />,
      yAxis: <YAxis unit="GB" width={YAXIS_WIDTH} />,
    },
    {
      name: "Scheduler GPU Memory",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:gpu_fb_mem_used | * .001",
          typeLabel: "used",
        },
        {
          name: "scheduler:(gpu_fb_mem_used+gpu_fb_mem_free) | * .001",
          typeLabel: "total",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "#2159FF", opacity: 0.2 },
          stack: 1,
        },
        {
          name: "total",
          color: { stroke: "#000000", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
      yAxis: <YAxis unit="GB" width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Memory"
        variations={variations}
        tooltip={memoryTooltip}
        {...props}
      />
    </>
  );
};

export const HostGpuMemChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Scheduler GPU Memory",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:gpu_fb_mem_used | * .001",
          typeLabel: "used",
        },
        {
          name: "scheduler:(gpu_fb_mem_used+gpu_fb_mem_free) | * .001",
          typeLabel: "total",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "#2159FF", opacity: 0.2 },
          stack: 1,
        },
        {
          name: "total",
          color: { stroke: "#000000", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
      yAxis: <YAxis unit="GB" width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Memory"
        variations={variations}
        tooltip={memoryTooltip}
        {...props}
      />
    </>
  );
};

export const HostGpuUtilChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Host GPU Utilization",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:gpu_util",
          typeLabel: "util",
        },
      ],
      domains: [
        {
          name: "util",
          color: "#8fe38f",
          stack: 0,
        },
      ],
      yAxis: <YAxis unit="%" domain={[0, 100]} width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Utilization"
        variations={variations}
        tooltip={utilTooltip}
        {...props}
      />
    </>
  );
};

export const CombinedGpuUtilChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Util Distribution",
      groupKey: "type",
      queries: [
        {
          name: "all:gpu_util | sum_by(instance,gpu) | min",
          typeLabel: "min",
        },
        {
          name: "all:gpu_util | sum_by(instance,gpu) | max",
          typeLabel: "max",
        },
        {
          name: "all:gpu_util | sum_by(instance,gpu) | pct20",
          typeLabel: "pct20",
        },
        {
          name: "all:gpu_util | sum_by(instance,gpu) | pct80",
          typeLabel: "pct80",
        },
        {
          name: "all:gpu_util | sum_by(instance,gpu) | pct50",
          typeLabel: "median",
        },
      ],
      domains: distributionDomain,
      yAxis: <YAxis unit="%" domain={[0, 100]} width={YAXIS_WIDTH} />,
      legend: <Legend content={GroupedLegend} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Utilization"
        variations={variations}
        tooltip={utilTooltip}
        {...props}
      />
    </>
  );
};

export const CombinedGpuMemChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Memory Distribution",
      groupKey: "type",
      queries: [
        {
          name: "all:(gpu_fb_mem_used+gpu_fb_mem_free) | * .001 | max",
          typeLabel: "total",
        },
        {
          name: "all:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | min",
          typeLabel: "min",
        },
        {
          name: "all:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | max",
          typeLabel: "max",
        },
        {
          name: "all:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct20",
          typeLabel: "pct20",
        },
        {
          name: "all:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct80",
          typeLabel: "pct80",
        },
        {
          name: "all:gpu_fb_mem_used | * .001 | sum_by(instance,gpu) | pct50",
          typeLabel: "median",
        },
      ],
      domains: distributionDomain,
      legend: <Legend content={GroupedLegend} />,
      yAxis: <YAxis unit="GB" width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="GPU Memory"
        variations={variations}
        tooltip={memoryTooltip}
        {...props}
      />
    </>
  );
};
