/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirewallRuleSchema } from './FirewallRuleSchema';
import {
    FirewallRuleSchemaFromJSON,
    FirewallRuleSchemaFromJSONTyped,
    FirewallRuleSchemaToJSON,
} from './FirewallRuleSchema';

/**
 * 
 * @export
 * @interface GCPAccountSettingsSchema
 */
export interface GCPAccountSettingsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountSettingsSchema
     */
    autoSetup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountSettingsSchema
     */
    giveWorkersPublicIp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountSettingsSchema
     */
    giveSchedulerPublicIp?: boolean;
    /**
     * 
     * @type {Array<FirewallRuleSchema>}
     * @memberof GCPAccountSettingsSchema
     */
    schedulerFirewall?: Array<FirewallRuleSchema>;
    /**
     * 
     * @type {string}
     * @memberof GCPAccountSettingsSchema
     */
    customSoftwareBucketPrefix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountSettingsSchema
     */
    useSelfHostedBucket?: boolean;
}

/**
 * Check if a given object implements the GCPAccountSettingsSchema interface.
 */
export function instanceOfGCPAccountSettingsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GCPAccountSettingsSchemaFromJSON(json: any): GCPAccountSettingsSchema {
    return GCPAccountSettingsSchemaFromJSONTyped(json, false);
}

export function GCPAccountSettingsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPAccountSettingsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoSetup': !exists(json, 'auto_setup') ? undefined : json['auto_setup'],
        'giveWorkersPublicIp': !exists(json, 'give_workers_public_ip') ? undefined : json['give_workers_public_ip'],
        'giveSchedulerPublicIp': !exists(json, 'give_scheduler_public_ip') ? undefined : json['give_scheduler_public_ip'],
        'schedulerFirewall': !exists(json, 'scheduler_firewall') ? undefined : ((json['scheduler_firewall'] as Array<any>).map(FirewallRuleSchemaFromJSON)),
        'customSoftwareBucketPrefix': !exists(json, 'custom_software_bucket_prefix') ? undefined : json['custom_software_bucket_prefix'],
        'useSelfHostedBucket': !exists(json, 'use_self_hosted_bucket') ? undefined : json['use_self_hosted_bucket'],
    };
}

export function GCPAccountSettingsSchemaToJSON(value?: GCPAccountSettingsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_setup': value.autoSetup,
        'give_workers_public_ip': value.giveWorkersPublicIp,
        'give_scheduler_public_ip': value.giveSchedulerPublicIp,
        'scheduler_firewall': value.schedulerFirewall === undefined ? undefined : ((value.schedulerFirewall as Array<any>).map(FirewallRuleSchemaToJSON)),
        'custom_software_bucket_prefix': value.customSoftwareBucketPrefix,
        'use_self_hosted_bucket': value.useSelfHostedBucket,
    };
}

