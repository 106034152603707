/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { StatefulObjectLite } from './StatefulObjectLite';
import {
    StatefulObjectLiteFromJSON,
    StatefulObjectLiteFromJSONTyped,
    StatefulObjectLiteToJSON,
} from './StatefulObjectLite';

/**
 * 
 * @export
 * @interface StateResponseSchema
 */
export interface StateResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof StateResponseSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StateResponseSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StateResponseSchema
     */
    updated: Date;
    /**
     * 
     * @type {State}
     * @memberof StateResponseSchema
     */
    state: State;
    /**
     * 
     * @type {string}
     * @memberof StateResponseSchema
     */
    reason: string;
    /**
     * 
     * @type {StatefulObjectLite}
     * @memberof StateResponseSchema
     */
    statefulObject: StatefulObjectLite;
}

/**
 * Check if a given object implements the StateResponseSchema interface.
 */
export function instanceOfStateResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "statefulObject" in value;

    return isInstance;
}

export function StateResponseSchemaFromJSON(json: any): StateResponseSchema {
    return StateResponseSchemaFromJSONTyped(json, false);
}

export function StateResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'state': StateFromJSON(json['state']),
        'reason': json['reason'],
        'statefulObject': StatefulObjectLiteFromJSON(json['stateful_object']),
    };
}

export function StateResponseSchemaToJSON(value?: StateResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'state': StateToJSON(value.state),
        'reason': value.reason,
        'stateful_object': StatefulObjectLiteToJSON(value.statefulObject),
    };
}

