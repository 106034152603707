/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalData
 */
export interface AdditionalData {
}

/**
 * Check if a given object implements the AdditionalData interface.
 */
export function instanceOfAdditionalData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdditionalDataFromJSON(json: any): AdditionalData {
    return AdditionalDataFromJSONTyped(json, false);
}

export function AdditionalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalData {
    return json;
}

export function AdditionalDataToJSON(value?: AdditionalData | null): any {
    return value;
}

