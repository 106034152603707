import React, { createContext, ReactElement, ReactNode } from "react";
import {
  SoftwareEnvironmentAliasSchema,
  SoftwareEnvironmentBuildStateEnum,
  SoftwareEnvironmentSpecSchema,
} from "../../api-client";

export const SoftwareAliasContext =
  createContext<SoftwareEnvironmentAliasSchema>({
    created: new Date(),
    updated: new Date(),
    id: 0,
    name: "",
    specCount: 0,
    accountSlug: "",
    latestSpec: {
      id: 0,
      created: new Date(),
      updated: new Date(),
      gpuEnabled: false,
      md5: "",
      latestBuild: {
        id: 0,
        created: new Date(),
        updated: new Date(),
        state: SoftwareEnvironmentBuildStateEnum.Queued,
        reason: "",
      },
    },
  });
export const SoftwareSpecContext = createContext<SoftwareEnvironmentSpecSchema>(
  {
    created: new Date(),
    updated: new Date(),
    id: 0,
    packages: [],
    md5: "",
    aliasId: 0,
    latestBuildId: 0,
    gpuEnabled: false,
  },
);

type SoftwareAliasContextProviderProps = {
  alias: SoftwareEnvironmentAliasSchema;
  children: ReactNode;
};

type SoftwareSpecContextProviderProps = {
  spec: SoftwareEnvironmentSpecSchema;
  children: ReactNode;
};

export const SoftwareAliasContextProvider = ({
  alias,
  children,
}: SoftwareAliasContextProviderProps): ReactElement => {
  return (
    <SoftwareAliasContext.Provider value={alias}>
      {children}
    </SoftwareAliasContext.Provider>
  );
};

export const SoftwareSpecContextProvider = ({
  spec,
  children,
}: SoftwareSpecContextProviderProps): ReactElement => {
  return (
    <SoftwareSpecContext.Provider value={spec}>
      {children}
    </SoftwareSpecContext.Provider>
  );
};
