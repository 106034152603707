/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All currently supported backend types
 * @export
 */
export const BackendTypesEnum = {
    InProcess: 'in_process',
    Aks: 'aks',
    Gke: 'gke',
    VmAws: 'vm_aws',
    VmGcp: 'vm_gcp',
    VmAzure: 'vm_azure'
} as const;
export type BackendTypesEnum = typeof BackendTypesEnum[keyof typeof BackendTypesEnum];


export function BackendTypesEnumFromJSON(json: any): BackendTypesEnum {
    return BackendTypesEnumFromJSONTyped(json, false);
}

export function BackendTypesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendTypesEnum {
    return json as BackendTypesEnum;
}

export function BackendTypesEnumToJSON(value?: BackendTypesEnum | null): any {
    return value as any;
}

