import React, { ReactNode, useContext, useEffect } from "react";
import {
  useNavigate,
  RouteProps,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { Urls } from "../domain/urls";
import { REDIRECT_URI_PARAM } from "./constants";
import { LoadingScreen } from "../shared-components/LoadingScreen";
import { PageCouldntLoad } from "../shared-components/PageCouldntLoad";
import {
  useMe,
  useUserMemberships,
  useUserOrganizationMemberships,
} from "../crud/user/hooks";
import {
  AuthenticatedContextProvider,
  AuthenticationContext,
} from "../crud/auth/context";
import { useInteractions } from "../crud/interactions/hooks";
import { UserContextProvider } from "../crud/user/context";
import { useLogoutMutation } from "../crud/auth/hooks";
import { ResponseError } from "../api-client";

type Props = RouteProps & {
  staffRequired?: boolean;
  membershipRequired?: boolean;
};

type TokenGateProps = {
  children: ReactNode;
};

const TokenGate = ({ children }: TokenGateProps): React.ReactElement => {
  const { token } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (token === undefined) {
      navigate(
        {
          pathname: `/${Urls.Login}`,
          search: createSearchParams({
            [REDIRECT_URI_PARAM]: `${location.pathname}?${searchParams}`,
          }).toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [token, navigate, searchParams]);
  if (!token) {
    return <></>;
  }
  return (
    <AuthenticatedContextProvider token={token}>
      {children}
    </AuthenticatedContextProvider>
  );
};
type UserContextProps = {
  children: ReactNode;
  staffRequired: boolean;
};

const UserGate = ({
  children,
  staffRequired,
}: UserContextProps): React.ReactElement => {
  const { setToken } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const { mutate: doLogout } = useLogoutMutation();
  const { data: user, error: meError } = useMe();
  const { data: memberships } = useUserMemberships();
  const { data: interactions } = useInteractions();
  const { data: orgMemberships } = useUserOrganizationMemberships();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (meError && meError instanceof ResponseError) {
      if (meError.response.status === 401) {
        setToken(undefined);
        navigate(
          {
            pathname: `/${Urls.Login}`,
            search: createSearchParams({
              [REDIRECT_URI_PARAM]: `${location.pathname}?${searchParams}`,
            }).toString(),
          },
          { replace: true },
        );
      }
    }
  }, [meError, navigate, setToken, doLogout, searchParams]);
  if (user && memberships && interactions && orgMemberships) {
    if (staffRequired && !user.isStaff) {
      return (
        <PageCouldntLoad
          errorLines={[
            "Sorry, only Coiled admins can view this page.",
            "If this is a mistake, please contact coiled support.",
          ]}
        />
      );
    } else {
      return (
        <UserContextProvider
          user={user}
          memberships={memberships}
          organizationMemberships={orgMemberships}
          interactions={interactions}
        >
          {children}
        </UserContextProvider>
      );
    }
  }
  return <LoadingScreen />;
};
export const LoginRequiredRoute = ({
  staffRequired = false,
  membershipRequired = false,
  children,
  ...rest
}: Props): React.ReactElement => {
  return (
    <TokenGate>
      <UserGate staffRequired={staffRequired}>{children}</UserGate>
    </TokenGate>
  );
};
