/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnalyticsProfileSchema
 */
export interface AnalyticsProfileSchema {
    /**
     * 
     * @type {object}
     * @memberof AnalyticsProfileSchema
     */
    profile: object;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsProfileSchema
     */
    nWorkers: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsProfileSchema
     */
    ident: number;
}

/**
 * Check if a given object implements the AnalyticsProfileSchema interface.
 */
export function instanceOfAnalyticsProfileSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "nWorkers" in value;
    isInstance = isInstance && "ident" in value;

    return isInstance;
}

export function AnalyticsProfileSchemaFromJSON(json: any): AnalyticsProfileSchema {
    return AnalyticsProfileSchemaFromJSONTyped(json, false);
}

export function AnalyticsProfileSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsProfileSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': json['profile'],
        'nWorkers': json['n_workers'],
        'ident': json['ident'],
    };
}

export function AnalyticsProfileSchemaToJSON(value?: AnalyticsProfileSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': value.profile,
        'n_workers': value.nWorkers,
        'ident': value.ident,
    };
}

