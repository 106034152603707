/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudWatchLogGroupSchema } from './CloudWatchLogGroupSchema';
import {
    CloudWatchLogGroupSchemaFromJSON,
    CloudWatchLogGroupSchemaFromJSONTyped,
    CloudWatchLogGroupSchemaToJSON,
} from './CloudWatchLogGroupSchema';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';
import type { SecurityGroupSchema } from './SecurityGroupSchema';
import {
    SecurityGroupSchemaFromJSON,
    SecurityGroupSchemaFromJSONTyped,
    SecurityGroupSchemaToJSON,
} from './SecurityGroupSchema';
import type { SubnetSchema } from './SubnetSchema';
import {
    SubnetSchemaFromJSON,
    SubnetSchemaFromJSONTyped,
    SubnetSchemaToJSON,
} from './SubnetSchema';
import type { Vpc } from './Vpc';
import {
    VpcFromJSON,
    VpcFromJSONTyped,
    VpcToJSON,
} from './Vpc';

/**
 * 
 * @export
 * @interface AWSRegionalInfraSchema
 */
export interface AWSRegionalInfraSchema {
    /**
     * 
     * @type {number}
     * @memberof AWSRegionalInfraSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof AWSRegionalInfraSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof AWSRegionalInfraSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof AWSRegionalInfraSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {boolean}
     * @memberof AWSRegionalInfraSchema
     */
    _default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AWSRegionalInfraSchema
     */
    managed: boolean;
    /**
     * 
     * @type {string}
     * @memberof AWSRegionalInfraSchema
     */
    region: string;
    /**
     * 
     * @type {Vpc}
     * @memberof AWSRegionalInfraSchema
     */
    vpc?: Vpc;
    /**
     * 
     * @type {Array<SubnetSchema>}
     * @memberof AWSRegionalInfraSchema
     */
    subnets: Array<SubnetSchema>;
    /**
     * 
     * @type {SecurityGroupSchema}
     * @memberof AWSRegionalInfraSchema
     */
    clusterSecurityGroup?: SecurityGroupSchema;
    /**
     * 
     * @type {SecurityGroupSchema}
     * @memberof AWSRegionalInfraSchema
     */
    schedulerSecurityGroup?: SecurityGroupSchema;
    /**
     * 
     * @type {CloudWatchLogGroupSchema}
     * @memberof AWSRegionalInfraSchema
     */
    cloudwatchLogGroup: CloudWatchLogGroupSchema;
}

/**
 * Check if a given object implements the AWSRegionalInfraSchema interface.
 */
export function instanceOfAWSRegionalInfraSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "managed" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "subnets" in value;
    isInstance = isInstance && "cloudwatchLogGroup" in value;

    return isInstance;
}

export function AWSRegionalInfraSchemaFromJSON(json: any): AWSRegionalInfraSchema {
    return AWSRegionalInfraSchemaFromJSONTyped(json, false);
}

export function AWSRegionalInfraSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSRegionalInfraSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        '_default': json['default'],
        'managed': json['managed'],
        'region': json['region'],
        'vpc': !exists(json, 'vpc') ? undefined : VpcFromJSON(json['vpc']),
        'subnets': ((json['subnets'] as Array<any>).map(SubnetSchemaFromJSON)),
        'clusterSecurityGroup': !exists(json, 'cluster_security_group') ? undefined : SecurityGroupSchemaFromJSON(json['cluster_security_group']),
        'schedulerSecurityGroup': !exists(json, 'scheduler_security_group') ? undefined : SecurityGroupSchemaFromJSON(json['scheduler_security_group']),
        'cloudwatchLogGroup': CloudWatchLogGroupSchemaFromJSON(json['cloudwatch_log_group']),
    };
}

export function AWSRegionalInfraSchemaToJSON(value?: AWSRegionalInfraSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'default': value._default,
        'managed': value.managed,
        'region': value.region,
        'vpc': VpcToJSON(value.vpc),
        'subnets': ((value.subnets as Array<any>).map(SubnetSchemaToJSON)),
        'cluster_security_group': SecurityGroupSchemaToJSON(value.clusterSecurityGroup),
        'scheduler_security_group': SecurityGroupSchemaToJSON(value.schedulerSecurityGroup),
        'cloudwatch_log_group': CloudWatchLogGroupSchemaToJSON(value.cloudwatchLogGroup),
    };
}

