import ky from "ky";
import { getStandardHeaders } from "../../../../apiUtils";
import { useQuery } from "react-query";
import Skeleton from "@mui/material/Skeleton";
import React, { useMemo } from "react";
import { Alert, Card, CardContent, CardHeader } from "@mui/material";
import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GroupedTooltip } from "./GroupedTooltip";
import { formatInTimeZone } from "date-fns-tz";
import { useTimezone } from "../../../../utils/hooks";

type ClusterSizeChartProps = {
  clusterId: string;
  accountSlug: string;
  token: string;
};
const fetchSizes = async (
  clusterId: string,
  accountSlug: string,
  token: string,
): Promise<number[]> => {
  const route = `/api/v2/clusters/account/${accountSlug}/id/${clusterId}/size`;
  const sizesRaw = await ky
    .get(route, {
      headers: getStandardHeaders(),
    })
    .json();
  return sizesRaw as number[];
};
const clusterSizeDomains = [
  ["error", "Error", "#E90050"],
  ["stopping", "Stopping", "#BABABA"],
  ["queued", "Queued", "#FDD10B"],
  ["starting", "Starting", "#16AB95"],
  ["instance_running", "Instance Running", "#1DE5FF"],
  ["downloading", "Downloading", "#317CFF"],
  ["ready_on_demand", "Ready", "#183CFD"],
  ["ready_spot", "Ready (Spot)", "#183CFD"],
];
// we want "error" to show at top (first) on chart, but at right (last) in legend
const legendOrder = [2, 3, 4, 5, 6, 7, 1, 0];

export const ClusterSizeChart = ({
  clusterId,
  accountSlug,
  token,
}: ClusterSizeChartProps): React.ReactElement => {
  const [displayTz] = useTimezone();
  const { error, data, isSuccess } = useQuery(
    // small optimization, possible todo: stop fetching if the cluster is stopped
    ["fetchSizes", clusterId, accountSlug, token],
    () => fetchSizes(clusterId as string, accountSlug, token),
    {
      refetchInterval: 5000,
    },
  );
  const legendComponent = useMemo(
    () => (
      <Legend
        payload={legendOrder.map((key) => {
          const label = clusterSizeDomains[key][1];
          const fill = clusterSizeDomains[key][2];
          return {
            value: label,
            type: "square",
            color: label === "Ready (Spot)" ? "url(#spotPattern)" : fill,
            // for some reason, "Ready (Spot)" is black without this formatter
            formatter: () => <span style={{ color: fill }}>{label}</span>,
          };
        })}
      />
    ),
    [],
  );
  const areaCharts = useMemo(
    () =>
      clusterSizeDomains
        .slice()
        .reverse()
        .map(([domain, label, fill]) => (
          <Area
            activeDot={false}
            isAnimationActive={false}
            name={label}
            type="stepAfter"
            key={domain}
            dataKey={domain}
            fill={label === "Ready (Spot)" ? "url(#spotPattern)" : fill}
            fillOpacity={1}
            stroke={"none"}
            stackId="0"
          />
        )),
    [],
  );

  if (error) {
    return <Alert severity="error">{"Error fetching cluster sizes."}</Alert>;
  }

  return (
    <Card>
      <CardHeader title="Cluster Size" />
      <CardContent>
        {!isSuccess && (
          <Skeleton animation="wave" variant="rectangular" height={250} />
        )}
        {isSuccess && (
          <ResponsiveContainer height={250} width="100%">
            <AreaChart data={data}>
              <defs>
                <pattern
                  id="spotPattern"
                  width="4"
                  height="4"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect width="3" height="3" fill="#183CFD" />
                </pattern>
              </defs>
              <XAxis
                dataKey="ts"
                type="category"
                scale="utc"
                domain={["auto", "auto"]}
                tickFormatter={(v, i) =>
                  formatInTimeZone(new Date(v), displayTz, "HH:mm:ss")
                }
              />
              <YAxis
                type="number"
                domain={[0, "dataMax + 1"]}
                label={{ value: "Instances", angle: -90, dx: -20 }}
                allowDecimals={false}
              />
              {legendComponent}
              {areaCharts}
              <Tooltip
                content={
                  <GroupedTooltip
                    labelFormatter={(v) =>
                      formatInTimeZone(
                        new Date(v),
                        displayTz,
                        "LLL do, HH:mm:ss O",
                      )
                    }
                    showTotal
                  />
                }
                wrapperStyle={{
                  outline: "none",
                  zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
                }}
                isAnimationActive={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};
