/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkerErrorCountSchema
 */
export interface WorkerErrorCountSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkerErrorCountSchema
     */
    availability: number;
    /**
     * 
     * @type {number}
     * @memberof WorkerErrorCountSchema
     */
    quota: number;
    /**
     * 
     * @type {number}
     * @memberof WorkerErrorCountSchema
     */
    interruption: number;
    /**
     * 
     * @type {number}
     * @memberof WorkerErrorCountSchema
     */
    other: number;
}

/**
 * Check if a given object implements the WorkerErrorCountSchema interface.
 */
export function instanceOfWorkerErrorCountSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availability" in value;
    isInstance = isInstance && "quota" in value;
    isInstance = isInstance && "interruption" in value;
    isInstance = isInstance && "other" in value;

    return isInstance;
}

export function WorkerErrorCountSchemaFromJSON(json: any): WorkerErrorCountSchema {
    return WorkerErrorCountSchemaFromJSONTyped(json, false);
}

export function WorkerErrorCountSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerErrorCountSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availability': json['availability'],
        'quota': json['quota'],
        'interruption': json['interruption'],
        'other': json['other'],
    };
}

export function WorkerErrorCountSchemaToJSON(value?: WorkerErrorCountSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availability': value.availability,
        'quota': value.quota,
        'interruption': value.interruption,
        'other': value.other,
    };
}

