/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AWSClusterOptionsSchema } from './AWSClusterOptionsSchema';
import {
    AWSClusterOptionsSchemaFromJSON,
    AWSClusterOptionsSchemaFromJSONTyped,
    AWSClusterOptionsSchemaToJSON,
} from './AWSClusterOptionsSchema';
import type { AzureClusterOptionsSchema } from './AzureClusterOptionsSchema';
import {
    AzureClusterOptionsSchemaFromJSON,
    AzureClusterOptionsSchemaFromJSONTyped,
    AzureClusterOptionsSchemaToJSON,
} from './AzureClusterOptionsSchema';
import type { FirewallSpec } from './FirewallSpec';
import {
    FirewallSpecFromJSON,
    FirewallSpecFromJSONTyped,
    FirewallSpecToJSON,
} from './FirewallSpec';
import type { GCPClusterOptionsSchema } from './GCPClusterOptionsSchema';
import {
    GCPClusterOptionsSchemaFromJSON,
    GCPClusterOptionsSchemaFromJSONTyped,
    GCPClusterOptionsSchemaToJSON,
} from './GCPClusterOptionsSchema';

/**
 * 
 * @export
 * @interface ClusterOptions
 */
export interface ClusterOptions {
    /**
     * 
     * @type {number}
     * @memberof ClusterOptions
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ClusterOptions
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClusterOptions
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    zoneName?: string;
    /**
     * 
     * @type {FirewallSpec}
     * @memberof ClusterOptions
     */
    firewallSpec?: FirewallSpec;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    tlsCert: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    tlsKey: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    type: ClusterOptionsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    vpcArn?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    schedulerSubnetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    workerSubnetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    securityGroupId?: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterOptions
     */
    schedulerAcceleratorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    schedulerAcceleratorType?: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterOptions
     */
    workerAcceleratorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterOptions
     */
    workerAcceleratorType?: string;
}


/**
 * @export
 */
export const ClusterOptionsTypeEnum = {
    Aws: 'vm_aws',
    Gcp: 'vm_gcp',
    Azure: 'vm_azure'
} as const;
export type ClusterOptionsTypeEnum = typeof ClusterOptionsTypeEnum[keyof typeof ClusterOptionsTypeEnum];


/**
 * Check if a given object implements the ClusterOptions interface.
 */
export function instanceOfClusterOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "tlsCert" in value;
    isInstance = isInstance && "tlsKey" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ClusterOptionsFromJSON(json: any): ClusterOptions {
    return ClusterOptionsFromJSONTyped(json, false);
}

export function ClusterOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'zoneName': !exists(json, 'zone_name') ? undefined : json['zone_name'],
        'firewallSpec': !exists(json, 'firewall_spec') ? undefined : FirewallSpecFromJSON(json['firewall_spec']),
        'tlsCert': json['tls_cert'],
        'tlsKey': json['tls_key'],
        'type': json['type'],
        'vpcArn': !exists(json, 'vpc_arn') ? undefined : json['vpc_arn'],
        'schedulerSubnetId': !exists(json, 'scheduler_subnet_id') ? undefined : json['scheduler_subnet_id'],
        'workerSubnetId': !exists(json, 'worker_subnet_id') ? undefined : json['worker_subnet_id'],
        'securityGroupId': !exists(json, 'security_group_id') ? undefined : json['security_group_id'],
        'schedulerAcceleratorCount': !exists(json, 'scheduler_accelerator_count') ? undefined : json['scheduler_accelerator_count'],
        'schedulerAcceleratorType': !exists(json, 'scheduler_accelerator_type') ? undefined : json['scheduler_accelerator_type'],
        'workerAcceleratorCount': !exists(json, 'worker_accelerator_count') ? undefined : json['worker_accelerator_count'],
        'workerAcceleratorType': !exists(json, 'worker_accelerator_type') ? undefined : json['worker_accelerator_type'],
    };
}

export function ClusterOptionsToJSON(value?: ClusterOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'region_name': value.regionName,
        'zone_name': value.zoneName,
        'firewall_spec': FirewallSpecToJSON(value.firewallSpec),
        'tls_cert': value.tlsCert,
        'tls_key': value.tlsKey,
        'type': value.type,
        'vpc_arn': value.vpcArn,
        'scheduler_subnet_id': value.schedulerSubnetId,
        'worker_subnet_id': value.workerSubnetId,
        'security_group_id': value.securityGroupId,
        'scheduler_accelerator_count': value.schedulerAcceleratorCount,
        'scheduler_accelerator_type': value.schedulerAcceleratorType,
        'worker_accelerator_count': value.workerAcceleratorCount,
        'worker_accelerator_type': value.workerAcceleratorType,
    };
}

