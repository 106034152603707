import React from "react";
import { styled, useTheme } from "@mui/material";

const Code = styled("pre")({
  margin: 0,
  backgroundColor: "transparent",
  color: "#ffff",
});

const CodeBash = styled(Code)({
  "&::before": {
    // Weird stuff, but we need to wrap content like this otherwise
    // it doesn't work. Yay material ui!
    content: "'$'",
    width: "10px",
    marginRight: "0.5rem",
  },
});

const CodeIpython = styled(Code)({
  "&::before": {
    content: "'>>>'",
    width: "10px",
    marginRight: "0.5rem",
  },
});

export const Terminal = (): React.ReactElement => {
  const theme = useTheme();
  return (
    <div
      style={{
        backgroundColor: "#191B1F",
        border: "1px solid #706f66",
        borderRadius: theme.shape.borderRadius,
        position: "relative",
        margin: "2rem 0",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #706f66",
          position: "relative",
          height: "1.8rem",
          width: "100%",
        }}
      >
        <span
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#ED6A5E",
            display: "inline-block",
            position: "absolute",
            top: "0.5rem",
            left: "0.5rem",
          }}
        />
        <span
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#F4BF4F",
            display: "inline-block",
            position: "absolute",
            top: "0.5rem",
            left: "1.5rem",
          }}
        />
        <span
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#61C454",
            display: "inline-block",
            position: "absolute",
            top: "0.5rem",
            left: "2.5rem",
          }}
        />
      </div>
      <div style={{ padding: "2rem", overflow: "auto" }}>
        <CodeBash>pip install coiled</CodeBash>
        <CodeBash>coiled setup</CodeBash>
        <CodeBash>ipython </CodeBash>
        <CodeIpython sx={{ marginTop: "1rem" }}>
          <span style={{ color: "#26f0ff" }}>import</span>{" "}
          <span style={{ color: "#52ff26" }}>coiled</span>
        </CodeIpython>
        <CodeIpython>
          cluster = coiled.Cluster(n_workers=
          <span style={{ color: "#F2FF26" }}>500</span>)
        </CodeIpython>
      </div>
    </div>
  );
};
