import React, { useContext, useEffect, ReactElement, useMemo } from "react";
import { ClusterContext } from "../context";
import { useWorkspaceContextSlug, useToken } from "../../../utils/hooks";
import { useClusterHistory } from "./hooks";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { StateResponse } from "./crud";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { stateStyles, stateToClassName } from "./stateStyles";
import { formatISO } from "date-fns";

const SimpleToolBar = (): ReactElement => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);
const StateHistoryColumns: GridColDef<StateResponse>[] = [
  {
    field: "created",
    headerName: "Time",
    type: "dateTime",
    valueFormatter: (params) =>
      formatISO(params.value, { representation: "time" }),
    valueGetter: (params) => new Date(params.row.created),
    flex: 0.4,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => params.row.statefulObject.name,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 0.5,
  },
  { field: "state", headerName: "State", flex: 0.5 },
  { field: "reason", headerName: "Reason", flex: 1 },
];
export const LifecycleView = (): React.ReactElement => {
  const { clusterId, cluster } = useContext(ClusterContext);

  const account = useWorkspaceContextSlug();
  const token = useToken();

  const [shouldFetch, setShouldFetch] = React.useState(true);

  const { isLoading, data: stateHistory } = useClusterHistory(
    clusterId,
    account,
    token,
    shouldFetch,
  );
  useEffect(() => {
    if (
      (cluster
        ? ["error", "stopped"].includes(cluster.currentState.state)
        : false) &&
      shouldFetch
    ) {
      setShouldFetch(false);
    }
  }, [cluster, shouldFetch]);
  const allHistory: StateResponse[] = useMemo(
    () =>
      stateHistory
        ? [
            ...stateHistory.scheduler.map((state) => ({
              ...state,
              id: `scheduler-${state.id}`,
              type: "scheduler",
            })),
            ...stateHistory.schedulerInstance.map((state) => ({
              ...state,
              id: `scheduler-instance-${state.id}`,
              type: "scheduler-instance",
            })),
            ...stateHistory.worker.map((state) => ({
              ...state,
              id: `worker-${state.id}`,
              type: "worker",
            })),
            ...stateHistory.workerInstance.map((state) => ({
              ...state,
              id: `worker-${state.id}`,
              type: "worker-instance",
            })),

            ...stateHistory.cluster.map((state) => ({
              ...state,
              id: `cluster-${state.id}`,
              type: "cluster",
            })),
            ...stateHistory.clusterInfra.map((state) => ({
              ...state,
              id: `cluster-infra-${state.id}`,

              type: "cluster-infra",
            })),
          ]
        : [],
    [stateHistory],
  );
  const apiRef = useGridApiRef();
  useEffect(() => apiRef.current.updateRows(allHistory), [allHistory, apiRef]);

  return (
    <Card>
      <CardContent sx={{ height: "100%" }}>
        <Stack sx={{ height: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            slots={{ toolbar: SimpleToolBar }}
            autoHeight
            density="compact"
            getRowHeight={() => "auto"}
            loading={isLoading}
            hideFooter
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "created", sort: "desc" }],
              },
            }}
            sx={(theme) => ({
              flexGrow: 1,
              "& .MuiDataGrid-cell": {
                border: 0,
                fontFamily: "Roboto Mono, monospace",
              },
              "& .MuiDataGrid-row": {
                background: theme.palette.text.primary,
                "&:hover": {
                  background: theme.palette.text.primary,
                },
                ...stateStyles,
              },
            })}
            getRowClassName={(params) => {
              return stateToClassName(params.row.state);
            }}
            columns={StateHistoryColumns}
            rows={allHistory}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
