import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Urls } from "../domain/urls";
import { Avatar } from "@mui/material";
import { ProfileIcon } from "../icons/ProfileIcon";
import { LogoutIcon } from "../icons/LogoutIcon";
import { UserContext } from "../crud/user/context";
import { useCookie } from "../utils/hooks";
import { VIEWEDACCOUNT_COOKIE } from "../cookieUtils";
import { useLogoutMutation } from "../crud/auth/hooks";
type Props = {
  anchorEl: null | HTMLElement;
  userMenuOpen: boolean;
  handleUserMenuClose: () => void;
  handleUserMenu: (e: React.MouseEvent<HTMLElement>) => void;
};

export const UserMenu = ({
  anchorEl,
  userMenuOpen,
  handleUserMenuClose,
  handleUserMenu,
}: Props): React.ReactElement => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();
  const [, setViewedAccountCookie] = useCookie(VIEWEDACCOUNT_COOKIE);
  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleUserMenu}
        color="inherit"
        id="user-menu"
        size="large"
      >
        <Avatar
          sx={{ maxWidth: "28px", maxHeight: "28px" }}
          variant="circular"
          src={user.profile.avatarUrl}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={userMenuOpen}
        onClose={handleUserMenuClose}
      >
        <MenuItem
          component={Link}
          to={Urls.Profile}
          onClick={handleUserMenuClose}
        >
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText
            primary="Profile"
            primaryTypographyProps={{ variant: "body1" }}
          />
        </MenuItem>
        <MenuItem
          id="profile-logout"
          onClick={() => {
            setViewedAccountCookie(undefined);
            logoutMutation.mutate({});
            navigate(`/${Urls.Login}`);
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ variant: "body1" }}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
