import React from "react";
import { Button, ButtonProps, Box } from "@mui/material";
import { useSpring, animated as a, config } from "react-spring";

interface AnimatedButtonProps extends ButtonProps {
  animate?: boolean;
}

const AnimatedButton: React.FC<AnimatedButtonProps> = ({
  animate,
  ...props
}) => {
  const { scale, opacity } = useSpring({
    from: { scale: 1.0, opacity: 0.3 },
    to: { scale: 1.5, opacity: 0.0 },
    loop: true,
    config: config.slow,
  });

  const AnimatedBox = a(Box);

  return (
    <Box position="relative" display="inline-block" overflow="visible">
      <AnimatedBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        style={
          animate && !props.disabled
            ? {
                float: "revert",
                scale,
                opacity,
                backgroundColor: "grey",
              }
            : undefined
        }
      />
      <Button {...props}>{props.children}</Button>
    </Box>
  );
};

export default AnimatedButton;
