import React, { useContext } from "react";
import {
  DataGridPro,
  GridColDef,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { useWorkspaceContextSlug } from "../../utils/hooks";
import { useQuery } from "react-query";
import { getSoftwareSpecYaml } from "../../crud/software/fetch";
import { LoadingButton } from "@mui/lab";
import { SoftwareSpecContext } from "../../crud/software/context";
import { PackageSchema } from "../../api-client";

declare module "@mui/x-data-grid-pro" {
  interface FooterPropsOverrides {
    specId: number | undefined;
  }
}

const gridColumns: GridColDef<PackageSchema>[] = [
  {
    field: "name",
    headerName: "Package Name",
    flex: 1.5,
    valueGetter: (params) => {
      return params.row.condaName || params.row.name || "";
    },
  },
  { field: "specifier", headerName: "Specifier", flex: 1 },
  {
    field: "clientVersion",
    headerName: "Local Version",
    flex: 1,
    valueGetter: (params) => params.row.clientVersion || "",
  },
  {
    field: "source",
    headerName: "Source",
    flex: 0.75,
    valueGetter: (params) => (params.row.file ? "File" : params.row.source),
  },
  {
    field: "channel",
    headerName: "Channel",
    flex: 2,
    valueGetter: (params) => params.row.channel || "",
  },
  {
    field: "filename",
    headerName: "File",
    flex: 1.5,
    valueGetter: (params) => params.row.file?.fileName,
  },
];
const ExportYamlButton = ({
  specId,
}: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  const account = useWorkspaceContextSlug();
  const { data, isFetched } = useQuery(
    ["get-spec-yaml", specId],
    () => getSoftwareSpecYaml(account, specId as number),
    { enabled: !!specId },
  );
  const handleDownload = () => {
    if (data) {
      const link = document.createElement("a");
      link.href = "data:text/yaml," + encodeURIComponent(data);
      link.download = `env.yaml`;
      link.click();
    }
  };
  return (
    <Box sx={{ padding: 1 }}>
      <LoadingButton loading={!isFetched} onClick={handleDownload}>
        Export for Conda
      </LoadingButton>
    </Box>
  );
};

export const SoftwareSpecTable = (): React.ReactElement => {
  const spec = useContext(SoftwareSpecContext);
  return (
    <DataGridPro
      sx={{ height: "100%", border: "unset" }}
      pagination={false}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      slots={{
        footer: ExportYamlButton,
      }}
      slotProps={{
        footer: { specId: spec.id },
      }}
      columns={gridColumns}
      rows={spec.packages.filter((pkg) => pkg.include) || []}
    />
  );
};
