/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstalledPackage
 */
export interface InstalledPackage {
    /**
     * 
     * @type {number}
     * @memberof InstalledPackage
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof InstalledPackage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledPackage
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledPackage
     */
    source: InstalledPackageSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof InstalledPackage
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledPackage
     */
    condaName?: string;
}


/**
 * @export
 */
export const InstalledPackageSourceEnum = {
    Conda: 'conda',
    Pip: 'pip'
} as const;
export type InstalledPackageSourceEnum = typeof InstalledPackageSourceEnum[keyof typeof InstalledPackageSourceEnum];


/**
 * Check if a given object implements the InstalledPackage interface.
 */
export function instanceOfInstalledPackage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function InstalledPackageFromJSON(json: any): InstalledPackage {
    return InstalledPackageFromJSONTyped(json, false);
}

export function InstalledPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstalledPackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'version': json['version'],
        'source': json['source'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'condaName': !exists(json, 'conda_name') ? undefined : json['conda_name'],
    };
}

export function InstalledPackageToJSON(value?: InstalledPackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'version': value.version,
        'source': value.source,
        'channel': value.channel,
        'conda_name': value.condaName,
    };
}

