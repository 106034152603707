/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComputationUpdate
 */
export interface ComputationUpdate {
    /**
     * 
     * @type {string}
     * @memberof ComputationUpdate
     */
    identifier: string;
    /**
     * 
     * @type {number}
     * @memberof ComputationUpdate
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof ComputationUpdate
     */
    stop: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComputationUpdate
     */
    code?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ComputationUpdate
     */
    callstack?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComputationUpdate
     */
    taskGroupNames?: Array<string>;
}

/**
 * Check if a given object implements the ComputationUpdate interface.
 */
export function instanceOfComputationUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "stop" in value;

    return isInstance;
}

export function ComputationUpdateFromJSON(json: any): ComputationUpdate {
    return ComputationUpdateFromJSONTyped(json, false);
}

export function ComputationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'start': json['start'],
        'stop': json['stop'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'callstack': !exists(json, 'callstack') ? undefined : json['callstack'],
        'taskGroupNames': !exists(json, 'task_group_names') ? undefined : json['task_group_names'],
    };
}

export function ComputationUpdateToJSON(value?: ComputationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'start': value.start,
        'stop': value.stop,
        'code': value.code,
        'callstack': value.callstack,
        'task_group_names': value.taskGroupNames,
    };
}

