/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubnetSchema
 */
export interface SubnetSchema {
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    availabilityZone: string;
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    availabilityZoneId: string;
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    cidr: string;
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    subnetId: string;
    /**
     * 
     * @type {string}
     * @memberof SubnetSchema
     */
    arn: string;
}

/**
 * Check if a given object implements the SubnetSchema interface.
 */
export function instanceOfSubnetSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availabilityZone" in value;
    isInstance = isInstance && "availabilityZoneId" in value;
    isInstance = isInstance && "cidr" in value;
    isInstance = isInstance && "subnetId" in value;
    isInstance = isInstance && "arn" in value;

    return isInstance;
}

export function SubnetSchemaFromJSON(json: any): SubnetSchema {
    return SubnetSchemaFromJSONTyped(json, false);
}

export function SubnetSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubnetSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'availabilityZone': json['availability_zone'],
        'availabilityZoneId': json['availability_zone_id'],
        'cidr': json['cidr'],
        'subnetId': json['subnet_id'],
        'arn': json['arn'],
    };
}

export function SubnetSchemaToJSON(value?: SubnetSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'availability_zone': value.availabilityZone,
        'availability_zone_id': value.availabilityZoneId,
        'cidr': value.cidr,
        'subnet_id': value.subnetId,
        'arn': value.arn,
    };
}

