import React from "react";
import { Container, Card, CardHeader, CardContent } from "@mui/material";

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

export const Section = (props: SectionProps): React.ReactElement => {
  const { title, children } = props;

  return (
    <Container maxWidth="xs" sx={{ minHeight: "100vh" }}>
      <Card>
        <CardHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
          title={title}
        />
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
};
