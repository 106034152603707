import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { UseQueryResult } from "react-query";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useClusterMetrics } from "../../../../crud/metrics/hooks";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { getLatestTimestamp } from "../utils";
import { GroupedMetric } from "../../../../crud/metrics/types";
import { ZoomableAreaChartProps } from "./ZoomableAreaChart";
import { ZoomableAreaChartWithQuery } from "./ZoomableAreaChartWithQuery";
import { Variation } from "./types";

type ZoomableAreaChartWithVariationsProps = Omit<
  ZoomableAreaChartProps,
  "data" | "domains" | "isLoading" | "isError"
> & {
  clusterId: string;
  variations: Variation[];
  title: string;
  chartKey?: string;
};

/**
 * A zoomable area chart that allows the user to select from a list of variations
 * and automatically fetches the appropriate data.
 */
export const ZoomableAreaChartWithVariations = (
  props: ZoomableAreaChartWithVariationsProps,
): ReactElement => {
  const variationKey = `${
    props.chartKey || props.title.toLowerCase()
  }_variation`;
  // use local storage for variation preference if not explicitly set in URL
  const defaultVariationValue =
    localStorage.getItem(variationKey) || props.variations?.[0]?.name;

  const [selectedVariationName, setSelectedVariationName] = useQueryParam(
    variationKey,
    withDefault(StringParam, defaultVariationValue),
  );
  const selectedVariation =
    props.variations.find(
      (v) => v.name === (selectedVariationName || defaultVariationValue),
    ) || props.variations?.[0];

  const domains = selectedVariation?.domains || [];
  const yAxis = selectedVariation?.yAxis || props.yAxis;
  const legend = selectedVariation?.legend || props.legend;

  const query = useClusterMetrics(
    props.clusterId,
    selectedVariation.groupKey,
    selectedVariation.queries,
    selectedVariation.name,
    selectedVariation?.domains,
    props.defaultTimeRange,
  );

  const fetchNextPage = useCallback(() => {
    const latestTimestamp = getLatestTimestamp(query.data?.pages);

    if (latestTimestamp || props.defaultTimeRange) {
      query.fetchNextPage({
        pageParam:
          latestTimestamp || Math.floor(props.defaultTimeRange![0] / 1000),
      });
    }
  }, [query]);

  // Fetch new data when defaultTimeRange updates
  useEffect(() => {
    if (props.defaultTimeRange && props.clusterIsRunning) {
      fetchNextPage();
    }
  }, [props.defaultTimeRange, props.clusterIsRunning]);

  const metricsQuery = useMemo(
    () =>
      ({
        ...query,
        data: query.data?.pages?.flatMap((p) => p.data) || [],
      }) as UseQueryResult<GroupedMetric[]>,
    [query.data],
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    localStorage.setItem(variationKey, event.target.value);
    setSelectedVariationName(event.target.value);
  };

  const title =
    props.variations?.length > 1 ? (
      <>
        {props.title}
        <Select
          sx={{ ml: 1 }}
          variant="standard"
          value={selectedVariation?.name}
          onChange={handleChange}
        >
          {props.variations.map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </>
    ) : (
      <>{props.title}</>
    );

  return (
    <ZoomableAreaChartWithQuery
      {...props}
      legend={legend}
      yAxis={yAxis}
      title={title}
      domains={domains}
      metricsQuery={metricsQuery}
    />
  );
};
