import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  StepProps,
  StepperContext,
} from "../../../shared-components/StepperContext";
import { useAnalytics } from "use-analytics";
import { BackendTypeServer } from "../../../domain/people";
import { InfoOutlined } from "@mui/icons-material";

export const GCPBeforeStep = ({ index }: StepProps): React.ReactElement => {
  const { dispatch } = useContext(StepperContext);
  const analytics = useAnalytics();
  return (
    <GCPBeforeForm
      onSuccess={() => {
        dispatch({ type: "STEP_COMPLETED", payload: index });
        dispatch({ type: "COLLAPSE_STEP", payload: index });
        analytics.track("setup-before-step-completed", {
          provider: BackendTypeServer.GCP_HOSTED,
        });
      }}
    />
  );
};
type GCPBeforeFormProps = {
  disabled?: boolean;
  onSuccess: () => void;
};

const GCPBeforeForm = ({
  onSuccess,
}: GCPBeforeFormProps): React.ReactElement => {
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
  return (
    <Stack alignItems={"flex-start"}>
      <Dialog
        open={resourceDialogOpen}
        onClose={() => setResourceDialogOpen(false)}
      >
        <DialogTitle>Resources</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Coiled will create these resources in your GCP account.</p>
            <p>
              <strong>
                Note: We only create resources that do not incur any charges
                from your cloud provider.
              </strong>
            </p>
          </DialogContentText>
          <List dense>
            <ListItem>
              <ListItemText
                primary="Cluster Service Account"
                secondary="Grants clusters logging permissions."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Bigquery Table"
                secondary="Used to store logs from your cluster."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Firewalls"
                secondary="Allows us to control ingress/egress traffic to/from your cluster."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VPC + Subnets"
                secondary="A network to place your clusters in."
              />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <List>
        <ListItem>
          <ListItemText
            primary="You'll need a GCP account and admin credentials."
            secondary={
              <>
                {"If you don’t have these, consider the "}
                <Link href="https://cloud.google.com/free" target="_blank">
                  GCP free trial
                </Link>
                {" or talk to a colleague who does."}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="This will not create resources that cost money." />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Stack direction={"row"} spacing={1}>
                <span>{"This will not grant Coiled access to your data."}</span>
                <Link href="https://www.coiled.io/security" target="_blank">
                  <InfoOutlined fontSize="small" />
                </Link>
              </Stack>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span>
                {"Here are some details of the"}{" "}
                <Link onClick={() => setResourceDialogOpen(true)}>
                  resources
                </Link>
                {" that will be created."}
              </span>
            }
          />
        </ListItem>
      </List>
      <Button onClick={() => onSuccess()}>Next</Button>
    </Stack>
  );
};
