import React, { useMemo } from "react";
import { useBillingEvents } from "../../crud/billingevents/hooks";
import { DataGridProCard } from "../../shared-components/Cards";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  BillingEventListSchema,
  PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum,
  PricingNinjaViewsBillingEventsListBillingEventsRequest,
} from "../../api-client";
import { Stack } from "@mui/system";
import { KeyValue, KeyValueAutocomplete } from "./AutocompleteCustom";
import { ApiClient, camelizeKeys } from "../../apiUtils";
import { RefreshSelector } from "../../shared-components/RefreshSelector";

export const BillingEventListView = (): React.ReactElement => {
  const [filters, setFilters] = React.useState<KeyValue[]>([]);
  const [refreshInterval, setRefreshInterval] = React.useState<number>(0);
  const {
    data: billingEvents,
    isLoading,
    fetchNextPage,
    refetch,
  } = useBillingEvents(
    camelizeKeys(
      filters.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }, {}),
        {},
      ),
    ) as PricingNinjaViewsBillingEventsListBillingEventsRequest,
  );
  const columns = useMemo<GridColDef<BillingEventListSchema>[]>(
    () => [
      {
        field: "programId",
        flex: 1,
      },
      {
        field: "organization",
        headerName: "Organization",
        flex: 1,
      },

      { field: "account", headerName: "Account", flex: 1 },
      { field: "kind", headerName: "Kind", flex: 1 },
      { field: "amountCredits", headerName: "Credits", type: "number" },
      { field: "note", headerName: "Note" },
      { field: "eventDatetime", headerName: "Time", type: "dateTime" },
      { field: "clusterId" },
      { field: "instanceId" },
      { field: "creator" },
      { field: "user" },
    ],
    [],
  );
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems={"flex-start"}>
        <KeyValueAutocomplete
          fetchKeys={async () =>
            Object.values(
              PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum,
            )
          }
          fetchValues={async (key, startsWith) =>
            ApiClient.pricingNinjaViewsBillingEventsGetBillingEventsListKeyValues(
              {
                key: key as PricingNinjaViewsBillingEventsGetBillingEventsListKeyValuesKeyEnum,
                startsWith,
              },
            )
          }
          keyValuesChanged={(keyValues) => setFilters(keyValues)}
        />
        <RefreshSelector
          onClick={refetch}
          refreshInterval={refreshInterval}
          setRefreshInterval={setRefreshInterval}
          query="billingEvents"
        />
      </Stack>
      <DataGridProCard
        sx={{ height: "calc(100vh - 200px)" }}
        dataGridProps={{
          loading: isLoading,
          rowCount: billingEvents?.pages[0].count || 0,
          onRowsScrollEnd: () => fetchNextPage(),
          rows: billingEvents?.pages.flatMap((p) => p.items) || [],
          columns,
        }}
      />
    </Stack>
  );
};
