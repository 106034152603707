import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useAllTagValues, useTags } from "../../crud/tags/hooks";
import { Tag, TagOption, TagValues } from "../../crud/tags/types";
import { some } from "lodash";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { TagFilter } from "./types";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { useScopedContext } from "../../shared-components/ScopeSelector";

const ANY_OPTION = {
  label: "*",
  value: "*",
};

type ClusterFilterFieldProps = {
  tagFilters?: TagFilter[];
  setTagFilters: (tagFilters: TagFilter[]) => void;
  clusterNameFilter: string;
  setClusterNameFilter: (clusterNameFilter: string) => void;
};

export const ClusterFilterField = ({
  tagFilters,
  setTagFilters,
  clusterNameFilter,
  setClusterNameFilter,
}: ClusterFilterFieldProps): React.ReactElement => {
  const [scope] = useScopedContext();
  const formattedTagFilters =
    tagFilters?.map((filter) =>
      !filter?.includes(":") ? `${filter}:*` : filter,
    ) || [];

  const [isOpen, setIsOpen] = useState(false);
  const [highlightedOption, setHighlightedOption] = useState<TagOption | null>(
    null,
  );
  const { data: tags } = useTags({
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    systemTags: true,
  });
  const { data: allTagValues } = useAllTagValues({
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
  });
  const options = getOptionsFromTagValues(
    tags,
    allTagValues,
    formattedTagFilters,
    clusterNameFilter,
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const formatAndSetTagFilters = (filters: TagFilter[]) => {
    const formattedFilters = filters.map((filter) =>
      // Remove trailing ":*" from "match any" tag filters before
      // sending the to the backend
      filter?.endsWith(":*") ? filter.slice(0, -2) : filter,
    );
    setTagFilters(formattedFilters);
  };

  const setTagFiltersAndClearNameFilter = (filters: TagFilter[]) => {
    formatAndSetTagFilters(filters);
    setClusterNameFilter("");
  };

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string,
  ) => {
    if (event.type === "change") {
      setClusterNameFilter(newInputValue);
    }
  };

  const isExistingTag = (tag: TagOption | TagFilter) => {
    const tagValue = ((tag as TagOption)?.value || tag) as string;
    const tagName = tagValue.split(":")[0];
    return some(options, (option) => option.value.split(":")[0] === tagName);
  };

  // Handle options being added or removed
  const handleOptionChange = (
    event: React.SyntheticEvent,
    values: (string | TagOption)[],
  ) => {
    const newTagFilters = (values || [])
      .map(tagOptionToTagFilter)
      .filter((v) => !!v) as TagFilter[];

    // Removing a value
    if (newTagFilters.length < formattedTagFilters.length) {
      formatAndSetTagFilters(newTagFilters);
      return;
    }

    const lastTag = newTagFilters[newTagFilters.length - 1];

    // Let's make sure the tag exists before adding it
    if (isEnteringValue(clusterNameFilter)) {
      const tagName = clusterNameFilter.split(":")[0];
      const newTagFilter = isExistingTag(lastTag)
        ? `${tagName}:${lastTag}` // Autocompleted tag value (user-made tag)
        : lastTag; // Free form value

      // If we're already entering a value, let's add the tag filter
      setTagFiltersAndClearNameFilter([...formattedTagFilters, newTagFilter]);
    } else if (isExistingTag(lastTag)) {
      // If we're not yet entering a value, let's start entering a value by adding a colon (":")
      setClusterNameFilter(`${lastTag}:`);

      // Let's make sure popup stays open
      setTimeout(() => {
        setIsOpen(true);
      }, 0);
    }
  };

  // Handle tab being pressed to autocomplete a tag name or tag value
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === "Tab" && highlightedOption) {
      event.preventDefault();

      const tagName = clusterNameFilter.split(":")[0];

      if (isEnteringValue(clusterNameFilter)) {
        // If we're already in value entering mode, let's add the tag filter when pressing tab
        setTagFiltersAndClearNameFilter([
          ...formattedTagFilters,
          `${tagName}:${highlightedOption.value}`,
        ]);
      } else if (!isEnteringValue(highlightedOption?.value)) {
        // If we're not yet entering a value, let's start entering a value
        setClusterNameFilter(`${highlightedOption.value}:`);
      }
    }
  };

  // Track the highlighted option so we can add a colon (":") when pressing tab
  const handleHighlightChange = (
    event: React.SyntheticEvent,
    option: TagOption | null,
  ) => {
    setHighlightedOption(option);
  };

  // Let's not show already selected tags in the options
  const optionsWithoutTagFilter = options.filter((option) => !option.tagFilter);

  return (
    <Autocomplete
      freeSolo
      multiple
      autoHighlight
      options={optionsWithoutTagFilter}
      slotProps={{
        paper: {
          elevation: 8,
        },
      }}
      filterOptions={filterOptions}
      value={mapTagFiltersToOptions(formattedTagFilters, options)}
      renderOption={(props, option) => {
        const isHighlighted = option.value === highlightedOption?.value;
        return !option ? null : (
          <li {...props}>
            <Chip
              label={
                <>
                  {formatTagLabel(option.label)}{" "}
                  {isHighlighted ? (
                    <>
                      <KeyboardReturnIcon
                        sx={{ verticalAlign: "middle", fontSize: "11px" }}
                      />
                    </>
                  ) : null}
                </>
              }
              size="small"
              color={option.systemTag ? "default" : "info"}
            />{" "}
            {
              // Special formatting for the match any option
              option.label.split(":")[0] === ANY_OPTION.label ? (
                <i
                  style={{
                    display: "inline-block",
                    marginLeft: "8px",
                  }}
                >
                  match any value
                </i>
              ) : null
            }
          </li>
        );
      }}
      inputValue={clusterNameFilter}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleOptionChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onHighlightChange={handleHighlightChange}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) =>
          option ? (
            <Chip
              label={formatTagLabel(option.label)}
              {...getTagProps({ index })}
              size="small"
              color={option.systemTag ? "default" : "info"}
              key={option.label}
            />
          ) : null,
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            ...params.InputProps,
            sx: {
              backgroundColor: (theme) => theme.palette.background.paper,
            },
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          placeholder="Filter by tags and/or by cluster name..."
          size="small"
          variant="outlined"
        />
      )}
    />
  );
};

const isEnteringValue = (tag?: string) => (tag || "").includes(":");
const isEnteringSystemTagValue = (clusterNameFilter: string, tags: Tag[]) => {
  const tagName = clusterNameFilter.split(":")[0];
  const tag = tags.find(({ label }) => label === tagName);

  return !!tag?.systemTag;
};
/**
 * Decides what items are shown in the autocomplete list.
 * Matching logic for filter is different depending on if we're adding a tag name or value
 */
const filterOptions = (
  options: TagOption[],
  { inputValue }: { inputValue: string },
) => {
  const value = isEnteringValue(inputValue)
    ? inputValue.split(":")[1]
    : inputValue;

  return options.filter((option) => option.label.includes(value));
};

/**
 * MUI Autocomplete can return both a string or an option object, so we need to standardize the value to a string
 */
const tagOptionToTagFilter = (option: string | TagOption) =>
  ((option as TagOption)?.value || option) as string;

const mapTagFiltersToOptions = (
  tagFilters?: TagFilter[],
  options?: TagOption[],
) => {
  return (tagFilters || [])
    .map((tag) => {
      return options?.find((option) => option.value === tag);
    })
    .filter((option) => !!option) as TagOption[];
};

/**
 * Selected tag filters need to also be in the options to be visible,
 * so let's add them
 */
const mergeOptionsAndTagFilters = (
  options: TagOption[],
  tagFilters?: TagFilter[],
): TagOption[] => {
  const optionsMap: Record<string, TagOption> = {};

  options.forEach((option) => {
    optionsMap[option.value] = option;
  });

  (tagFilters || []).forEach((tagValue) => {
    if (!tagValue) return;
    const tagName = tagValue.split(":")[0];
    const systemTag = optionsMap[tagName]?.systemTag;

    if (optionsMap[tagName]) {
      delete optionsMap[tagName];
    }

    optionsMap[tagValue] = {
      label: tagValue,
      value: tagValue,
      tagFilter: true,
      systemTag,
    };
  });

  return Object.values(optionsMap);
};

/**
 * We need to add selected tags (tagFilters) to the options so that they can be selected
 */
const getOptionsFromTagValues = (
  tags: Tag[] = [],
  tagValues?: TagValues,
  tagFilters?: TagFilter[],
  clusterNameFilter?: string,
): TagOption[] => {
  let options = tags.map((tag) => ({
    ...tag,
    value: tag.label,
  }));

  // If we are entering a value, let's suggest tag values instead of tag names
  if (clusterNameFilter && isEnteringValue(clusterNameFilter)) {
    const tagName = clusterNameFilter.split(":")[0];

    options = [
      {
        ...ANY_OPTION,
        systemTag: isEnteringSystemTagValue(clusterNameFilter, tags),
      },
    ];

    const tagValueOptions = tagValues?.[tagName]?.map((tagValue) => ({
      label: tagValue,
      value: tagValue,
    }));

    options = options.concat(tagValueOptions || []);
  }

  options = mergeOptionsAndTagFilters(options, tagFilters);
  options = withClusterNameFilter(options, clusterNameFilter);

  return options;
};

const withClusterNameFilter = (
  options: TagOption[],
  clusterNameFilter?: string,
) => {
  if (isEnteringValue(clusterNameFilter)) {
    const tagName = clusterNameFilter?.split(":")[0];
    let newTag;
    options.forEach((option) => {
      if (option.value === tagName) {
        newTag = {
          ...option,
          label: clusterNameFilter,
          value: clusterNameFilter,
        };
      }
    });

    if (newTag) {
      options.push(newTag);
    }
  }

  return options;
};

/**
 * Makes tag values bold
 */
const formatTagLabel = (label: string) => {
  const tagParts = label.split(":");

  const tagLabel =
    tagParts.length > 1 ? (
      <>
        {tagParts[0]}: <b>{tagParts[1]}</b>
      </>
    ) : (
      <>{tagParts[0]}</>
    );

  return tagLabel;
};
