import React, { useMemo, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { Page } from "../../shared-components/Page";
import { NavTabs } from "../../shared-components/NavTabs";
import { CredentialsForm } from "./Forms/CredentialsForm";
import { Card, Stack } from "@mui/material";
import { InfrastructureForm } from "./Forms/Infrastructure";
import { SettingsForm } from "./Forms/Settings";
import { BackendTypesEnum, AccountScopeSchema } from "../../api-client";
import { useAccountDetails } from "../../crud/account/hooks";
import { LoadingScreen } from "../../shared-components/LoadingScreen";
import { SelectSomething } from "../../shared-components/SelectSomething";
import { WorkspaceSelector } from "../../shared-components/WorkspaceSelector";

const AccountLoader = ({
  workspaceId,
}: {
  workspaceId: number;
}): React.ReactElement => {
  const { data, isSuccess } = useAccountDetails({ workspaceId });
  if (!isSuccess) {
    return <LoadingScreen />;
  }
  return (
    <Card>
      <Stack>
        <NavTabs
          tabs={[
            {
              href: `/${Urls.Setup}/${Urls.Credentials}`,
              label: "Credentials",
            },
            {
              href: `/${Urls.Setup}/${Urls.Infrastructure}`,
              label: "Infrastructure",
              disabled:
                !data.activeBackend ||
                data.activeBackend === BackendTypesEnum.VmAzure,
            },
            {
              href: `/${Urls.Setup}/${Urls.AccountSettings}`,
              label: "Settings",
              disabled:
                !data.activeBackend ||
                data.activeBackend === BackendTypesEnum.VmAzure,
            },
          ]}
        />
        <Routes>
          <Route
            path="/update-backend-options"
            element={
              <Navigate to={`/${Urls.Setup}/${Urls.Credentials}`} replace />
            }
          />
          <Route
            path={Urls.Credentials}
            element={<CredentialsForm workspace={data} />}
          />
          <Route
            path={Urls.Infrastructure}
            element={<InfrastructureForm workspace={data} />}
          />
          <Route
            path={Urls.AccountSettings}
            element={<SettingsForm workspace={data} />}
          />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={
                  data.activeBackend
                    ? `/${Urls.Setup}/${Urls.Infrastructure}`
                    : `/${Urls.Setup}/${Urls.Credentials}`
                }
              />
            }
          />
        </Routes>
      </Stack>
    </Card>
  );
};

export const SetupRouter = (): React.ReactElement => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    AccountScopeSchema | undefined
  >();
  const crumbs = useMemo(
    () => [
      { text: "Cloud Provider" },
      ...(selectedWorkspace?.name ? [{ text: selectedWorkspace.name }] : []),
    ],
    [selectedWorkspace],
  );
  return (
    <Page title="Cloud Provider Setup" breadCrumbs={crumbs}>
      <Stack spacing={2}>
        <div style={{ marginLeft: "auto" }}>
          <WorkspaceSelector onChange={setSelectedWorkspace} />
        </div>
        {selectedWorkspace && (
          <AccountLoader workspaceId={selectedWorkspace.id} />
        )}
        {!selectedWorkspace && (
          <SelectSomething message="Select a workspace." />
        )}
      </Stack>
    </Page>
  );
};
