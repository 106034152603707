import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const SoftwareIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      width="22px"
      height="20px"
      viewBox="0 0 22 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-/-software-/-dark"
          transform="translate(0.000000, -1.000000)"
          stroke="currentColor"
        >
          <g
            id="icon-/-software-/-light"
            transform="translate(0.000000, 1.000000)"
          >
            <line
              x1="0.611111111"
              y1="3.38888889"
              x2="13.61"
              y2="3.39"
              id="Line-2"
              strokeLinecap="square"
            />
            <line
              x1="18.39"
              y1="3.39"
              x2="21.3888889"
              y2="3.38888889"
              id="Line-2"
              strokeLinecap="square"
            />
            <line
              x1="0.611111111"
              y1="10.3888889"
              x2="4"
              y2="10.39"
              id="Line-2-Copy"
              strokeLinecap="square"
            />
            <line
              x1="13.39"
              y1="17.39"
              x2="21.3888889"
              y2="17.3888889"
              id="Line-2-Copy-2"
              strokeLinecap="square"
            />
            <circle id="Oval" cx="11" cy="17.3888889" r="2" />
            <circle id="Oval-Copy-9" cx="16" cy="3.38888889" r="2" />
            <circle id="Oval-Copy" cx="6" cy="10.3888889" r="2" />
            <line
              x1="8.5"
              y1="10.39"
              x2="21.3888889"
              y2="10.3888889"
              id="Line-2-Copy"
              strokeLinecap="square"
            />
            <line
              x1="0.611111111"
              y1="17.3888889"
              x2="8.61"
              y2="17.39"
              id="Line-2-Copy-2"
              strokeLinecap="square"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
