import { datadogLogs } from "@datadog/browser-logs";

// Stolen from MDN docs:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const storageAvailable = (): boolean | undefined => {
  let localStorage;
  try {
    localStorage = window.localStorage;
    const x = "__storage_test__";
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      localStorage &&
      localStorage.length !== 0
    );
  }
};

interface SafeStorage extends Storage {
  isNoOp?: boolean;
}

export const storage: SafeStorage = (() => {
  if (storageAvailable()) {
    return window.localStorage;
  } else {
    /* eslint-disable-next-line no-console */
    console.log("localStorage not available");
    datadogLogs.logger.error("localStorage not available");
    return {
      isNoOp: true,
      length: 0,
      clear: (): void => {},
      getItem: (key: string): string | null => null,
      key: (index: number): string | null => null,
      removeItem: (key: string): void => {},
      setItem: (key: string, value: string): void => {},
    };
  }
})();
