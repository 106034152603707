import React, { useContext } from "react";
import { CardContent } from "@mui/material";
import { UserContext } from "../../../crud/user/context";
import {
  AverageWorkerCpuChart,
  CombinedCpuChart,
  SingleNodeCpuChart,
} from "./charts/AverageWorkerCpuChart";
import {
  CombinedMemoryChart,
  SingleMachineMemoryChart,
  WorkerMemoryChart,
} from "./charts/WorkerMemoryChart";
import {
  CombinedNetChart,
  SingleNodeNetChart,
  WorkerNetChart,
} from "./charts/WorkerNetChart";
import {
  CombinedDiskChart,
  SingleNodeDiskChart,
  WorkerDiskChart,
} from "./charts/WorkerDiskChart";
import { GilChart, MiscMetricChart } from "./charts/MiscMetricChart";
import { ChartProps, ClusterUseCase } from "./charts/types";
import {
  ClusterGpuMemChart,
  ClusterGpuUtilChart,
  CombinedGpuMemChart,
  CombinedGpuUtilChart,
  HostGpuMemChart,
  HostGpuUtilChart,
} from "./charts/GpuCharts";

export const MetricsView = (props: ChartProps): React.ReactElement => {
  const { user } = useContext(UserContext);
  return (
    <CardContent
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {props.clusterUseCase === ClusterUseCase.singleMachine ? (
        // show "host" (scheduler) metrics
        <>
          <SingleNodeCpuChart {...props} />
          <SingleMachineMemoryChart {...props} />
          {props.hasGpu ? (
            <>
              <HostGpuUtilChart {...props} />
              <HostGpuMemChart {...props} />
            </>
          ) : (
            <></>
          )}
          <SingleNodeNetChart {...props} />
          <SingleNodeDiskChart {...props} />
        </>
      ) : props.clusterUseCase === ClusterUseCase.workerOnSchedulerCluster ? (
        // show combined metrics (@coiled.function clusters)
        <>
          <CombinedCpuChart {...props} />
          <CombinedMemoryChart {...props} />
          {props.hasGpu ? (
            <>
              <CombinedGpuUtilChart {...props} />
              <CombinedGpuMemChart {...props} />
            </>
          ) : (
            <></>
          )}
          <CombinedDiskChart {...props} />
          <CombinedNetChart {...props} />
        </>
      ) : (
        // normal clusters show scheduler and worker metrics separately
        <>
          <AverageWorkerCpuChart {...props} />
          <WorkerMemoryChart {...props} />
          {props.hasGpu ? (
            <>
              <ClusterGpuUtilChart {...props} />
              <ClusterGpuMemChart {...props} />
            </>
          ) : (
            <></>
          )}
          <WorkerDiskChart {...props} />
          <WorkerNetChart {...props} />
        </>
      )}
      <GilChart {...props} />
      {user.isStaff && <MiscMetricChart {...props} />}
    </CardContent>
  );
};
