import { HTTPError } from "ky";

export const handleError = async (err: Error): Promise<void> => {
  if (err instanceof HTTPError) {
    const serverResponse = await err.response?.json();

    if (serverResponse?.code === "COILED_EXCEPTION") {
      throw new CoiledException(serverResponse.message);
    }
  }
};

export class CoiledException extends Error {}
