import React from "react";
import { Stop } from "@mui/icons-material";
import { Button } from "@mui/material";
import { StopClusterDialog } from "./StopClusterDialog";
import { ClusterFrontendSchema, ClusterSchema } from "../../api-client";

type StopButtonType = {
  accountSlug: string;
  cluster: ClusterFrontendSchema;
  clusterStopping?: boolean;
};

export const StopButton = (props: StopButtonType): React.ReactElement => {
  const [stopTarget, setStopTarget] = React.useState<
    ClusterSchema | ClusterFrontendSchema | undefined
  >(undefined);
  const [stopClusterDialogOpen, setStopClusterDialogOpen] =
    React.useState(false);
  return (
    <>
      {stopTarget && (
        <StopClusterDialog
          open={stopClusterDialogOpen}
          onClose={() => setStopClusterDialogOpen(false)}
          stopTarget={{
            accountSlug: props.accountSlug,
            clusterId: stopTarget.id,
            clusterCreatorId: stopTarget.creator.id,
            clusterCreatorName: stopTarget.creator.username,
          }}
        />
      )}
      <Button
        startIcon={
          <Stop
            sx={({ palette }) => ({
              mr: -0.5,
              color: props.clusterStopping
                ? palette.custom.grey
                : palette.error.main,
            })}
          />
        }
        variant="secondary"
        size="small"
        disabled={props.clusterStopping}
        onClick={() => {
          setStopTarget(props.cluster);
          setStopClusterDialogOpen(true);
        }}
      >
        Stop Cluster
      </Button>
    </>
  );
};
