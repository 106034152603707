import React from "react";
import { useOrganization } from "../../crud/organizations/hooks";
import { useProgram, useProgramUsage } from "../../crud/pricing/hooks";
import { SimpleTable } from "../../shared-components/SimpleTable";

type OrganizationDetailsProps = {
  organizationId: number;
};
export const StaffOrganizationDetails = ({
  organizationId,
}: OrganizationDetailsProps): React.ReactElement => {
  const { data: organization } = useOrganization(organizationId);
  const { data: program, isLoading: programLoading } = useProgram(
    organization?.activeProgramId,
  );
  const { data: programUsage, isLoading: programUsageLoading } =
    useProgramUsage(organization?.activeProgramId);
  return (
    <div>
      {organization?.activeProgramId && (
        <SimpleTable
          loading={programLoading || programUsageLoading}
          text={[
            ["Coiled Credit Value", program?.coiledCreditValue],
            ["Credit Allotment", program?.creditAllotment],
            ["Renew Date", program?.renewDate?.toLocaleDateString()],
            ["Current Credit Balance", programUsage?.currentCredits],
            ["Credit Spend This Month", programUsage?.programCreditsSpent],
          ]}
        />
      )}
      {!organization?.activeProgramId && <>No Program</>}
    </div>
  );
};
