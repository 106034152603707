/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrefixDurations
 */
export interface PrefixDurations {
    /**
     * 
     * @type {number}
     * @memberof PrefixDurations
     */
    compute: number;
    /**
     * 
     * @type {number}
     * @memberof PrefixDurations
     */
    diskWrite: number;
    /**
     * 
     * @type {number}
     * @memberof PrefixDurations
     */
    diskRead: number;
    /**
     * 
     * @type {number}
     * @memberof PrefixDurations
     */
    deserialize: number;
    /**
     * 
     * @type {number}
     * @memberof PrefixDurations
     */
    transfer: number;
    /**
     * 
     * @type {string}
     * @memberof PrefixDurations
     */
    prefixName: string;
}

/**
 * Check if a given object implements the PrefixDurations interface.
 */
export function instanceOfPrefixDurations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "compute" in value;
    isInstance = isInstance && "diskWrite" in value;
    isInstance = isInstance && "diskRead" in value;
    isInstance = isInstance && "deserialize" in value;
    isInstance = isInstance && "transfer" in value;
    isInstance = isInstance && "prefixName" in value;

    return isInstance;
}

export function PrefixDurationsFromJSON(json: any): PrefixDurations {
    return PrefixDurationsFromJSONTyped(json, false);
}

export function PrefixDurationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrefixDurations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compute': json['compute'],
        'diskWrite': json['disk_write'],
        'diskRead': json['disk_read'],
        'deserialize': json['deserialize'],
        'transfer': json['transfer'],
        'prefixName': json['prefix_name'],
    };
}

export function PrefixDurationsToJSON(value?: PrefixDurations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compute': value.compute,
        'disk_write': value.diskWrite,
        'disk_read': value.diskRead,
        'deserialize': value.deserialize,
        'transfer': value.transfer,
        'prefix_name': value.prefixName,
    };
}

