/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileSchema
 */
export interface UserProfileSchema {
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSchema
     */
    hasGitAccessToken?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSchema
     */
    canContact: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSchema
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSchema
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSchema
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSchema
     */
    workEmail: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSchema
     */
    phoneNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSchema
     */
    hasSshPublicKey?: boolean;
}

/**
 * Check if a given object implements the UserProfileSchema interface.
 */
export function instanceOfUserProfileSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "canContact" in value;
    isInstance = isInstance && "avatarUrl" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "workEmail" in value;
    isInstance = isInstance && "phoneNumber" in value;

    return isInstance;
}

export function UserProfileSchemaFromJSON(json: any): UserProfileSchema {
    return UserProfileSchemaFromJSONTyped(json, false);
}

export function UserProfileSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasGitAccessToken': !exists(json, 'has_git_access_token') ? undefined : json['has_git_access_token'],
        'canContact': json['can_contact'],
        'avatarUrl': json['avatar_url'],
        'title': json['title'],
        'company': json['company'],
        'workEmail': json['work_email'],
        'phoneNumber': json['phone_number'],
        'hasSshPublicKey': !exists(json, 'has_ssh_public_key') ? undefined : json['has_ssh_public_key'],
    };
}

export function UserProfileSchemaToJSON(value?: UserProfileSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_git_access_token': value.hasGitAccessToken,
        'can_contact': value.canContact,
        'avatar_url': value.avatarUrl,
        'title': value.title,
        'company': value.company,
        'work_email': value.workEmail,
        'phone_number': value.phoneNumber,
        'has_ssh_public_key': value.hasSshPublicKey,
    };
}

