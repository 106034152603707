/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Class for the received interaction
 * @export
 * @interface UserInteractionSchema
 */
export interface UserInteractionSchema {
    /**
     * 
     * @type {string}
     * @memberof UserInteractionSchema
     */
    action: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInteractionSchema
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionSchema
     */
    coiledVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionSchema
     */
    source?: UserInteractionSchemaSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionSchema
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof UserInteractionSchema
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserInteractionSchema
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionSchema
     */
    additionalText?: string;
    /**
     * 
     * @type {object}
     * @memberof UserInteractionSchema
     */
    additionalData?: object;
}


/**
 * @export
 */
export const UserInteractionSchemaSourceEnum = {
    Backend: 'backend',
    Frontend: 'frontend',
    PythonApi: 'python-api'
} as const;
export type UserInteractionSchemaSourceEnum = typeof UserInteractionSchemaSourceEnum[keyof typeof UserInteractionSchemaSourceEnum];


/**
 * Check if a given object implements the UserInteractionSchema interface.
 */
export function instanceOfUserInteractionSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function UserInteractionSchemaFromJSON(json: any): UserInteractionSchema {
    return UserInteractionSchemaFromJSONTyped(json, false);
}

export function UserInteractionSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInteractionSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'success': json['success'],
        'coiledVersion': !exists(json, 'coiled_version') ? undefined : json['coiled_version'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
        'accountId': !exists(json, 'account_id') ? undefined : json['account_id'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'additionalText': !exists(json, 'additional_text') ? undefined : json['additional_text'],
        'additionalData': !exists(json, 'additional_data') ? undefined : json['additional_data'],
    };
}

export function UserInteractionSchemaToJSON(value?: UserInteractionSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'success': value.success,
        'coiled_version': value.coiledVersion,
        'source': value.source,
        'error_message': value.errorMessage,
        'account_id': value.accountId,
        'organization_id': value.organizationId,
        'additional_text': value.additionalText,
        'additional_data': value.additionalData,
    };
}

