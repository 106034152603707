import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  styled,
} from "@mui/material";
import { QueryFetchingIndicator } from "./QueryFetchingIndicator";
import { formatSeconds } from "../utils";

const REFRESH_INTERVALS = [5, 10, 30, 60, 300, 600, 0];
type RefreshSelectorProps = {
  onClick?: () => void;
  query: string;
  refreshInterval: number;
  setRefreshInterval: (interval: number) => void;
};

const StyledSelectorButton = styled(Button)({
  paddingRight: 1,
  paddingLeft: 1,
  borderWidth: 1,
  minWidth: "0px",
  borderColor: "rgba(0, 0, 0, 0.23)",
});

export const RefreshSelector = ({
  query,
  onClick,
  refreshInterval,
  setRefreshInterval,
}: RefreshSelectorProps): React.ReactElement => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        variant="secondary"
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Tooltip title="Refresh">
          <StyledSelectorButton onClick={onClick}>
            <QueryFetchingIndicator query={query} />
          </StyledSelectorButton>
        </Tooltip>
        <Tooltip title="Select refresh interval">
          <StyledSelectorButton onClick={() => setOpen(!open)}>
            <ArrowDropDown />
          </StyledSelectorButton>
        </Tooltip>
      </ButtonGroup>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          {REFRESH_INTERVALS.map((interval) => (
            <MenuItem
              key={interval}
              selected={interval === refreshInterval}
              onClick={() => {
                setRefreshInterval(interval);
                setOpen(false);
              }}
            >
              {interval === 0 ? "Never" : `${formatSeconds(interval)}`}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};
