import React, { useContext } from "react";
import {
  useMatch,
  resolvePath,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import { useWorkspaceContextSlug } from "../utils/hooks";
import { theme } from "../theme";
import { ClusterContext } from "../pages/Clusters/context";
import { StopButton } from "../pages/Clusters/StopButton";
import { DashboardButton } from "../pages/Clusters/DashboardButton";

interface LinkTabProps extends TabProps {
  to?: string;
  value?: string;
}

const LinkTab = (props: LinkTabProps) => {
  return (
    <Tab
      sx={{ fontSize: "16px", minWidth: "10px" }}
      component={Link}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

type NavTabLocation = TabProps & {
  href: string;
  disabled?: boolean;
};
type NavTabsProps = {
  tabs: NavTabLocation[];
};
export const NavTabs = ({ tabs }: NavTabsProps): React.ReactElement => {
  const location = useLocation();
  const slug = useWorkspaceContextSlug();
  const { cluster } = useContext(ClusterContext);
  const clusterStopping =
    cluster &&
    ["stopped", "error", "stopping"].includes(cluster.currentState.state);

  const currentTab = useMatch(location.pathname)?.pathname;
  const navigate = useNavigate();
  return (
    <Tabs
      TabIndicatorProps={{
        style: { background: theme.palette.secondary.main },
      }}
      value={currentTab}
      onChange={(event, newValue) => {
        event.preventDefault();
        navigate(newValue);
      }}
    >
      {tabs.map((t) => (
        <LinkTab
          key={t.href}
          to={t.href}
          value={resolvePath(t.href).pathname}
          disabled={t.disabled}
          {...t}
        />
      ))}
      {cluster ? (
        <Box
          sx={{
            ml: "auto",
            mt: 1,
            mr: 2,
            "&>button": {
              ml: 1,
            },
          }}
        >
          <DashboardButton cluster={cluster} />
          <StopButton
            clusterStopping={clusterStopping}
            accountSlug={slug}
            cluster={cluster}
          />
        </Box>
      ) : null}
    </Tabs>
  );
};
