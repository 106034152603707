import React, { useMemo } from "react";
import { DataGridProCard } from "../../shared-components/Cards";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CacheEntrySchema,
  DeclarativeViewsPackagesCacheListRequest,
} from "../../api-client";
import { Stack } from "@mui/system";
import { camelizeKeys } from "../../apiUtils";
import { RefreshSelector } from "../../shared-components/RefreshSelector";
import { usePackagesCache } from "../../crud/cache/hooks";

export const RedisCacheListView = (): React.ReactElement => {
  const [refreshInterval, setRefreshInterval] = React.useState<number>(0);
  const {
    data: packagesCache,
    isLoading,
    fetchNextPage,
    refetch,
  } = usePackagesCache(
    camelizeKeys({ name: "" }) as DeclarativeViewsPackagesCacheListRequest,
  );

  const columns = useMemo<GridColDef<CacheEntrySchema>[]>(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "platform",
        headerName: "Platform",
        flex: 1,
      },
      {
        field: "source",
        headerName: "Source",
        flex: 1,
      },
      {
        field: "versions",
        headerName: "Versions",
        flex: 1,
      },
      {
        field: "channelUrl",
        headerName: "Channel URL",
        flex: 1,
      },
      {
        field: "pythonVersion",
        headerName: "Python Version",
        flex: 1,
      },
    ],
    [],
  );
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems={"flex-start"}>
        <RefreshSelector
          onClick={refetch}
          refreshInterval={refreshInterval}
          setRefreshInterval={setRefreshInterval}
          query="packagesCache"
        />
      </Stack>
      <DataGridProCard
        sx={{ height: "calc(100vh - 200px)" }}
        dataGridProps={{
          loading: isLoading,
          rowCount: packagesCache?.pages[0].count || 0,
          onRowsScrollEnd: () => fetchNextPage(),
          rows: packagesCache?.pages.flatMap((p) => p.items) || [],
          columns,
        }}
      />
    </Stack>
  );
};
