import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { StyledAlert } from "../Alerts/StyledAlert";
import { styled } from "@mui/material";
import { BannersContext } from "../../crud/banners/context";
import { Banner } from "../../crud/banners/types";

/* 
we iteratively create banners from our database
so here we need to shim in our styles 
*/
const StyledRoot = styled("div")(({ theme }) => {
  return {
    "& a": {
      color: theme.palette.primary.main,
      // TODO Fabio: Fix MuiLink issue
      // ...theme.overrides!.MuiLink!.root,
    },
    "& a:hover": {
      // TODO Fabio: Fix MuiLink issue
      // ...theme.overrides!.MuiLink!.root!["&.MuiLink-root:hover"],
    },
    "& p": {
      marginTop: "unset",
      marginBottom: "unset",
      ...theme.typography.body1,
    },
  };
});

export const BannerAlerts = (): React.ReactElement | null => {
  const banners = useContext(BannersContext);

  return banners.length > 0 ? (
    <StyledRoot>
      {banners.map((banner: Banner) => (
        <StyledAlert
          key={banner.id}
          severity={banner.level}
          title={banner.title}
          message={<ReactMarkdown>{banner.message}</ReactMarkdown>}
        />
      ))}
    </StyledRoot>
  ) : null;
};
