import {
  UseQueryResult,
  useQuery,
  UseMutationResult,
  useQueryClient,
  useMutation,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  DeclarativeViewsClusterClusterListV3Request,
  PagedListClusterV2,
} from "../../api-client";

export const useClusters = (
  request: DeclarativeViewsClusterClusterListV3Request,
): UseQueryResult<PagedListClusterV2> => {
  const query = useQuery<PagedListClusterV2>(
    ["clusterList", request],
    ({ signal }) => {
      return ApiClient.declarativeViewsClusterClusterListV3(request, {
        signal,
      });
    },
    {
      keepPreviousData: true,
      refetchInterval: 2000,
      notifyOnChangeProps: "tracked",
    },
  );
  return query;
};

type staffHasSeenProps = {
  account: string;
  pk: number;
  seen: boolean;
};
export const useStaffHasSeenMutation = (): UseMutationResult<
  boolean,
  unknown,
  staffHasSeenProps
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: "setStaffHasSeen",
    mutationFn: async ({ account, pk, seen }: staffHasSeenProps) =>
      ApiClient.declarativeViewsClusterStaffHasSeenCluster({
        account,
        pk,
        seen,
      }),
    onSuccess: (resp, variables) => {
      queryClient.setQueriesData<PagedListClusterV2>(
        ["clusterList"],
        (oldData) => {
          return oldData
            ? {
                items: oldData.items.map((item) => {
                  return item.id === variables.pk
                    ? {
                        ...item,
                        staffHasSeen: resp,
                      }
                    : item;
                }),
                count: oldData.count,
              }
            : { count: 0, items: [] };
        },
      );
    },
  });
};
