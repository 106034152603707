import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AWSCredentials, GCPCredentials } from "../../domain/people";
import {
  AvailablityZone,
  AWSRegionalInfra,
  AWSRegionalInfras,
  deleteAWSCredentials,
  getAWSCredentials,
  getAWSRegionalInfra,
  getAWSRegionAzs,
  getAWSSetupGlobalInfra,
  postAWSCredentials,
  postAWSRegionalInfra,
  postAWSSetupGlobalInfra,
  createCloudFormationSetupAttempt,
  CloudFormationSetupAttempt,
  getCloudFormationSetupAttempt,
  UserSubnet,
  getAWSRegionSubnets,
  patchAWSAccountSettings,
  AWSAccountSettings,
  getAWSAccountSettings,
  deleteAWSSetupGlobalInfra,
  UserSecurityGroup,
  getAWSRegionSecurityGroups,
  UserVPC,
  getAWSRegionVPCs,
  deleteAWSSetupRegionInfra,
  postGCPCredentials,
  deleteGCPCredentials,
  getGCPCredentials,
  getGCPSetupGlobalInfra,
  GCPRegionalInfras,
  getGCPRegionalInfra,
  postGCPSetupGlobalInfra,
  deleteGCPSetupGlobalInfra,
  postGCPRegionalInfra,
  deleteGCPSetupRegionInfra,
  UserNetwork,
  getGCPNetworks,
  getGCPSubnets,
  GCPUserSubnet,
  getGCPFirewallTags,
  GCPAccountSettings,
  getGCPAccountSettings,
  patchGCPAccountSettings,
  getRoleAssumptionSetup,
} from "./fetch";
import {
  AWSGlobalInfraSchema,
  GCPGlobalInfraSchema,
  GCPRegionalInfraSchema,
  RoleAssumptionSetupSchema,
} from "./types";
import { AccountQueryKeys } from "../account/hooks";

export const useUpdateAWSCredentialsMutation = (
  account: string,
): UseMutationResult<unknown, unknown, AWSCredentials, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (credentials: AWSCredentials) => {
      return postAWSCredentials(account, credentials);
    },
    {
      onSuccess: () => {
        client.invalidateQueries("awsCredentials");
        client.invalidateQueries(AccountQueryKeys.ACCOUNT_DETAILS);
      },
    },
  );
};
export const useUpdateGCPCredentialsMutation = (
  account: string,
): UseMutationResult<unknown, unknown, GCPCredentials, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (credentials: GCPCredentials) => {
      return postGCPCredentials(account, credentials);
    },
    {
      onSuccess: () => {
        client.invalidateQueries("gcpCredentials");
        client.invalidateQueries(AccountQueryKeys.ACCOUNT_DETAILS);
      },
    },
  );
};

export const useDeleteAWSCredentialsMutation = (
  account: string,
): UseMutationResult<unknown, unknown, void, unknown> => {
  const client = useQueryClient();

  return useMutation(
    () => {
      return deleteAWSCredentials(account);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(AccountQueryKeys.ACCOUNT_DETAILS);
        client.invalidateQueries("awsCredentials");
      },
    },
  );
};

export const useDeleteGCPCredentialsMutation = (
  account: string,
): UseMutationResult<unknown, unknown, void, unknown> => {
  const client = useQueryClient();

  return useMutation(
    () => {
      return deleteGCPCredentials(account);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(AccountQueryKeys.ACCOUNT_DETAILS);
        client.invalidateQueries("gcpCredentials");
      },
    },
  );
};

export const useRoleAssumptionSetup = (
  account: string,
): UseQueryResult<RoleAssumptionSetupSchema | undefined> => {
  return useQuery(["awsRoleAssumptionSetup", account], () => {
    return getRoleAssumptionSetup(account);
  });
};

export const useAWSCredentials = (
  account: string,
): UseQueryResult<AWSCredentials | undefined> => {
  return useQuery(["awsCredentials", account], () => {
    return getAWSCredentials(account);
  });
};

export const useGCPCredentials = (
  account: string,
): UseQueryResult<GCPCredentials | undefined> => {
  return useQuery(["gcpCredentials", account], () => {
    return getGCPCredentials(account);
  });
};

export const useRequestAWSGlobalSetup = (
  account: string,
): UseMutationResult<unknown, unknown, AWSGlobalInfraSchema, unknown> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      return postAWSSetupGlobalInfra(account);
    },
    {
      onSuccess: () => client.invalidateQueries(["awsGlobalInfra", account]),
    },
  );
};

export const useRequestGCPGlobalSetup = (
  account: string,
): UseMutationResult<unknown, unknown, GCPGlobalInfraSchema, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (data: GCPGlobalInfraSchema) => {
      return postGCPSetupGlobalInfra(account, data);
    },
    {
      onSuccess: () => client.invalidateQueries(["gcpGlobalInfra", account]),
    },
  );
};

export const useDeleteAWSGlobalSetup = (
  account: string,
): UseMutationResult<unknown, unknown, unknown, unknown> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      return deleteAWSSetupGlobalInfra(account);
    },
    {
      onSuccess: () => client.invalidateQueries(["awsGlobalInfra", account]),
    },
  );
};

export const useDeleteGCPGlobalSetup = (
  account: string,
): UseMutationResult<unknown, unknown, unknown, unknown> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      return deleteGCPSetupGlobalInfra(account);
    },
    {
      onSuccess: () => client.invalidateQueries(["gcpGlobalInfra", account]),
    },
  );
};

export const useDeleteAWSRegionSetup = (
  account: string,
): UseMutationResult<unknown, unknown, number, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (id: number) => {
      return deleteAWSSetupRegionInfra(account, id);
    },
    {
      onSuccess: () => client.invalidateQueries(["awsRegionalInfra", account]),
    },
  );
};

export const useDeleteGCPRegionSetup = (
  account: string,
): UseMutationResult<unknown, unknown, number, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (id: number) => {
      return deleteGCPSetupRegionInfra(account, id);
    },
    {
      onSuccess: () => client.invalidateQueries(["gcpRegionalInfra", account]),
    },
  );
};
export const useAWSGlobalSetup = (
  account: string,
): UseQueryResult<AWSGlobalInfraSchema | undefined> => {
  return useQuery(
    ["awsGlobalInfra", account],
    () => {
      return getAWSSetupGlobalInfra(account);
    },
    { refetchInterval: 1000 },
  );
};

export const useGCPGlobalSetup = (
  account: string,
): UseQueryResult<GCPGlobalInfraSchema | undefined> => {
  return useQuery(
    ["gcpGlobalInfra", account],
    () => {
      return getGCPSetupGlobalInfra(account);
    },
    { refetchInterval: 1000 },
  );
};

export const useRequestAWSRegionalSetup = (
  account: string,
): UseMutationResult<AWSRegionalInfra, unknown, AWSRegionalInfra, unknown> => {
  const client = useQueryClient();
  return useMutation(
    (infra) => {
      return postAWSRegionalInfra(account, infra);
    },
    {
      onSuccess: () => client.invalidateQueries(["awsRegionalInfra", account]),
    },
  );
};

export const useRequestGCPRegionalSetup = (
  account: string,
): UseMutationResult<
  GCPRegionalInfraSchema,
  unknown,
  GCPRegionalInfraSchema,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (infra) => {
      return postGCPRegionalInfra(account, infra);
    },
    {
      onSuccess: () => client.invalidateQueries(["gcpRegionalInfra", account]),
    },
  );
};

export const useAWSRegionalInfra = (
  account: string,
): UseQueryResult<AWSRegionalInfras> => {
  return useQuery(
    ["awsRegionalInfra", account],
    () => {
      return getAWSRegionalInfra(account);
    },
    { refetchInterval: 2000 },
  );
};

export const useGCPRegionalInfra = (
  account: string,
): UseQueryResult<GCPRegionalInfras> => {
  return useQuery(
    ["gcpRegionalInfra", account],
    () => {
      return getGCPRegionalInfra(account);
    },
    { refetchInterval: 2000 },
  );
};

export const useRegionAzs = (
  account: string,
  region?: string,
): UseQueryResult<AvailablityZone[]> => {
  return useQuery(["regionAZs", account, region], () => {
    if (region) return getAWSRegionAzs(account, region);
    return [];
  });
};

export const useRegionVPCs = (
  account: string,
  region?: string,
): UseQueryResult<UserVPC[]> => {
  return useQuery(["aws-region-vpcs", account, region], () => {
    if (region) return getAWSRegionVPCs(account, region);
    return [];
  });
};

export const useNetworks = (account: string): UseQueryResult<UserNetwork[]> => {
  return useQuery(["gcpNetworks", account], () => {
    return getGCPNetworks(account);
  });
};

export const useRegionSubnets = (
  account: string,
  region?: string,
  vpcId?: string,
): UseQueryResult<UserSubnet[]> => {
  return useQuery(["aws-region-subnets", account, region, vpcId], () => {
    if (region && vpcId) return getAWSRegionSubnets(account, region, vpcId);
    return [];
  });
};

export const useGCPSubnets = (
  account: string,
  region: string,
): UseQueryResult<GCPUserSubnet[]> => {
  return useQuery(["gcpRegionSubnets", account, region], () => {
    return getGCPSubnets(account, region);
  });
};

export const useRegionSecurityGroups = (
  account: string,
  region?: string,
  vpcId?: string,
): UseQueryResult<UserSecurityGroup[]> => {
  return useQuery(["awsRegionSecurityGroups", account, region, vpcId], () => {
    if (region && vpcId)
      return getAWSRegionSecurityGroups(account, region, vpcId);
    return [];
  });
};

export const useFirewallTargetTags = (
  account: string,
  networkLink?: string,
): UseQueryResult<string[]> => {
  return useQuery(["gcpFirewallTargetTags", account, networkLink], () => {
    if (networkLink) {
      return getGCPFirewallTags(account, networkLink);
    }
    return [];
  });
};

export const useCreateCFSetupAttemptMutation = (
  account: string,
): UseMutationResult<CloudFormationSetupAttempt, unknown, unknown, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return createCloudFormationSetupAttempt(account);
    },
    {
      onSuccess: () => queryClient.invalidateQueries("getCFSetupAttempt"),
    },
  );
};

export const useAWSAccountSettings = (
  account: string,
): UseQueryResult<AWSAccountSettings> => {
  return useQuery(["awsAccountSettings", account], () => {
    return getAWSAccountSettings(account);
  });
};

export const useGCPAccountSettings = (
  account: string,
): UseQueryResult<GCPAccountSettings> => {
  return useQuery(["gcpAccountSettings", account], () => {
    return getGCPAccountSettings(account);
  });
};

export const useUpdateAWSAccountSettingsMutation = (
  accountSlug: string,
): UseMutationResult<
  AWSAccountSettings,
  unknown,
  AWSAccountSettings,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (settings: AWSAccountSettings) => {
      return patchAWSAccountSettings(accountSlug, settings);
    },
    {
      onSuccess: () => queryClient.invalidateQueries("awsAccountSettings"),
    },
  );
};

export const useUpdateGCPAccountSettingsMutation = (
  account: string,
): UseMutationResult<
  GCPAccountSettings,
  unknown,
  GCPAccountSettings,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (settings: GCPAccountSettings) => {
      return patchGCPAccountSettings(account, settings);
    },
    {
      onSuccess: () => queryClient.invalidateQueries("gcpAccountSettings"),
    },
  );
};

export const useCFSetupAttempt = (
  account: string,
  id?: number,
): UseQueryResult<CloudFormationSetupAttempt> => {
  const queryClient = useQueryClient();
  return useQuery(
    ["getCFSetupAttempt", account, id],
    () => getCloudFormationSetupAttempt(account, id as number),
    {
      enabled: !!id,
      refetchInterval: 3000,
      onSuccess: (data) => {
        if (data.complete) queryClient.invalidateQueries("awsCredentials");
      },
    },
  );
};
