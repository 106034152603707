import React, { useContext, useState } from "react";
import { TitleCard } from "../../shared-components/Cards";
import { Subscription } from "./Subscription";
import { Grid, Stack, Card } from "@mui/material";
import { useIsOrgAdmin } from "../../crud/account/hooks";
import { OrganizationSelector } from "../../shared-components/OrganizationSelector";
import { OrganizationScopeSchema } from "../../api-client";
import { useOrganization } from "../../crud/organizations/hooks";
import { SelectSomething } from "../../shared-components/SelectSomething";
import { UserContext } from "../../crud/user/context";
import { SelectOrgTags } from "../../shared-components/SelectOrgTags";

export const Billing = (): React.ReactElement => {
  const {
    user: { isStaff: isStaff },
  } = useContext(UserContext);

  const [org, setOrg] = useState<OrganizationScopeSchema | undefined>(
    undefined,
  );
  const { data: organization, isSuccess: organizationLoaded } = useOrganization(
    org?.id,
  );
  const isOrgAdmin = useIsOrgAdmin(org?.id);
  return (
    <Stack spacing={2}>
      <div style={{ marginLeft: "auto" }}>
        <OrganizationSelector onChange={setOrg} />
      </div>
      {(isOrgAdmin || isStaff) &&
        organizationLoaded &&
        organization.activeProgramId && (
          <Grid item xs={6} md={6}>
            <TitleCard title="Subscription">
              <Subscription organizationId={organization.id} />
            </TitleCard>
            {isStaff && (
              <Card sx={{ marginTop: ".5rem" }}>
                <SelectOrgTags organization={organization} />
              </Card>
            )}
          </Grid>
        )}

      {!org && (
        <SelectSomething message="Select an organization to view billing information." />
      )}
      {org && !isOrgAdmin && !isStaff && (
        <SelectSomething
          message={`You must be an admin to view billing information for ${org.name}.`}
        />
      )}
    </Stack>
  );
};
