export default {
  "hljs-comment": {
    color: "#999898",
  },
  "hljs-quote": {
    color: "#999898",
  },
  "hljs-variable": {
    color: "#a90500",
  },
  "hljs-template-variable": {
    color: "#a90500",
  },
  "hljs-tag": {
    color: "#a90500",
  },
  "hljs-name": {
    color: "#a90500",
  },
  "hljs-selector-id": {
    color: "#a90500",
  },
  "hljs-selector-class": {
    color: "#a90500",
  },
  "hljs-regexp": {
    color: "#a90500",
  },
  "hljs-deletion": {
    color: "#a90500",
  },
  "hljs-number": {
    color: "#1F5AFF",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "hljs-built_in": {
    color: "#1F5AFF",
  },
  "hljs-builtin-name": {
    color: "#1F5AFF",
  },
  "hljs-literal": {
    color: "#1F5AFF",
  },
  "hljs-type": {
    color: "#1F5AFF",
  },
  "hljs-params": {
    color: "#1F5AFF",
  },
  "hljs-meta": {
    color: "#1F5AFF",
  },
  "hljs-link": {
    color: "#1F5AFF",
  },
  "hljs-attribute": {
    color: "#1F5AFF",
  },
  "hljs-string": {
    color: "#1F5AFF",
  },
  "hljs-symbol": {
    color: "#1F5AFF",
  },
  "hljs-bullet": {
    color: "#1F5AFF",
  },
  "hljs-addition": {
    color: "#1F5AFF",
  },
  "hljs-title": {
    color: "#007faa",
  },
  "hljs-section": {
    color: "#007faa",
  },
  "hljs-keyword": {
    color: "#1F5AFF",
  },
  "hljs-selector-tag": {
    color: "#1F5AFF",
  },
  hljs: {
    display: "block",
    overflowX: "auto",
    background: "#f5f6fa",
    color: "#34343A",
    padding: "0.5em",
    margin: 0,
    borderRadius: "5px",
  },
  "hljs-emphasis": {
    fontStyle: "italic",
  },
  "hljs-strong": {
    fontWeight: "bold",
  },
};
