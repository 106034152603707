import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { Page } from "../../shared-components/Page";
import { Urls } from "../../domain/urls";
import { MemberSpend } from "./MemberSpend";

export const UsageRouter = (): React.ReactElement => {
  const crumbs = useMemo(() => [{ text: "Usage", link: `/${Urls.Usage}` }], []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Page title="Usage" breadCrumbs={crumbs}>
            <MemberSpend />
          </Page>
        }
      />
    </Routes>
  );
};
