import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  OrganizationInviteSchema,
  ResponseError,
  UsersViewsInvitesUpdateOrganizationInviteRequest,
} from "../../api-client";

export const useOrganizationInvites = (
  organizationId?: number,
): UseQueryResult<OrganizationInviteSchema[]> => {
  const result = useQuery(
    ["organizationInvites", organizationId],
    async () =>
      ApiClient.usersViewsInvitesOrganizationInvites({
        organizationId: organizationId as number,
      }),
    {
      enabled: !!organizationId,
    },
  );
  return result;
};

export const useOrganizationInviteDetails = (
  inviteId?: number,
): UseQueryResult<OrganizationInviteSchema> => {
  const result = useQuery(
    ["organizationInvite", inviteId],
    async () =>
      ApiClient.usersViewsInvitesGetOrganizationInvite({
        inviteId: inviteId as number,
      }),
    {
      enabled: !!inviteId,
    },
  );
  return result;
};

export const useUpdateOrganizationInviteMutation = (): UseMutationResult<
  OrganizationInviteSchema,
  ResponseError,
  UsersViewsInvitesUpdateOrganizationInviteRequest
> => {
  const mutation = useMutation<
    OrganizationInviteSchema,
    ResponseError,
    UsersViewsInvitesUpdateOrganizationInviteRequest
  >({
    mutationKey: "updateOrganizationInvite",
    mutationFn: (request: UsersViewsInvitesUpdateOrganizationInviteRequest) =>
      ApiClient.usersViewsInvitesUpdateOrganizationInvite(request),
  });
  return mutation;
};

export const useDeleteOrganizationeInviteMutation = (): UseMutationResult<
  void,
  unknown,
  number
> => {
  const client = useQueryClient();
  const mutation = useMutation(
    (id: number) =>
      ApiClient.usersViewsInvitesDeleteOrganizationInvite({ inviteId: id }),
    {
      onSuccess: (resp, id) => {
        client.setQueriesData<OrganizationInviteSchema[] | undefined>(
          "organizationInvites",
          (oldData) => {
            return oldData?.filter((invite) => invite.id !== id);
          },
        );
      },
    },
  );
  return mutation;
};

export const useResendOrganizationInviteMutation = (): UseMutationResult<
  void,
  ResponseError,
  number
> => {
  const mutation = useMutation<void, ResponseError, number>((id: number) =>
    ApiClient.usersViewsInvitesResendOrgInvite({ inviteId: id }),
  );
  return mutation;
};
