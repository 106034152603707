import { useQuery, UseQueryResult } from "react-query";
import { getMemberships } from "./fetch";
import { Membership } from "./types";
import { identifyUser } from "../../analytics";
import {
  OrganizationMembershipSchema,
  ResponseError,
  UserSchema,
  UsersViewsUserGetValidScopesRequest,
  ValidScopesSchema,
} from "../../api-client";
import { ApiClient } from "../../apiUtils";

export const useMe = (): UseQueryResult<UserSchema, ResponseError> => {
  const result = useQuery<UserSchema, ResponseError>(
    ["getMe"],
    () => ApiClient.declarativeViewsUserGetIdentity(),
    {
      retry: (failureCount, error) => {
        if (error instanceof ResponseError && error.response.status === 401) {
          return false;
        }
        return failureCount < 3;
      },
      onSuccess: (resp) => {
        if (resp) {
          identifyUser(resp);
        }
      },
    },
  );
  return result;
};

export const useUserMemberships = (): UseQueryResult<Membership[]> => {
  const result = useQuery(["getMemberships"], () => getMemberships());
  return result;
};

export const useUserOrganizationMemberships = (): UseQueryResult<
  OrganizationMembershipSchema[]
> => {
  const result = useQuery(["getOrganizationMemberships"], () =>
    ApiClient.usersViewsUserGetOrganizationMemberships(),
  );
  return result;
};

export const useValidScopes = (
  request: UsersViewsUserGetValidScopesRequest,
): UseQueryResult<ValidScopesSchema, ResponseError> => {
  const result = useQuery<ValidScopesSchema, ResponseError>(
    ["getValidScopes", request],
    () => ApiClient.usersViewsUserGetValidScopes(request),
    {
      keepPreviousData: true,
    },
  );
  return result;
};
