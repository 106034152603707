export const stateStyles = {
  "&.pending": {
    color: "#57dbff",
  },
  "&.starting": {
    color: "#b3ff5c",
  },
  "&.created": {
    color: "#57ff86",
  },
  "&.error": {
    color: "#ff4949",
  },
  "&.stopping": {
    color: "#ababab",
  },
  "&.stopped": {
    color: "#D4D4D4",
  },
  "&.destroyed": {
    color: "#fab94e",
  },
};

export const stateToClassName = (state: string): string => {
  switch (state) {
    case "pending":
    case "queued":
    case "scaling":
      return "pending";
    case "starting":
    case "creating":
      return "starting";
    case "ready":
    case "started":
    case "created":
      return "created";
    case "error":
      return "error";
    case "deleting":
    case "stopping":
      return "stopping";
    case "stopped":
      return "stopped";
    case "destroying":
    case "destroyed":
      return "destroyed";
    default:
      return "";
  }
};
