/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnalyticsUserSchema } from './AnalyticsUserSchema';
import {
    AnalyticsUserSchemaFromJSON,
    AnalyticsUserSchemaFromJSONTyped,
    AnalyticsUserSchemaToJSON,
} from './AnalyticsUserSchema';

/**
 * 
 * @export
 * @interface DaskSchedulerSchema
 */
export interface DaskSchedulerSchema {
    /**
     * 
     * @type {AnalyticsUserSchema}
     * @memberof DaskSchedulerSchema
     */
    user: AnalyticsUserSchema;
    /**
     * 
     * @type {string}
     * @memberof DaskSchedulerSchema
     */
    schedulerId: string;
    /**
     * 
     * @type {number}
     * @memberof DaskSchedulerSchema
     */
    id: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DaskSchedulerSchema
     */
    versions?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DaskSchedulerSchema
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof DaskSchedulerSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof DaskSchedulerSchema
     */
    lastSeen: Date;
    /**
     * 
     * @type {Date}
     * @memberof DaskSchedulerSchema
     */
    idleSince?: Date;
    /**
     * 
     * @type {number}
     * @memberof DaskSchedulerSchema
     */
    idleTimeout?: number;
    /**
     * 
     * @type {number}
     * @memberof DaskSchedulerSchema
     */
    nTasks?: number;
    /**
     * 
     * @type {number}
     * @memberof DaskSchedulerSchema
     */
    nthreads: number;
    /**
     * 
     * @type {number}
     * @memberof DaskSchedulerSchema
     */
    threadSeconds: number;
}

/**
 * Check if a given object implements the DaskSchedulerSchema interface.
 */
export function instanceOfDaskSchedulerSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "schedulerId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "lastSeen" in value;
    isInstance = isInstance && "nthreads" in value;
    isInstance = isInstance && "threadSeconds" in value;

    return isInstance;
}

export function DaskSchedulerSchemaFromJSON(json: any): DaskSchedulerSchema {
    return DaskSchedulerSchemaFromJSONTyped(json, false);
}

export function DaskSchedulerSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaskSchedulerSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': AnalyticsUserSchemaFromJSON(json['user']),
        'schedulerId': json['scheduler_id'],
        'id': json['id'],
        'versions': !exists(json, 'versions') ? undefined : json['versions'],
        'status': json['status'],
        'created': (new Date(json['created'])),
        'lastSeen': (new Date(json['last_seen'])),
        'idleSince': !exists(json, 'idle_since') ? undefined : (new Date(json['idle_since'])),
        'idleTimeout': !exists(json, 'idle_timeout') ? undefined : json['idle_timeout'],
        'nTasks': !exists(json, 'n_tasks') ? undefined : json['n_tasks'],
        'nthreads': json['nthreads'],
        'threadSeconds': json['thread_seconds'],
    };
}

export function DaskSchedulerSchemaToJSON(value?: DaskSchedulerSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': AnalyticsUserSchemaToJSON(value.user),
        'scheduler_id': value.schedulerId,
        'id': value.id,
        'versions': value.versions,
        'status': value.status,
        'created': (value.created.toISOString()),
        'last_seen': (value.lastSeen.toISOString()),
        'idle_since': value.idleSince === undefined ? undefined : (value.idleSince.toISOString()),
        'idle_timeout': value.idleTimeout,
        'n_tasks': value.nTasks,
        'nthreads': value.nthreads,
        'thread_seconds': value.threadSeconds,
    };
}

