/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagAlertHitWithMessageSchema
 */
export interface TagAlertHitWithMessageSchema {
    /**
     * 
     * @type {string}
     * @memberof TagAlertHitWithMessageSchema
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof TagAlertHitWithMessageSchema
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof TagAlertHitWithMessageSchema
     */
    nHits: number;
    /**
     * 
     * @type {Date}
     * @memberof TagAlertHitWithMessageSchema
     */
    firstOccurred: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TagAlertHitWithMessageSchema
     */
    staffOnly: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagAlertHitWithMessageSchema
     */
    message?: string;
}

/**
 * Check if a given object implements the TagAlertHitWithMessageSchema interface.
 */
export function instanceOfTagAlertHitWithMessageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nHits" in value;
    isInstance = isInstance && "firstOccurred" in value;
    isInstance = isInstance && "staffOnly" in value;

    return isInstance;
}

export function TagAlertHitWithMessageSchemaFromJSON(json: any): TagAlertHitWithMessageSchema {
    return TagAlertHitWithMessageSchemaFromJSONTyped(json, false);
}

export function TagAlertHitWithMessageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAlertHitWithMessageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'nHits': json['n_hits'],
        'firstOccurred': (new Date(json['first_occurred'])),
        'staffOnly': json['staff_only'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function TagAlertHitWithMessageSchemaToJSON(value?: TagAlertHitWithMessageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'level': value.level,
        'n_hits': value.nHits,
        'first_occurred': (value.firstOccurred.toISOString()),
        'staff_only': value.staffOnly,
        'message': value.message,
    };
}

