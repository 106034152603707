/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackendTypesEnum } from './BackendTypesEnum';
import {
    BackendTypesEnumFromJSON,
    BackendTypesEnumFromJSONTyped,
    BackendTypesEnumToJSON,
} from './BackendTypesEnum';
import type { MembershipLightSchema } from './MembershipLightSchema';
import {
    MembershipLightSchemaFromJSON,
    MembershipLightSchemaFromJSONTyped,
    MembershipLightSchemaToJSON,
} from './MembershipLightSchema';

/**
 * 
 * @export
 * @interface WorkspaceLightWithMembershipSchema
 */
export interface WorkspaceLightWithMembershipSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    avatarUrl: string;
    /**
     * 
     * @type {BackendTypesEnum}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    activeBackend?: BackendTypesEnum;
    /**
     * 
     * @type {Array<MembershipLightSchema>}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    membershipSet: Array<MembershipLightSchema>;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceLightWithMembershipSchema
     */
    creditLimit: number;
}

/**
 * Check if a given object implements the WorkspaceLightWithMembershipSchema interface.
 */
export function instanceOfWorkspaceLightWithMembershipSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "avatarUrl" in value;
    isInstance = isInstance && "membershipSet" in value;
    isInstance = isInstance && "creditLimit" in value;

    return isInstance;
}

export function WorkspaceLightWithMembershipSchemaFromJSON(json: any): WorkspaceLightWithMembershipSchema {
    return WorkspaceLightWithMembershipSchemaFromJSONTyped(json, false);
}

export function WorkspaceLightWithMembershipSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceLightWithMembershipSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'avatarUrl': json['avatar_url'],
        'activeBackend': !exists(json, 'active_backend') ? undefined : BackendTypesEnumFromJSON(json['active_backend']),
        'membershipSet': ((json['membership_set'] as Array<any>).map(MembershipLightSchemaFromJSON)),
        'creditLimit': json['credit_limit'],
    };
}

export function WorkspaceLightWithMembershipSchemaToJSON(value?: WorkspaceLightWithMembershipSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'active_backend': BackendTypesEnumToJSON(value.activeBackend),
        'membership_set': ((value.membershipSet as Array<any>).map(MembershipLightSchemaToJSON)),
        'credit_limit': value.creditLimit,
    };
}

