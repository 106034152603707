/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchitectureTypesEnum } from './ArchitectureTypesEnum';
import {
    ArchitectureTypesEnumFromJSON,
    ArchitectureTypesEnumFromJSONTyped,
    ArchitectureTypesEnumToJSON,
} from './ArchitectureTypesEnum';
import type { SoftwareEnvBuildLight } from './SoftwareEnvBuildLight';
import {
    SoftwareEnvBuildLightFromJSON,
    SoftwareEnvBuildLightFromJSONTyped,
    SoftwareEnvBuildLightToJSON,
} from './SoftwareEnvBuildLight';

/**
 * 
 * @export
 * @interface SoftwareEnvironmentSpecListSchema
 */
export interface SoftwareEnvironmentSpecListSchema {
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    md5: string;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    containerUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    gpuEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    useEntrypoint: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    hasRawPip: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    hasRawConda: boolean;
    /**
     * 
     * @type {ArchitectureTypesEnum}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    architecture?: ArchitectureTypesEnum;
    /**
     * 
     * @type {SoftwareEnvBuildLight}
     * @memberof SoftwareEnvironmentSpecListSchema
     */
    latestBuild?: SoftwareEnvBuildLight;
}

/**
 * Check if a given object implements the SoftwareEnvironmentSpecListSchema interface.
 */
export function instanceOfSoftwareEnvironmentSpecListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "md5" in value;
    isInstance = isInstance && "gpuEnabled" in value;
    isInstance = isInstance && "useEntrypoint" in value;
    isInstance = isInstance && "hasRawPip" in value;
    isInstance = isInstance && "hasRawConda" in value;

    return isInstance;
}

export function SoftwareEnvironmentSpecListSchemaFromJSON(json: any): SoftwareEnvironmentSpecListSchema {
    return SoftwareEnvironmentSpecListSchemaFromJSONTyped(json, false);
}

export function SoftwareEnvironmentSpecListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentSpecListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'md5': json['md5'],
        'containerUri': !exists(json, 'container_uri') ? undefined : json['container_uri'],
        'gpuEnabled': json['gpu_enabled'],
        'useEntrypoint': json['use_entrypoint'],
        'hasRawPip': json['has_raw_pip'],
        'hasRawConda': json['has_raw_conda'],
        'architecture': !exists(json, 'architecture') ? undefined : ArchitectureTypesEnumFromJSON(json['architecture']),
        'latestBuild': !exists(json, 'latest_build') ? undefined : SoftwareEnvBuildLightFromJSON(json['latest_build']),
    };
}

export function SoftwareEnvironmentSpecListSchemaToJSON(value?: SoftwareEnvironmentSpecListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'md5': value.md5,
        'container_uri': value.containerUri,
        'gpu_enabled': value.gpuEnabled,
        'use_entrypoint': value.useEntrypoint,
        'has_raw_pip': value.hasRawPip,
        'has_raw_conda': value.hasRawConda,
        'architecture': ArchitectureTypesEnumToJSON(value.architecture),
        'latest_build': SoftwareEnvBuildLightToJSON(value.latestBuild),
    };
}

