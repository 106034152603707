/* eslint-disable @typescript-eslint/naming-convention */
import ky from "ky";
import { getStandardHeaders } from "../../../apiUtils";
import { datadogLogs } from "@datadog/browser-logs";
import type { RawClusterAlert, ClusterAlert } from "./types";

const mungeClusterAlert = (alerts: RawClusterAlert[]): ClusterAlert[] => {
  const mungedAlerts = alerts.map((alert: RawClusterAlert) => ({
    id: alert.id,
    level: alert.level.toLowerCase(),
    title: alert.title,
    originalMessage: alert.original_message,
    coiledMessage: alert.coiled_message || "",
    recommendation: alert.recommendation || "",
    errorType: alert.error_type,
    clusterId: alert.cluster_id,
    label: alert.label,
  }));
  return mungedAlerts;
};

export const getClusterAlerts = async (
  accountSlug: string,
  token: string,
  clusterId?: number | string,
): Promise<ClusterAlert[]> => {
  if (!clusterId) {
    datadogLogs.logger.error(
      `Unable to get cluster alerts for the account ${accountSlug} - clusterId is undefined`,
    );
  }
  try {
    const alertPayload = (await ky
      .get(`/api/v2/clusters/account/${accountSlug}/id/${clusterId}/alerts`, {
        headers: getStandardHeaders(),
      })
      .json()) as RawClusterAlert[];
    return mungeClusterAlert(alertPayload);
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get cluter alerts for cluster ${clusterId} in the workspace ${accountSlug} - Reason: ${err}`,
    );
    return [];
  }
};
