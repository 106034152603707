import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DaskIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      sx={{
        enableBackground: "new 0 0 512 512",
        "& .st0": {
          fill: "#FFC11E",
        },
        "& .st1": {
          fill: "#04255C",
        },
        "& .st2": {
          fill: "#FC6E6B",
        },
        "& .st3": {
          fill: "#FFFFFF",
        },
        "& .st4": {
          fill: "#EF1161",
        },
      }}
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M143.71,157.61l126.5-72.99c1.25-0.72,2.02-2.05,2.02-3.5l0.01-43.77c0-6.48-2.66-12.9-7.83-16.81
		c-6.69-5.06-15.28-5.56-22.33-1.48L65.13,121.17c-6.22,3.59-10.06,10.23-10.06,17.41L55,369.18c0,6.47,2.65,12.89,7.81,16.81
		c6.68,5.07,15.29,5.57,22.35,1.49l37.48-21.62c1.25-0.72,2.02-2.05,2.02-3.5l0.05-171.85C124.71,176.93,131.95,164.4,143.71,157.61
		z"
        />
        <path
          className="st4"
          d="M446.95,124.53c-3.15-1.82-6.61-2.73-10.06-2.73c-3.45,0-6.9,0.91-10.05,2.73l-176.96,102.1
		c-6.2,3.58-10.06,10.25-10.06,17.41l-0.07,231.47c0,7.27,3.76,13.78,10.05,17.42c6.3,3.64,13.81,3.64,20.11,0l176.95-102.11
		c6.2-3.58,10.06-10.25,10.06-17.41L457,141.95C457,134.68,453.24,128.16,446.95,124.53z"
        />
        <path
          className="st2"
          d="M240.95,211.14l116.78-67.38c1.25-0.72,2.02-2.05,2.02-3.5l0.02-50.98c0-6.48-2.66-12.9-7.83-16.81
		c-6.69-5.06-15.27-5.55-22.33-1.48l-48.43,27.95L152.64,173.1c-6.22,3.59-10.06,10.23-10.06,17.41l-0.05,174.18l-0.02,56.41
		c0,6.48,2.65,12.89,7.81,16.81c6.69,5.07,15.29,5.57,22.35,1.49l47.2-27.24c1.25-0.72,2.02-2.05,2.02-3.5l0.05-164.64
		C221.95,230.46,229.19,217.92,240.95,211.14z"
        />
      </g>
    </SvgIcon>
  );
};
