/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkspaceMemberSchema } from './WorkspaceMemberSchema';
import {
    WorkspaceMemberSchemaFromJSON,
    WorkspaceMemberSchemaFromJSONTyped,
    WorkspaceMemberSchemaToJSON,
} from './WorkspaceMemberSchema';

/**
 * 
 * @export
 * @interface PagedWorkspaceMemberSchema
 */
export interface PagedWorkspaceMemberSchema {
    /**
     * 
     * @type {Array<WorkspaceMemberSchema>}
     * @memberof PagedWorkspaceMemberSchema
     */
    items: Array<WorkspaceMemberSchema>;
    /**
     * 
     * @type {number}
     * @memberof PagedWorkspaceMemberSchema
     */
    count: number;
}

/**
 * Check if a given object implements the PagedWorkspaceMemberSchema interface.
 */
export function instanceOfPagedWorkspaceMemberSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function PagedWorkspaceMemberSchemaFromJSON(json: any): PagedWorkspaceMemberSchema {
    return PagedWorkspaceMemberSchemaFromJSONTyped(json, false);
}

export function PagedWorkspaceMemberSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedWorkspaceMemberSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(WorkspaceMemberSchemaFromJSON)),
        'count': json['count'],
    };
}

export function PagedWorkspaceMemberSchemaToJSON(value?: PagedWorkspaceMemberSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(WorkspaceMemberSchemaToJSON)),
        'count': value.count,
    };
}

