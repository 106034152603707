/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GCPConfigSchema
 */
export interface GCPConfigSchema {
    /**
     * 
     * @type {string}
     * @memberof GCPConfigSchema
     */
    defaultRegion: string;
    /**
     * 
     * @type {string}
     * @memberof GCPConfigSchema
     */
    defaultZone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPConfigSchema
     */
    supportedRegions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPConfigSchema
     */
    supportedZones: Array<string>;
}

/**
 * Check if a given object implements the GCPConfigSchema interface.
 */
export function instanceOfGCPConfigSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "defaultRegion" in value;
    isInstance = isInstance && "defaultZone" in value;
    isInstance = isInstance && "supportedRegions" in value;
    isInstance = isInstance && "supportedZones" in value;

    return isInstance;
}

export function GCPConfigSchemaFromJSON(json: any): GCPConfigSchema {
    return GCPConfigSchemaFromJSONTyped(json, false);
}

export function GCPConfigSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPConfigSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultRegion': json['default_region'],
        'defaultZone': json['default_zone'],
        'supportedRegions': json['supported_regions'],
        'supportedZones': json['supported_zones'],
    };
}

export function GCPConfigSchemaToJSON(value?: GCPConfigSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_region': value.defaultRegion,
        'default_zone': value.defaultZone,
        'supported_regions': value.supportedRegions,
        'supported_zones': value.supportedZones,
    };
}

