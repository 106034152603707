import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import { FormControlLabel, FormGroup, styled } from "@mui/material";

const StyledLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    marginLeft: "unset",
  },
});

type Props<ColumnT> = {
  allColumnNames: readonly ColumnT[];
  getColumnName: (c: ColumnT) => string;
  columns: ColumnT[];
  updateColumns: (cs: ColumnT[]) => void;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
};

export const ColumnCheckboxes = <ColumnT extends string>({
  allColumnNames,
  getColumnName,
  columns,
  updateColumns,
  anchorEl,
  open,
  handleClose,
}: Props<ColumnT>): React.ReactElement => {
  const columnsNumbered: Record<ColumnT, number> = Object.fromEntries(
    allColumnNames.map((c, i) => [c, i]),
  ) as Record<ColumnT, number>;

  const columnSorter = (c1: ColumnT, c2: ColumnT) => {
    return columnsNumbered[c1] - columnsNumbered[c2];
  };

  const innerUpdateColumns = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newColumns;
    if (e.target.checked) {
      newColumns = [...columns, e.target.value as ColumnT].sort(columnSorter);
    } else {
      newColumns = columns.filter((c) => c !== e.target.value);
    }
    updateColumns(newColumns);
  };

  return (
    <Menu open={open} anchorEl={anchorEl} keepMounted onClose={handleClose}>
      <FormGroup>
        {allColumnNames.map((column) => (
          <StyledLabel
            key={`cb-${column}`}
            control={
              <Checkbox
                checked={columns.indexOf(column) > -1}
                onChange={innerUpdateColumns}
                value={column}
              />
            }
            label={getColumnName(column)}
          />
        ))}
      </FormGroup>
    </Menu>
  );
};
