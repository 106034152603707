/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AWSConfigSchema } from './AWSConfigSchema';
import {
    AWSConfigSchemaFromJSON,
    AWSConfigSchemaFromJSONTyped,
    AWSConfigSchemaToJSON,
} from './AWSConfigSchema';
import type { GCPConfigSchema } from './GCPConfigSchema';
import {
    GCPConfigSchemaFromJSON,
    GCPConfigSchemaFromJSONTyped,
    GCPConfigSchemaToJSON,
} from './GCPConfigSchema';

/**
 * 
 * @export
 * @interface ConfigSchema
 */
export interface ConfigSchema {
    /**
     * 
     * @type {AWSConfigSchema}
     * @memberof ConfigSchema
     */
    aws: AWSConfigSchema;
    /**
     * 
     * @type {GCPConfigSchema}
     * @memberof ConfigSchema
     */
    gcp: GCPConfigSchema;
}

/**
 * Check if a given object implements the ConfigSchema interface.
 */
export function instanceOfConfigSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "aws" in value;
    isInstance = isInstance && "gcp" in value;

    return isInstance;
}

export function ConfigSchemaFromJSON(json: any): ConfigSchema {
    return ConfigSchemaFromJSONTyped(json, false);
}

export function ConfigSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aws': AWSConfigSchemaFromJSON(json['aws']),
        'gcp': GCPConfigSchemaFromJSON(json['gcp']),
    };
}

export function ConfigSchemaToJSON(value?: ConfigSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aws': AWSConfigSchemaToJSON(value.aws),
        'gcp': GCPConfigSchemaToJSON(value.gcp),
    };
}

