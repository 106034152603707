/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateOrgWorkspaceMembershipSchema } from './UpdateOrgWorkspaceMembershipSchema';
import {
    UpdateOrgWorkspaceMembershipSchemaFromJSON,
    UpdateOrgWorkspaceMembershipSchemaFromJSONTyped,
    UpdateOrgWorkspaceMembershipSchemaToJSON,
} from './UpdateOrgWorkspaceMembershipSchema';

/**
 * 
 * @export
 * @interface UpdateOrganizationMembershipSchema
 */
export interface UpdateOrganizationMembershipSchema {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationMembershipSchema
     */
    role: string;
    /**
     * 
     * @type {Array<UpdateOrgWorkspaceMembershipSchema>}
     * @memberof UpdateOrganizationMembershipSchema
     */
    workspaces: Array<UpdateOrgWorkspaceMembershipSchema>;
}

/**
 * Check if a given object implements the UpdateOrganizationMembershipSchema interface.
 */
export function instanceOfUpdateOrganizationMembershipSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "workspaces" in value;

    return isInstance;
}

export function UpdateOrganizationMembershipSchemaFromJSON(json: any): UpdateOrganizationMembershipSchema {
    return UpdateOrganizationMembershipSchemaFromJSONTyped(json, false);
}

export function UpdateOrganizationMembershipSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationMembershipSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'workspaces': ((json['workspaces'] as Array<any>).map(UpdateOrgWorkspaceMembershipSchemaFromJSON)),
    };
}

export function UpdateOrganizationMembershipSchemaToJSON(value?: UpdateOrganizationMembershipSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'workspaces': ((value.workspaces as Array<any>).map(UpdateOrgWorkspaceMembershipSchemaToJSON)),
    };
}

