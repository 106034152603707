/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScanResultPackageSchema } from './ScanResultPackageSchema';
import {
    ScanResultPackageSchemaFromJSON,
    ScanResultPackageSchemaFromJSONTyped,
    ScanResultPackageSchemaToJSON,
} from './ScanResultPackageSchema';

/**
 * 
 * @export
 * @interface ScanResultSchema
 */
export interface ScanResultSchema {
    /**
     * 
     * @type {number}
     * @memberof ScanResultSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScanResultSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScanResultSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof ScanResultSchema
     */
    basePrefix?: string;
    /**
     * 
     * @type {Array<ScanResultPackageSchema>}
     * @memberof ScanResultSchema
     */
    packages: Array<ScanResultPackageSchema>;
    /**
     * 
     * @type {string}
     * @memberof ScanResultSchema
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanResultSchema
     */
    prefix?: string;
    /**
     * 
     * @type {number}
     * @memberof ScanResultSchema
     */
    pythonMajorVersion: number;
    /**
     * 
     * @type {number}
     * @memberof ScanResultSchema
     */
    pythonMinorVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ScanResultSchema
     */
    pythonPatchVersion: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScanResultSchema
     */
    sysPath?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScanResultSchema
     */
    indexUrls?: Array<string>;
}

/**
 * Check if a given object implements the ScanResultSchema interface.
 */
export function instanceOfScanResultSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "packages" in value;
    isInstance = isInstance && "pythonMajorVersion" in value;
    isInstance = isInstance && "pythonMinorVersion" in value;
    isInstance = isInstance && "pythonPatchVersion" in value;

    return isInstance;
}

export function ScanResultSchemaFromJSON(json: any): ScanResultSchema {
    return ScanResultSchemaFromJSONTyped(json, false);
}

export function ScanResultSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScanResultSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'basePrefix': !exists(json, 'base_prefix') ? undefined : json['base_prefix'],
        'packages': ((json['packages'] as Array<any>).map(ScanResultPackageSchemaFromJSON)),
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'prefix': !exists(json, 'prefix') ? undefined : json['prefix'],
        'pythonMajorVersion': json['python_major_version'],
        'pythonMinorVersion': json['python_minor_version'],
        'pythonPatchVersion': json['python_patch_version'],
        'sysPath': !exists(json, 'sys_path') ? undefined : json['sys_path'],
        'indexUrls': !exists(json, 'index_urls') ? undefined : json['index_urls'],
    };
}

export function ScanResultSchemaToJSON(value?: ScanResultSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'base_prefix': value.basePrefix,
        'packages': ((value.packages as Array<any>).map(ScanResultPackageSchemaToJSON)),
        'platform': value.platform,
        'prefix': value.prefix,
        'python_major_version': value.pythonMajorVersion,
        'python_minor_version': value.pythonMinorVersion,
        'python_patch_version': value.pythonPatchVersion,
        'sys_path': value.sysPath,
        'index_urls': value.indexUrls,
    };
}

