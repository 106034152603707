import React, { ReactElement } from "react";
import {
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const StyledCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    borderBottom: "none",
    padding: "8px 4px 8px 4px",
    whiteSpace: "pre-line",
  },
});

const BoldCell = styled(StyledCell)(({ theme }) => {
  return {
    fontWeight: theme.typography.fontWeightMedium
      ? theme.typography.fontWeightMedium
      : "bold",
  };
});

type SimpleTableProps = {
  text: [string, string | React.ReactNode][];
  loading?: boolean;
};

export const SimpleTable = ({
  text,
  loading,
}: SimpleTableProps): ReactElement => {
  return (
    <Table>
      <TableBody>
        {text.map(([left, right]) => (
          <TableRow key={left}>
            <BoldCell>
              <Typography variant="body1">{left}</Typography>
            </BoldCell>
            <StyledCell>
              {loading ? (
                <Typography>
                  <Skeleton width="150px" />
                </Typography>
              ) : (
                right
              )}
            </StyledCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
