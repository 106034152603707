/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceMembershipSchema
 */
export interface WorkspaceMembershipSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembershipSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembershipSchema
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembershipSchema
     */
    workspaceId: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceMembershipSchema
     */
    isAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembershipSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembershipSchema
     */
    coreLimit: number;
}

/**
 * Check if a given object implements the WorkspaceMembershipSchema interface.
 */
export function instanceOfWorkspaceMembershipSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "workspaceId" in value;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "coreLimit" in value;

    return isInstance;
}

export function WorkspaceMembershipSchemaFromJSON(json: any): WorkspaceMembershipSchema {
    return WorkspaceMembershipSchemaFromJSONTyped(json, false);
}

export function WorkspaceMembershipSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembershipSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['user_id'],
        'workspaceId': json['workspace_id'],
        'isAdmin': json['is_admin'],
        'creditLimit': json['credit_limit'],
        'coreLimit': json['core_limit'],
    };
}

export function WorkspaceMembershipSchemaToJSON(value?: WorkspaceMembershipSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_id': value.userId,
        'workspace_id': value.workspaceId,
        'is_admin': value.isAdmin,
        'credit_limit': value.creditLimit,
        'core_limit': value.coreLimit,
    };
}

