/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AWSCredentialsTypeEnum } from './AWSCredentialsTypeEnum';
import {
    AWSCredentialsTypeEnumFromJSON,
    AWSCredentialsTypeEnumFromJSONTyped,
    AWSCredentialsTypeEnumToJSON,
} from './AWSCredentialsTypeEnum';

/**
 * 
 * @export
 * @interface AWSCredentialsSchema
 */
export interface AWSCredentialsSchema {
    /**
     * 
     * @type {AWSCredentialsTypeEnum}
     * @memberof AWSCredentialsSchema
     */
    type?: AWSCredentialsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AWSCredentialsSchema
     */
    accessKeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof AWSCredentialsSchema
     */
    secretAccessKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AWSCredentialsSchema
     */
    roleArn?: string;
    /**
     * 
     * @type {string}
     * @memberof AWSCredentialsSchema
     */
    externalId?: string;
}

/**
 * Check if a given object implements the AWSCredentialsSchema interface.
 */
export function instanceOfAWSCredentialsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AWSCredentialsSchemaFromJSON(json: any): AWSCredentialsSchema {
    return AWSCredentialsSchemaFromJSONTyped(json, false);
}

export function AWSCredentialsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSCredentialsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AWSCredentialsTypeEnumFromJSON(json['type']),
        'accessKeyId': !exists(json, 'access_key_id') ? undefined : json['access_key_id'],
        'secretAccessKey': !exists(json, 'secret_access_key') ? undefined : json['secret_access_key'],
        'roleArn': !exists(json, 'role_arn') ? undefined : json['role_arn'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
    };
}

export function AWSCredentialsSchemaToJSON(value?: AWSCredentialsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AWSCredentialsTypeEnumToJSON(value.type),
        'access_key_id': value.accessKeyId,
        'secret_access_key': value.secretAccessKey,
        'role_arn': value.roleArn,
        'external_id': value.externalId,
    };
}

