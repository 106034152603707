import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const TeamIcon = (): React.ReactElement => {
  return (
    <SvgIcon>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 26 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="icon-/-team-/-light" stroke="currentColor">
            <polygon
              id="Line"
              strokeLinecap="square"
              points="19.5 15.5 19.1303906 15.8696094 15.8245557 19.1754443"
            />
            <line
              x1="10.1314153"
              y1="19.1314153"
              x2="6.792893"
              y2="15.792893"
              id="Line-2"
              strokeLinecap="square"
            />
            <line
              x1="6.5"
              y1="10.5"
              x2="9.79289304"
              y2="7.20710696"
              id="Line-3"
              strokeLinecap="square"
            />
            <line
              x1="15.9394073"
              y1="6.93940728"
              x2="19.4904959"
              y2="10.4904959"
              id="Line-4"
              strokeLinecap="square"
            />
            <circle id="Oval" cx="13" cy="4.5" r="3.5" />
            <circle id="Oval-Copy-6" cx="13" cy="21.5" r="3.5" />
            <circle
              id="Oval-Copy-8"
              transform="translate(22.000000, 13.500000) rotate(-270.000000) translate(-22.000000, -13.500000) "
              cx="22"
              cy="13.5"
              r="3.5"
            />
            <circle
              id="Oval-Copy-7"
              transform="translate(4.000000, 13.500000) rotate(-270.000000) translate(-4.000000, -13.500000) "
              cx="4"
              cy="13.5"
              r="3.5"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
