/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackageFileSchema } from './PackageFileSchema';
import {
    PackageFileSchemaFromJSON,
    PackageFileSchemaFromJSONTyped,
    PackageFileSchemaToJSON,
} from './PackageFileSchema';

/**
 * 
 * @export
 * @interface PackageSchema
 */
export interface PackageSchema {
    /**
     * 
     * @type {number}
     * @memberof PackageSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    specifier: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    condaName?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    clientVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageSchema
     */
    include: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    source: PackageSchemaSourceEnum;
    /**
     * 
     * @type {PackageFileSchema}
     * @memberof PackageSchema
     */
    file?: PackageFileSchema;
    /**
     * 
     * @type {string}
     * @memberof PackageSchema
     */
    channel?: string;
}


/**
 * @export
 */
export const PackageSchemaSourceEnum = {
    Pip: 'pip',
    Conda: 'conda'
} as const;
export type PackageSchemaSourceEnum = typeof PackageSchemaSourceEnum[keyof typeof PackageSchemaSourceEnum];


/**
 * Check if a given object implements the PackageSchema interface.
 */
export function instanceOfPackageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "specifier" in value;
    isInstance = isInstance && "include" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function PackageSchemaFromJSON(json: any): PackageSchema {
    return PackageSchemaFromJSONTyped(json, false);
}

export function PackageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'specifier': json['specifier'],
        'condaName': !exists(json, 'conda_name') ? undefined : json['conda_name'],
        'clientVersion': !exists(json, 'client_version') ? undefined : json['client_version'],
        'include': json['include'],
        'source': json['source'],
        'file': !exists(json, 'file') ? undefined : PackageFileSchemaFromJSON(json['file']),
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
    };
}

export function PackageSchemaToJSON(value?: PackageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'specifier': value.specifier,
        'conda_name': value.condaName,
        'client_version': value.clientVersion,
        'include': value.include,
        'source': value.source,
        'file': PackageFileSchemaToJSON(value.file),
        'channel': value.channel,
    };
}

