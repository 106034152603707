import { ReactElement } from "react";
import { GroupKey, MetricQuery } from "../../../../crud/metrics/types";
import { TimeRange } from "../../types";

// A Domain defines how a single metric is diplayed in a chart
export type Domain = {
  // The name of the metric rendered
  name: string;
  // Color of the line and fill. Null means the metric is visible
  color: string | { stroke: string; fill: string; opacity?: number } | null;
  // Metrics can be grouped into stacks by using the same stack number
  stack?: number;
  // Two metrics can be combined into a range (e.g. "min" & "max" -> "min-max")
  // See MetricsView.tsx for examples
  range?: number;
};

// Defines how a variation is rendered in a chart and what data (queries) it contains
export type Variation = {
  // The name of the variation
  name: string;
  groupKey: GroupKey;
  // The queries that are rendered in the chart
  queries: MetricQuery[];
  // The domains that are rendered in the chart
  domains: Domain[];
  yAxis?: ReactElement;
  legend?: ReactElement;
};

export enum ClusterUseCase {
  cluster,
  singleMachine,
  workerOnSchedulerCluster,
}

export type ChartProps = {
  clusterId: string;
  timeRange?: TimeRange;
  defaultTimeRange?: TimeRange;
  setTimeRange: (timeRange?: TimeRange) => void;
  refAreaLeft: number;
  refAreaRight: number;
  setRefAreaLeft: (refAreaLeft: number) => void;
  setRefAreaRight: (refAreaRight: number) => void;
  clusterIsRunning?: boolean;
  clusterUseCase?: ClusterUseCase;
  hasGpu?: boolean;
};
