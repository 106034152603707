import { Alert, Box, Chip, darken, Grid, lighten } from "@mui/material";
import React from "react";
import { theme } from "../../../theme";
import { ExceptionSummary } from "../Analytics/ClusterStatistics";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const ExceptionAlert = ({
  exception,
  setSelectedException,
}: {
  exception: ExceptionSummary;
  setSelectedException?: (exception: ExceptionSummary | null) => void;
}): React.ReactElement => {
  return (
    <Alert
      onClick={() => setSelectedException && setSelectedException(exception)}
      sx={{
        mt: 1,
        color: "black",
        cursor: setSelectedException ? "pointer" : "default",
        border: "1px solid transparent",
        "&:hover": setSelectedException
          ? {
              border: `1px solid ${lighten(
                theme.palette.custom.red.main,
                0.8,
              )}`,
              background: darken(theme.palette.custom.red.pale, 0.01),
            }
          : null,
        "& .MuiAlert-icon": {
          alignItems: "center",
        },
        "& .MuiAlert-message": {
          width: "100%",
        },
      }}
      severity="error"
    >
      <Grid container spacing={2}>
        <Grid
          component="pre"
          sx={{
            lineHeight: "26px",
            fontFamily: "monospace",
            whiteSpace: "pre-wrap",
          }}
          item
          xs
          title={exception.exception}
        >
          {exception.exception}
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            display: "inherit",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "0.8125rem",
            }}
          >
            <Chip sx={{ height: "26px" }} label={exception.count} />{" "}
            {exception.count === 1 ? "time" : "times"}
          </Box>
        </Grid>
        {setSelectedException && (
          <Grid item xs="auto" sx={{ my: "auto" }}>
            <KeyboardArrowRightIcon sx={{ display: "block" }} />
          </Grid>
        )}
      </Grid>
    </Alert>
  );
};
