/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';

/**
 * 
 * @export
 * @interface Subnet
 */
export interface Subnet {
    /**
     * 
     * @type {number}
     * @memberof Subnet
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Subnet
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subnet
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof Subnet
     */
    component: ComponentSchema;
    /**
     * 
     * @type {string}
     * @memberof Subnet
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Subnet
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Subnet
     */
    region: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subnet
     */
    forWorkers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Subnet
     */
    forSchedulers: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subnet
     */
    cidr?: string;
}

/**
 * Check if a given object implements the Subnet interface.
 */
export function instanceOfSubnet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "forWorkers" in value;
    isInstance = isInstance && "forSchedulers" in value;

    return isInstance;
}

export function SubnetFromJSON(json: any): Subnet {
    return SubnetFromJSONTyped(json, false);
}

export function SubnetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subnet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'link': !exists(json, 'link') ? undefined : json['link'],
        'name': json['name'],
        'region': json['region'],
        'forWorkers': json['for_workers'],
        'forSchedulers': json['for_schedulers'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
    };
}

export function SubnetToJSON(value?: Subnet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'link': value.link,
        'name': value.name,
        'region': value.region,
        'for_workers': value.forWorkers,
        'for_schedulers': value.forSchedulers,
        'cidr': value.cidr,
    };
}

