import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";

export const ComponentSandbox = (): React.ReactElement => {
  return (
    <Container>
      <Card>
        <CardHeader title="Buttons" />
        <CardContent>
          <Stack spacing={2} alignItems={"flex-start"}>
            <Divider sx={{ width: "100%" }}>Primary (default)</Divider>
            <Button>Primary</Button>
            <Button>i</Button>
            <Button disabled>Disabled Primary</Button>
            <Button size="small">Small Primary</Button>
            <Button size="small" disabled>
              Disabled Small Primary
            </Button>
            <Divider sx={{ width: "100%" }}>Secondary</Divider>
            <Button variant="secondary">Secondary Variant</Button>
            <Button variant="secondary" disabled>
              Disabled Secondary Variant
            </Button>
            <Button variant="secondary" size="small">
              Small Secondary Variant
            </Button>
            <Button variant="secondary" size="small" disabled>
              Small Disabled Secondary Variant
            </Button>
            <Divider sx={{ width: "100%" }}>Tertiary</Divider>
            <Button variant="tertiary">Tertiary Variant</Button>
            <Divider sx={{ width: "100%" }}>
              Loading Button Primary (default)
            </Divider>
            <LoadingButton>Idle</LoadingButton>
            <LoadingButton loading>Idle</LoadingButton>
            <Divider sx={{ width: "100%" }}>Loading Button Secondary</Divider>

            <LoadingButton variant="secondary">Idle</LoadingButton>
            <LoadingButton variant="secondary" loading>
              Idle
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};
