import ky, { HTTPError } from "ky";
import { getStandardHeaders } from "../apiUtils";
import { ActivationFailReasons, PasswordResetFailReasons } from "./types";
import { getDjangoCSRFCookie } from "../cookieUtils";
import { datadogLogs } from "@datadog/browser-logs";

type ServerSignUpResponse = {
  detail: string;
};

type ServerVerifyEmailResponse = {
  detail: string;
  key: string;
};

type ErrorMessages = {
  full_name?: string[];
  preferred_name?: string[];
  username?: string[];
  email?: string[];
  password1?: string[];
  password2?: string[];
  non_field_errors?: string[];
  captcha_token?: string[];
  unknownError?: ActivationFailReasons;
};

type SignUpResult =
  | {
      type: "success";
    }
  | {
      type: "alreadyVerified";
    }
  | {
      type: "error";
      errors: ErrorMessages;
    };

type SignUpPropFunc = {
  fullName: string;
  preferredName: string;
  username: string;
  email: string;
  password1: string;
  password2: string;
  captchaToken?: string;
};

export const signUp = async ({
  fullName,
  preferredName,
  username,
  email,
  password1,
  password2,
  captchaToken,
}: SignUpPropFunc): Promise<SignUpResult> => {
  let detail;
  let errors;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/registration/`, {
      json: {
        username,
        email,
        password1,
        password2,
        full_name: fullName,
        preferred_name: preferredName,
        captcha_token: captchaToken,
      },
      headers: { "X-CSRFToken": csrf },
    });
    detail = ((await response.json()) as unknown as ServerSignUpResponse)
      .detail;

    if (detail === "Verification e-mail sent.") {
      return {
        type: "success",
      };
    }
    if (detail === "E-mail already verified.") {
      return {
        type: "alreadyVerified",
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Sign up user error", {
      email,
      username,
    });
    if (err instanceof HTTPError) {
      try {
        errors = await err.response?.json();
      } catch {
        return {
          type: "error",
          errors: { unknownError: ActivationFailReasons.Unknown },
        };
      }
    }
    return {
      errors,
      type: "error",
    };
  }
  return {
    type: "error",
    errors: { unknownError: ActivationFailReasons.Unknown },
  };
};

type AuthFlowResults =
  | {
      type: "success";
    }
  | { type: "error"; error: ActivationFailReasons };

type VerifyEmailResults =
  | {
      type: "success";
      key: string;
    }
  | { type: "error"; error: ActivationFailReasons };

export const verifyEmail = async (key: string): Promise<VerifyEmailResults> => {
  let detail;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/registration/verify-email/`, {
      json: { key },
      headers: { "X-CSRFToken": csrf },
    });
    detail = (await response.json()) as unknown as ServerVerifyEmailResponse;

    if (detail.detail === "ok") {
      return {
        type: "success",
        key: detail.key,
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Verify user email error", {
      error: err,
    });
    if (err instanceof HTTPError) {
      try {
        detail = (
          (await err.response?.json()) as unknown as ServerSignUpResponse
        ).detail;
        if (detail === "Not found.") {
          return {
            type: "error",
            error: ActivationFailReasons.InvalidActivationKey,
          };
        }
      } catch {
        return { type: "error", error: ActivationFailReasons.Unknown };
      }
    }
  }
  return { type: "error", error: ActivationFailReasons.Unknown };
};

export const retrieveUserName = async (
  email: string,
): Promise<AuthFlowResults> => {
  let detail;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/users/retrieve-username/`, {
      json: { email },
      headers: { "X-CSRFToken": csrf },
    });
    detail = ((await response.json()) as unknown as ServerSignUpResponse)
      .detail;

    if (detail === "ok") {
      return {
        type: "success",
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Retrieve username error", {
      error: err,
    });

    return { type: "error", error: ActivationFailReasons.Unknown };
  }
  return { type: "error", error: ActivationFailReasons.Unknown };
};

export const resendEmailConfirmation = async (
  email: string,
): Promise<AuthFlowResults> => {
  let detail;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/resend-confirmation-email/`, {
      json: { email },
      headers: { "X-CSRFToken": csrf },
    });
    detail = ((await response.json()) as unknown as ServerSignUpResponse)
      .detail;

    if (detail === "ok") {
      return {
        type: "success",
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Resend email confirmation error", {
      email,
      error: err,
    });

    return { type: "error", error: ActivationFailReasons.Unknown };
  }
  return { type: "error", error: ActivationFailReasons.Unknown };
};

export const requestPasswordReset = async (
  email: string,
): Promise<AuthFlowResults> => {
  let detail;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/auth/password/reset/`, {
      json: { email },
      headers: { "X-CSRFToken": csrf },
    });
    detail = ((await response.json()) as unknown as ServerSignUpResponse)
      .detail;

    if (detail === "Password reset e-mail has been sent.") {
      return {
        type: "success",
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Request password reset error", {
      error: err,
    });
    return { type: "error", error: ActivationFailReasons.Unknown };
  }
  return { type: "error", error: ActivationFailReasons.Unknown };
};

type ResetPasswordErrorMessages = {
  uid?: string[];
  token?: string[];
  new_password1?: string[];
  new_password2?: string[];
  non_field_errors?: string[];
  unknownError?: PasswordResetFailReasons;
};

type ResetPasswordResults =
  | {
      type: "success";
    }
  | { type: "error"; errors: ResetPasswordErrorMessages };

type PasswordResetResponse = {
  token?: string[];
  detail?: string;
  uid?: string;
};

export const passwordReset = async (
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string,
): Promise<ResetPasswordResults> => {
  let serverResponse;
  try {
    const csrf = getDjangoCSRFCookie() || "";
    const response = await ky.post(`/api/v1/auth/password/reset/confirm/`, {
      json: {
        uid,
        token,
        new_password1,
        new_password2,
      },
      headers: { "X-CSRFToken": csrf },
    });
    serverResponse =
      (await response.json()) as unknown as PasswordResetResponse;

    if (
      serverResponse.detail === "Password has been reset with the new password."
    ) {
      return {
        type: "success",
      };
    }
  } catch (err) {
    if (err instanceof HTTPError) {
      serverResponse =
        (await err.response?.json()) as unknown as PasswordResetResponse;

      datadogLogs.logger.error("Password reset error");

      if (
        serverResponse.token &&
        serverResponse?.token[0] === "Invalid value"
      ) {
        return {
          type: "error",
          errors: {
            unknownError: PasswordResetFailReasons.InvalidActivationKey,
          },
        };
      } else if (
        serverResponse.uid &&
        serverResponse.uid[0] === "Invalid value"
      ) {
        return {
          type: "error",
          errors: {
            unknownError: PasswordResetFailReasons.InvalidActivationKey,
          },
        };
      } else {
        return {
          type: "error",
          errors: { unknownError: PasswordResetFailReasons.Unknown },
        };
      }
    }
  }
  return {
    type: "error",
    errors: { unknownError: PasswordResetFailReasons.Unknown },
  };
};

type PasswordChangeResponse = {
  detail: string;
  new_password1?: string[];
  new_password2?: string[];
  non_field_errors?: string[];
};

export const changePassword = async (
  newPassword1: string,
  newPassword2: string,
): Promise<ResetPasswordResults> => {
  let serverResponse;
  let errors;
  try {
    const response = await ky.post(`/api/v1/auth/password/change/`, {
      json: {
        new_password1: newPassword1,
        new_password2: newPassword2,
      },

      headers: getStandardHeaders(),
    });
    serverResponse =
      (await response.json()) as unknown as PasswordChangeResponse;

    if (serverResponse.detail === "New password has been saved.") {
      return {
        type: "success",
      };
    }
  } catch (err) {
    datadogLogs.logger.error("Change Password error");
    if (err instanceof HTTPError) {
      try {
        errors =
          (await err.response?.json()) as unknown as PasswordChangeResponse;
      } catch {
        return {
          type: "error",
          errors: { unknownError: PasswordResetFailReasons.Unknown },
        };
      }
      return {
        errors,
        type: "error",
      };
    }
  }
  return {
    type: "error",
    errors: { unknownError: PasswordResetFailReasons.Unknown },
  };
};
