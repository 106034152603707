import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  AddResultSchema,
  AddUserToOrganizationSchema,
  CreateOrganizationSchema,
  OrganizationListSchema,
  OrganizationMembershipSchema,
  OrganizationSchema,
  OrganizationScopeSchemaTypeEnum,
  PagedOrganizationMembershipSchema,
  PagedOrganizationStaffListSchema,
  ResponseError,
  UsersViewsOrganizationsOrganizationRemoveMemberRequest,
  UsersViewsOrganizationsOrganizationUpdateMemberRequest,
  UsersViewsOrganizationsOrganizationsStaffRequest,
  UsersViewsOrganizationsOrganizationsStaffValuesRequest,
  ValidScopesSchema,
  UsersViewsGroupSetOrgGroupsRequest,
  CustomGroupSchema,
} from "../../api-client";
import { useContext } from "react";
import { UserContext } from "../user/context";

export const useStaffOrganizations = (
  request: UsersViewsOrganizationsOrganizationsStaffRequest,
): UseQueryResult<PagedOrganizationStaffListSchema> => {
  const result = useQuery({
    queryKey: ["staffOrganizations", request],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsOrganizationsOrganizationsStaff(request, { signal }),
  });
  return result;
};

export const useStaffOrganizationsNames = (
  request: UsersViewsOrganizationsOrganizationsStaffValuesRequest,
): UseQueryResult<string[]> => {
  const result = useQuery({
    queryKey: ["staffOrganizationsNames", request],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsOrganizationsOrganizationsStaffValues(request, {
        signal,
      }),
  });
  return result;
};

export const useOrganization = (
  organizationId?: number,
): UseQueryResult<OrganizationSchema> => {
  return useQuery({
    queryKey: ["organization", organizationId],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsOrganizationsOrganizationDetail(
        { organizationId: organizationId as number },
        { signal },
      ),
    enabled: !!organizationId,
  });
};

export const useOrganizationMemberships = (
  organizationId: number,
): UseQueryResult<PagedOrganizationMembershipSchema> => {
  return useQuery({
    queryKey: ["organizationMemberships", organizationId],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsOrganizationsOrganizationMembers(
        {
          organizationId,
        },
        { signal },
      ),
  });
};

export const useCreateOrganizationMutation = (): UseMutationResult<
  OrganizationSchema,
  ResponseError,
  CreateOrganizationSchema,
  unknown
> => {
  const {
    user: { id: userId },
  } = useContext(UserContext);
  const queryClient = useQueryClient();
  const createOrganizationMutation = useMutation<
    OrganizationSchema,
    ResponseError,
    CreateOrganizationSchema,
    unknown
  >({
    mutationKey: "createOrganization",
    mutationFn: (params: CreateOrganizationSchema) =>
      ApiClient.usersViewsOrganizationsOrganizationCreate({
        createOrganizationSchema: params,
      }),
    onSuccess: (resp) => {
      queryClient.setQueryData(
        "getOrganizationMemberships",
        (oldData: OrganizationMembershipSchema[] | undefined) => {
          const newMembership = resp.memberSet.find(
            (m) => m.user.id === userId,
          );
          if (!newMembership) {
            return [];
          }
          return oldData
            ? [
                ...oldData,
                {
                  ...newMembership,
                },
              ]
            : [newMembership];
        },
      );
      queryClient.setQueryData(
        "useOrganizations",
        (oldData: OrganizationListSchema[] | undefined) => {
          return oldData
            ? [...oldData.filter((o) => o.id !== resp.id), resp]
            : [resp];
        },
      );
      queryClient.setQueriesData(
        "getValidScopes",
        (oldData: ValidScopesSchema | undefined) => {
          return oldData
            ? {
                accounts: oldData.accounts,
                organizations: [
                  ...oldData.organizations.filter((o) => o.id !== resp.id),
                  {
                    id: resp.id,
                    name: resp.name,
                    type: OrganizationScopeSchemaTypeEnum.Organization,
                  },
                ],
                compoundScopes: oldData.compoundScopes,
                users: oldData.users,
                customStaffScopes: oldData.customStaffScopes,
              }
            : {
                accounts: [],
                organizations: [],
                compoundScopes: [],
                users: [],
                customStaffScopes: [],
              };
        },
      );
    },
  });
  return createOrganizationMutation;
};

export const useOrganizationMembershipDetails = (
  membershipId?: number,
): UseQueryResult<OrganizationMembershipSchema> => {
  return useQuery({
    queryKey: ["organizationMembershipDetails", membershipId],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsOrganizationsOrganizationMembershipDetail(
        {
          membershipId: membershipId as number,
        },
        { signal },
      ),
    enabled: !!membershipId,
  });
};

export const useAddOrganizationMemberMutation = (): UseMutationResult<
  AddResultSchema,
  ResponseError,
  AddUserToOrganizationSchema,
  unknown
> => {
  const queryClient = useQueryClient();
  const addOrganizationMemberMutation = useMutation<
    AddResultSchema,
    ResponseError,
    AddUserToOrganizationSchema,
    unknown
  >({
    mutationKey: "addOrganizationMember",
    mutationFn: async (params: AddUserToOrganizationSchema) =>
      ApiClient.usersViewsOrganizationsOrganizationAddMember({
        addUserToOrganizationSchema: params,
      }),
    onSuccess: (data) => {
      if (data.newMembership) {
        const newMembership = data.newMembership;
        queryClient.setQueryData<PagedOrganizationMembershipSchema | undefined>(
          ["organizationMemberships", newMembership.organizationId],
          (oldData) => {
            return oldData
              ? {
                  ...oldData,
                  items: [...oldData.items, newMembership],
                }
              : undefined;
          },
        );
      }
    },
  });
  return addOrganizationMemberMutation;
};

export const useUpdateOrganizationMembershipMutation = (): UseMutationResult<
  OrganizationMembershipSchema,
  ResponseError,
  UsersViewsOrganizationsOrganizationUpdateMemberRequest
> => {
  const queryClient = useQueryClient();
  const updateOrganizationMembershipMutation = useMutation<
    OrganizationMembershipSchema,
    ResponseError,
    UsersViewsOrganizationsOrganizationUpdateMemberRequest
  >({
    mutationKey: "updateOrganizationMembership",
    mutationFn: async (
      params: UsersViewsOrganizationsOrganizationUpdateMemberRequest,
    ) =>
      ApiClient.usersViewsOrganizationsOrganizationUpdateMember({
        membershipId: params.membershipId,
        updateOrganizationMembershipSchema:
          params.updateOrganizationMembershipSchema,
      }),
    onSuccess: (data, params) => {
      queryClient.setQueryData<OrganizationMembershipSchema | undefined>(
        ["organizationMembershipDetails", params.membershipId],
        (oldData) => {
          return data;
        },
      );
    },
  });
  return updateOrganizationMembershipMutation;
};

export const useRemoveOrganizationMemberMutation = (): UseMutationResult<
  void,
  unknown,
  UsersViewsOrganizationsOrganizationRemoveMemberRequest
> => {
  const queryClient = useQueryClient();
  const deleteOrganizationMembershipMutation = useMutation({
    mutationKey: "deleteOrganizationMembership",
    mutationFn: async (
      params: UsersViewsOrganizationsOrganizationRemoveMemberRequest,
    ) =>
      ApiClient.usersViewsOrganizationsOrganizationRemoveMember({
        membershipId: params.membershipId,
      }),
    onSuccess: (params, vars) => {
      queryClient.setQueriesData<PagedOrganizationMembershipSchema | undefined>(
        "organizationMemberships",
        (oldData) => {
          return oldData?.items
            ? {
                items: oldData.items.filter(
                  (item) => item.id !== vars.membershipId,
                ),
                count: oldData.count - 1,
              }
            : undefined;
        },
      );
    },
  });
  return deleteOrganizationMembershipMutation;
};

export const useCustomGroupList = (): UseQueryResult<CustomGroupSchema[]> => {
  return useQuery({
    queryKey: ["customGroupList"],
    queryFn: async ({ signal }) =>
      ApiClient.usersViewsGroupGetGroupList({ signal }),
  });
};

export const useSetOrgGroupsMutation = (): UseMutationResult<
  CustomGroupSchema[],
  unknown,
  UsersViewsGroupSetOrgGroupsRequest
> => {
  const queryClient = useQueryClient();
  const setOrgGroupsMutation = useMutation({
    mutationKey: "setOrgGroups",
    mutationFn: async (params: UsersViewsGroupSetOrgGroupsRequest) =>
      ApiClient.usersViewsGroupSetOrgGroups(params),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<OrganizationSchema | undefined>(
        ["organization", variables.setOrgGroupsSchema.organizationId],
        (
          oldData: OrganizationSchema | undefined,
        ): OrganizationSchema | undefined => {
          return oldData ? { ...oldData, customGroups: data } : undefined;
        },
      );
    },
  });
  return setOrgGroupsMutation;
};
