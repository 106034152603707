import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  DeclarativeViewsPackagesCacheListRequest,
  PagedCacheEntrySchema,
} from "../../api-client";
import { sum } from "lodash";

export const usePackagesCache = (
  query: DeclarativeViewsPackagesCacheListRequest,
  refetchInterval?: number,
): UseInfiniteQueryResult<PagedCacheEntrySchema> => {
  return useInfiniteQuery(
    ["packagesCache", query],
    async ({ pageParam }) =>
      ApiClient.declarativeViewsPackagesCacheList({
        ...query,
        ...pageParam,
      }),

    {
      refetchInterval,
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = sum(allPages.map((page) => page.items.length));
        if (totalCount < lastPage.count) {
          return {
            ...query,
            offset: totalCount,
            limit: query.limit,
          };
        } else {
          return undefined;
        }
      },
    },
  );
};
