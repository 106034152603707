/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All currently supported architecture types
 * @export
 */
export const ArchitectureTypesEnum = {
    X8664: 'x86_64',
    Aarch64: 'aarch64'
} as const;
export type ArchitectureTypesEnum = typeof ArchitectureTypesEnum[keyof typeof ArchitectureTypesEnum];


export function ArchitectureTypesEnumFromJSON(json: any): ArchitectureTypesEnum {
    return ArchitectureTypesEnumFromJSONTyped(json, false);
}

export function ArchitectureTypesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchitectureTypesEnum {
    return json as ArchitectureTypesEnum;
}

export function ArchitectureTypesEnumToJSON(value?: ArchitectureTypesEnum | null): any {
    return value as any;
}

