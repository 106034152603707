import { storage } from "../storageUtils";
import { datadogLogs } from "@datadog/browser-logs";

export const loadStoredColumns = <ColumnT extends string>(
  columnsKey: string,
  defaultColumns: readonly ColumnT[],
  allColumns: readonly ColumnT[],
): ColumnT[] => {
  // This is meant to be called when the app is loaded.
  // After this initial loading columns will be managed in state.
  const defaultColumnsCopy = [...defaultColumns];
  try {
    const savedColumns = storage.getItem(columnsKey);
    const columns = savedColumns
      ? (JSON.parse(savedColumns) as ColumnT[])
      : null;
    if (!columns) {
      return defaultColumnsCopy;
    }
    // The available columns may have changed since they were last here
    const filtered = columns.filter((c) => allColumns.indexOf(c) > -1);
    if (filtered.length > 0) {
      return filtered;
    } else {
      return defaultColumnsCopy;
    }
  } catch (e) {
    let message;
    if (e instanceof Error) message = e.message;
    else message = String(e);
    datadogLogs.logger.error("Error reading columns from local storage", {
      message,
      error: e,
    });
    return defaultColumnsCopy;
  }
};

export const saveColumns = <ColumnT extends string>(
  columnKey: string,
  columns: ColumnT[],
): void => storage.setItem(columnKey, JSON.stringify(columns));
