/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchitectureTypesEnum } from './ArchitectureTypesEnum';
import {
    ArchitectureTypesEnumFromJSON,
    ArchitectureTypesEnumFromJSONTyped,
    ArchitectureTypesEnumToJSON,
} from './ArchitectureTypesEnum';
import type { PackageSchema } from './PackageSchema';
import {
    PackageSchemaFromJSON,
    PackageSchemaFromJSONTyped,
    PackageSchemaToJSON,
} from './PackageSchema';
import type { ScanResultSchema } from './ScanResultSchema';
import {
    ScanResultSchemaFromJSON,
    ScanResultSchemaFromJSONTyped,
    ScanResultSchemaToJSON,
} from './ScanResultSchema';

/**
 * 
 * @export
 * @interface SoftwareEnvironmentSpecSchema
 */
export interface SoftwareEnvironmentSpecSchema {
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    updated: Date;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    aliasId: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    md5?: string;
    /**
     * 
     * @type {Array<PackageSchema>}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    packages: Array<PackageSchema>;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    containerUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    gpuEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    rawPip?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    rawConda?: string;
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    latestBuildId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    useEntrypoint?: boolean;
    /**
     * 
     * @type {ArchitectureTypesEnum}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    architecture?: ArchitectureTypesEnum;
    /**
     * 
     * @type {ScanResultSchema}
     * @memberof SoftwareEnvironmentSpecSchema
     */
    scanResult?: ScanResultSchema;
}

/**
 * Check if a given object implements the SoftwareEnvironmentSpecSchema interface.
 */
export function instanceOfSoftwareEnvironmentSpecSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "aliasId" in value;
    isInstance = isInstance && "packages" in value;
    isInstance = isInstance && "gpuEnabled" in value;

    return isInstance;
}

export function SoftwareEnvironmentSpecSchemaFromJSON(json: any): SoftwareEnvironmentSpecSchema {
    return SoftwareEnvironmentSpecSchemaFromJSONTyped(json, false);
}

export function SoftwareEnvironmentSpecSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentSpecSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'aliasId': json['alias_id'],
        'md5': !exists(json, 'md5') ? undefined : json['md5'],
        'packages': ((json['packages'] as Array<any>).map(PackageSchemaFromJSON)),
        'containerUri': !exists(json, 'container_uri') ? undefined : json['container_uri'],
        'gpuEnabled': json['gpu_enabled'],
        'rawPip': !exists(json, 'raw_pip') ? undefined : json['raw_pip'],
        'rawConda': !exists(json, 'raw_conda') ? undefined : json['raw_conda'],
        'latestBuildId': !exists(json, 'latest_build_id') ? undefined : json['latest_build_id'],
        'useEntrypoint': !exists(json, 'use_entrypoint') ? undefined : json['use_entrypoint'],
        'architecture': !exists(json, 'architecture') ? undefined : ArchitectureTypesEnumFromJSON(json['architecture']),
        'scanResult': !exists(json, 'scan_result') ? undefined : ScanResultSchemaFromJSON(json['scan_result']),
    };
}

export function SoftwareEnvironmentSpecSchemaToJSON(value?: SoftwareEnvironmentSpecSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'alias_id': value.aliasId,
        'md5': value.md5,
        'packages': ((value.packages as Array<any>).map(PackageSchemaToJSON)),
        'container_uri': value.containerUri,
        'gpu_enabled': value.gpuEnabled,
        'raw_pip': value.rawPip,
        'raw_conda': value.rawConda,
        'latest_build_id': value.latestBuildId,
        'use_entrypoint': value.useEntrypoint,
        'architecture': ArchitectureTypesEnumToJSON(value.architecture),
        'scan_result': ScanResultSchemaToJSON(value.scanResult),
    };
}

