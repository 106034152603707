/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";

import syntaxTheme from "../../../shared-components/syntaxTheme";
import {
  Collapse,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { formatSeconds, formatBytes } from "../../../utils/index";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { AnalyticsRendererProps } from "./AnalyticsPane";
import { Exception, ExceptionListing } from "./Exceptions";

type CodeFrame = {
  code: string;
  frame_index: number;
  filename?: string;
  relative_line?: number;
};

type Computation = {
  start?: string;
  stop?: string;
  code?: string;
  callstack: CodeFrame[];
  identifier: string;
};

type TaskGroup = {
  name: string;
  computation?: string;
  start?: string;
  stop?: string;
  nbytes: number;
  n_tasks: number;
  tasks_erred?: number;
  duration_compute: number;
  duration_disk_write: number;
  duration_disk_read: number;
  duration_deserialize: number;
  duration_transfer: number;
  types?: string[];
};

/*
The return type of the `computations` endpoint. This lists all computations for a
cluster, as well as the task groups and dependency structures.
*/
export type ComputationListingData = {
  computations: Computation[];
  task_groups: TaskGroup[];
  exceptions?: Exception[];
};

/*
Same as ComputationListingData, but scoped to a single computation.
*/
type ComputationData = {
  computation: Computation;
  taskGroups: TaskGroup[];
  exceptions?: Exception[];
};

const TABLE_HEADERS = [
  "",
  "Code",
  "Duration",
  "# Tasks",
  "Memory Processed",
  "# Exceptions",
];

/*
A single table row that can expand to show more information about a computation.
*/
const Row = (props: { data: ComputationData; initiallyOpen: boolean }) => {
  const { data, initiallyOpen } = props;
  const { computation, taskGroups } = data;
  const [open, setOpen] = React.useState(initiallyOpen);

  // Construct a preview of the code block to show inline.
  const lines = (computation?.callstack ? computation?.callstack[0].code : "")
    .trim()
    .split("\n");
  const preview =
    lines.length <= 5
      ? lines.join("\n")
      : lines
          .slice(0, 2)
          .concat(["\u2026"])
          .concat(lines.slice(-2))
          .map((l) => (l.length < 60 ? l : l.slice(0, 60) + "\u2026"))
          .join("\n");

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.05)",
          },
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell size={"small"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <SyntaxHighlighter
            language="python"
            style={syntaxTheme}
            customStyle={{ backgroundColor: "transparent" }}
          >
            {preview}
          </SyntaxHighlighter>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {computation.start &&
            computation.stop &&
            computation.start < computation.stop
              ? formatSeconds(
                  (new Date(computation.stop).getTime() -
                    new Date(computation.start).getTime()) /
                    1000,
                )
              : ""}
          </Typography>
        </TableCell>
        <TableCell>
          {
            // sum of tasks across task groups in this computation
            taskGroups.reduce((acc, x) => acc + x.n_tasks, 0).toLocaleString()
          }
        </TableCell>
        <TableCell>
          {
            // sum of bytes across task groups in this computation
            formatBytes(taskGroups.reduce((acc, x) => acc + x.nbytes, 0))
          }
        </TableCell>
        <TableCell size={"small"}>
          {
            // number of exceptions is direction on the computation
            data.exceptions ? data.exceptions.length : 0
          }
        </TableCell>
      </TableRow>
      <TableRow
        sx={(theme) => ({
          "& > *": {
            borderBottom: "unset",
          },
          borderBottom: `1px solid ${theme.palette.custom!.grey.warm}`,
        })}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={TABLE_HEADERS.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={data.exceptions ? 6 : 12}>
                {data.exceptions && data.exceptions.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Code </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                ) : null}
                {computation.callstack ? (
                  <Container sx={{ maxWidth: "900px" }}>
                    <b>Index: {computation.callstack[0].frame_index}</b>
                    <SyntaxHighlighter
                      language="python"
                      style={syntaxTheme}
                      customStyle={{ backgroundColor: "transparent" }}
                    >
                      {computation.callstack[0].code}
                    </SyntaxHighlighter>
                  </Container>
                ) : null}
              </Grid>
              {data.exceptions && data.exceptions.length ? (
                <Grid item xs={12} sm={12} md={6}>
                  <ExceptionListing exceptions={data.exceptions} />
                </Grid>
              ) : null}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

/*
A table listing the computations for a cluster.
*/
export const ComputationListing = ({
  data,
}: AnalyticsRendererProps<ComputationListingData>): React.ReactElement => {
  const [page, setPage] = React.useState<number>(0);
  const rowsPerPage = 10;

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((h) => (
              <TableCell key={h}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.computations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((c) => {
              // task groups that reference this computation
              const taskGroups = data.task_groups.filter(
                (tg) => tg.computation === c.identifier,
              );
              const tgNames = taskGroups.map((tg) => tg.name);

              // exceptions that reference task groups that reference this computation
              const exceptions = (data.exceptions || []).filter((e) =>
                tgNames.includes(e.task_group),
              );

              return (
                <Row
                  key={c.identifier}
                  data={{
                    computation: c,
                    taskGroups,
                    exceptions,
                  }}
                  initiallyOpen={data.computations.length === 1}
                />
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        component={"div"}
        count={data.computations.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
};
