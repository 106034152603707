import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";

type ControlledCheckBoxProps<TFieldValues extends FieldValues = any> =
  CheckboxProps & {
    control: Control<TFieldValues>;
    name: string;
    label?: string | React.ReactNode;
  };

export const HookCheck = ({
  control,
  name,
  label,
  ...props
}: ControlledCheckBoxProps): React.ReactElement => {
  return label ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                {...field}
                {...props}
                value={field.value}
                checked={field.value}
                defaultValue={field.value}
              />
            }
          />
        );
      }}
    />
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Checkbox
            {...field}
            {...props}
            value={field.value}
            checked={field.value}
          />
        );
      }}
    />
  );
};
