import React, { createContext, ReactElement, ReactNode } from "react";
import { ConfigSchema } from "../../api-client";

export const AppConfigContext = createContext<ConfigSchema>({
  aws: { defaultRegion: "", supportedRegions: [] },
  gcp: {
    defaultRegion: "",
    defaultZone: "",
    supportedRegions: [],
    supportedZones: [],
  },
});

type AppConfigContextProviderProps = {
  config: ConfigSchema;
  children?: ReactNode;
};

export const AppConfigContextProvider = ({
  config,
  children,
}: AppConfigContextProviderProps): ReactElement => {
  return (
    <AppConfigContext.Provider value={config}>
      {children}
    </AppConfigContext.Provider>
  );
};
