import { datadogLogs } from "@datadog/browser-logs";
import ky from "ky";
import { camelizeKeys, getStandardHeaders, is404 } from "../../apiUtils";
import {
  ClusterData,
  ClusterAnalyticsSummary,
} from "../../pages/Clusters/Analytics/ClusterStatistics";
import { handleError } from "../../utils/errors";

export const getClusterStatistics = async (
  accountSlug: string,
  analyticsId: number,
  signal?: AbortSignal,
): Promise<ClusterData> => {
  try {
    const buildPayload = await ky
      .get(
        `/api/v1/${accountSlug}/analytics/cluster/${analyticsId}/cluster-statistics`,
        {
          headers: getStandardHeaders(),
          // Use AbortController.signal to cancel ongoing request if the user changes the filter
          signal,
        },
      )
      .json();

    return buildPayload as ClusterData;
  } catch (err: any) {
    if (!is404(err)) {
      datadogLogs.logger.error(
        `Unable to get cluster statistics for analytics id ${analyticsId} in the workspace ${accountSlug} - Reason: ${err}`,
      );
    }
    await handleError(err as Error);
    throw err;
  }
};

export const getClusterAnalyticsSummary = async (
  accountSlug: string,
  clusterId: number,
  signal?: AbortSignal,
): Promise<ClusterAnalyticsSummary> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/analytics/${accountSlug}/cluster/${clusterId}/summary`, {
        headers: getStandardHeaders(),
        // Use AbortController.signal to cancel ongoing request if the user changes the filter
        signal,
      })
      .json();

    return camelizeKeys(buildPayload) as ClusterAnalyticsSummary;
  } catch (err: any) {
    if (!is404(err)) {
      datadogLogs.logger.error(
        `Unable to get analytics summary for cluster id ${clusterId} in the workspace ${accountSlug} - Reason: ${err}`,
      );
    }
    await handleError(err as Error);
    throw err;
  }
};
