/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SizeResponseSchema
 */
export interface SizeResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    ts: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    downloading: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    error: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    instanceRunning: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    queued: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    starting: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    stopping: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    readyOnDemand: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponseSchema
     */
    readySpot: number;
}

/**
 * Check if a given object implements the SizeResponseSchema interface.
 */
export function instanceOfSizeResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "downloading" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "instanceRunning" in value;
    isInstance = isInstance && "queued" in value;
    isInstance = isInstance && "starting" in value;
    isInstance = isInstance && "stopping" in value;
    isInstance = isInstance && "readyOnDemand" in value;
    isInstance = isInstance && "readySpot" in value;

    return isInstance;
}

export function SizeResponseSchemaFromJSON(json: any): SizeResponseSchema {
    return SizeResponseSchemaFromJSONTyped(json, false);
}

export function SizeResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SizeResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ts': json['ts'],
        'downloading': json['downloading'],
        'error': json['error'],
        'instanceRunning': json['instance_running'],
        'queued': json['queued'],
        'starting': json['starting'],
        'stopping': json['stopping'],
        'readyOnDemand': json['ready_on_demand'],
        'readySpot': json['ready_spot'],
    };
}

export function SizeResponseSchemaToJSON(value?: SizeResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ts': value.ts,
        'downloading': value.downloading,
        'error': value.error,
        'instance_running': value.instanceRunning,
        'queued': value.queued,
        'starting': value.starting,
        'stopping': value.stopping,
        'ready_on_demand': value.readyOnDemand,
        'ready_spot': value.readySpot,
    };
}

