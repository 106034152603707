import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { useCreateOrganizationMutation } from "../../crud/organizations/hooks";
import { Page } from "../../shared-components/Page";
import {
  ValidScopes,
  useScopedContext,
} from "../../shared-components/ScopeSelector";
import { LoadingButton } from "@mui/lab";

export const CreateOrganizationForm = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: "",
    },
  });
  const { mutate: createOrganization, isLoading } =
    useCreateOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [, setSelectedOrg] = useScopedContext();
  return (
    <Page
      title="Create Organization"
      breadCrumbs={[
        {
          text: "Team",
          link: `/${Urls.Team}`,
        },
        {
          text: "Organizations",
        },
        { text: "Create" },
      ]}
    >
      <form
        onSubmit={handleSubmit((data) => {
          createOrganization(
            {
              name: data.name,
            },
            {
              onSuccess: (resp) => {
                reset();
                enqueueSnackbar("Organization created", {
                  variant: "success",
                });
                const newScope: ValidScopes = {
                  name: data.name,
                  type: "organization",
                  id: resp.id,
                };
                setSelectedOrg(newScope);
                navigate(`/${Urls.Team}`);
              },
              onError: async (err) => {
                const { message } = await err.response.json();
                enqueueSnackbar(`Error: ${message}`, {
                  variant: "error",
                });
              },
            },
          );
        })}
      >
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography>Create a new organization</Typography>
              <TextField
                {...register("name", {
                  minLength: {
                    value: 4,
                    message: "Must be at least 4 characters",
                  },
                  maxLength: {
                    value: 34,
                    message: "Must be less than 34 characters",
                  },
                  required: "Organization name is required",
                })}
                placeholder="Organization Name"
                helperText={
                  errors.name
                    ? errors.name.message
                    : "Workspace name, must be unique and between 4 and 34 characters."
                }
                size="small"
                error={!!errors.name}
                sx={{ width: "450px" }}
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="secondary"
                component={Link}
                to={`/${Urls.Team}`}
                onClick={() => reset()}
              >
                Cancel
              </Button>
              <LoadingButton type="submit" loading={isLoading}>
                Create Organization
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </Page>
  );
};
