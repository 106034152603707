import { format } from "date-fns";
import React from "react";
import { formatSeconds, formatBytes } from "../../../utils";
import { AnalyticsRendererProps } from "./AnalyticsPane";
import { ColumnCheckboxes } from "../../../shared-components/ColumnCheckboxes";
import {
  saveColumns,
  loadStoredColumns,
} from "../../../shared-components/columnsCrud";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import startCase from "lodash/startCase";

const TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const ANALYTICS_COLUMNS_STORAGE = "coiled_columns_single_cluster_analytics";

export type ClusterData = {
  id: number;
  name: string;
  creator: string;
  created: string;
  nthreads: number;
  memory_limit: number;
  thread_seconds: number;
  n_tasks: number;
  n_exceptions: number;
  cost: string;
  version: string;
  cluster_id?: number;
  last_seen?: string;
  idle_since?: string;
  idle_timeout?: number;
  idle_duration?: number;
};

export type ClusterAnalyticsSummary = {
  durationsTotal: DurationsByType;
  durationsByPrefix: PrefixDurations[];
  computations: ComputationSummary[];
};

type DurationsByType = {
  compute: number;
  diskWrite: number;
  diskRead: number;
  deserialize: number;
  transfer: number;
};

type PrefixDurations = DurationsByType & {
  prefixName: string;
};

export type ExceptionSummary = {
  taskGroupName: string;
  exception: string;
  traceback: string;
  count: number;
  start?: string;
  stop?: string;
};

type CodeFrame = {
  code: string;
  frameIndex: number;
  filename?: string;
  relativeLine?: number;
};

export type StackSpecificFrame = {
  index: number;
  relativeLine?: number;
};

export type StackCode = {
  code: string;
  filename?: string;
  frames: StackSpecificFrame[];
};

type FineMetricItem = {
  name: string;
  value: number;
  group: number;
  activity: string;
};

export type ComputationSummary = {
  identifier: string;
  level: number;
  label?: string;
  start?: number;
  stop?: number;
  code: string;
  callstack: CodeFrame[];
  callstackDeduped: StackCode[];
  durations: DurationsByType;
  exceptions: ExceptionSummary[];
  nTasks: number;
  nbytes: number;
  fineMetricsMinor: FineMetricItem[];
  fineMetricsMajor: FineMetricItem[];
};

type Column = keyof ClusterData;

const getHumanReadableColumnName = (column: Column): string => {
  // TODO: Remove any not being used by v2
  switch (column) {
    case "id":
      return "Cluster Id";
    case "name":
      return "Cluster Name";
    case "creator":
      return "Creator";
    case "nthreads":
      return "# Threads";
    case "memory_limit":
      return "Memory";
    case "cost":
      return "Cost";
    case "created":
      return "Start Time";
    case "n_tasks":
      return "# Tasks";
    case "n_exceptions":
      return "# Exceptions";
    case "thread_seconds":
      return "Compute Time";
    default:
      return startCase(column);
  }
};

const DEFAULT_COLUMNS: Column[] = [
  "created",
  "creator",
  "n_tasks",
  "thread_seconds",
  "cost",
];

const ALL_COLUMNS_CLUSTER_DATA: Readonly<Column[]> = [
  "id",
  "name",
  ...DEFAULT_COLUMNS,
];

export const ClusterStatistics = ({
  data,
}: AnalyticsRendererProps<ClusterData>): React.ReactElement => {
  const [columnAnchor, setColumnAnchor] = React.useState<HTMLElement | null>(
    null,
  );
  const [columns, setColumns] = React.useState<Column[]>(
    loadStoredColumns(
      ANALYTICS_COLUMNS_STORAGE,
      DEFAULT_COLUMNS,
      ALL_COLUMNS_CLUSTER_DATA,
    ),
  );
  const handleUpdateColumns = (cs: Column[]) => {
    setColumns(cs);
    saveColumns(ANALYTICS_COLUMNS_STORAGE, cs);
  };

  const handleCloseColumnCheckboxes = () => setColumnAnchor(null);
  const handleSettingsClick = (e: React.MouseEvent<HTMLElement>) =>
    setColumnAnchor(e.currentTarget);

  // Tried to get this returning the human name and data, but failed
  // on the `getColumnName` arg.
  const getValue = (column: Column): string => {
    switch (column) {
      case "id":
        return data.cluster_id ? String(data.cluster_id) : "";
      case "name":
        return data.name;
      case "creator":
        return data.creator;
      case "nthreads":
        return String(data.nthreads);
      case "memory_limit":
        return formatBytes(data.memory_limit);
      case "cost":
        return data.cost;
      case "created":
        return format(new Date(data.created), TIME_FORMAT);
      case "n_tasks":
        return String(data.n_tasks);
      case "n_exceptions":
        return String(data.n_exceptions);
      case "thread_seconds":
        return formatSeconds(data.thread_seconds);
      default:
        return startCase(column);
    }
  };

  return (
    <TableContainer>
      <ColumnCheckboxes<Column>
        allColumnNames={ALL_COLUMNS_CLUSTER_DATA}
        getColumnName={getHumanReadableColumnName.bind(undefined)}
        columns={columns}
        updateColumns={handleUpdateColumns}
        anchorEl={columnAnchor}
        open={Boolean(columnAnchor)}
        handleClose={handleCloseColumnCheckboxes}
      />
      <Table aria-label="cluster data table">
        <TableHead>
          <TableRow>
            {columns.map((name) => (
              <TableCell key={name}>
                {getHumanReadableColumnName(name)}
              </TableCell>
            ))}
            <TableCell>
              <IconButton onClick={handleSettingsClick} size="large">
                <SettingsIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map((name) => (
              <TableCell key={name}>{getValue(name)}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
