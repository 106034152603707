import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../apiUtils";
import { AWSCredentials, GCPCredentials } from "../../domain/people";
import { datadogLogs } from "@datadog/browser-logs";
import { handleError } from "../../utils/errors";
import {
  AWSGlobalInfraSchema,
  ComponentDetailsSchema,
  GCPGlobalInfraSchema,
  GCPRegionalInfraSchema,
  RoleAssumptionSetupSchema,
} from "./types";

export const postAWSCredentials = async (
  account: string,
  credentials: AWSCredentials,
  signal?: AbortSignal,
): Promise<AWSCredentials> => {
  const buildPayload = await ky
    .post(`/api/v2/cloud-credentials/${account}/aws`, {
      json: {
        access_key_id: credentials.accessKeyId,
        secret_access_key: credentials.secretAccessKey,
        role_arn: credentials.roleArn,
        external_id: credentials.externalId,
        type: credentials.type,
      },
      headers: getStandardHeaders(),
      signal,
    })
    .json();
  return camelizeKeys(buildPayload) as AWSCredentials;
};

export const deleteAWSCredentials = async (
  account: string,
  signal?: AbortSignal,
): Promise<void> => {
  await ky
    .delete(`/api/v2/cloud-credentials/${account}/aws`, {
      headers: getStandardHeaders(),
      signal,
    })
    .json();
};

export const getAWSCredentials = async (
  account: string,
  signal?: AbortSignal,
): Promise<AWSCredentials | undefined> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/cloud-credentials/${account}/aws`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    if (buildPayload === null) return undefined;
    return camelizeKeys(buildPayload) as AWSCredentials;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getRoleAssumptionSetup = async (
  account: string,
  signal?: AbortSignal,
): Promise<RoleAssumptionSetupSchema | undefined> => {
  try {
    const buildPayload = await ky
      .post(
        `/api/v2/cloud-credentials/account/${account}/start-role-assumption-setup`,
        {
          headers: getStandardHeaders(),
          signal,
        },
      )
      .json();
    if (buildPayload === null) return undefined;
    return camelizeKeys(buildPayload) as RoleAssumptionSetupSchema;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const postGCPCredentials = async (
  account: string,
  credentials: GCPCredentials,
  signal?: AbortSignal,
): Promise<GCPCredentials> => {
  try {
    const buildPayload = await ky
      .post(`/api/v2/cloud-credentials/${account}/gcp`, {
        json: {
          instance_service_account: credentials.instanceServiceAccount,
          ...(credentials.credentials?.type
            ? {
                credentials: {
                  type: credentials.credentials.type,
                  project_id: credentials.credentials.projectId,
                  private_key_id: credentials.credentials.privateKeyId,
                  private_key: credentials.credentials.privateKey,
                  client_email: credentials.credentials.clientEmail,
                  client_id: credentials.credentials.clientId,
                  auth_uri: credentials.credentials.authUri,
                  token_uri: credentials.credentials.tokenUri,
                  auth_provider_x509_cert_url:
                    credentials.credentials.authProviderX509CertUrl,
                  client_x509_cert_url:
                    credentials.credentials.clientX509CertUrl,
                },
              }
            : {}),
        },
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return buildPayload as GCPCredentials;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const deleteGCPCredentials = async (
  account: string,
  signal?: AbortSignal,
): Promise<void> => {
  try {
    await ky
      .delete(`/api/v2/cloud-credentials/${account}/gcp`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get delete credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};
export const getGCPCredentials = async (
  account: string,
  signal?: AbortSignal,
): Promise<GCPCredentials | undefined> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/cloud-credentials/${account}/gcp`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    if (buildPayload === null) return undefined;
    return camelizeKeys(buildPayload) as GCPCredentials;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const postAWSSetupGlobalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<AWSGlobalInfraSchema> => {
  try {
    const buildPayload = await ky
      .post(`/api/v2/setup/account/${account}/aws/global`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as AWSGlobalInfraSchema;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const postGCPSetupGlobalInfra = async (
  account: string,
  request: GCPGlobalInfraSchema,
  signal?: AbortSignal,
): Promise<GCPGlobalInfraSchema> => {
  try {
    const buildPayload = await ky
      .post(`/api/v2/setup/account/${account}/gcp/global`, {
        headers: getStandardHeaders(),
        signal,
        json: {
          managed: request.managed,
          scheduler_network_tags: request.schedulerNetworkTags,
          cluster_network_tags: request.clusterNetworkTags,
          ...(request.network?.link
            ? {
                network: {
                  link: request.network?.link,
                  name: request.network?.name,
                },
              }
            : {}),
        },
      })
      .json();
    return camelizeKeys(buildPayload) as GCPGlobalInfraSchema;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getAWSSetupGlobalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<AWSGlobalInfraSchema | undefined> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/global`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    if (buildPayload === null) return undefined;
    return camelizeKeys(buildPayload) as AWSGlobalInfraSchema | undefined;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getGCPSetupGlobalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<GCPGlobalInfraSchema | undefined> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/global`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    if (buildPayload === null) return undefined;
    return camelizeKeys(buildPayload) as GCPGlobalInfraSchema | undefined;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const deleteGCPSetupGlobalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<void> => {
  try {
    await ky
      .delete(`/api/v2/setup/account/${account}/gcp`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const deleteAWSSetupGlobalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<void> => {
  try {
    await ky
      .delete(`/api/v2/setup/account/${account}/aws`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const deleteAWSSetupRegionInfra = async (
  account: string,
  id: number,
  signal?: AbortSignal,
): Promise<void> => {
  try {
    await ky
      .delete(`/api/v2/setup/account/${account}/aws/region`, {
        headers: getStandardHeaders(),
        searchParams: {
          id,
        },
        signal,
      })
      .json();
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const deleteGCPSetupRegionInfra = async (
  account: string,
  id: number,
  signal?: AbortSignal,
): Promise<void> => {
  try {
    await ky
      .delete(`/api/v2/setup/account/${account}/gcp/region`, {
        headers: getStandardHeaders(),
        searchParams: {
          id,
        },
        signal,
      })
      .json();
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type UserVPC = {
  name?: string;
  id: string;
  cidr: string;
};

export const getAWSRegionVPCs = async (
  account: string,
  region: string,
  signal?: AbortSignal,
): Promise<UserVPC[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/available-vpcs/${region}`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as UserVPC[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get azs for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type UserNetwork = {
  name?: string;
  id: string;
  link?: string;
};

export const getGCPNetworks = async (
  account: string,
  signal?: AbortSignal,
): Promise<UserNetwork[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/available-networks`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as UserNetwork[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get networks for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type AvailablityZone = {
  name: string;
  id: string;
  type: "availability-zone" | "local-zone" | "wavelength-zone";
};

export const getAWSRegionAzs = async (
  account: string,
  region: string,
  signal?: AbortSignal,
): Promise<AvailablityZone[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/available-azs`, {
        headers: getStandardHeaders(),
        searchParams: {
          region,
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as AvailablityZone[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get azs for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type UserSubnet = {
  name: string;
  availabilityZone: string;
  availabilityZoneId: string;
  cidr: string;
  subnetId: string;
  arn: string;
};

export const getAWSRegionSubnets = async (
  account: string,
  region: string,
  vpcId: string,
  signal?: AbortSignal,
): Promise<UserSubnet[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/available-subnets`, {
        searchParams: {
          vpc_id: vpcId,
          region,
        },
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as UserSubnet[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type GCPUserSubnet = {
  name: string;
  cidr: string;
  link: string;
  id: string;
};

export const getGCPSubnets = async (
  account: string,
  region: string,
  signal?: AbortSignal,
): Promise<GCPUserSubnet[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/available-subnets`, {
        searchParams: {
          region,
        },
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as GCPUserSubnet[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type UserSecurityGroup = {
  groupId: string;
  securityGroupName?: string;
  vpcId: string;
};

export const getAWSRegionSecurityGroups = async (
  account: string,
  region: string,
  vpcId: string,
  signal?: AbortSignal,
): Promise<UserSecurityGroup[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/available-security-groups`, {
        headers: getStandardHeaders(),
        searchParams: {
          vpc_id: vpcId,
          region,
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as UserSecurityGroup[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getGCPFirewallTags = async (
  account: string,
  networkLink: string,
  signal?: AbortSignal,
): Promise<string[]> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/available-target-tags`, {
        headers: getStandardHeaders(),
        searchParams: {
          network_link: networkLink,
        },
        signal,
      })
      .json();
    return buildPayload as string[];
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type AWSAccountSettings = {
  autoSetup: boolean;
  giveWorkersPublicIp: boolean;
  giveSchedulerPublicIp: boolean;
  customSoftwareBucketPrefix: string;
  useSelfHostedBucket: boolean;
  amiOwnerAccount: string | null;
  amiNameFilter: string | null;
};

export const getAWSAccountSettings = async (
  account: string,
  signal?: AbortSignal,
): Promise<AWSAccountSettings> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/settings`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as AWSAccountSettings;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type GCPAccountSettings = {
  autoSetup: boolean;
  giveWorkersPublicIp: boolean;
  giveSchedulerPublicIp: boolean;
  customSoftwareBucketPrefix: string;
  useSelfHostedBucket: boolean;
};

export const getGCPAccountSettings = async (
  account: string,
  signal?: AbortSignal,
): Promise<GCPAccountSettings> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/settings`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as GCPAccountSettings;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const patchAWSAccountSettings = async (
  account: string,
  payload: AWSAccountSettings,
  signal?: AbortSignal,
): Promise<AWSAccountSettings> => {
  try {
    const buildPayload = await ky
      .patch(`/api/v2/setup/account/${account}/aws/settings`, {
        headers: getStandardHeaders(),
        json: {
          auto_setup: payload.autoSetup,
          give_workers_public_ip: payload.giveWorkersPublicIp,
          give_scheduler_public_ip: payload.giveSchedulerPublicIp,
          custom_software_bucket_prefix: payload.customSoftwareBucketPrefix,
          use_self_hosted_bucket: payload.useSelfHostedBucket,
          ami_name_filter: payload.amiNameFilter,
          ami_owner_account: payload.amiOwnerAccount,
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as AWSAccountSettings;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const patchGCPAccountSettings = async (
  account: string,
  payload: GCPAccountSettings,
  signal?: AbortSignal,
): Promise<GCPAccountSettings> => {
  try {
    const buildPayload = await ky
      .patch(`/api/v2/setup/account/${account}/gcp/settings`, {
        headers: getStandardHeaders(),
        json: {
          auto_setup: payload.autoSetup,
          give_workers_public_ip: payload.giveWorkersPublicIp,
          give_scheduler_public_ip: payload.giveSchedulerPublicIp,
          custom_software_bucket_prefix: payload.customSoftwareBucketPrefix,
          use_self_hosted_bucket: payload.useSelfHostedBucket,
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as GCPAccountSettings;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get subnets for ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getAWSRegionalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<AWSRegionalInfras> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/aws/regions`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return { regions: camelizeKeys(buildPayload) } as AWSRegionalInfras;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const getGCPRegionalInfra = async (
  account: string,
  signal?: AbortSignal,
): Promise<GCPRegionalInfras> => {
  try {
    const buildPayload = await ky
      .get(`/api/v2/setup/account/${account}/gcp/regions`, {
        headers: getStandardHeaders(),
        signal,
      })
      .json();
    return { regions: camelizeKeys(buildPayload) } as GCPRegionalInfras;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type SubnetComponent = ComponentDetailsSchema & {
  subnetId?: string;
  cidr?: string;
  arn?: string;
  availabilityZone?: string;
  forWorkers: boolean;
  forSchedulers: boolean;
};

type VPCComponent = ComponentDetailsSchema & {
  arn?: string;
  vpcId?: string;
};

type CloudWatchLogGroupComponent = ComponentDetailsSchema & {
  logGroupName?: string;
};

type SecurityGroupComponent = ComponentDetailsSchema & {
  arn?: string;
  groupId?: string;
  securityGroupName?: string;
  vpcId?: string;
  ingress?: string[];
  egress?: string[];
  forWorkers: boolean;
  forSchedulers: boolean;
};

export type AWSRegionalInfra = ComponentDetailsSchema & {
  default: boolean;
  region: string;
  managed: boolean;
  subnets?: SubnetComponent[];
  vpc?: VPCComponent;
  schedulerSecurityGroup?: SecurityGroupComponent;
  clusterSecurityGroup?: SecurityGroupComponent;
  cloudwatchLogGroup?: CloudWatchLogGroupComponent;
};

export type AWSRegionalInfras = {
  regions: AWSRegionalInfra[];
};

export type GCPRegionalInfras = {
  regions: GCPRegionalInfraSchema[];
};

export const postAWSRegionalInfra = async (
  account: string,
  request: AWSRegionalInfra,
  signal?: AbortSignal,
): Promise<AWSRegionalInfra> => {
  try {
    const buildPayload = await ky
      .post(`/api/v2/setup/account/${account}/aws/regions`, {
        headers: getStandardHeaders(),
        json: {
          default: request.default,
          region: request.region,
          managed: request.managed,
          subnets: request.subnets?.map((subnet) => ({
            availability_zone: subnet.availabilityZone,
            subnet_id: subnet.subnetId,
            for_workers: subnet.forWorkers,
            for_schedulers: subnet.forSchedulers,
          })),
          ...(!request.managed
            ? {
                cluster_security_group: {
                  for_workers: request.clusterSecurityGroup?.forWorkers,
                  for_schedulers: request.clusterSecurityGroup?.forSchedulers,
                  security_group_name:
                    request.clusterSecurityGroup?.securityGroupName,
                  vpc_id: request.clusterSecurityGroup?.vpcId,
                  group_id: request.clusterSecurityGroup?.groupId,
                },
                scheduler_security_group: {
                  for_workers: request.schedulerSecurityGroup?.forWorkers,
                  for_schedulers: request.schedulerSecurityGroup?.forSchedulers,
                  security_group_name:
                    request.schedulerSecurityGroup?.securityGroupName,
                  vpc_id: request.schedulerSecurityGroup?.vpcId,
                  group_id: request.schedulerSecurityGroup?.groupId,
                },
              }
            : {}),
          ...(request.vpc?.vpcId
            ? {
                vpc: {
                  vpc_id: request.vpc?.vpcId,
                },
              }
            : {}),
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as AWSRegionalInfra;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export const postGCPRegionalInfra = async (
  account: string,
  request: GCPRegionalInfraSchema,
  signal?: AbortSignal,
): Promise<GCPRegionalInfraSchema> => {
  try {
    const buildPayload = await ky
      .post(`/api/v2/setup/account/${account}/gcp/regions`, {
        headers: getStandardHeaders(),
        json: {
          default: request.default,
          region: request.region,
          managed: request.managed,
          subnets: request.subnets?.map((subnet) => ({
            link: subnet.link,
            name: subnet.name,
            for_workers: subnet.forWorkers,
            for_schedulers: subnet.forSchedulers,
          })),
        },
        signal,
      })
      .json();
    return camelizeKeys(buildPayload) as GCPRegionalInfraSchema;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get post credentials ${account} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};

export type CloudFormationSetupAttempt = {
  id: number;
  externalId: string;
  complete: boolean;
  roleArn?: string;
  link: string;
};

export const createCloudFormationSetupAttempt = async (
  accountSlug: string,
  signal?: AbortSignal,
): Promise<CloudFormationSetupAttempt> => {
  try {
    const buildPayload = await ky
      .post(
        `/api/v2/cloud-credentials/account/${accountSlug}/cf-setup-attempt`,
        {
          headers: getStandardHeaders(),
          signal,
        },
      )
      .json();
    return camelizeKeys(buildPayload) as CloudFormationSetupAttempt;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get create setup attempt in the workspace ${accountSlug} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};
export const getCloudFormationSetupAttempt = async (
  accountSlug: string,
  attemptId: number,
  signal?: AbortSignal,
): Promise<CloudFormationSetupAttempt> => {
  try {
    const buildPayload = await ky
      .get(
        `/api/v2/cloud-credentials/account/${accountSlug}/cf-setup-attempt/${attemptId}`,
        {
          headers: getStandardHeaders(),
          signal,
        },
      )
      .json();
    return camelizeKeys(buildPayload) as CloudFormationSetupAttempt;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get get setup attempt in the workspace ${accountSlug} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};
