import { TagAlertHitWithMessageSchema } from "../../../api-client";
import { Alert, Stack, Typography } from "@mui/material";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import React from "react";
import { useTimezone } from "../../../utils/hooks";
import { formatInTimeZone } from "date-fns-tz";

type TagAlertsVerboseListProps = {
  alertList: TagAlertHitWithMessageSchema[];
};

export const TagAlertsVerboseList = (
  props: TagAlertsVerboseListProps,
): React.ReactElement => {
  const [displayTz] = useTimezone();
  return (
    <Stack
      sx={{
        padding: "1rem",
      }}
      spacing={1}
    >
      {props.alertList.map((hit: TagAlertHitWithMessageSchema) => (
        <Alert severity={levelToAlertSev(hit.level)} key={`alert_${hit.title}`}>
          <Typography>
            <div>
              <b>{hit.title}</b> (first happened at{" "}
              <b>
                {formatInTimeZone(
                  hit.firstOccurred,
                  displayTz,
                  "yyyy-MM-dd HH:mm:ss",
                )}
              </b>
              )
            </div>
            {hit.message && (
              // @ts-ignore
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {hit.message}
              </ReactMarkdown>
            )}
          </Typography>
        </Alert>
      ))}
    </Stack>
  );
};
export const levelToAlertSev = (
  level: number | undefined,
): "warning" | "error" | "info" => {
  if (level === 30) {
    return "warning";
  } else if (level === 40) {
    return "error";
  } else {
    return "info";
  }
};
