/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationScopeSchema
 */
export interface OrganizationScopeSchema {
    /**
     * 
     * @type {string}
     * @memberof OrganizationScopeSchema
     */
    type?: OrganizationScopeSchemaTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrganizationScopeSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationScopeSchema
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationScopeSchema
     */
    workspaceNames?: Array<string>;
}


/**
 * @export
 */
export const OrganizationScopeSchemaTypeEnum = {
    Organization: 'organization'
} as const;
export type OrganizationScopeSchemaTypeEnum = typeof OrganizationScopeSchemaTypeEnum[keyof typeof OrganizationScopeSchemaTypeEnum];


/**
 * Check if a given object implements the OrganizationScopeSchema interface.
 */
export function instanceOfOrganizationScopeSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OrganizationScopeSchemaFromJSON(json: any): OrganizationScopeSchema {
    return OrganizationScopeSchemaFromJSONTyped(json, false);
}

export function OrganizationScopeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationScopeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': json['id'],
        'name': json['name'],
        'workspaceNames': !exists(json, 'workspace_names') ? undefined : json['workspace_names'],
    };
}

export function OrganizationScopeSchemaToJSON(value?: OrganizationScopeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'name': value.name,
        'workspace_names': value.workspaceNames,
    };
}

