import ky from "ky";
import { getDjangoCSRFCookie } from "../../cookieUtils";

interface LoginRequest {
  username?: string;
  email?: string;
  password: string;
}

export const login = async (
  username: string,
  password: string,
): Promise<string> => {
  const csrf = getDjangoCSRFCookie();
  const request: LoginRequest = { password };
  if (username.includes("@")) {
    request.email = username;
  } else {
    request.username = username;
  }
  const buildPayload = (await ky
    .post(`/api/v1/auth/login/`, {
      json: request,
      headers: { "X-CSRFToken": csrf },
    })
    .json()) as any;
  return buildPayload.key;
};

export const logout = async (): Promise<Response> => {
  const csrf = getDjangoCSRFCookie();
  return ky.post(`/api/v1/auth/logout/`, {
    headers: { "X-CSRFToken": csrf },
  });
};
