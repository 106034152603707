import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ExceptionSummary } from "../Analytics/ClusterStatistics";
import CloseIcon from "@mui/icons-material/Close";
import { ExceptionAlert } from "./ExceptionAlert";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxTheme from "../../../shared-components/syntaxTheme";
import { theme } from "../../../theme";

/*
Simple modal for showing an exception traceback.
*/
export const ExceptionDialog = (props: {
  exception: ExceptionSummary | null;
  open: boolean;
  handleClose: () => void;
}): React.ReactElement | null => {
  const { exception, open, handleClose } = props;
  if (!exception) {
    return null;
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <div>
        <DialogTitle>
          Exception while computing {exception.taskGroupName}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              size="large"
              sx={{
                position: "absolute",
                right: "8px",
                top: "5px",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <ExceptionAlert exception={exception} />
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              mt: 2,
            }}
            variant="body1"
          >
            Traceback
          </Typography>
          <SyntaxHighlighter
            language="python"
            style={syntaxTheme}
            customStyle={{
              marginTop: "4px",
            }}
          >
            {`${exception.traceback}\n${exception.exception}`}
          </SyntaxHighlighter>
        </DialogContent>
      </div>
    </Dialog>
  );
};
