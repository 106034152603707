import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

type Props = { className?: string };

export const ProfileIcon = ({ className = "" }: Props): React.ReactElement => {
  return (
    <SvgIcon
      className={className}
      // width="24px"
      // height="24px"
      viewBox="0 0 26 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon-/-profile-/-dark" stroke="currentColor">
          <rect id="Rectangle" x="0.5" y="0.5" width="25" height="18" />
          <line
            x1="14.5"
            y1="9.5"
            x2="20.5"
            y2="9.5"
            id="Line-2"
            strokeLinecap="square"
          />
          <line
            x1="14.5"
            y1="5.5"
            x2="20.5"
            y2="5.5"
            id="Line-2-Copy-2"
            strokeLinecap="square"
          />
          <circle id="Oval" cx="8" cy="7" r="2.5" />
          <line
            x1="5.5"
            y1="13.5"
            x2="20.5"
            y2="13.5"
            id="Line-2-Copy"
            strokeLinecap="square"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
