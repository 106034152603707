/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstalledPackage } from './InstalledPackage';
import {
    InstalledPackageFromJSON,
    InstalledPackageFromJSONTyped,
    InstalledPackageToJSON,
} from './InstalledPackage';

/**
 * 
 * @export
 * @interface EnvSpec
 */
export interface EnvSpec {
    /**
     * 
     * @type {Array<InstalledPackage>}
     * @memberof EnvSpec
     */
    packages: Array<InstalledPackage>;
}

/**
 * Check if a given object implements the EnvSpec interface.
 */
export function instanceOfEnvSpec(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "packages" in value;

    return isInstance;
}

export function EnvSpecFromJSON(json: any): EnvSpec {
    return EnvSpecFromJSONTyped(json, false);
}

export function EnvSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvSpec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packages': ((json['packages'] as Array<any>).map(InstalledPackageFromJSON)),
    };
}

export function EnvSpecToJSON(value?: EnvSpec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packages': ((value.packages as Array<any>).map(InstalledPackageToJSON)),
    };
}

