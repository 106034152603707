import { datadogLogs, HandlerType } from "@datadog/browser-logs";
import { Analytics, AnalyticsPlugin } from "analytics";
import segmentPlugin from "@analytics/segment";
import { datadogRum } from "@datadog/browser-rum";
import { UserSchema } from "../api-client";

const customEnricherPlugin: AnalyticsPlugin = {
  name: "enrich-segment",
  "track:segment": ({ payload, config }: any) => {
    const enrichedProperties = {
      ...payload.properties,
      email: config.email,
    };
    return { ...payload, properties: enrichedProperties };
  },
  identify: ({ payload, config }: any) => {
    config.email = payload.traits.email;
    return payload;
  },
  track: ({ payload, config }: any) => {
    datadogRum.addAction(payload.event, payload.properties);
  },
};

export const analytics = Analytics({
  app: "coiled",
  plugins: [
    customEnricherPlugin,
    segmentPlugin({
      writeKey: import.meta.env.FE_SEGMENT_WRITE_KEY,
      enabled: import.meta.env.FE_SEGMENT_WRITE_KEY ? true : false,
    }),
  ],
});

export const initialize = (): void => {
  datadogLogs.init({
    clientToken: "pub231f00748151d906e040ef36497521d2",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    service: "frontend",
    env: import.meta.env.FE_TARGET_ENV,
    sessionSampleRate: 100,
  });
  datadogRum.init({
    applicationId: "9115ed6b-81eb-45ca-909f-fd41a61133f7",
    clientToken: "pub231f00748151d906e040ef36497521d2",
    site: "datadoghq.com",
    service: "frontend",
    env: import.meta.env.FE_TARGET_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      /^(https?:\/\/)(cloud\.coiled\.io|staging\.coiledhq\.com|localhost)/,
    ],
  });

  datadogLogs.logger.setHandler(
    import.meta.env.FE_DD_ENABLED === "true"
      ? HandlerType.http
      : HandlerType.console,
  );
};

export const trackPageView = (path: string): void => {
  analytics.page();
  // Putting the pageview in datadog too to make debugging easier
  datadogLogs.logger.info(`page view ${path}`, {
    path,
  });
};

/**
 * Mark the user (presumably freshly logged-in) for various analytics
 * frameworks in use at the time.
 */
export const identifyUser = (user: UserSchema): void => {
  datadogRum.setUser({
    id: `${user.id}`,
    email: user.email,
    name: user.fullName,
    username: user.username,
    isStaff: user.isStaff,
  });
  analytics.identify(`${user.id}`, {
    // segment
    id: `${user.id}`,
    email: user.email,
    fullName: user.fullName,
    preferredName: user.preferredName,
    firstName: user.fullName.split(" ")[0],
    lastName: user.fullName.split(" ").slice(1).join(" "),
    username: user.username,
    company: user.profile.company,
    isStaff: user.isStaff,
    title: user.profile.title,
  });

  // @ts-ignore
  window.signals.identify({
    email: user.email,
    name: user.fullName,
  });
};
