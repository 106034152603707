import React from "react";
import ky from "ky";
import { format } from "date-fns";
import { datadogLogs } from "@datadog/browser-logs";
import { Alert, useTheme, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { getStandardHeaders, parseError } from "../../apiUtils";
import { CreateTokenForm } from "../../shared-components/CreateTokenForm";
import { LoadStateNotNone } from "../../store";
import { Waiting } from "../../shared-components/Waiting";
import { PageCouldntLoad } from "../../shared-components/PageCouldntLoad";
import { useToken } from "../../utils/hooks";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { CodeBlock } from "../../shared-components/CodeBlock";
import { ApiToken } from "./types";

export const LONG_LIVED_TOKENS_ENABLED = true;

type ApiTokenResponse = {
  next: string | null;
  results: ApiToken[];
};

// making our state a mapping from identifiers to tokens allows easily updating
// the state of an individual token via its identifier
type ApiTokensByIdentifier = { [identifier: string]: ApiToken };

type Column = keyof ApiToken | "doRevoke";

type ApiTokensResponse =
  | { type: "error"; error: string }
  | { type: "good"; data: ApiTokensByIdentifier };

const getApiTokens = async (authToken: string): Promise<ApiTokensResponse> => {
  try {
    const tokens: ApiToken[] = [];

    let next: string | null = `/api/v1/api-tokens/`;
    while (next) {
      const response = (await ky
        .get(next, {
          headers: getStandardHeaders(),
        })
        .json()) as ApiTokenResponse;
      next = response.next;

      const newTokens: ApiToken[] = response.results.map(
        ({ identifier, expired, expiry, revoked, label, created }) => ({
          identifier,
          expired,
          expiry,
          revoked,
          label,
          created,
        }),
      );
      tokens.push(...newTokens);
    }

    const tokensByIdentifier: ApiTokensByIdentifier = {};
    for (const token of tokens) {
      tokensByIdentifier[token.identifier] = token;
    }
    return { type: "good", data: tokensByIdentifier };
  } catch (err) {
    return { type: "error", error: parseError(err) };
  }
};

const isActive = (token: ApiToken): boolean => {
  return !token.expired && !token.revoked;
};

const columnToColName: { [key in Column]: string } = {
  identifier: "Identifier",
  created: "Created",
  expiry: "Expiration",
  expired: "Expired",
  revoked: "Revoked",
  label: "Label",
  doRevoke: "Revoke",
};

const sortTokens = (tokens: ApiToken[]): ApiToken[] => {
  // at first I thought sorting with unexpired tokens at the top would be
  // nice, but in the list showing revoked tokens, it's better if the tokens
  // don't move around when user clicks "revoke"
  const compare = (token1: ApiToken, token2: ApiToken): number => {
    return token1.created > token2.created ? -1 : 1;
  };
  return tokens.sort(compare);
};

const appendTokenToTokenLoadState = (
  oldState: LoadStateNotNone<ApiTokensByIdentifier>,
  token: ApiToken,
): LoadStateNotNone<ApiTokensByIdentifier> => {
  // do nothing if old load state isn't "loaded"
  if (oldState.type === "loaded") {
    return {
      type: "loaded",
      data: { ...oldState.data, [token.identifier]: token },
    };
  } else {
    return oldState;
  }
};

export const ApiTokensSection = (): React.ReactElement => {
  const theme = useTheme();
  const authToken = useToken();
  const [loadState, setLoadState] = React.useState<
    LoadStateNotNone<ApiTokensByIdentifier>
  >({
    type: "loading",
  });
  const [createTokenFormOpen, setCreateTokenFormOpen] = useQueryParam(
    "createTokenDialog",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true },
  );

  const columns: Column[] = ["label", "created", "expiry", "doRevoke"];

  React.useEffect(() => {
    (async () => {
      if (loadState.type !== "loading") return;
      const tokensResponse = await getApiTokens(authToken);
      if (tokensResponse.type === "good") {
        setLoadState({ type: "loaded", data: tokensResponse.data });
      } else {
        setLoadState({ type: "error", error: tokensResponse.error });
      }
    })();
  }, [authToken, loadState.type]);

  const revokeApiToken = async (token: ApiToken): Promise<void> => {
    setLoadState((oldState) => {
      // Update the token's "revoked" state to null, indicating that revoking is in-progress
      if (oldState.type !== "loaded") {
        datadogLogs.logger.error(
          "We only expect to revoke a token after loading state",
        );
        return oldState;
      }
      const newToken = { ...token, revoked: null };
      return {
        type: "loaded",
        data: { ...oldState.data, [token.identifier]: newToken },
      };
    });

    try {
      await ky
        .delete(`/api/v1/api-tokens/${token.identifier}/revoke`, {
          headers: getStandardHeaders(),
        })
        .json();
      // Revoke API call succeeded, so update the token's "revoked" state to true
      setLoadState((oldState) => {
        if (oldState.type !== "loaded") {
          datadogLogs.logger.error(
            "We only expect to revoke a token after loading state",
          );
          return oldState;
        }
        const newToken = { ...token, revoked: true };
        return {
          type: "loaded",
          data: { ...oldState.data, [token.identifier]: newToken },
        };
      });
    } catch (err) {
      setLoadState({
        type: "error",
        error: "Error from server revoking token.",
      });
    }
  };

  const getDisplayValue = (
    column: Column,
    token: ApiToken,
  ): React.ReactNode => {
    const isoStrToShortenedDate = (dateStr: string) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {format(new Date(dateStr), "yyyy-MM-dd")}
        </span>
      );
    };

    switch (column) {
      case "created":
        return isoStrToShortenedDate(token.created);
      case "identifier":
        return token.identifier;
      case "expiry":
        return token.expiry ? isoStrToShortenedDate(token.expiry) : "(never)";
      case "expired":
        return token.expired ? "Y" : "N";
      case "revoked":
        return token.revoked ? "Y" : "N";
      case "label":
        return token.label || "";
      case "doRevoke":
        switch (token.revoked) {
          case null:
            // revoking is in progress
            return "...";
          case false:
            // token isn't revoked, so show revoke button
            return (
              <Button
                variant="secondary"
                size="small"
                onClick={revokeApiToken.bind(null, token)}
              >
                Revoke
              </Button>
            );
          case true:
            // can't revoke token again; no button
            return " ";
        }
    }
  };

  const appendToken = (token: ApiToken): void => {
    // adds a token to the token state
    // (called after creating a token)
    setLoadState((oldLoadState) =>
      appendTokenToTokenLoadState(oldLoadState, token),
    );
  };

  if (loadState.type === "loading") return <Waiting />;
  if (loadState.type === "error")
    return <PageCouldntLoad errorLines={[loadState.error]} />;

  const tokensByIdentifier = loadState.data;
  const tokens = Object.keys(tokensByIdentifier).map(
    (key) => tokensByIdentifier[key],
  );

  const activeTokens = sortTokens(tokens.filter(isActive));

  const closeCreateTokenForm = () => setCreateTokenFormOpen(false);
  const openCreateTokenForm = () => setCreateTokenFormOpen(true);

  return (
    <div>
      <CreateTokenForm
        formTitle="Create an API Token"
        handleClose={closeCreateTokenForm}
        open={createTokenFormOpen}
        handleSuccess={appendToken}
      />
      {activeTokens.length === 0 ? (
        <Alert severity="info">
          <Stack spacing={1}>
            <div>
              You have no active tokens. Please run the following in your
              terminal:
            </div>
            <CodeBlock snippet="coiled login" isTerminal />
            <div>
              This will create an API token and authorize your local machine
              with that token.
            </div>
            <div>
              <br />
              For using Coiled in CI or other places where interactive login is
              not an option, you should manually create API tokens here.
            </div>
          </Stack>
        </Alert>
      ) : (
        <TableContainer sx={{ marginTop: theme.spacing(-2) }}>
          <Table aria-label="tokens table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col}>{columnToColName[col]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {activeTokens.map((token) => {
                return (
                  <TableRow key={token.identifier}>
                    {columns.map((col) => (
                      <TableCell key={`${col}_${token.identifier}`}>
                        {getDisplayValue(col, token)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: theme.spacing(3),
        }}
      >
        <Button variant="secondary" onClick={openCreateTokenForm} size="small">
          Create an API Token
        </Button>
      </div>
    </div>
  );
};
