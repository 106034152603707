/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';
import type { Dataset } from './Dataset';
import {
    DatasetFromJSON,
    DatasetFromJSONTyped,
    DatasetToJSON,
} from './Dataset';
import type { Network } from './Network';
import {
    NetworkFromJSON,
    NetworkFromJSONTyped,
    NetworkToJSON,
} from './Network';

/**
 * 
 * @export
 * @interface GCPAccountInfraSchema
 */
export interface GCPAccountInfraSchema {
    /**
     * 
     * @type {number}
     * @memberof GCPAccountInfraSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof GCPAccountInfraSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof GCPAccountInfraSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof GCPAccountInfraSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {Network}
     * @memberof GCPAccountInfraSchema
     */
    network: Network;
    /**
     * 
     * @type {Dataset}
     * @memberof GCPAccountInfraSchema
     */
    dataset?: Dataset;
    /**
     * 
     * @type {boolean}
     * @memberof GCPAccountInfraSchema
     */
    managed: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPAccountInfraSchema
     */
    schedulerNetworkTags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GCPAccountInfraSchema
     */
    clusterNetworkTags?: Array<string>;
}

/**
 * Check if a given object implements the GCPAccountInfraSchema interface.
 */
export function instanceOfGCPAccountInfraSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "network" in value;
    isInstance = isInstance && "managed" in value;

    return isInstance;
}

export function GCPAccountInfraSchemaFromJSON(json: any): GCPAccountInfraSchema {
    return GCPAccountInfraSchemaFromJSONTyped(json, false);
}

export function GCPAccountInfraSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPAccountInfraSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        'network': NetworkFromJSON(json['network']),
        'dataset': !exists(json, 'dataset') ? undefined : DatasetFromJSON(json['dataset']),
        'managed': json['managed'],
        'schedulerNetworkTags': !exists(json, 'scheduler_network_tags') ? undefined : json['scheduler_network_tags'],
        'clusterNetworkTags': !exists(json, 'cluster_network_tags') ? undefined : json['cluster_network_tags'],
    };
}

export function GCPAccountInfraSchemaToJSON(value?: GCPAccountInfraSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'network': NetworkToJSON(value.network),
        'dataset': DatasetToJSON(value.dataset),
        'managed': value.managed,
        'scheduler_network_tags': value.schedulerNetworkTags,
        'cluster_network_tags': value.clusterNetworkTags,
    };
}

