/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateWorkspaceInviteDirectSchema
 */
export interface UpdateWorkspaceInviteDirectSchema {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkspaceInviteDirectSchema
     */
    isAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteDirectSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceInviteDirectSchema
     */
    coreLimit: number;
}

/**
 * Check if a given object implements the UpdateWorkspaceInviteDirectSchema interface.
 */
export function instanceOfUpdateWorkspaceInviteDirectSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "coreLimit" in value;

    return isInstance;
}

export function UpdateWorkspaceInviteDirectSchemaFromJSON(json: any): UpdateWorkspaceInviteDirectSchema {
    return UpdateWorkspaceInviteDirectSchemaFromJSONTyped(json, false);
}

export function UpdateWorkspaceInviteDirectSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkspaceInviteDirectSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAdmin': json['is_admin'],
        'creditLimit': json['credit_limit'],
        'coreLimit': json['core_limit'],
    };
}

export function UpdateWorkspaceInviteDirectSchemaToJSON(value?: UpdateWorkspaceInviteDirectSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_admin': value.isAdmin,
        'credit_limit': value.creditLimit,
        'core_limit': value.coreLimit,
    };
}

