/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationProgramUsageSchema
 */
export interface OrganizationProgramUsageSchema {
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsSpent: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsUsagePercent: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationProgramUsageSchema
     */
    organizationCreditsUserSpent: number;
}

/**
 * Check if a given object implements the OrganizationProgramUsageSchema interface.
 */
export function instanceOfOrganizationProgramUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organizationCreditsSpent" in value;
    isInstance = isInstance && "organizationCreditsUsagePercent" in value;
    isInstance = isInstance && "organizationCreditsUserSpent" in value;

    return isInstance;
}

export function OrganizationProgramUsageSchemaFromJSON(json: any): OrganizationProgramUsageSchema {
    return OrganizationProgramUsageSchemaFromJSONTyped(json, false);
}

export function OrganizationProgramUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationProgramUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationCreditsSpent': json['organization_credits_spent'],
        'organizationCreditsUsagePercent': json['organization_credits_usage_percent'],
        'organizationCreditsUserSpent': json['organization_credits_user_spent'],
    };
}

export function OrganizationProgramUsageSchemaToJSON(value?: OrganizationProgramUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_credits_spent': value.organizationCreditsSpent,
        'organization_credits_usage_percent': value.organizationCreditsUsagePercent,
        'organization_credits_user_spent': value.organizationCreditsUserSpent,
    };
}

