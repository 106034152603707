import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { ClusterInformationDetails } from "./detailsPageComponents";
import { ClusterContext } from "../context";
import { useToken, useWorkspaceContextSlug } from "../../../utils/hooks";
import { ApiError } from "../../../apiUtils";
import { ProcessDataGrid } from "./components";
import { ClusterSizeChart } from "../Information/charts/ClusterSizeChart";

export const InfrastructureView = (): React.ReactElement => {
  const { clusterId, cluster, loading, error } = useContext(ClusterContext);
  const token = useToken();
  const account = useWorkspaceContextSlug();

  if (error) {
    // TODO: distinguish "not found" from unpexpected errors
    if (error === ApiError.NotFound) {
      return (
        <p>
          Did not find cluster <code>{clusterId}</code> in account{" "}
          <code>{account}</code>.
        </p>
      );
    } else {
      return (
        <p>
          Unexpected error fetching cluster <code>{clusterId}</code> in account{" "}
          <code>{account}</code>.
        </p>
      );
    }
  }
  return (
    <CardContent>
      <Stack spacing={2}>
        <ClusterInformationDetails loading={loading} cluster={cluster} />
        <ClusterSizeChart
          clusterId={clusterId}
          accountSlug={account}
          token={token}
        />
        <Card>
          <CardHeader title="Scheduler" />
          <CardContent>
            <ProcessDataGrid
              title="Scheduler"
              processes={cluster ? [cluster.scheduler] : []}
              loading={loading}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Workers" />
          <CardContent>
            <ProcessDataGrid
              title="Worker"
              processes={cluster?.workersExceptUnprovisionable || []}
              loading={loading}
            />
          </CardContent>
        </Card>
      </Stack>
    </CardContent>
  );
};
