/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountScopeSchema
 */
export interface AccountScopeSchema {
    /**
     * 
     * @type {string}
     * @memberof AccountScopeSchema
     */
    type?: AccountScopeSchemaTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AccountScopeSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AccountScopeSchema
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountScopeSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountScopeSchema
     */
    slug: string;
}


/**
 * @export
 */
export const AccountScopeSchemaTypeEnum = {
    Account: 'account'
} as const;
export type AccountScopeSchemaTypeEnum = typeof AccountScopeSchemaTypeEnum[keyof typeof AccountScopeSchemaTypeEnum];


/**
 * Check if a given object implements the AccountScopeSchema interface.
 */
export function instanceOfAccountScopeSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;

    return isInstance;
}

export function AccountScopeSchemaFromJSON(json: any): AccountScopeSchema {
    return AccountScopeSchemaFromJSONTyped(json, false);
}

export function AccountScopeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountScopeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': json['id'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'name': json['name'],
        'slug': json['slug'],
    };
}

export function AccountScopeSchemaToJSON(value?: AccountScopeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'organization_id': value.organizationId,
        'name': value.name,
        'slug': value.slug,
    };
}

