/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountLightSchema } from './AccountLightSchema';
import {
    AccountLightSchemaFromJSON,
    AccountLightSchemaFromJSONTyped,
    AccountLightSchemaToJSON,
} from './AccountLightSchema';

/**
 * 
 * @export
 * @interface MembershipSchema
 */
export interface MembershipSchema {
    /**
     * 
     * @type {boolean}
     * @memberof MembershipSchema
     */
    isAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof MembershipSchema
     */
    coreLimit: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipSchema
     */
    creditLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof MembershipSchema
     */
    isDefaultAccount: boolean;
    /**
     * 
     * @type {AccountLightSchema}
     * @memberof MembershipSchema
     */
    account: AccountLightSchema;
}

/**
 * Check if a given object implements the MembershipSchema interface.
 */
export function instanceOfMembershipSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "coreLimit" in value;
    isInstance = isInstance && "creditLimit" in value;
    isInstance = isInstance && "isDefaultAccount" in value;
    isInstance = isInstance && "account" in value;

    return isInstance;
}

export function MembershipSchemaFromJSON(json: any): MembershipSchema {
    return MembershipSchemaFromJSONTyped(json, false);
}

export function MembershipSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAdmin': json['is_admin'],
        'coreLimit': json['core_limit'],
        'creditLimit': json['credit_limit'],
        'isDefaultAccount': json['is_default_account'],
        'account': AccountLightSchemaFromJSON(json['account']),
    };
}

export function MembershipSchemaToJSON(value?: MembershipSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_admin': value.isAdmin,
        'core_limit': value.coreLimit,
        'credit_limit': value.creditLimit,
        'is_default_account': value.isDefaultAccount,
        'account': AccountLightSchemaToJSON(value.account),
    };
}

