import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { AuthLayout } from "./AuthLayout";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validation";
import { retrieveUserName } from "./auth";
import { Urls } from "../domain/urls";
import { Section } from "./CardSection";
import { Text, ExternalLink, BackToSignInButton, AuthForm } from "./components";
import { Button } from "@mui/material";

type Inputs = {
  email: string;
};

export const ForgotUsername = (): React.ReactElement => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async ({ email }: Inputs) => {
    setSubmitted(true);
    const result = await retrieveUserName(email);
    if (result.type === "success") {
      setSuccess(true);
      setSubmitted(false);
    }
    if (result.type === "error") {
      setError(result.error);
      setSubmitted(false);
    }
  };

  if (success === true) {
    return (
      <AuthLayout>
        <Section title="Retrieve Username">
          <Text>We have sent you an email with your account username.</Text>
          <Text>
            Please{" "}
            <ExternalLink
              target={"_blank"}
              rel="noopener noreferrer"
              href={"mailto:support@coiled.io"}
            >
              contact us
            </ExternalLink>{" "}
            if you do not receive it within a few minutes.
          </Text>
          <BackToSignInButton
            color="primary"
            variant="text"
            href={`/${Urls.Login}?open=true`}
          >
            Back to Sign In
          </BackToSignInButton>
        </Section>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Section title="Retrieve Username">
        <Text>
          Enter the email associated with your account and {"we'll"} send and
          email with your account username.
        </Text>
        {error && (
          <Typography
            sx={{ marginTop: "16px" }}
            variant="body1"
            align="center"
            color="error"
          >
            {error}
          </Typography>
        )}

        <AuthForm
          style={{
            padding: "24px 16px 0 16px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            {...register("email", {
              required: "email is required",
              validate: (value) =>
                validateEmail(value) === true || "invalid email",
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            sx={{ width: "100%", marginBottom: "8px" }}
            variant="outlined"
            placeholder="email"
          />

          <Button variant="primary" type="submit">
            {submitted ? <CircularProgress size={24} /> : "submit"}
          </Button>
        </AuthForm>
      </Section>
    </AuthLayout>
  );
};
