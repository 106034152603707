import React from "react";
import { SoftwareEnvironmentsTable } from "./SoftwareEnvironments";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { SoftwareEnvironmentView } from "./SoftwareEnvironment";
import { StringParam, useQueryParam } from "use-query-params";
import { Urls } from "../../domain/urls";
import { useScopedContext } from "../../shared-components/ScopeSelector";

const LegacyAliasRedirect = (): React.ReactElement => {
  const [globalScope] = useScopedContext();
  let account = "";
  if (globalScope.type === "account") {
    account = globalScope.slug;
  }
  const [accountSlug] = useQueryParam("account", StringParam);
  const [tab] = useQueryParam("tab", StringParam);
  if (accountSlug) {
    account = accountSlug;
  }
  const path = useLocation();
  if (!account) {
    return <Navigate to={`/${Urls.Software}`} replace />;
  } else {
    // Example paths:
    // /software/alias/51407/build/47810?account=package-sync-integration-tests&tab=logs
    // /software/alias/51407?account=package-sync-integration-tests
    const regex = /^\/software\/alias\/(\d+)(?:\/build\/(\d+))?$/;
    const newPath = path.pathname.replace(regex, (match, aliasId, buildId) => {
      if (buildId) {
        if (tab) {
          return `/${Urls.Software}/alias/${aliasId}/account/${account}/build/${buildId}?tab=${tab}`;
        }
        return `/${Urls.Software}/alias/${aliasId}/account/${account}/build/${buildId}`;
      } else {
        return `/${Urls.Software}/alias/${aliasId}/account/${account}`;
      }
    });
    return <Navigate to={newPath} replace />;
  }
};

export const Software = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<SoftwareEnvironmentsTable />} />
      <Route
        path="/alias/:aliasId/account/:accountSlug/*"
        element={<SoftwareEnvironmentView />}
      />
      <Route path="/alias/:aliasId/*" element={<LegacyAliasRedirect />} />
    </Routes>
  );
};
