/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClusterOptionsSchema } from './ClusterOptionsSchema';
import {
    ClusterOptionsSchemaFromJSON,
    ClusterOptionsSchemaFromJSONTyped,
    ClusterOptionsSchemaToJSON,
} from './ClusterOptionsSchema';
import type { ClusterStateEnum } from './ClusterStateEnum';
import {
    ClusterStateEnumFromJSON,
    ClusterStateEnumFromJSONTyped,
    ClusterStateEnumToJSON,
} from './ClusterStateEnum';
import type { ClusterTag } from './ClusterTag';
import {
    ClusterTagFromJSON,
    ClusterTagFromJSONTyped,
    ClusterTagToJSON,
} from './ClusterTag';
import type { CustomGroupSchema } from './CustomGroupSchema';
import {
    CustomGroupSchemaFromJSON,
    CustomGroupSchemaFromJSONTyped,
    CustomGroupSchemaToJSON,
} from './CustomGroupSchema';
import type { MetricFlag } from './MetricFlag';
import {
    MetricFlagFromJSON,
    MetricFlagFromJSONTyped,
    MetricFlagToJSON,
} from './MetricFlag';
import type { ProcessStateEnum } from './ProcessStateEnum';
import {
    ProcessStateEnumFromJSON,
    ProcessStateEnumFromJSONTyped,
    ProcessStateEnumToJSON,
} from './ProcessStateEnum';
import type { TagAlertHitSchema } from './TagAlertHitSchema';
import {
    TagAlertHitSchemaFromJSON,
    TagAlertHitSchemaFromJSONTyped,
    TagAlertHitSchemaToJSON,
} from './TagAlertHitSchema';
import type { WorkerByTypeSchema } from './WorkerByTypeSchema';
import {
    WorkerByTypeSchemaFromJSON,
    WorkerByTypeSchemaFromJSONTyped,
    WorkerByTypeSchemaToJSON,
} from './WorkerByTypeSchema';
import type { WorkerOptionsSchema } from './WorkerOptionsSchema';
import {
    WorkerOptionsSchemaFromJSON,
    WorkerOptionsSchemaFromJSONTyped,
    WorkerOptionsSchemaToJSON,
} from './WorkerOptionsSchema';

/**
 * 
 * @export
 * @interface ListClusterV2
 */
export interface ListClusterV2 {
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListClusterV2
     */
    staffHasSeen: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    costPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    totalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListClusterV2
     */
    lastSeen?: Date;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    billableTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    accountSlug: string;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    accountName: string;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    creatorUsername: string;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    creatorId: number;
    /**
     * 
     * @type {ClusterStateEnum}
     * @memberof ListClusterV2
     */
    state: ClusterStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    reason: string;
    /**
     * 
     * @type {ProcessStateEnum}
     * @memberof ListClusterV2
     */
    schedulerState: ProcessStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    senvAliasId?: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    senvBuildId?: number;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    softwareEnvironmentName?: string;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    desiredWorkers: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    runningWorkers: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    totalWorkers: number;
    /**
     * 
     * @type {Array<WorkerByTypeSchema>}
     * @memberof ListClusterV2
     */
    workerTypes: Array<WorkerByTypeSchema>;
    /**
     * 
     * @type {WorkerOptionsSchema}
     * @memberof ListClusterV2
     */
    workerInstanceOptions?: WorkerOptionsSchema;
    /**
     * 
     * @type {ClusterOptionsSchema}
     * @memberof ListClusterV2
     */
    clusterOptions?: ClusterOptionsSchema;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    dashboardAddress?: string;
    /**
     * 
     * @type {Array<MetricFlag>}
     * @memberof ListClusterV2
     */
    metricFlags: Array<MetricFlag>;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    nTasks?: number;
    /**
     * 
     * @type {Array<ClusterTag>}
     * @memberof ListClusterV2
     */
    tagList: Array<ClusterTag>;
    /**
     * 
     * @type {Array<TagAlertHitSchema>}
     * @memberof ListClusterV2
     */
    alertList: Array<TagAlertHitSchema>;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    approximateCloudCostPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    approximateCloudTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof ListClusterV2
     */
    approximateCloudCostVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ListClusterV2
     */
    organizationFeatureTier?: string;
    /**
     * 
     * @type {CustomGroupSchema}
     * @memberof ListClusterV2
     */
    dealStageGroup?: CustomGroupSchema;
}

/**
 * Check if a given object implements the ListClusterV2 interface.
 */
export function instanceOfListClusterV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "staffHasSeen" in value;
    isInstance = isInstance && "accountSlug" in value;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "creatorUsername" in value;
    isInstance = isInstance && "creatorId" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "schedulerState" in value;
    isInstance = isInstance && "desiredWorkers" in value;
    isInstance = isInstance && "runningWorkers" in value;
    isInstance = isInstance && "totalWorkers" in value;
    isInstance = isInstance && "workerTypes" in value;
    isInstance = isInstance && "metricFlags" in value;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "alertList" in value;
    isInstance = isInstance && "approximateCloudCostVersion" in value;

    return isInstance;
}

export function ListClusterV2FromJSON(json: any): ListClusterV2 {
    return ListClusterV2FromJSONTyped(json, false);
}

export function ListClusterV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ListClusterV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'staffHasSeen': json['staff_has_seen'],
        'costPerHour': !exists(json, 'cost_per_hour') ? undefined : json['cost_per_hour'],
        'totalCost': !exists(json, 'total_cost') ? undefined : json['total_cost'],
        'lastSeen': !exists(json, 'last_seen') ? undefined : (new Date(json['last_seen'])),
        'billableTime': !exists(json, 'billable_time') ? undefined : json['billable_time'],
        'accountSlug': json['account_slug'],
        'accountName': json['account_name'],
        'accountId': json['account_id'],
        'creatorUsername': json['creator_username'],
        'creatorId': json['creator_id'],
        'state': ClusterStateEnumFromJSON(json['state']),
        'reason': json['reason'],
        'schedulerState': ProcessStateEnumFromJSON(json['scheduler_state']),
        'senvAliasId': !exists(json, 'senv_alias_id') ? undefined : json['senv_alias_id'],
        'senvBuildId': !exists(json, 'senv_build_id') ? undefined : json['senv_build_id'],
        'softwareEnvironmentName': !exists(json, 'software_environment_name') ? undefined : json['software_environment_name'],
        'desiredWorkers': json['desired_workers'],
        'runningWorkers': json['running_workers'],
        'totalWorkers': json['total_workers'],
        'workerTypes': ((json['worker_types'] as Array<any>).map(WorkerByTypeSchemaFromJSON)),
        'workerInstanceOptions': !exists(json, 'worker_instance_options') ? undefined : WorkerOptionsSchemaFromJSON(json['worker_instance_options']),
        'clusterOptions': !exists(json, 'cluster_options') ? undefined : ClusterOptionsSchemaFromJSON(json['cluster_options']),
        'dashboardAddress': !exists(json, 'dashboard_address') ? undefined : json['dashboard_address'],
        'metricFlags': ((json['metric_flags'] as Array<any>).map(MetricFlagFromJSON)),
        'nTasks': !exists(json, 'n_tasks') ? undefined : json['n_tasks'],
        'tagList': ((json['tag_list'] as Array<any>).map(ClusterTagFromJSON)),
        'alertList': ((json['alert_list'] as Array<any>).map(TagAlertHitSchemaFromJSON)),
        'approximateCloudCostPerHour': !exists(json, 'approximate_cloud_cost_per_hour') ? undefined : json['approximate_cloud_cost_per_hour'],
        'approximateCloudTotalCost': !exists(json, 'approximate_cloud_total_cost') ? undefined : json['approximate_cloud_total_cost'],
        'approximateCloudCostVersion': json['approximate_cloud_cost_version'],
        'organizationFeatureTier': !exists(json, 'organization_feature_tier') ? undefined : json['organization_feature_tier'],
        'dealStageGroup': !exists(json, 'deal_stage_group') ? undefined : CustomGroupSchemaFromJSON(json['deal_stage_group']),
    };
}

export function ListClusterV2ToJSON(value?: ListClusterV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'staff_has_seen': value.staffHasSeen,
        'cost_per_hour': value.costPerHour,
        'total_cost': value.totalCost,
        'last_seen': value.lastSeen === undefined ? undefined : (value.lastSeen.toISOString()),
        'billable_time': value.billableTime,
        'account_slug': value.accountSlug,
        'account_name': value.accountName,
        'account_id': value.accountId,
        'creator_username': value.creatorUsername,
        'creator_id': value.creatorId,
        'state': ClusterStateEnumToJSON(value.state),
        'reason': value.reason,
        'scheduler_state': ProcessStateEnumToJSON(value.schedulerState),
        'senv_alias_id': value.senvAliasId,
        'senv_build_id': value.senvBuildId,
        'software_environment_name': value.softwareEnvironmentName,
        'desired_workers': value.desiredWorkers,
        'running_workers': value.runningWorkers,
        'total_workers': value.totalWorkers,
        'worker_types': ((value.workerTypes as Array<any>).map(WorkerByTypeSchemaToJSON)),
        'worker_instance_options': WorkerOptionsSchemaToJSON(value.workerInstanceOptions),
        'cluster_options': ClusterOptionsSchemaToJSON(value.clusterOptions),
        'dashboard_address': value.dashboardAddress,
        'metric_flags': ((value.metricFlags as Array<any>).map(MetricFlagToJSON)),
        'n_tasks': value.nTasks,
        'tag_list': ((value.tagList as Array<any>).map(ClusterTagToJSON)),
        'alert_list': ((value.alertList as Array<any>).map(TagAlertHitSchemaToJSON)),
        'approximate_cloud_cost_per_hour': value.approximateCloudCostPerHour,
        'approximate_cloud_total_cost': value.approximateCloudTotalCost,
        'approximate_cloud_cost_version': value.approximateCloudCostVersion,
        'organization_feature_tier': value.organizationFeatureTier,
        'deal_stage_group': CustomGroupSchemaToJSON(value.dealStageGroup),
    };
}

