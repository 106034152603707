import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";

interface Option {
  value: string;
  label: string;
}

interface ControlledRadioGroupProps<TFieldValues extends FieldValues = any> {
  control: Control<TFieldValues>;
  name: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  options: Option[];
  row?: boolean;
}

export const HookRadio = ({
  control,
  name,
  label,
  disabled,
  row,
  options,
}: ControlledRadioGroupProps): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          {label && <FormLabel id={`form-label-${label}`}>{label}</FormLabel>}
          <RadioGroup
            aria-labelledby={`form-label-${label}`}
            {...field}
            row={row}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio disabled={disabled} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
