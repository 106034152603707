/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GCPServiceAccountSchema } from './GCPServiceAccountSchema';
import {
    GCPServiceAccountSchemaFromJSON,
    GCPServiceAccountSchemaFromJSONTyped,
    GCPServiceAccountSchemaToJSON,
} from './GCPServiceAccountSchema';

/**
 * 
 * @export
 * @interface GCPCredentialsSchema
 */
export interface GCPCredentialsSchema {
    /**
     * 
     * @type {GCPServiceAccountSchema}
     * @memberof GCPCredentialsSchema
     */
    credentials?: GCPServiceAccountSchema;
    /**
     * 
     * @type {string}
     * @memberof GCPCredentialsSchema
     */
    instanceServiceAccount?: string;
}

/**
 * Check if a given object implements the GCPCredentialsSchema interface.
 */
export function instanceOfGCPCredentialsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GCPCredentialsSchemaFromJSON(json: any): GCPCredentialsSchema {
    return GCPCredentialsSchemaFromJSONTyped(json, false);
}

export function GCPCredentialsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPCredentialsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentials': !exists(json, 'credentials') ? undefined : GCPServiceAccountSchemaFromJSON(json['credentials']),
        'instanceServiceAccount': !exists(json, 'instance_service_account') ? undefined : json['instance_service_account'],
    };
}

export function GCPCredentialsSchemaToJSON(value?: GCPCredentialsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentials': GCPServiceAccountSchemaToJSON(value.credentials),
        'instance_service_account': value.instanceServiceAccount,
    };
}

