import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useWorkspaceContextSlug } from "../../utils/hooks";
import {
  fetchSoftwareAlias,
  fetchSoftwareAliasSpecs,
  getClusterSoftwareBuild,
  getSoftwareAliasBuilds,
  getSoftwareSpec,
} from "./fetch";
import {
  DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRequest,
  PagedSoftwareEnvironmentAliasListSchema,
  PagedSoftwareEnvironmentSpecListSchema,
  SoftwareEnvBuildLight,
  SoftwareEnvironmentAliasSchema,
  SoftwareEnvironmentBuildSchema,
  SoftwareEnvironmentSpecSchema,
} from "../../api-client";
import { ApiClient } from "../../apiUtils";

export const useSoftwareBuild = (
  senvBuildId: number | undefined,
): UseQueryResult<SoftwareEnvironmentBuildSchema> => {
  const accountSlug = useWorkspaceContextSlug();
  const [shouldFetch, setShouldFetch] = useState(true);
  const result = useQuery(
    ["softwareBuild", accountSlug, senvBuildId],
    () => getClusterSoftwareBuild(accountSlug, senvBuildId as number),
    {
      enabled: !!senvBuildId && shouldFetch,
      refetchInterval: 2000,
    },
  );
  if (
    result.data &&
    ["error", "built"].includes(result.data?.state) &&
    shouldFetch
  ) {
    setShouldFetch(false);
  }
  return result;
};

export const useSoftwareSpec = (
  senvSpecId: number | undefined,
): UseQueryResult<SoftwareEnvironmentSpecSchema> => {
  const accountSlug = useWorkspaceContextSlug();
  return useQuery(
    ["softwareSpec", accountSlug, senvSpecId],
    () => getSoftwareSpec(accountSlug, senvSpecId as number),
    { enabled: !!senvSpecId },
  );
};

export const useSoftwareAliasBuilds = (
  aliasId: number,
): UseQueryResult<SoftwareEnvBuildLight[]> => {
  const accountSlug = useWorkspaceContextSlug();
  return useQuery(["softwareAliasBuilds", accountSlug, aliasId], () =>
    getSoftwareAliasBuilds(accountSlug, aliasId as number),
  );
};

export const useSoftwareAliases = (
  request: DeclarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliasesRequest,
): UseQueryResult<PagedSoftwareEnvironmentAliasListSchema> => {
  const q = useQuery(["softwareAliasList", request], ({ signal }) =>
    ApiClient.declarativeViewsSoftwareEnvironmentListFilterSoftwareEnvAliases(
      request,
      { signal },
    ),
  );
  return q;
};

export const useSoftwareAliasSpecs = (
  aliasId: number,
  limit?: number,
  offset?: number,
): UseQueryResult<PagedSoftwareEnvironmentSpecListSchema> => {
  const account = useWorkspaceContextSlug();
  return useQuery(
    ["softwareAliasSpecsList", account, aliasId, limit, offset],
    () => fetchSoftwareAliasSpecs(account, aliasId, limit, offset),
  );
};

export const useSoftwareAlias = (
  id: number,
): UseQueryResult<SoftwareEnvironmentAliasSchema> => {
  const account = useWorkspaceContextSlug();
  return useQuery(["softwareAlias", account, id], () =>
    fetchSoftwareAlias(account, id),
  );
};
