import React from "react";
import { useForm } from "react-hook-form";
import {
  useCreateWorkspaceMutation,
  useWorkspaces,
} from "../../crud/workspaces/hooks";
import { useSnackbar } from "notistack";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { useOrganization } from "../../crud/organizations/hooks";
import { Page } from "../../shared-components/Page";
import { LoadingButton } from "@mui/lab";

const workspaceLimitAlertText = (
  <>
    Creating additional workspaces is enabled in the{" "}
    <Link to="https://www.coiled.io/pricing">Coiled Professional Tier</Link>.{" "}
    Please email <Link to="mailto:support@coiled.io">support@coiled.io</Link>.
  </>
);

export const CreateWorkspaceForm = ({
  organizationId,
}: {
  organizationId: number;
}): React.ReactElement => {
  const { data: org } = useOrganization(organizationId);
  const { data: organizationWorkspaces } = useWorkspaces({ organizationId });

  const workspaceLimit = org?.workspaceLimit || null;
  const workspaceLimitReached =
    (organizationWorkspaces &&
      workspaceLimit &&
      organizationWorkspaces.length >= workspaceLimit) ||
    false;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: "",
    },
  });
  const { mutate: createWorkspace, isLoading } = useCreateWorkspaceMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  return (
    <Page
      title="Create Workspace"
      breadCrumbs={[
        {
          text: "Team",
          link: `/${Urls.Team}`,
        },
        {
          text: org?.name || "",
        },
        {
          text: "Workspaces",
        },
        { text: "Create" },
      ]}
    >
      {workspaceLimitReached && (
        <Alert severity="info" sx={{ marginBottom: "1rem" }}>
          {workspaceLimitAlertText}
        </Alert>
      )}
      <form
        onSubmit={handleSubmit((data) => {
          createWorkspace(
            {
              name: data.name,
              organizationId,
            },
            {
              onSuccess: () => {
                reset();
                enqueueSnackbar("Workspace created", {
                  variant: "success",
                });
                navigate(`/${Urls.Team}`);
              },
              onError: async (err) => {
                const { message, code } = await err.response.json();
                if (code === "REQUIRE_UNIQUE_ACCOUNT_NAMES") {
                  setError("name", {
                    type: "validate",
                    message: "Workspace names must be globally unique",
                  });
                } else {
                  enqueueSnackbar(`Error: ${message}`, {
                    variant: "error",
                  });
                }
              },
            },
          );
        })}
      >
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography>
                Add a workspace to the organization:{" "}
                <strong>{org?.name}</strong>
              </Typography>
              <TextField
                {...register("name", {
                  minLength: {
                    value: 4,
                    message: "Must be at least 4 characters",
                  },
                  maxLength: {
                    value: 34,
                    message: "Must be less than 34 characters",
                  },
                  required: "Workspace name is required",
                })}
                placeholder="staging-workspace"
                disabled={workspaceLimitReached}
                helperText={
                  errors.name
                    ? errors.name.message
                    : "Workspace name, must be unique and between 4 and 34 characters."
                }
                size="small"
                error={!!errors.name}
                sx={{ width: "450px" }}
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="secondary"
                component={Link}
                to={`/${Urls.Team}`}
                onClick={() => reset()}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                loading={isLoading}
                disabled={workspaceLimitReached}
              >
                Create Workspace
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </Page>
  );
};
