/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingEventListSchema } from './BillingEventListSchema';
import {
    BillingEventListSchemaFromJSON,
    BillingEventListSchemaFromJSONTyped,
    BillingEventListSchemaToJSON,
} from './BillingEventListSchema';

/**
 * 
 * @export
 * @interface PagedBillingEventListSchema
 */
export interface PagedBillingEventListSchema {
    /**
     * 
     * @type {Array<BillingEventListSchema>}
     * @memberof PagedBillingEventListSchema
     */
    items: Array<BillingEventListSchema>;
    /**
     * 
     * @type {number}
     * @memberof PagedBillingEventListSchema
     */
    count: number;
}

/**
 * Check if a given object implements the PagedBillingEventListSchema interface.
 */
export function instanceOfPagedBillingEventListSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function PagedBillingEventListSchemaFromJSON(json: any): PagedBillingEventListSchema {
    return PagedBillingEventListSchemaFromJSONTyped(json, false);
}

export function PagedBillingEventListSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedBillingEventListSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(BillingEventListSchemaFromJSON)),
        'count': json['count'],
    };
}

export function PagedBillingEventListSchemaToJSON(value?: PagedBillingEventListSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(BillingEventListSchemaToJSON)),
        'count': value.count,
    };
}

