/*
This file is one of these things:
  a) a collection of functionality that any self-respecting language should have built in
  b) an enumeration of things I don't fully understand about typescript
  c) some combination of the above

Note:
  As any good test taker will tell you, when in doubt go with c!

If the ratio of b/a becomes greater than that of a/b, please rename this file to
`danIsDumbUtils.ts`
*/
/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions */
export function hasOwnProperty<
  O extends Record<string, unknown>,
  K extends PropertyKey,
>(obj: O, key: K): obj is O & Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

// This is very simple, only does positive integer ranges
export const range = (min: number, maxInclusive: number): number[] =>
  Array.from({ length: maxInclusive - min + 1 }, (_, i) => i + min);

// i.e. `await sleep(2000)` to sleep for 2 seconds. Nice for debugging race conditions;
export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(() => resolve(), ms));

export const formatCurrency = (
  n: string | number | undefined,
): string | undefined => {
  // @ts-ignore: JS happily accepts non-strings here, and just outputs NaN
  const asFloat = parseFloat(n);
  if (isNaN(asFloat)) {
    return;
  }
  return asFloat.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

type FileReadResult = {
  error?: DOMException | Error;
  data?: string;
};

export const readFile = async (f: File): Promise<FileReadResult> => {
  // A dead-simple file reading function.
  const fileReader = new FileReader();
  let fileReaderStatus: FileReadResult | undefined;
  fileReader.onload = (e) => {
    fileReaderStatus = { data: e.target?.result as string };
  };
  fileReader.onabort = (e) => {
    // TODO when aborted does it give us an error?
    fileReaderStatus = { error: new Error("aborted") };
  };
  fileReader.onerror = (e) => {
    fileReaderStatus = { error: e.target?.error || undefined };
  };
  await fileReader.readAsBinaryString(f);
  while (fileReader.readyState < FileReader.DONE) {
    await sleep(500);
  }
  if (!fileReaderStatus) {
    // This SHOULDN'T happen, but it might, and keeps typescript happy
    return { error: new Error("unexpected error reading file") };
  }
  return fileReaderStatus;
};

// convert snake or kebab to camel case
// https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
export const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

type FilterCallback<T> = (
  item: T,
  index: number,
  originalArray: T[],
) => boolean;

export const partition = <T>(
  array: T[],
  callback: FilterCallback<T>,
): [T[], T[]] => {
  // Example:
  // const [trueThings, falseThings] = partition<number>([1, 0, 1, 1], (thing) => thing === 1);
  // console.log(trueThings); [1, 1, 1]
  // console.log(falseThings); [0]
  return array.reduce(
    (result, element, i) => {
      if (callback(element, i, array)) {
        result[0].push(element);
      } else {
        result[1].push(element);
      }
      return result;
    },
    [[], []] as [T[], T[]],
  );
};

export type ErrbackError<ErrorT> = { error: ErrorT; result: undefined };
export type ErrbackSuccess<ResultT> = { error: undefined; result: ResultT };

export type ErrbackResult<ResultT, ErrorT> =
  | ErrbackError<ErrorT>
  | ErrbackSuccess<ResultT>;
