import { useNavigate } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { UPGRADE_COOKIE } from "../../cookieUtils";
import React, { useContext } from "react";
import { AuthenticationContext } from "../../crud/auth/context";
import { useCookie } from "../../utils/hooks";

export const GoCoiledPro = (): React.ReactElement => {
  const navigate = useNavigate();
  const [, setUpgradeCookie] = useCookie(UPGRADE_COOKIE);
  const { token } = useContext(AuthenticationContext);
  if (token !== undefined) {
    // if already auth, redirect them to billing screen
    navigate(`/${Urls.Billing}`);
  } else {
    // if not auth, have them signup/ login with that has redirect to billing
    setUpgradeCookie("true");
    navigate(`/${Urls.Login}`);
  }
  return <></>;
};
