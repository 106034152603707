/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackendTypesEnum } from './BackendTypesEnum';
import {
    BackendTypesEnumFromJSON,
    BackendTypesEnumFromJSONTyped,
    BackendTypesEnumToJSON,
} from './BackendTypesEnum';

/**
 * 
 * @export
 * @interface UserDefaultAccountSchema
 */
export interface UserDefaultAccountSchema {
    /**
     * 
     * @type {number}
     * @memberof UserDefaultAccountSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDefaultAccountSchema
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof UserDefaultAccountSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDefaultAccountSchema
     */
    avatarUrl: string;
    /**
     * 
     * @type {BackendTypesEnum}
     * @memberof UserDefaultAccountSchema
     */
    activeBackend?: BackendTypesEnum;
}

/**
 * Check if a given object implements the UserDefaultAccountSchema interface.
 */
export function instanceOfUserDefaultAccountSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "avatarUrl" in value;

    return isInstance;
}

export function UserDefaultAccountSchemaFromJSON(json: any): UserDefaultAccountSchema {
    return UserDefaultAccountSchemaFromJSONTyped(json, false);
}

export function UserDefaultAccountSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDefaultAccountSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'avatarUrl': json['avatar_url'],
        'activeBackend': !exists(json, 'active_backend') ? undefined : BackendTypesEnumFromJSON(json['active_backend']),
    };
}

export function UserDefaultAccountSchemaToJSON(value?: UserDefaultAccountSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'active_backend': BackendTypesEnumToJSON(value.activeBackend),
    };
}

