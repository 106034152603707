/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentSchema } from './ComponentSchema';
import {
    ComponentSchemaFromJSON,
    ComponentSchemaFromJSONTyped,
    ComponentSchemaToJSON,
} from './ComponentSchema';
import type { Subnet } from './Subnet';
import {
    SubnetFromJSON,
    SubnetFromJSONTyped,
    SubnetToJSON,
} from './Subnet';

/**
 * 
 * @export
 * @interface GCPRegionalInfraSchema
 */
export interface GCPRegionalInfraSchema {
    /**
     * 
     * @type {number}
     * @memberof GCPRegionalInfraSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof GCPRegionalInfraSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof GCPRegionalInfraSchema
     */
    updated: Date;
    /**
     * 
     * @type {ComponentSchema}
     * @memberof GCPRegionalInfraSchema
     */
    component: ComponentSchema;
    /**
     * 
     * @type {boolean}
     * @memberof GCPRegionalInfraSchema
     */
    _default: boolean;
    /**
     * 
     * @type {string}
     * @memberof GCPRegionalInfraSchema
     */
    region: string;
    /**
     * 
     * @type {boolean}
     * @memberof GCPRegionalInfraSchema
     */
    managed: boolean;
    /**
     * 
     * @type {Array<Subnet>}
     * @memberof GCPRegionalInfraSchema
     */
    subnets: Array<Subnet>;
}

/**
 * Check if a given object implements the GCPRegionalInfraSchema interface.
 */
export function instanceOfGCPRegionalInfraSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "managed" in value;
    isInstance = isInstance && "subnets" in value;

    return isInstance;
}

export function GCPRegionalInfraSchemaFromJSON(json: any): GCPRegionalInfraSchema {
    return GCPRegionalInfraSchemaFromJSONTyped(json, false);
}

export function GCPRegionalInfraSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPRegionalInfraSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'component': ComponentSchemaFromJSON(json['component']),
        '_default': json['default'],
        'region': json['region'],
        'managed': json['managed'],
        'subnets': ((json['subnets'] as Array<any>).map(SubnetFromJSON)),
    };
}

export function GCPRegionalInfraSchemaToJSON(value?: GCPRegionalInfraSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'component': ComponentSchemaToJSON(value.component),
        'default': value._default,
        'region': value.region,
        'managed': value.managed,
        'subnets': ((value.subnets as Array<any>).map(SubnetToJSON)),
    };
}

