/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLimitsSchema
 */
export interface UpdateLimitsSchema {
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchema
     */
    spendLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLimitsSchema
     */
    spendLimitShouldStopInstances?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsSchema
     */
    organizationCreditLimit?: number;
}

/**
 * Check if a given object implements the UpdateLimitsSchema interface.
 */
export function instanceOfUpdateLimitsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLimitsSchemaFromJSON(json: any): UpdateLimitsSchema {
    return UpdateLimitsSchemaFromJSONTyped(json, false);
}

export function UpdateLimitsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLimitsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spendLimit': !exists(json, 'spend_limit') ? undefined : json['spend_limit'],
        'spendLimitShouldStopInstances': !exists(json, 'spend_limit_should_stop_instances') ? undefined : json['spend_limit_should_stop_instances'],
        'organizationCreditLimit': !exists(json, 'organization_credit_limit') ? undefined : json['organization_credit_limit'],
    };
}

export function UpdateLimitsSchemaToJSON(value?: UpdateLimitsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spend_limit': value.spendLimit,
        'spend_limit_should_stop_instances': value.spendLimitShouldStopInstances,
        'organization_credit_limit': value.organizationCreditLimit,
    };
}

