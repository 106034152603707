/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchitectureTypesEnum } from './ArchitectureTypesEnum';
import {
    ArchitectureTypesEnumFromJSON,
    ArchitectureTypesEnumFromJSONTyped,
    ArchitectureTypesEnumToJSON,
} from './ArchitectureTypesEnum';
import type { SoftwareEnvironmentLatestBuildSchema } from './SoftwareEnvironmentLatestBuildSchema';
import {
    SoftwareEnvironmentLatestBuildSchemaFromJSON,
    SoftwareEnvironmentLatestBuildSchemaFromJSONTyped,
    SoftwareEnvironmentLatestBuildSchemaToJSON,
} from './SoftwareEnvironmentLatestBuildSchema';

/**
 * 
 * @export
 * @interface SoftwareEnvironmentSpecLightSchema
 */
export interface SoftwareEnvironmentSpecLightSchema {
    /**
     * 
     * @type {number}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    md5: string;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    gpuEnabled: boolean;
    /**
     * 
     * @type {SoftwareEnvironmentLatestBuildSchema}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    latestBuild?: SoftwareEnvironmentLatestBuildSchema;
    /**
     * 
     * @type {string}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    containerUri?: string;
    /**
     * 
     * @type {ArchitectureTypesEnum}
     * @memberof SoftwareEnvironmentSpecLightSchema
     */
    architecture?: ArchitectureTypesEnum;
}

/**
 * Check if a given object implements the SoftwareEnvironmentSpecLightSchema interface.
 */
export function instanceOfSoftwareEnvironmentSpecLightSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "md5" in value;
    isInstance = isInstance && "gpuEnabled" in value;

    return isInstance;
}

export function SoftwareEnvironmentSpecLightSchemaFromJSON(json: any): SoftwareEnvironmentSpecLightSchema {
    return SoftwareEnvironmentSpecLightSchemaFromJSONTyped(json, false);
}

export function SoftwareEnvironmentSpecLightSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareEnvironmentSpecLightSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'md5': json['md5'],
        'gpuEnabled': json['gpu_enabled'],
        'latestBuild': !exists(json, 'latest_build') ? undefined : SoftwareEnvironmentLatestBuildSchemaFromJSON(json['latest_build']),
        'containerUri': !exists(json, 'container_uri') ? undefined : json['container_uri'],
        'architecture': !exists(json, 'architecture') ? undefined : ArchitectureTypesEnumFromJSON(json['architecture']),
    };
}

export function SoftwareEnvironmentSpecLightSchemaToJSON(value?: SoftwareEnvironmentSpecLightSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'md5': value.md5,
        'gpu_enabled': value.gpuEnabled,
        'latest_build': SoftwareEnvironmentLatestBuildSchemaToJSON(value.latestBuild),
        'container_uri': value.containerUri,
        'architecture': ArchitectureTypesEnumToJSON(value.architecture),
    };
}

