import ky from "ky";
import {
  camelizeKeys,
  getStandardHeaders,
  parseError,
} from "../../../apiUtils";
import { datadogLogs } from "@datadog/browser-logs";
import {
  ClusterDetails,
  ClusterResponse,
  LogEvent,
  WorkerLogsResponse,
} from "../types";

export const fetchCluster = async (
  clusterId: string,
  accountSlug: string,
  token: string,
): Promise<ClusterResponse> => {
  if (clusterId === "-") clusterId = "";

  const route = `/api/v2/clusters/account/${accountSlug}/id/${clusterId}`;
  const clusterRaw = await ky
    .get(route, {
      timeout: 25000,
      headers: getStandardHeaders(),
    })
    .json();
  return {
    type: "good",
    cluster: camelizeKeys(clusterRaw) as ClusterDetails,
  };
};

export const fetchLogs = async (
  instanceName: string,
  accountSlug: string,
  token: string,
): Promise<WorkerLogsResponse> => {
  try {
    const route = `/api/v2/instances/${accountSlug}/instance/${instanceName}/logs`;
    const logsRaw = await ky
      .get(route, {
        headers: getStandardHeaders(),
      })
      .json();
    return {
      type: "good",
      logs: logsRaw as LogEvent[],
    };
  } catch (err) {
    const parsedError = parseError(err);
    if (parsedError === "NotFound") {
      return { type: "error", error: parsedError, errorType: "notFound" };
    } else {
      datadogLogs.logger.error(`unexpected frontend error: ${parsedError}`);
      return { type: "error", error: parsedError, errorType: "unexpected" };
    }
  }
};
