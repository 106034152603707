import ky from "ky";
import { getStandardHeaders, parseError } from "../../apiUtils";
import { StopResult } from "./types";

export const stopCluster = async (
  id: number,
  accountSlug: string,
  stopReason: string,
): Promise<StopResult> => {
  const route = `api/v2/clusters/account/${accountSlug}/id/${id}`;

  try {
    await ky
      .delete(`/${route}`, {
        headers: getStandardHeaders(),
        timeout: 40000,
        searchParams: { stop_reason: stopReason },
      })
      .json();
    return {};
  } catch (err) {
    return {
      error: parseError(err),
    };
  }
};
