/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CloseSchema
 */
export interface CloseSchema {
    /**
     * 
     * @type {number}
     * @memberof CloseSchema
     */
    ident: number;
    /**
     * 
     * @type {string}
     * @memberof CloseSchema
     */
    schedulerId: string;
    /**
     * 
     * @type {string}
     * @memberof CloseSchema
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof CloseSchema
     */
    clusterType: string;
}

/**
 * Check if a given object implements the CloseSchema interface.
 */
export function instanceOfCloseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ident" in value;
    isInstance = isInstance && "schedulerId" in value;
    isInstance = isInstance && "clusterType" in value;

    return isInstance;
}

export function CloseSchemaFromJSON(json: any): CloseSchema {
    return CloseSchemaFromJSONTyped(json, false);
}

export function CloseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ident': json['ident'],
        'schedulerId': json['scheduler_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'clusterType': json['cluster_type'],
    };
}

export function CloseSchemaToJSON(value?: CloseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ident': value.ident,
        'scheduler_id': value.schedulerId,
        'account': value.account,
        'cluster_type': value.clusterType,
    };
}

