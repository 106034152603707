/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddUserToWorkspaceSchema } from './AddUserToWorkspaceSchema';
import {
    AddUserToWorkspaceSchemaFromJSON,
    AddUserToWorkspaceSchemaFromJSONTyped,
    AddUserToWorkspaceSchemaToJSON,
} from './AddUserToWorkspaceSchema';
import type { OrganizationRoles } from './OrganizationRoles';
import {
    OrganizationRolesFromJSON,
    OrganizationRolesFromJSONTyped,
    OrganizationRolesToJSON,
} from './OrganizationRoles';

/**
 * 
 * @export
 * @interface AddUserToOrganizationSchema
 */
export interface AddUserToOrganizationSchema {
    /**
     * 
     * @type {number}
     * @memberof AddUserToOrganizationSchema
     */
    organizationId: number;
    /**
     * 
     * @type {string}
     * @memberof AddUserToOrganizationSchema
     */
    emailOrUsername: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof AddUserToOrganizationSchema
     */
    role: OrganizationRoles;
    /**
     * 
     * @type {Array<AddUserToWorkspaceSchema>}
     * @memberof AddUserToOrganizationSchema
     */
    workspaces: Array<AddUserToWorkspaceSchema>;
}

/**
 * Check if a given object implements the AddUserToOrganizationSchema interface.
 */
export function instanceOfAddUserToOrganizationSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "emailOrUsername" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "workspaces" in value;

    return isInstance;
}

export function AddUserToOrganizationSchemaFromJSON(json: any): AddUserToOrganizationSchema {
    return AddUserToOrganizationSchemaFromJSONTyped(json, false);
}

export function AddUserToOrganizationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddUserToOrganizationSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organization_id'],
        'emailOrUsername': json['email_or_username'],
        'role': OrganizationRolesFromJSON(json['role']),
        'workspaces': ((json['workspaces'] as Array<any>).map(AddUserToWorkspaceSchemaFromJSON)),
    };
}

export function AddUserToOrganizationSchemaToJSON(value?: AddUserToOrganizationSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_id': value.organizationId,
        'email_or_username': value.emailOrUsername,
        'role': OrganizationRolesToJSON(value.role),
        'workspaces': ((value.workspaces as Array<any>).map(AddUserToWorkspaceSchemaToJSON)),
    };
}

