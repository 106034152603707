/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDefaultAccountSchema } from './UserDefaultAccountSchema';
import {
    UserDefaultAccountSchemaFromJSON,
    UserDefaultAccountSchemaFromJSONTyped,
    UserDefaultAccountSchemaToJSON,
} from './UserDefaultAccountSchema';
import type { UserDefaultOrganizationSchema } from './UserDefaultOrganizationSchema';
import {
    UserDefaultOrganizationSchemaFromJSON,
    UserDefaultOrganizationSchemaFromJSONTyped,
    UserDefaultOrganizationSchemaToJSON,
} from './UserDefaultOrganizationSchema';
import type { UserProfileSchema } from './UserProfileSchema';
import {
    UserProfileSchemaFromJSON,
    UserProfileSchemaFromJSONTyped,
    UserProfileSchemaToJSON,
} from './UserProfileSchema';

/**
 * 
 * @export
 * @interface UserSchema
 */
export interface UserSchema {
    /**
     * 
     * @type {number}
     * @memberof UserSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    username: string;
    /**
     * 
     * @type {UserDefaultAccountSchema}
     * @memberof UserSchema
     */
    defaultAccount: UserDefaultAccountSchema;
    /**
     * 
     * @type {UserDefaultOrganizationSchema}
     * @memberof UserSchema
     */
    defaultOrganization?: UserDefaultOrganizationSchema;
    /**
     * 
     * @type {boolean}
     * @memberof UserSchema
     */
    isStaff: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    preferredName?: string;
    /**
     * 
     * @type {UserProfileSchema}
     * @memberof UserSchema
     */
    profile: UserProfileSchema;
    /**
     * 
     * @type {boolean}
     * @memberof UserSchema
     */
    hasUsablePassword: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    email: string;
}

/**
 * Check if a given object implements the UserSchema interface.
 */
export function instanceOfUserSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "defaultAccount" in value;
    isInstance = isInstance && "isStaff" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "hasUsablePassword" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function UserSchemaFromJSON(json: any): UserSchema {
    return UserSchemaFromJSONTyped(json, false);
}

export function UserSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'defaultAccount': UserDefaultAccountSchemaFromJSON(json['default_account']),
        'defaultOrganization': !exists(json, 'default_organization') ? undefined : UserDefaultOrganizationSchemaFromJSON(json['default_organization']),
        'isStaff': json['is_staff'],
        'fullName': json['full_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'profile': UserProfileSchemaFromJSON(json['profile']),
        'hasUsablePassword': json['has_usable_password'],
        'email': json['email'],
    };
}

export function UserSchemaToJSON(value?: UserSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'default_account': UserDefaultAccountSchemaToJSON(value.defaultAccount),
        'default_organization': UserDefaultOrganizationSchemaToJSON(value.defaultOrganization),
        'is_staff': value.isStaff,
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'profile': UserProfileSchemaToJSON(value.profile),
        'has_usable_password': value.hasUsablePassword,
        'email': value.email,
    };
}

