/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleAssumptionSetupSchema
 */
export interface RoleAssumptionSetupSchema {
    /**
     * 
     * @type {string}
     * @memberof RoleAssumptionSetupSchema
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAssumptionSetupSchema
     */
    allowedPrincipal: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAssumptionSetupSchema
     */
    trustPolicy: string;
}

/**
 * Check if a given object implements the RoleAssumptionSetupSchema interface.
 */
export function instanceOfRoleAssumptionSetupSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalId" in value;
    isInstance = isInstance && "allowedPrincipal" in value;
    isInstance = isInstance && "trustPolicy" in value;

    return isInstance;
}

export function RoleAssumptionSetupSchemaFromJSON(json: any): RoleAssumptionSetupSchema {
    return RoleAssumptionSetupSchemaFromJSONTyped(json, false);
}

export function RoleAssumptionSetupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleAssumptionSetupSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': json['external_id'],
        'allowedPrincipal': json['allowed_principal'],
        'trustPolicy': json['trust_policy'],
    };
}

export function RoleAssumptionSetupSchemaToJSON(value?: RoleAssumptionSetupSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.externalId,
        'allowed_principal': value.allowedPrincipal,
        'trust_policy': value.trustPolicy,
    };
}

