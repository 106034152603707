import ky from "ky";
import { camelizeKeys, getStandardHeaders } from "../../apiUtils";
import { datadogLogs } from "@datadog/browser-logs";
import { handleError } from "../../utils/errors";

type MetricKey = {
  mode?: string;
  type?: string;
  taskPrefixName?: string;
};
export type Metric = {
  metric: MetricKey;
  values: [string, number][];
};

type SearchParams = {
  query?: string;
  sort?: string;
  // Note: The API accepts timestamps in seconds, not milliseconds.
  start?: number;
  end?: number;
};

export const getClusterMetrics = async (
  accountSlug: string,
  clusterId: string,
  queryName: string,
  sort?: string,
  start?: number,
  end?: number,
): Promise<Metric[]> => {
  try {
    const searchParams: SearchParams = {
      query: queryName || "",
      sort: sort || "",
    };

    if (start) {
      searchParams.start = start;
    }

    if (end) {
      searchParams.end = end;
    }

    const buildPayload = await ky
      .get(`/api/v2/metrics/account/${accountSlug}/cluster/${clusterId}`, {
        headers: getStandardHeaders(),
        searchParams,
      })
      .json();
    const data = camelizeKeys(buildPayload) as Metric[];
    return data;
  } catch (err) {
    datadogLogs.logger.error(
      `Unable to get cluster metrics for cluster ${clusterId} in the workspace ${accountSlug} - Reason: ${err}`,
    );
    await handleError(err as Error);
    throw err;
  }
};
