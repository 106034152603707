/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AWSSecurityGroupCreateSchema
 */
export interface AWSSecurityGroupCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof AWSSecurityGroupCreateSchema
     */
    groupId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AWSSecurityGroupCreateSchema
     */
    forWorkers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AWSSecurityGroupCreateSchema
     */
    forSchedulers: boolean;
    /**
     * 
     * @type {string}
     * @memberof AWSSecurityGroupCreateSchema
     */
    securityGroupName?: string;
}

/**
 * Check if a given object implements the AWSSecurityGroupCreateSchema interface.
 */
export function instanceOfAWSSecurityGroupCreateSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "forWorkers" in value;
    isInstance = isInstance && "forSchedulers" in value;

    return isInstance;
}

export function AWSSecurityGroupCreateSchemaFromJSON(json: any): AWSSecurityGroupCreateSchema {
    return AWSSecurityGroupCreateSchemaFromJSONTyped(json, false);
}

export function AWSSecurityGroupCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSSecurityGroupCreateSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupId': !exists(json, 'group_id') ? undefined : json['group_id'],
        'forWorkers': json['for_workers'],
        'forSchedulers': json['for_schedulers'],
        'securityGroupName': !exists(json, 'security_group_name') ? undefined : json['security_group_name'],
    };
}

export function AWSSecurityGroupCreateSchemaToJSON(value?: AWSSecurityGroupCreateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.groupId,
        'for_workers': value.forWorkers,
        'for_schedulers': value.forSchedulers,
        'security_group_name': value.securityGroupName,
    };
}

