/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationsByType } from './DurationsByType';
import {
    DurationsByTypeFromJSON,
    DurationsByTypeFromJSONTyped,
    DurationsByTypeToJSON,
} from './DurationsByType';
import type { ExceptionSummary } from './ExceptionSummary';
import {
    ExceptionSummaryFromJSON,
    ExceptionSummaryFromJSONTyped,
    ExceptionSummaryToJSON,
} from './ExceptionSummary';
import type { FineMetricItem } from './FineMetricItem';
import {
    FineMetricItemFromJSON,
    FineMetricItemFromJSONTyped,
    FineMetricItemToJSON,
} from './FineMetricItem';
import type { StackCode } from './StackCode';
import {
    StackCodeFromJSON,
    StackCodeFromJSONTyped,
    StackCodeToJSON,
} from './StackCode';

/**
 * 
 * @export
 * @interface ComputationSummary
 */
export interface ComputationSummary {
    /**
     * 
     * @type {string}
     * @memberof ComputationSummary
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof ComputationSummary
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof ComputationSummary
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof ComputationSummary
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof ComputationSummary
     */
    stop?: number;
    /**
     * 
     * @type {Array<StackCode>}
     * @memberof ComputationSummary
     */
    callstackDeduped: Array<StackCode>;
    /**
     * 
     * @type {DurationsByType}
     * @memberof ComputationSummary
     */
    durations: DurationsByType;
    /**
     * 
     * @type {Array<ExceptionSummary>}
     * @memberof ComputationSummary
     */
    exceptions: Array<ExceptionSummary>;
    /**
     * 
     * @type {number}
     * @memberof ComputationSummary
     */
    nbytes: number;
    /**
     * 
     * @type {number}
     * @memberof ComputationSummary
     */
    nTasks: number;
    /**
     * 
     * @type {Array<FineMetricItem>}
     * @memberof ComputationSummary
     */
    fineMetricsMinor?: Array<FineMetricItem>;
    /**
     * 
     * @type {Array<FineMetricItem>}
     * @memberof ComputationSummary
     */
    fineMetricsMajor?: Array<FineMetricItem>;
}

/**
 * Check if a given object implements the ComputationSummary interface.
 */
export function instanceOfComputationSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "callstackDeduped" in value;
    isInstance = isInstance && "durations" in value;
    isInstance = isInstance && "exceptions" in value;
    isInstance = isInstance && "nbytes" in value;
    isInstance = isInstance && "nTasks" in value;

    return isInstance;
}

export function ComputationSummaryFromJSON(json: any): ComputationSummary {
    return ComputationSummaryFromJSONTyped(json, false);
}

export function ComputationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputationSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'level': json['level'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'stop': !exists(json, 'stop') ? undefined : json['stop'],
        'callstackDeduped': ((json['callstack_deduped'] as Array<any>).map(StackCodeFromJSON)),
        'durations': DurationsByTypeFromJSON(json['durations']),
        'exceptions': ((json['exceptions'] as Array<any>).map(ExceptionSummaryFromJSON)),
        'nbytes': json['nbytes'],
        'nTasks': json['n_tasks'],
        'fineMetricsMinor': !exists(json, 'fine_metrics_minor') ? undefined : ((json['fine_metrics_minor'] as Array<any>).map(FineMetricItemFromJSON)),
        'fineMetricsMajor': !exists(json, 'fine_metrics_major') ? undefined : ((json['fine_metrics_major'] as Array<any>).map(FineMetricItemFromJSON)),
    };
}

export function ComputationSummaryToJSON(value?: ComputationSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'label': value.label,
        'level': value.level,
        'start': value.start,
        'stop': value.stop,
        'callstack_deduped': ((value.callstackDeduped as Array<any>).map(StackCodeToJSON)),
        'durations': DurationsByTypeToJSON(value.durations),
        'exceptions': ((value.exceptions as Array<any>).map(ExceptionSummaryToJSON)),
        'nbytes': value.nbytes,
        'n_tasks': value.nTasks,
        'fine_metrics_minor': value.fineMetricsMinor === undefined ? undefined : ((value.fineMetricsMinor as Array<any>).map(FineMetricItemToJSON)),
        'fine_metrics_major': value.fineMetricsMajor === undefined ? undefined : ((value.fineMetricsMajor as Array<any>).map(FineMetricItemToJSON)),
    };
}

