/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const BillingMethod = {
    Empty: '',
    Stripe: 'stripe',
    Aws: 'aws',
    Invoice: 'invoice'
} as const;
export type BillingMethod = typeof BillingMethod[keyof typeof BillingMethod];


export function BillingMethodFromJSON(json: any): BillingMethod {
    return BillingMethodFromJSONTyped(json, false);
}

export function BillingMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingMethod {
    return json as BillingMethod;
}

export function BillingMethodToJSON(value?: BillingMethod | null): any {
    return value as any;
}

