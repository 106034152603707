/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FlagSchema } from './FlagSchema';
import {
    FlagSchemaFromJSON,
    FlagSchemaFromJSONTyped,
    FlagSchemaToJSON,
} from './FlagSchema';

/**
 * 
 * @export
 * @interface ClusterSummaryMetricsSchema
 */
export interface ClusterSummaryMetricsSchema {
    /**
     * 
     * @type {Array<FlagSchema>}
     * @memberof ClusterSummaryMetricsSchema
     */
    flags?: Array<FlagSchema>;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workersConnected?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    processingTasks?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    allIdle?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workersLoopBlocked?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    erredTasks?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workerCpuAvgLow?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    cluster20pctMemoryPressure?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workerIndividualHighMemory?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workerNetworkRecv?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    workerExternalNetwork?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryMetricsSchema
     */
    cluster20pctWrite?: number;
}

/**
 * Check if a given object implements the ClusterSummaryMetricsSchema interface.
 */
export function instanceOfClusterSummaryMetricsSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClusterSummaryMetricsSchemaFromJSON(json: any): ClusterSummaryMetricsSchema {
    return ClusterSummaryMetricsSchemaFromJSONTyped(json, false);
}

export function ClusterSummaryMetricsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterSummaryMetricsSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'flags': !exists(json, 'flags') ? undefined : ((json['flags'] as Array<any>).map(FlagSchemaFromJSON)),
        'workersConnected': !exists(json, 'workers_connected') ? undefined : json['workers_connected'],
        'processingTasks': !exists(json, 'processing_tasks') ? undefined : json['processing_tasks'],
        'allIdle': !exists(json, 'all_idle') ? undefined : json['all_idle'],
        'workersLoopBlocked': !exists(json, 'workers_loop_blocked') ? undefined : json['workers_loop_blocked'],
        'erredTasks': !exists(json, 'erred_tasks') ? undefined : json['erred_tasks'],
        'workerCpuAvgLow': !exists(json, 'worker_cpu_avg_low') ? undefined : json['worker_cpu_avg_low'],
        'cluster20pctMemoryPressure': !exists(json, 'cluster_20pct_memory_pressure') ? undefined : json['cluster_20pct_memory_pressure'],
        'workerIndividualHighMemory': !exists(json, 'worker_individual_high_memory') ? undefined : json['worker_individual_high_memory'],
        'workerNetworkRecv': !exists(json, 'worker_network_recv') ? undefined : json['worker_network_recv'],
        'workerExternalNetwork': !exists(json, 'worker_external_network') ? undefined : json['worker_external_network'],
        'cluster20pctWrite': !exists(json, 'cluster_20pct_write') ? undefined : json['cluster_20pct_write'],
    };
}

export function ClusterSummaryMetricsSchemaToJSON(value?: ClusterSummaryMetricsSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'flags': value.flags === undefined ? undefined : ((value.flags as Array<any>).map(FlagSchemaToJSON)),
        'workers_connected': value.workersConnected,
        'processing_tasks': value.processingTasks,
        'all_idle': value.allIdle,
        'workers_loop_blocked': value.workersLoopBlocked,
        'erred_tasks': value.erredTasks,
        'worker_cpu_avg_low': value.workerCpuAvgLow,
        'cluster_20pct_memory_pressure': value.cluster20pctMemoryPressure,
        'worker_individual_high_memory': value.workerIndividualHighMemory,
        'worker_network_recv': value.workerNetworkRecv,
        'worker_external_network': value.workerExternalNetwork,
        'cluster_20pct_write': value.cluster20pctWrite,
    };
}

