/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContainerAPISchema } from './ContainerAPISchema';
import {
    ContainerAPISchemaFromJSON,
    ContainerAPISchemaFromJSONTyped,
    ContainerAPISchemaToJSON,
} from './ContainerAPISchema';

/**
 * 
 * @export
 * @interface InstanceSpecSchema
 */
export interface InstanceSpecSchema {
    /**
     * 
     * @type {number}
     * @memberof InstanceSpecSchema
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSpecSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstanceSpecSchema
     */
    updated: Date;
    /**
     * 
     * @type {number}
     * @memberof InstanceSpecSchema
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecSchema
     */
    backendType: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceSpecSchema
     */
    privateIpAddress?: string;
    /**
     * 
     * @type {Array<ContainerAPISchema>}
     * @memberof InstanceSpecSchema
     */
    containers: Array<ContainerAPISchema>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InstanceSpecSchema
     */
    tags: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof InstanceSpecSchema
     */
    prioritizedInstanceTypes: Array<string>;
}

/**
 * Check if a given object implements the InstanceSpecSchema interface.
 */
export function instanceOfInstanceSpecSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "backendType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "containers" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "prioritizedInstanceTypes" in value;

    return isInstance;
}

export function InstanceSpecSchemaFromJSON(json: any): InstanceSpecSchema {
    return InstanceSpecSchemaFromJSONTyped(json, false);
}

export function InstanceSpecSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSpecSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'accountId': json['account_id'],
        'backendType': json['backend_type'],
        'name': json['name'],
        'privateIpAddress': !exists(json, 'private_ip_address') ? undefined : json['private_ip_address'],
        'containers': ((json['containers'] as Array<any>).map(ContainerAPISchemaFromJSON)),
        'tags': json['tags'],
        'prioritizedInstanceTypes': json['prioritized_instance_types'],
    };
}

export function InstanceSpecSchemaToJSON(value?: InstanceSpecSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'account_id': value.accountId,
        'backend_type': value.backendType,
        'name': value.name,
        'private_ip_address': value.privateIpAddress,
        'containers': ((value.containers as Array<any>).map(ContainerAPISchemaToJSON)),
        'tags': value.tags,
        'prioritized_instance_types': value.prioritizedInstanceTypes,
    };
}

