import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CoiledLogo = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 514.03 688.7"
      {...props}
    >
      <defs>
        <style>.cls-1</style>
      </defs>
      <path
        className="cls-1"
        d="m267.13,391.36c-87.02.84-158.24-69.03-159.08-156.05-.84-87.02,69.03-158.24,156.05-159.08h3.03v315.12Z"
      />
      <path
        className="cls-1"
        d="m396.91,435.14c0-64.94-39.82-123.22-100.33-146.81v293.6c60.49-23.59,100.33-81.85,100.33-146.79Z"
      />
      <path
        className="cls-1"
        d="m296.8,206.06c56.91,0,103.03-46.1,103.05-103.01C399.85,46.14,353.74.02,296.84,0h-.04v206.06Z"
      />
      <path
        className="cls-1"
        d="m194.13,566.24c-.04,50.34,28.09,96.48,72.87,119.5v-238.96c-44.75,23.01-72.87,69.12-72.87,119.44v.02Z"
      />
    </SvgIcon>
  );
};
