import { useContext } from "react";
import { Membership } from "../crud/user/types";
import { WorkspaceContext } from "../utils/context";
import { WorkspaceSchema } from "../api-client";

const mustDoSetup = (account: WorkspaceSchema): boolean => {
  return !(account.canUseCoiledHosted || account.configuredCustomerHosted);
};

export const useMustDoSetup = (): boolean => {
  const viewedAccount = useContext(WorkspaceContext);
  return mustDoSetup(viewedAccount);
};

export const userIsAdmin = (
  memberships: Membership[],
  accountSlug: string,
): boolean => {
  const mem = memberships.find(({ account: { slug } }) => slug === accountSlug);
  if (!mem) return false;
  return mem.isAdmin;
};

export const userIsAdminById = (
  memberships: Membership[],
  accountId: number,
): boolean => {
  const mem = memberships.find(({ account: { id } }) => id === accountId);
  if (!mem) return false;
  return mem.isAdmin;
};
