/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSchema
 */
export interface RegisterSchema {
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    schedulerId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    account?: string;
    /**
     * 
     * @type {object}
     * @memberof RegisterSchema
     */
    versions: object;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    pythonVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    operatingSystem: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    pythonExecutable: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchema
     */
    architecture: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterSchema
     */
    idleTimeout?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof RegisterSchema
     */
    extensions?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof RegisterSchema
     */
    preloads?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof RegisterSchema
     */
    schedulerPlugins?: Array<any>;
}

/**
 * Check if a given object implements the RegisterSchema interface.
 */
export function instanceOfRegisterSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "schedulerId" in value;
    isInstance = isInstance && "versions" in value;
    isInstance = isInstance && "pythonVersion" in value;
    isInstance = isInstance && "operatingSystem" in value;
    isInstance = isInstance && "hostname" in value;
    isInstance = isInstance && "pythonExecutable" in value;
    isInstance = isInstance && "architecture" in value;

    return isInstance;
}

export function RegisterSchemaFromJSON(json: any): RegisterSchema {
    return RegisterSchemaFromJSONTyped(json, false);
}

export function RegisterSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedulerId': json['scheduler_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'versions': json['versions'],
        'pythonVersion': json['python_version'],
        'operatingSystem': json['operating_system'],
        'hostname': json['hostname'],
        'pythonExecutable': json['python_executable'],
        'architecture': json['architecture'],
        'idleTimeout': !exists(json, 'idle_timeout') ? undefined : json['idle_timeout'],
        'extensions': !exists(json, 'extensions') ? undefined : json['extensions'],
        'preloads': !exists(json, 'preloads') ? undefined : json['preloads'],
        'schedulerPlugins': !exists(json, 'scheduler_plugins') ? undefined : json['scheduler_plugins'],
    };
}

export function RegisterSchemaToJSON(value?: RegisterSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduler_id': value.schedulerId,
        'account': value.account,
        'versions': value.versions,
        'python_version': value.pythonVersion,
        'operating_system': value.operatingSystem,
        'hostname': value.hostname,
        'python_executable': value.pythonExecutable,
        'architecture': value.architecture,
        'idle_timeout': value.idleTimeout,
        'extensions': value.extensions,
        'preloads': value.preloads,
        'scheduler_plugins': value.schedulerPlugins,
    };
}

