import { useQueryParam } from "use-query-params";
import { StringParam, withDefault } from "serialize-query-params";
import ky from "ky";
import { getStandardHeaders } from "../../apiUtils";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/auth.lottie.json";

import {
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Box,
  Typography,
  Link,
  Slide,
  MenuItem,
  Toolbar,
  Menu,
  ListItemIcon,
  ListItemText,
  Container,
} from "@mui/material";
import { Page } from "../../shared-components/Page";
import { ExpandMore, Warning } from "@mui/icons-material";
import { useMutation } from "react-query";
import { LoadingButton } from "@mui/lab";
import { ThemedAppBar } from "../../Layouts/components";
import { UserContext } from "../../crud/user/context";
import { LogoutIcon } from "../../icons/LogoutIcon";
import { CoiledLogo } from "../../icons/CoiledLogo";
import { useLogoutMutation } from "../../crud/auth/hooks";
import { useCookie } from "../../utils/hooks";
import { Urls } from "../../domain/urls";
import { VIEWEDACCOUNT_COOKIE } from "../../cookieUtils";

const makeTokenActivationRequest = async (
  tokenId: string,
  signal?: AbortSignal,
): Promise<boolean> => {
  const result = await ky.patch(`api/v1/api-tokens/${tokenId}/activate/`, {
    headers: getStandardHeaders(),
    signal,
  });
  await result.json<any>();
  return true;
};

export const ActivateToken = (): React.ReactElement => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();
  const [, setViewedAccountCookie] = useCookie(VIEWEDACCOUNT_COOKIE);

  const [tokenIdentifierShort] = useQueryParam(
    "id",
    withDefault(StringParam, ""),
  );
  const [tokenIdentifierLong] = useQueryParam(
    "identifier",
    withDefault(StringParam, ""),
  );
  const [fadeout, setFadeout] = React.useState(false);
  const { mutate, isLoading, isError, isSuccess, isIdle } = useMutation(
    makeTokenActivationRequest,
  );
  const tokenIdentifier = tokenIdentifierLong || tokenIdentifierShort || "";
  const getTitle = () => {
    if (!tokenIdentifier) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Warning color="error" />
          <span>Token Missing</span>
        </Stack>
      );
    }
    if (isIdle) {
      return "Confirm Access Request";
    }
    if (isLoading) {
      return "Authorizing Access..";
    }
    if (isError) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Warning color="error" />
          <span>Something went wrong...</span>
        </Stack>
      );
    }
    if (isSuccess) {
      return "Authorized";
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page title="Activate" breadCrumbs={[{ text: "Activate API Token" }]}>
      <Stack spacing={2} alignItems={"center"}>
        <ThemedAppBar position="static">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <CoiledLogo />
            <Button
              size="small"
              onClick={handleClick}
              variant="text"
              sx={{ textTransform: "none" }}
              endIcon={<ExpandMore />}
            >
              {user.email}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                dense
                onClick={() => {
                  setViewedAccountCookie(undefined);
                  logoutMutation.mutate({});
                  navigate(`/${Urls.Login}`);
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Toolbar>
        </ThemedAppBar>
        <Container maxWidth="sm" fixed>
          <Card sx={{ minHeight: "20vh" }}>
            <CardHeader title={getTitle()} />
            <Divider />
            <CardContent
              sx={{
                height: "auto",
                overflow: "hidden",
              }}
            >
              {!tokenIdentifier && (
                <Stack spacing={1}>
                  <Typography>
                    The token identifer is missing. Some terminals will strip it
                    out of the link.
                  </Typography>
                  <Typography>A proper link should look like this:</Typography>
                  <Typography fontFamily="monospace">
                    https://cloud.coiled.io/activate-token?id=c3dc3f798db94a6787eea8b8ba62d77a
                  </Typography>
                </Stack>
              )}
              {tokenIdentifier && (
                <>
                  <Slide
                    in={fadeout && (isSuccess || isError)}
                    unmountOnExit
                    direction="left"
                  >
                    <Stack
                      spacing={2}
                      alignItems={"center"}
                      justifyItems={"center"}
                      alignContent={"center"}
                    >
                      {isSuccess && (
                        <>
                          <Lottie
                            animationData={animationData}
                            loop={false}
                            style={{ width: "30%" }}
                          />
                          <Typography>
                            Authentication successful, you can close this window
                            now.
                          </Typography>
                        </>
                      )}
                      {isError && (
                        <>
                          <Typography>
                            Try again or contact{" "}
                            <Link href="mailto:support@coiled.io">
                              support@coiled.io.
                            </Link>
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Slide>
                  <Slide
                    direction={"right"}
                    in={isIdle || isLoading}
                    appear={false}
                    unmountOnExit
                    onExited={() => setFadeout(true)}
                  >
                    <Stack alignItems={"left"} spacing={2}>
                      <Typography>
                        A client application is requesting access act as you on
                        Coiled.
                      </Typography>
                      <Typography>
                        Confirm the validation code matches the one displayed in
                        your terminal before accepting.
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: 1,
                          padding: "15px",
                          display: "flex",
                          border: "1px solid",
                          bgcolor: (theme) => theme.palette.grey[200],
                          justifyContent: "center",
                        }}
                      >
                        {tokenIdentifier}
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"flex-end"}
                        >
                          <Button
                            component={RouterLink}
                            variant="secondary"
                            to={"/"}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            loading={isLoading}
                            variant="primary"
                            onClick={() => mutate(tokenIdentifier)}
                          >
                            Authorize Access
                          </LoadingButton>
                        </Stack>
                      </Box>
                    </Stack>
                  </Slide>
                </>
              )}
            </CardContent>
          </Card>
        </Container>
      </Stack>
    </Page>
  );
};
