import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ChangePassword } from "../../auth/ChangePassword";

export const PasswordChangeform = (): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button size="small" onClick={handleOpen} variant="secondary">
        Change
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        closeAfterTransition
      >
        <div>
          <ChangePassword onSuccessCallBack={handleClose} />
        </div>
      </Modal>
    </div>
  );
};
