import React, { useContext, useEffect, useMemo, useState } from "react";

import { useValidScopes } from "../crud/user/hooks";
import {
  AccountScopeSchema,
  AccountScopeSchemaTypeEnum,
  CompoundScopeSchema,
  CompoundScopeSchemaTypeEnum,
  CompoundScopes,
  OrganizationScopeSchema,
  OrganizationScopeSchemaTypeEnum,
  UserScopeSchema,
  UserScopeSchemaTypeEnum,
  StaffScopeSchema,
  StaffScopeSchemaTypeEnum,
} from "../api-client";
import { useDebounce } from "usehooks-ts";
import { UserContext } from "../crud/user/context";
import { SelectAutocomplete } from "./SelectAutocomplete";
import { Box } from "@mui/material";

export type ValidScopes =
  | OrganizationScopeSchema
  | AccountScopeSchema
  | CompoundScopeSchema
  | UserScopeSchema
  | StaffScopeSchema;

type DisplayScope = ValidScopes & {
  indent?: boolean;
};

type ScopeContext = [
  ValidScopes,
  (scope: ValidScopes, keepHistory?: boolean) => void,
];

const ScopedContext = React.createContext<ScopeContext>([
  {
    type: CompoundScopeSchemaTypeEnum.Compound,
    name: CompoundScopes.Everything,
  },
  () => {},
]);

export const useScopedContext = (): ScopeContext => {
  return React.useContext(ScopedContext);
};

export const ScopedContextProvider = (props: {
  children: React.ReactNode;
  value: ScopeContext;
}): React.ReactElement => {
  return (
    <ScopedContext.Provider value={props.value}>
      {props.children}
    </ScopedContext.Provider>
  );
};

const getHumanName = (scope: ValidScopes): string => {
  switch (scope.type) {
    case AccountScopeSchemaTypeEnum.Account:
      return `Workspace: ${scope.name}`;
    case OrganizationScopeSchemaTypeEnum.Organization:
      return `Organization: ${scope.name}`;
    case UserScopeSchemaTypeEnum.User:
      return `User: ${scope.name}`;
    default:
      return scope.name;
  }
};

const getElementForScope = (scope: ValidScopes): React.ReactElement => {
  switch (scope.type) {
    case AccountScopeSchemaTypeEnum.Account:
      return (
        <>
          Workspace:{" "}
          <Box
            component="span"
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            {scope.name}
          </Box>
        </>
      );
    case OrganizationScopeSchemaTypeEnum.Organization:
      return (
        <>
          Organization:{" "}
          <Box
            component="span"
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            {scope.name}
          </Box>
        </>
      );
    case UserScopeSchemaTypeEnum.User:
      return (
        <>
          User:{" "}
          <Box
            component="span"
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            {scope.name}
          </Box>
          {scope.email && (
            <Box
              component="span"
              sx={{ fontSize: "small", textTransform: "none" }}
            >
              {" "}
              ({scope.email})
            </Box>
          )}
        </>
      );
    default:
      return <>{scope.name}</>;
  }
};

type ScopeSelectorProps = {
  onChange?: (scope: ValidScopes) => void;
};

export const ScopeSelector = ({
  onChange,
}: ScopeSelectorProps): React.ReactElement => {
  const user = useContext(UserContext);
  const [scope, setScope] = useScopedContext();
  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const debouncedAutocompleteValue = useDebounce(autocompleteValue, 150);
  // hit backend to get scopes to show based on search value
  const {
    data: scopes,
    isLoading,
    isSuccess,
  } = useValidScopes({
    account: debouncedAutocompleteValue,
    organization: debouncedAutocompleteValue,
    user: debouncedAutocompleteValue,
  });

  useEffect(() => {
    if (isSuccess && !user.user.isStaff) {
      // if scope is not in list of valid scopes, clear it
      // this only applies to staff, so we don't need to explicitly include scopes.customStaffScopes
      const scopesToCheck = [
        ...(scopes?.accounts || []),
        ...(scopes?.organizations || []),
        ...(scopes?.users || []),
        ...(scopes?.compoundScopes || []),
      ];

      if (
        scopes &&
        scope &&
        !scopesToCheck.find(
          (s) => s.name === scope.name && s.type === scope.type,
        )
      ) {
        if (onChange) onChange(scopes.compoundScopes[0]);
        setScope(scopes.compoundScopes[0]); // reset to "Everything" scope
      }
    }
  }, [scopes, scope, onChange, isSuccess, user.user.isStaff, setScope]);

  // generate the sorted list of scopes to show
  const options = useMemo<DisplayScope[]>(() => {
    const accounts = scopes?.accounts || [];
    const organizations = scopes?.organizations || [];
    const compoundScopes = scopes?.compoundScopes || [];
    const userScopes = scopes?.users || [];
    const staffScopes = scopes?.customStaffScopes || [];

    // first compound scopes, then custom staff scopes
    const merged = [...compoundScopes, ...staffScopes] as DisplayScope[];
    // then sort workspace scopes under their org, if org is in list of scopes to show
    for (let i = 0; i < organizations.length; i++) {
      merged.push(organizations[i]);
      for (let j = 0; j < accounts.length; j++) {
        if (accounts[j].organizationId === organizations[i].id) {
          merged.push({ ...accounts[j], indent: true });
        }
      }
    }
    // then workspaces where the org is not included in list of scopes to show
    for (let j = 0; j < accounts.length; j++) {
      if (
        !accounts[j].organizationId ||
        organizations.findIndex((o) => o.id === accounts[j].organizationId) ===
          -1
      ) {
        merged.push(accounts[j]);
      }
    }
    // finally, user scopes
    for (let i = 0; i < userScopes.length; i++) {
      merged.push(userScopes[i]);
    }
    return merged;
  }, [scopes]);

  return (
    <SelectAutocomplete
      options={options}
      value={scope}
      onChange={(value) => {
        if (onChange) onChange(value);
        setScope(value);
      }}
      getOptionLabel={getHumanName}
      renderOption={(option: DisplayScope, forSelect) => {
        const shouldIndent = !!option.indent;
        return (
          <span style={{ marginLeft: shouldIndent ? "1em" : undefined }}>
            {getElementForScope(option)}
          </span>
        );
      }}
      isLoading={isLoading}
      filterValueChanged={setAutocompleteValue}
      placeHolder={"Search for a scope"}
      groupBy={(option) => {
        if (option.type === CompoundScopeSchemaTypeEnum.Compound) {
          return "Quick Scopes";
        } else if (option.type === StaffScopeSchemaTypeEnum.CustomStaffList) {
          return "Custom Group Scopes (staff)";
        } else {
          return "Specific Scopes";
        }
      }}
    />
  );
};
