/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackageApproxResult
 */
export interface PackageApproxResult {
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    condaName?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    specifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageApproxResult
     */
    include: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    channelUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageApproxResult
     */
    clientVersion?: string;
}

/**
 * Check if a given object implements the PackageApproxResult interface.
 */
export function instanceOfPackageApproxResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "include" in value;

    return isInstance;
}

export function PackageApproxResultFromJSON(json: any): PackageApproxResult {
    return PackageApproxResultFromJSONTyped(json, false);
}

export function PackageApproxResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageApproxResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'condaName': !exists(json, 'conda_name') ? undefined : json['conda_name'],
        'specifier': !exists(json, 'specifier') ? undefined : json['specifier'],
        'include': json['include'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'channelUrl': !exists(json, 'channel_url') ? undefined : json['channel_url'],
        'clientVersion': !exists(json, 'client_version') ? undefined : json['client_version'],
    };
}

export function PackageApproxResultToJSON(value?: PackageApproxResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'conda_name': value.condaName,
        'specifier': value.specifier,
        'include': value.include,
        'note': value.note,
        'error': value.error,
        'channel_url': value.channelUrl,
        'client_version': value.clientVersion,
    };
}

