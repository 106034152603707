/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GCPServiceAccountSchema
 */
export interface GCPServiceAccountSchema {
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    privateKeyId: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    privateKey: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    authUri: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    tokenUri: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    clientEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    authProviderX509CertUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GCPServiceAccountSchema
     */
    clientX509CertUrl: string;
}

/**
 * Check if a given object implements the GCPServiceAccountSchema interface.
 */
export function instanceOfGCPServiceAccountSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "privateKeyId" in value;
    isInstance = isInstance && "privateKey" in value;
    isInstance = isInstance && "authUri" in value;
    isInstance = isInstance && "tokenUri" in value;
    isInstance = isInstance && "clientEmail" in value;
    isInstance = isInstance && "authProviderX509CertUrl" in value;
    isInstance = isInstance && "clientX509CertUrl" in value;

    return isInstance;
}

export function GCPServiceAccountSchemaFromJSON(json: any): GCPServiceAccountSchema {
    return GCPServiceAccountSchemaFromJSONTyped(json, false);
}

export function GCPServiceAccountSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GCPServiceAccountSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'clientId': json['client_id'],
        'projectId': json['project_id'],
        'privateKeyId': json['private_key_id'],
        'privateKey': json['private_key'],
        'authUri': json['auth_uri'],
        'tokenUri': json['token_uri'],
        'clientEmail': json['client_email'],
        'authProviderX509CertUrl': json['auth_provider_x509_cert_url'],
        'clientX509CertUrl': json['client_x509_cert_url'],
    };
}

export function GCPServiceAccountSchemaToJSON(value?: GCPServiceAccountSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'client_id': value.clientId,
        'project_id': value.projectId,
        'private_key_id': value.privateKeyId,
        'private_key': value.privateKey,
        'auth_uri': value.authUri,
        'token_uri': value.tokenUri,
        'client_email': value.clientEmail,
        'auth_provider_x509_cert_url': value.authProviderX509CertUrl,
        'client_x509_cert_url': value.clientX509CertUrl,
    };
}

