/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirewallRuleTarget } from './FirewallRuleTarget';
import {
    FirewallRuleTargetFromJSON,
    FirewallRuleTargetFromJSONTyped,
    FirewallRuleTargetToJSON,
} from './FirewallRuleTarget';

/**
 * 
 * @export
 * @interface FirewallSpec
 */
export interface FirewallSpec {
    /**
     * 
     * @type {Array<FirewallRuleTarget>}
     * @memberof FirewallSpec
     */
    ingress: Array<FirewallRuleTarget>;
}

/**
 * Check if a given object implements the FirewallSpec interface.
 */
export function instanceOfFirewallSpec(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ingress" in value;

    return isInstance;
}

export function FirewallSpecFromJSON(json: any): FirewallSpec {
    return FirewallSpecFromJSONTyped(json, false);
}

export function FirewallSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirewallSpec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingress': ((json['ingress'] as Array<any>).map(FirewallRuleTargetFromJSON)),
    };
}

export function FirewallSpecToJSON(value?: FirewallSpec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingress': ((value.ingress as Array<any>).map(FirewallRuleTargetToJSON)),
    };
}

