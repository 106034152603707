import React from "react";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material/";
import {
  useUpdateWorkspaceMutation,
  useWorkspaces,
} from "../../crud/workspaces/hooks";
import { WorkspaceTeamGrid } from "./WorkspaceTeamGrid";
import { ExpandableCard } from "../../shared-components/Cards";
import { Save } from "@mui/icons-material";
import { WorkspaceLightWithMembershipSchema } from "../../api-client";
import { useForm } from "react-hook-form";
import { useIsAccountAdmin, useIsOrgAdmin } from "../../crud/account/hooks";
import { useSnackbar } from "notistack";

const WorkspaceCard = ({
  workspace,
  forceOpen,
  organizationId,
}: {
  workspace: WorkspaceLightWithMembershipSchema;
  forceOpen?: boolean;
  organizationId: number;
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<WorkspaceLightWithMembershipSchema>({
    defaultValues: workspace,
  });
  const { mutate: updateWorkspace } = useUpdateWorkspaceMutation();
  const isAdmin = useIsAccountAdmin(workspace.slug);
  const isOrgAdmin = useIsOrgAdmin(organizationId);
  const canEdit = isAdmin || isOrgAdmin;
  return (
    <ExpandableCard
      key={workspace.id}
      isOpen={forceOpen}
      iconAlign="left"
      sx={{
        borderRadius: 1,
        borderColor: (theme) => theme.palette.custom.grey.warm,
      }}
      title={
        <Stack direction="row" width="100%">
          <Tooltip title={workspace.name}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                src={workspace.avatarUrl}
                alt={workspace.name}
                sx={{ width: 24, height: 24, fontSize: "1rem" }}
              />
              <Typography sx={{ textTransform: "none" }}>
                <strong>{workspace.name}</strong>
              </Typography>
            </Stack>
          </Tooltip>
          <div style={{ marginLeft: "auto" }}>
            <form
              onSubmit={handleSubmit(
                (data) => {
                  updateWorkspace(
                    {
                      workspaceId: workspace.id,
                      updateWorkspaceSchema: {
                        creditLimit: data.creditLimit,
                      },
                    },
                    {
                      onSuccess: (result) => {
                        reset(result);
                      },
                      onError: async (error) => {
                        const { message } = await error.response.json();
                        enqueueSnackbar(message, {
                          variant: "error",
                        });
                      },
                    },
                  );
                },
                (validationErrors) => {
                  if (validationErrors.creditLimit)
                    enqueueSnackbar(validationErrors.creditLimit.message, {
                      variant: "error",
                    });
                },
              )}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ marginLeft: "auto" }}
              >
                <TextField
                  size="small"
                  disabled={!canEdit}
                  label="Credit Limit"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  error={!!errors.creditLimit}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  {...register("creditLimit", {
                    valueAsNumber: true,
                    required: "A credit limit is required",
                    min: {
                      value: 0,
                      message:
                        "Credit limit must be greater than or equal to 0",
                    },
                  })}
                />

                {canEdit && (
                  <IconButton
                    type="submit"
                    disabled={!isDirty}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Save
                      fontSize="small"
                      color={isDirty ? "primary" : "disabled"}
                    />
                  </IconButton>
                )}
              </Stack>
            </form>
          </div>
        </Stack>
      }
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <WorkspaceTeamGrid workspaceId={workspace.id} />
      </Box>
    </ExpandableCard>
  );
};

export const WorkspacesList = ({
  organizationId,
}: {
  organizationId: number;
}): React.ReactElement => {
  const { data: workspaces, isSuccess } = useWorkspaces({ organizationId });
  return (
    <Stack spacing={2}>
      {isSuccess && workspaces.length === 0 && (
        <Typography
          variant="h1"
          sx={{ alignSelf: "center" }}
          color="GrayText"
          paddingTop={10}
        >
          No workspaces have been created.
        </Typography>
      )}
      {isSuccess &&
        workspaces.length > 0 &&
        workspaces?.map((workspace) => (
          <WorkspaceCard
            key={workspace.id}
            workspace={workspace}
            forceOpen
            organizationId={organizationId}
          />
        ))}
      {!isSuccess && <Typography>Loading workspaces...</Typography>}
    </Stack>
  );
};
